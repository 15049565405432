import React, { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import { Input, Button, Form, Segment, Icon, Popup, Table, Checkbox, Confirm } from "semantic-ui-react";
import { toast } from "react-toastify";
import { fetchApi, getErrorsString } from "../common/fetchApi";
import { Clipboard } from "../components/Clipboard";
import _ from "underscore";
import "./Api.css";

export function Api(props) {
  const { t } = useTranslation();
  const defaultApiKey = {
    privateApiKey: "",
    maxRequestsPerMinute: 0,
    maxRequestsPerHour: 0,
    maxRequestsPerDay: 0,
    maxRequestsPerWeek: 0,
    maxRequestsPerMonth: 0,
    profileImage: null,
    authorizations: []
  };
  const [loading, setLoading] = useState(true);
  const [apiKey, setApiKey] = useState(defaultApiKey);
  const [ipAddress, setIpAddress] = useState(null);
  const [cidrMask, setCIDRMask] = useState("");
  const [isNewAuthorizationVisible, setIsNewAuthorizationVisible] = useState(false);
  const [confirmIsOpen, setConfirmIsOpen] = useState(false);
  const [confirmContent, setConfirmContent] = useState("Are you sure?");
  const [confirmAction, setConfirmAction] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUser();

    function fetchUser() {
      setLoading(true);
      fetchApi(`api/account`).then((response) => {
        const { data } = response;
        if (data) {
          setApiKey(data.apiKey || defaultApiKey);
          setIpAddress(data.ipAddress);
          setLoading(false);
        }
      });
    }
  }, []);

  const createOrUpdateApiKey = (e) => {
    e.preventDefault();
    e.stopPropagation();

    fetchApi(`api/apiKey`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      }
    }).then((response) => {
      if (response.responseObject.ok) {
        const { data } = response;
        setApiKey(data);

        if (apiKey && apiKey.privateApiKey.length > 0) toast.success("Api key regenerated!");
        else toast.success("Api key created!");
      } else {
        console.error(response.data);
        toast.error(response.data);
      }
    });
  };

  const createAuthorization = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const request = {
      cidrMask: cidrMask,
      isEnabled: true
    };
    fetchApi(`api/apiKey/authorization`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(request)
    }).then((response) => {
      if (response.responseObject.ok) {
        const { data } = response;

        const newAuthorizations = [...apiKey.authorizations];
        newAuthorizations.push(data);
        setApiKey({ ...apiKey, authorizations: newAuthorizations });
        setCIDRMask("");
        toast.success(`Added authorization for ${data.cidrMask}`);
        toggleCreateRestriction(e);
      } else {
        console.error(response.data);
        toast.error(response.data);
      }
    });
  };

  const updateAuthorization = (e, row) => {
    e.preventDefault();
    e.stopPropagation();

    const request = {
      apiKeyAuthorizationId: row.apiKeyAuthorizationId,
      cidrMask: row.cidrMask,
      isEnabled: row.isEnabled
    };
    fetchApi(`api/apiKey/authorization`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(request)
    }).then((response) => {
      if (response.responseObject.ok) {
        const { data } = response;
        //setApiKey(data);
      } else {
        console.error(response.data);
        toast.error(response.data);
      }
    });
  };

  const deleteAuthorization = (e, row) => {
    e.preventDefault();
    e.stopPropagation();

    const request = {
      apiKeyAuthorizationId: row.apiKeyAuthorizationId,
      cidrMask: row.cidrMask,
      isEnabled: row.isEnabled
    };
    fetchApi(`api/apiKey/authorization`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(request)
    }).then((response) => {
      if (response.responseObject.ok) {
        const { data } = response;
        if (data) {
          const newAuthorizations = _.filter(apiKey.authorizations, (item) => item.apiKeyAuthorizationId !== row.apiKeyAuthorizationId);
          setApiKey({ ...apiKey, authorizations: newAuthorizations });
          toast.success(`Removed authorization for ${row.cidrMask}`);
        } else {
          toast.error(`Failed to remove authorization`);
        }
      } else {
        console.error(response.data);
        toast.error(response.data);
      }
    });
  };

  const handleAuthorizationEnabledChange = (e, control, row) => {
    const newAuthorizations = [...apiKey.authorizations];
    const rowEntry = _.find(newAuthorizations, (i) => i.apiKeyAuthorizationId === row.apiKeyAuthorizationId);
    rowEntry[control.name] = control.checked;
    setApiKey({ ...apiKey, authorizations: newAuthorizations });
    updateAuthorization(e, rowEntry);
  };

  const handleChange = (e, control) => {
    apiKey[control.name] = control.value;
  };

  const handleCIDRChange = (e, control) => {
    setCIDRMask(control.value);
  };

  const toggleCreateRestriction = (e) => {
    setIsNewAuthorizationVisible(!isNewAuthorizationVisible);
  };

  const openConfirm = (e, content, action) => {
    e.preventDefault();
    e.stopPropagation();

    setConfirmContent(content);
    setConfirmAction(() => action); // required for storing the action in state
    setConfirmIsOpen(true);
  };

  const invokeConfirm = () => {
    confirmAction();
    setConfirmIsOpen(false);
  };

  const handleGenerateApiKey = (e) => {
    if (apiKey !== null && apiKey.privateApiKey !== null && apiKey.privateApiKey.length > 0) {
      const message = (
        <div style={{ padding: "50px", textAlign: "center" }}>
          Are you sure you want to regenerate your key?
          <br />
          <br />
          Your previous key will no longer work.
        </div>
      );
      openConfirm(e, message, () => createOrUpdateApiKey(e));
    } else {
      createOrUpdateApiKey(e);
    }
  };

  return (
    <div>
      <h1>Api Access</h1>
      <p>Manage your access to the Swarm api.</p>
      <p>
        Full documentation for the api can be found at <a href="https://swarm.binner.io">https://swarm.binner.io</a>
      </p>
      <Confirm open={confirmIsOpen} content={confirmContent} onCancel={() => setConfirmIsOpen(false)} onConfirm={() => invokeConfirm()} />
      <Segment loading={loading} secondary>
        <Form>
          <Popup
            hideOnScroll
            content="This is your private api key for the Swarm Api."
            trigger={
              <Form.Input label="Swarm Api Key" iconPosition="left" value={apiKey.privateApiKey || ""} name="apiKey" className="noteditable">
                <Icon name="key" />
                <input />
								<span style={{marginTop: '4px', marginLeft: '5px', marginRight: '50px'}}><Clipboard color="blue" size="big" text={apiKey.privateApiKey} /></span>
                <Button secondary onClick={handleGenerateApiKey} style={{ marginLeft: "0px" }}>
                  <Icon name="refresh" /> {apiKey.privateApiKey.length > 0 ? "Regenerate" : "Generate"}
                </Button>
              </Form.Input>
            }
          />

          <div className="field">
            <label>Request limits</label>
            <Table className="request-limits">
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <label>per minute:</label> {apiKey.maxRequestsPerMinute}
                  </Table.Cell>
                  <Table.Cell>
                    <label>per hour:</label> {apiKey.maxRequestsPerHour}
                  </Table.Cell>
                  <Table.Cell>
                    <label>per day:</label> {apiKey.maxRequestsPerDay}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <label>per week:</label> {apiKey.maxRequestsPerWeek}
                  </Table.Cell>
                  <Table.Cell>
                    <label>per month:</label> {apiKey.maxRequestsPerMonth}
                  </Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>

          <div className="field">
            <label>Security Restrictions - <i>optional</i></label>
            <p>These settings allow you to restrict usage of your api key to a certain IP/range.</p>
            <div style={{ marginBottom: "5px", float: "right" }}>
              <Button size="mini" onClick={toggleCreateRestriction} style={{ marginLeft: "10px" }}>
                <Icon name="add" /> Create Restriction
              </Button>
            </div>
            <Table className="request-limits">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>CIDR Network Mask</Table.HeaderCell>
                  <Table.HeaderCell>Enabled</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row key="new" style={{ display: isNewAuthorizationVisible ? "table-row" : "none", backgroundColor: "#eee" }}>
                  <Table.Cell>
                    <Popup
                      hideOnScroll
                      content={
                        <div>
                          Enter a network or address in CIDR notation. <i>Examples:</i>
                          <br />
                          192.168.1.50
                          <br />
                          192.168.1.0/24
                          <br />
                          192.168.1.50/32
                          <br />
                        </div>
                      }
                      trigger={<Input placeholder="192.168.1.0/24, 192.168.1.1/32" name="cidrMask" value={cidrMask} onChange={handleCIDRChange} />}
                    />
                    <span className="small">Your current ip address: {ipAddress}</span>
                  </Table.Cell>
                  <Table.Cell>&nbsp;</Table.Cell>
                  <Table.Cell textAlign="right">
                    <Button size="tiny" primary onClick={createAuthorization} disabled={!(cidrMask.length > 0)}>
                      <Icon name="save" /> Save
                    </Button>
                  </Table.Cell>
                </Table.Row>
                {apiKey && apiKey.authorizations && apiKey.authorizations.length > 0 ? (
                  apiKey.authorizations.map((row, key) => (
                    <Table.Row key={key}>
                      <Table.Cell>{row.cidrMask}</Table.Cell>
                      <Table.Cell>
                        <Checkbox
                          toggle
                          name="isEnabled"
                          checked={row.isEnabled}
                          onChange={(e, control) => handleAuthorizationEnabledChange(e, control, row)}
                        />
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Link
                          to=""
                          onClick={(e) => openConfirm(e, "Are you sure you want to delete this security restriction?", () => deleteAuthorization(e, row))}
                        >
                          Delete
                        </Link>
                      </Table.Cell>
                    </Table.Row>
                  ))
                ) : (
                  <Table.Row>
                    <Table.Cell colSpan={2} textAlign="center">
                      No restrictions are configured.<br/>
                      While configuring IP restrictions is not required, it is <i>recommended</i>.
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </div>
        </Form>
      </Segment>
    </div>
  );
}
