import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Segment, Breadcrumb, Image, Icon } from "semantic-ui-react";
import "./help.css";

export const ApiIntegrations = () => {
  const navigate = useNavigate();
  return (
    <div className="help">
      <Breadcrumb>
        <Breadcrumb.Section link onClick={() => navigate("/")}>
          Home
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section link onClick={() => navigate("/help")}>
          Help
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>Api Integrations</Breadcrumb.Section>
      </Breadcrumb>
      <h1>How to configure API Integrations</h1>

      <Segment raised>
        <h3>Overview</h3>
        <p>
          Binner currently supports <Link to="/swarm">Swarm</Link>, <a href="https://digikey.com" target="_blank" rel="noreferrer">DigiKey</a>, <a href="https://mouser.com" target="_blank" rel="noreferrer">Mouser</a>, <a href="https://nexar.com" target="_blank" rel="noreferrer">Octopart/Nexar</a> and <a href="https://arrow.com" target="_blank" rel="noreferrer">Arrow</a>. You don't need to do anything to activate Swarm, DigiKey or Mouser as they are built into our service. 
					However, Octopart/Nexar and Arrow both require API keys and they don't cost anything to register. See the <Link to="/settings">settings page</Link> for signup details. You can also 
					optionally provide your own api keys for Swarm, Digikey and Mouser if you choose to do so.
        </p>
        <p>
          Integrations enable features such as automatic part metadata lookup, datasheet retrieval and automatic importing parts from your orders. To get the
          best out of Binner, it is a good idea to sign up for Digikey and Mouser API keys at a minimum however they are not required.
        </p>
        <p>Configuration values can be found in appsettings.json alongside the Binner executable.</p>

				<hr />
        <h3>Configuring Binner Swarm API</h3>

        <p>
          Binner comes with free Swarm API support built-in. Swarm is an aggregate of part information that includes parametrics, datasheets, product images,
          pinouts and schematics. It is a new service, so content is still being expanded and indexed so a lot more data will be coming in the near future.
          There is a limit on how many Swarm requests can be made per hour/day - if you feel you need larger limits you can signup for a <Link to="/subscriptions">paid subscription</Link> and your limits are increased accordingly.
        </p>

				<h5>Obtaining a key</h5>

				<div className="bullet">
          Click on the <Link to="/api">Swarm API</Link> link in the user menu. Here you can generate your API key.
          <div className="helpimage">
            <img src="/image/help/swarm-menu.png" alt="Swarm Menu" />
            Figure 1. Visit the <Link to="/api">Swarm API</Link> section, located on the user menu.
          </div>
        </div>

				<h5>Using your own keys</h5>

				<div className="bullet">
          Enter all of the values below in the Swarm section. 
          <div className="helpimage">
            <img src="/image/help/swarm-settings.png" alt="Swarm Settings" />
            Figure 2. Swarm settings, located in the <Link to="/settings">settings page</Link>
          </div>
        </div>

				<hr />
        <h3>Configuring DigiKey API</h3>
        <p>
          DigiKey support is built-in. If you wish to use your own api keys, visit <a href="https://developer.digikey.com">https://developer.digikey.com</a> and sign up for a free developer account. You will be asked to create
          an App which will come with a ClientId and ClientSecret and needs to be set in the <Link to="/settings">settings page</Link> under the DigiKey configuration section.
        </p>

        <h5>Creating an App</h5>

        <div className="bullet">
          The DigiKey Api uses oAuth with postbacks so this must be configured in your DigiKey developer account. DigiKey calls this a <b>Callback URL</b>,
          while in Binner this is the <b>oAuthPostbackUrl</b>. This must be set to <b>https://binner.io/Authorization/Authorize</b> in both systems.
          <div className="helpimage">
            <img src="/image/help/digikey-callbackurl.png" alt="DigiKey Callback URL" />
            Figure 3. DigiKey's api settings, located in the DigiKey api <a href="https://developer.digikey.com">developers portal</a>
          </div>
        </div>

        <div className="bullet highlight"><Icon name="warning sign" color="red" />You will want to enable API access for the <b>Product Information</b>, <b>Order Support</b>, and <b>Barcode</b> Apis.</div>

        <h5>Sandbox</h5>

        <p>
          If you wish to use the DigiKey sandbox rather than their production API, you can specify the ApiUrl to use <b>https://sandbox-api.digikey.com</b>.
          Otherwise, you can leave it set to <b>https://api.digikey.com</b>
        </p>

				<h5>Using your own keys</h5>

				<div className="bullet">
          Enter all of the values below in the DigiKey section, except for the Postback Url which cannot be changed. 
          <div className="helpimage">
            <img src="/image/help/digikey-settings.png" alt="DigiKey Settings" />
            Figure 4. DigiKey's settings, located in the <Link to="/settings">settings page</Link>
          </div>
        </div>

				<hr />
        <h3>Configuring Mouser API</h3>
        <p>
          Mouser product search support is built in. If you wish to use your own api keys, visit <a href="https://www.mouser.com/api-hub/">https://www.mouser.com/api-hub</a> and sign up for a free developer account. Mouser requires you to
          sign up for each API product you wish to use. Currently, Binner supports both the Search API and Orders API, and if you wish to import your orders from Mouser you must sign up for your own Orders API key as it is a limitation of their system.
        </p>

        <h5>Using your own keys</h5>

				<div className="bullet">
          Enter all of the values below in the Mouser section. 
          <div className="helpimage">
            <img src="/image/help/mouser-settings.png" alt="Mouser Settings" />
            Figure 5. Mouser's settings, located in the <Link to="/settings">settings page</Link>
          </div>
        </div>

				<hr />
        <h3>Configuring Octopart/Nexar API</h3>
        <p>
          Visit <a href="https://portal.nexar.com/sign-up">https://portal.nexar.com/sign-up</a> and sign up for a developer account.
        </p>

        <h5>Using your own keys</h5>

				<div className="bullet">
          Enter all of the values below in the Octopart/Nexar section. 
          <div className="helpimage">
            <img src="/image/help/nexar-settings.png" alt="Nexar Settings" />
            Figure 6. Nexar's settings, located in the <Link to="/settings">settings page</Link>
          </div>
        </div>

				<hr />
				<h3>Configuring Arrow's API</h3>
        <p>
          Visit <a href="https://developers.arrow.com/api/index.php/site/page?view=requestAPIKey">https://developers.arrow.com/api/index.php/site/page?view=requestAPIKey</a> and sign up for an API developer account.
					Currently, it is not possible to import orders from Arrow as they require commercial credit applications and additional paperwork, so we have excluded it from our integration.
        </p>

        <h5>Using your own keys</h5>

				<div className="bullet">
          Enter all of the values below in the Arrow section. 
          <div className="helpimage">
            <img src="/image/help/arrow-settings.png" alt="Arrow Settings" />
            Figure 7. Arrow's settings, located in the <Link to="/settings">settings page</Link>
          </div>
        </div>
      </Segment>
    </div>
  );
};
