import _ from "underscore";
import { Icon } from "semantic-ui-react";
import { ResistorIcon } from "./icons";

export const ProjectColors = [
  { name: '', value: 0 },
  { name: 'red', value: 1 },
  { name: 'blue', value: 2 },
  { name: 'black', value: 3 },
  { name: 'green', value: 4 },
  { name: 'orange', value: 5 },
  { name: 'purple', value: 6 },
  { name: 'yellow', value: 7 },
  { name: 'pink', value: 8 },
  { name: 'grey', value: 9 },
  { name: 'violet', value: 10 },
];

export const MountingTypes = {
  None: { value: 0, name: 'None', icon: 'cancel', description: '' },
  ThroughHole: { value: 1, name: 'Through Hole', icon: <ResistorIcon />, description: 'Part leads run through the PCB' },
  SurfaceMount: { value: 2, name: 'Surface Mount', icon: 'microchip', description: 'Part is soldered to the surface of the PCB' },
};

export const BarcodeProfiles = {
  Default: 0
};

export const DEFAULT_FONT = "Segoe UI";

export const PaymentFrequency = {
  Monthly: 0,
  Annually: 1
};

export const SubscriptionLevels = {
  Free: 0,
  Maker: 1,
  Professional: 2,
  Commercial: 3
};

export const SubscriptionStates =
{
  NotActivated: 0,
  Active: 1,
  Cancelled: 2,
  PaymentExpired: 3,
  Paused: 4
};

export const SubscriptionAction = {
  Error: 0,
  /** Customer can create a subscription */
  CreateSubscription: 1,
  /** Customer can manage an existing subscription */
  ManageExistingSubscription: 2
};

export const PaymentStatus = {
  Succeeded: 0,
  Pending: 1,
  Failed: 2
};

export const ChargeType = {
  Charge: 0,
  Refund: 1
};

export const ImageTypes = {
  Unknown: 0,
  FullPage: 1,
  ProductShot: 2,
  Pinout: 3,
  Schematic: 4,
  BlockDiagram: 5,
  Characteristics: 6,
  Circuit: 7,
  Layout: 8,
  Dimensions: 9,
  Package: 10
};

export const ImageSizes = {
  Original: 0,
  Small: 1,
  Medium: 2,
  Large: 3
};

export const DocumentTypes = { 
  Unknown: 0,
  Datasheet: 1,
  Brochure: 2,
  ProductSheet: 3,
  MarketingGuide: 4,
  WhitePaper: 5,
  EBook: 6
};

export const Sources = { 
  ManualInput: 0,
  Crawler: 1,
  SwarmApi: 2
};

export const AccountTypes = {
  Normal: false,
  Admin: true
};

export const BooleanTypes = {
  False: false,
  True: true
};

export const ReCaptchaActions = {
  Register: 0,
  Login: 1,
  PasswordReset: 2
};

export const FilterTypes = {
  NeverLoggedIn: { name: "Never Logged In", value: 'neverloggedin', description: 'Users that have never logged in', icon: <Icon name="eye" color="red" /> },
  HasLoggedIn: { name: "Has Logged In", value: 'hasloggedin', description: 'Users that have logged in at least once', icon: <Icon name="eye" color="blue" /> },
  HasActiveSubscription: { name: "Active Subscription", value: 'hasactivesubscription', description: 'Users that have an active subscription', icon: <Icon name="dollar" color="green" /> },
  HasCancelledSubscription: { name: "Cancelled Subscription", value: 'hascancelledsubscription', description: 'Users that have cancelled their subscription', icon: <Icon name="dollar" color="grey" /> },
  HasConfirmedEmail: { name: "Confirmed Email", value: 'hasconfirmedemail', description: 'Users that have confirmed/validated their email address', icon: <Icon name="mail" color="blue" /> },
  HasNotConfirmedEmail: { name: "Not Confirmed Email", value: 'hasnotconfirmedemail', description: 'Users that have NOT confirmed/validated their email address', icon: <Icon name="mail" color="grey" /> },
  HasRecentLogin: { name: "Recent Login", value: 'hasrecentlogin', description: 'Users that have logged in within 2 weeks from now', icon: <Icon name="user" color="blue" /> },
  All: { name: "All Users", value: 'all', description: 'All users regardless of status', icon: <Icon name="warning sign" color="red" /> }
};

/**
 * Gets the name of a type by value
 * @param {object} type Type to get
 * @param {number} value Value of the type
 * @returns The name of the type definition
 */
 export const GetTypeName = (type, value) => {
  const typeKeys = Object.keys(type);
  const typeValues = Object.values(type);
  const valueType = typeValues && typeValues.length > 0 ? typeValues[0] : "number";
  
  switch(typeof valueType){
    case "object":
      const matchingType = _.findWhere(typeValues, { value: value });
      return matchingType?.name;
    default:
      return typeKeys[value];
  }
};

/**
 * Gets the value of a type by name
 * @param {object} type Type to get
 * @param {string} name Value of the type
 * @returns The value of the type definition
 */
export const GetTypeValue = (type, name) => {
  const typeKeys = Object.keys(type);
  if (typeKeys.includes(name))
    return type[name];
  return -1;
};

/**
 * Gets the specified property of a type by value
 * @param {object} type Type to get
 * @param {number} value Value to match against
 * @param {string} propertyToGet The property to get
 * @param {string} valueProperty The name of the value property to match
 * @returns The value of parameter propertyToGet
 */
 export const GetTypeProperty = (type, value, propertyToGet = "name", valueProperty = "value") => {
  const typeKeys = Object.keys(type);
  const typeValues = Object.values(type);
  const valueType = typeValues && typeValues.length > 0 ? typeValues[0] : "number";
  switch(typeof valueType){
    case "object":
      let searchObj = {};
      searchObj[valueProperty] = value;
      const matchingType = _.find(typeValues, searchObj);
      if (matchingType)
        return matchingType[propertyToGet];
      return null;
    default:
      return typeKeys[value];
  }
};

/**
 * Gets the full object of a type by value
 * @param {object} type Type to get
 * @param {number} value Value to match against
 * @param {string} valueProperty The name of the value property to match
 * @returns {object} The type object that matched
 */
 export const GetTypeMeta = (type, value, valueProperty = "value") => {
  const typeKeys = Object.keys(type);
  const typeValues = Object.values(type);
  const valueType = typeValues && typeValues.length > 0 ? typeValues[0] : "number";
  switch(typeof valueType){
    case "object":
      let searchObj = {};
      searchObj[valueProperty] = value;
      const matchingType = _.find(typeValues, searchObj);
      if (matchingType)
        return matchingType;
      return null;
    default:
      return typeKeys[value];
  }
};

/**
 * Generate a dropdown from a type
 * @param {object} type The type to generate a dropdown for
 * @param {bool} includeEmptyOption True to generate a blank empty option
 * @param {number} keyIndex The key index to start at
 * @param {object} extraFields Extra fields to add
 * @returns Dropdown items array
 */
export const GetTypeDropdown = (type, includeEmptyOption = false, keyIndex = 1, extraFields = {}) => {
  const typeKeys = Object.keys(type);
  let options = [];
  if (includeEmptyOption)
    options.push({
      key: -1,
      value: -1,
      text: ''
    });
  const newOptions = typeKeys.map(t => {
    return {
      ...extraFields,
      key: type[t] + keyIndex,
      value: type[t],
      text: t
    };
  });
  for(let i = 0; i < newOptions.length; i++)
    options.push(newOptions[i]);
  return options;
};

/**
 * Generate a dropdown from a type
 * @param {object} type The type to generate a dropdown for
 * @param {bool} showDescription True to include the description if available
 * @param {number} keyIndex The key index to start at
 * @param {object} extraFields Extra fields to add
 * @returns Dropdown items array
 */
export const GetAdvancedTypeDropdown = (type, showDescription = false, keyIndex = 1, extraFields = {}) => {
  const typeKeys = Object.keys(type);

  return typeKeys.map(t => {
    return {
      ...extraFields,
      key: type[t].value + keyIndex,
      value: type[t].value,
      icon: type[t].icon,
      text: type[t].text || type[t].name,
      description: showDescription ? type[t].description : null,
      ...(type[t].flag && {flag: type[t].flag.toLowerCase() })
    };
  });
};