import React, { useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { fetchApi, getErrorsString } from "../common/fetchApi";
import { toast } from "react-toastify";

export function InventoryManagement (props) {
	const navigate = useNavigate();
	const formRef = useRef();

	const submitSubscribe = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (formRef.current.emailAddress.value.length <= 0) {
			toast.error(`Email address must be specified.`);
			return;
		}

    return fetchApi("api/newsletter/subscribe",
    {
      method: "POST",
      body: JSON.stringify({
        emailAddress: formRef.current.emailAddress.value
      })
    }).then((response) => {
      const { data } = response;
			if (data.result === true)
				toast.success("You will receive the next newsletter!");
			else if(data.errors)
				toast.error(getErrorsString(response));
			else
				toast.error("Failed to signup for the newsletter.");
    });
  }

  return (
    <div>
			{/* ***** Breadcrumb Area Start ***** */}
			<div className="mosh-breadcumb-area">
					<div className="container h-100">
							<div className="row h-100 align-items-center">
									<div className="col-12">
											<div className="bradcumbContent">
													<h2>More About Binner</h2>
													<nav aria-label="breadcrumb">
															<ol className="breadcrumb">
																	<li className="breadcrumb-item"><a href="/" onClick={(e) => { e.preventDefault(); navigate('/'); }}>Home</a></li>
																	<li className="breadcrumb-item"><a href="/features" onClick={(e) => { e.preventDefault(); navigate('/features'); }}>Features</a></li>
																	<li className="breadcrumb-item active" aria-current="page">Inventory Management</li>
															</ol>
													</nav>
											</div>
									</div>
							</div>
					</div>
			</div>
			{/* ***** Breadcrumb Area End ***** */}

			{/* ***** Inventory Management Area Start ***** */}
			<section className="mosh-aboutUs-area section_padding_100_0">
					<div className="container">
							<div className="row align-items-center">
									<div className="col-12 col-md-10">
											<div className="mosh-about-us-content dark">
													<div className="section-heading">
															<p>Inventory Management</p>
															<h2>Get Organized Easily</h2>
													</div>
													<p>
														Binner's inventory management approach makes it easy to organize small shops or large warehouses. 
														Parts are categorized, keyworded and associated with critical metadata making it easy to find what you're looking for even if you don't 
														know the exact part number.
													</p>
													<p>
														It's more than just a place to track quantities and locations. Binner provides invaluable data about your parts right at your fingertips - technical data, schematics, dimensional data, product images, circuit examples and so much more.
													</p>
											</div>
									</div>
							</div>
					</div>
			</section>
			{/* ***** Inventory Management Area End ***** */}

			{/* ***** Electronic Storage Bins Area End ***** */}
			<section className="mosh-about-features-area section_padding_100">
					<div className="container">
							<div className="row align-items-center">

							<div className="col-12 col-md-4">
										<div className="mosh-features-thumb wow fadeIn" data-wow-delay="0.5s">
												<img src="img/bg-img/storagebin_sm_animated.png" alt="" style={{marginTop: '30px'}} />
										</div>
									</div>

									<div className="col-12 col-md-8">
											<div className="mosh-about-us-content">
													<div className="section-heading">
															<p>Inventory Management</p>
															<h2>Electronic Storage Bins</h2>
													</div>
													<div className="row dark">
														<p>
					 										No longer are fancy storage solutions only available to the largest of warehouses. Get it right in your home, lab or office using Binner <a href="/binnerbin">Electronic Storage bins</a>.
														</p>
														<p>
															Electronic bins are simple parts containers consisting of an addressable RGB LED matrix mounted behind an array of clear/translucent part boxes. By addressing the correct location you can highlight one or more bins to indicate where your part is, or a whole BOM list of parts at once!
														</p>
														<p>
															These bins can be connected together so only a single power source and wireless controller unit required keeping the parts cost to a minimum. Both Binner Cloud and open-source Binner can communicate with your storage unit for a simple and personal warehouse as small or large as you require.
														</p>
													</div>
											</div>
									</div>
							</div>
					</div>
			</section>
			{/* ***** Electronic Storage Bins Area End ***** */}

			{/* ***** Few Words Area Start ***** */}
			{/** https://www.arenasolutions.com/resources/category/bom-management/creating-a-bill-of-materials/ */}
			<section className="few-words-from-ceo d-md-flex">
					<div className="few-words-contents d-flex align-items-center justify-content-center wow fadeInLeftBig" data-wow-delay="0.1s">
							<div className="container-fluid">
									<div className="row justify-content-center">
											<div className="col-12 col-md-11 col-lg-9 col-xl-7">
													<div className="few-words-text">
															<div className="section-heading">
																	<p>Inventory Management</p>
																	<h2>Optimizing BOM</h2>
															</div>
															<p>
																Advanced Bill of Materials (BOM) tools are provided with the Professional level subscription to help you optimize your costs on each project you manage. BOM tools can offer alternative part selection for your project with little or no changes to your design.
															</p>
															<br/>
															<p>
																Track lifecycle for parts in your BOM both during design and after manufacturing to keep informed on changes to that may affect future batches. Your BOM can be sent out to multiple suppliers directly from Binner to be filled as required.
															</p>
													</div>
											</div>
									</div>
							</div>
					</div>
					<div className="few-words-thumb bg-img wow fadeInRightBig" data-wow-delay="1.1s" style={{backgroundImage: 'url(img/bg-img/bom-management.jpg)'}}></div>
			</section>
			{/* ***** Few Words Area End ***** */}

			<section className="mosh-subscribe-newsletter-area bg-img bg-overlay-white section_padding_100" style={{backgroundImage: 'url(img/bg-img/sub-1.jpg)'}}>
					<div className="container">
							<div className="row">
									<div className="col-12">
											<div className="subscribe-newsletter-content text-center wow fadeIn" data-wow-delay="0.5s">
													<p>give us a shout</p>
													<h2>Subscribe to our newsletter</h2>
													<form onSubmit={submitSubscribe} ref={formRef} autoComplete="on">
															<input type="email" name="emailAddress" placeholder="Your e-mail here" />
															<button type="submit">Subscribe</button>
													</form>
											</div>
									</div>
							</div>
					</div>
			</section>		
		</div>
  );
}
