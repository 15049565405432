import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { decodeJwt, getUserAccount } from "../../common/authentication";

export function Cancelled(props) {
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    // see if the user needs to verify their email first
    const userAccount = getUserAccount();
    const jwt = decodeJwt(userAccount.accessToken);
    if (jwt.CanLogin === "True") {
      setSuccessMessage(<div><p style={{fontSize: '1.5em'}}><Link to="/">Return home</Link></p></div>);
    } else {
      setSuccessMessage(<div><p>Your account was created however you will still need to verify your email address before logging in.</p><p className="emphasis">Check your email for a link that will activate your account.</p></div>);
    }
  }, []);

  return (
    <div>
      {/* ***** Breadcrumb Area Start ***** */}
      <div className="mosh-breadcumb-area">
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-12">
              <div className="bradcumbContent">
                <h2>Subscription</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ***** Breadcrumb Area End ***** */}

      <div className="centered" style={{minHeight: '200px'}}>
        <h1>Payment request cancelled at your request.</h1>
        {successMessage}
      </div>
    </div>
  );
}
