import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import { Statistic, Segment, Icon } from "semantic-ui-react";
import { getUserAccount } from "../common/authentication";
import { fetchApi } from "../common/fetchApi";
import customEvents from '../common/customEvents';

import { isAdmin } from "../common/authentication";

export function Home(props) {
  const { t } = useTranslation();
  const [summary, setSummary] = useState({});
  const [loading, setLoading] = useState(true);
  const [userAccount] = useState(getUserAccount());
  const [version, setVersion] = useState(null);
  let navigate = useNavigate();

  /**
   * When we receive a version header from an api request, update it.
   * This callback will be called on all updates to version
   */
  const updateVersion = useCallback((installedVersionData) => {
    setVersion(installedVersionData.version);
  }, []);

  useEffect(() => {
    const subscriptionId = customEvents.subscribe("version", (data) => updateVersion(data));
    return () => {
      customEvents.unsubscribe(subscriptionId);
    };
  }, [updateVersion]);

  useEffect(() => {
    Home.abortController = new AbortController();
    if (userAccount.isAuthenticated) {
      if (Home.abortController) {
        Home.abortController.abort(); // Cancel the previous request
      }
      Home.abortController = new AbortController();
      setLoading(true);
      fetchApi(`api/part/summary`, {
        signal: Home.abortController.signal,
      }).then((response) => {
        const { data } = response;
        setSummary(data || {});
        setLoading(false);
      });
    }

    return () => {
      Home.abortController.abort();
    };
  }, []);

  const route = (e, url) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(url);
  };

  return (
    <Container>
      <div className="centered">
      <h1>{t('page.home.title', "Dashboard")}</h1>
      <p>{t('page.home.description', "Binner is an inventory management app for makers, hobbyists and professionals.")}</p>
        <Segment loading={loading}>
          <Statistic.Group widths="four">
            <Statistic onClick={(e) => route(e, "/inventory/add")} style={{ cursor: "pointer" }}>
              <Statistic.Value>
                <Icon name="plus" />
              </Statistic.Value>
              <Statistic.Label>{t('page.home.addInventory', "Add Inventory")}</Statistic.Label>
            </Statistic>
            <Statistic onClick={(e) => route(e, "/inventory")} style={{ cursor: "pointer" }}>
              <Statistic.Value>
                <Icon name="search" />
              </Statistic.Value>
              <Statistic.Label>{t('page.home.searchInventory', "Search Inventory")}</Statistic.Label>
            </Statistic>
            <Statistic onClick={(e) => route(e, "/bom")} style={{ cursor: "pointer" }}>
              <Statistic.Value>
                <Icon name="list alternate outline" />
              </Statistic.Value>
              <Statistic.Label>{t('page.home.bom', "BOM")}</Statistic.Label>
            </Statistic>
            <Statistic onClick={(e) => route(e, "/datasheets")} style={{ cursor: "pointer" }}>
              <Statistic.Value>
                <Icon name="file alternate" />
              </Statistic.Value>
              <Statistic.Label>{t('page.home.datasheets', "Datasheets")}</Statistic.Label>
            </Statistic>
          </Statistic.Group>
          <Statistic.Group widths="three" size="tiny" style={{ marginTop: "50px" }}>
            <Statistic onClick={(e) => route(e, "/lowstock")} style={{ cursor: "pointer" }}>
              <Statistic.Value>
                <Icon name="battery low" />
              </Statistic.Value>
              <Statistic.Label>{t('page.home.viewLowStock', "View Low Stock")}</Statistic.Label>
            </Statistic>
            <Statistic onClick={(e) => route(e, "/partTypes")} style={{ cursor: "pointer" }}>
              <Statistic.Value>
                <Icon name="sitemap" />
              </Statistic.Value>
              <Statistic.Label>{t('page.home.partTypes', "Part Types")}</Statistic.Label>
            </Statistic>
            <Statistic onClick={(e) => route(e, "/exportData")} style={{ cursor: "pointer" }}>
              <Statistic.Value>
                <Icon name="cloud download" />
              </Statistic.Value>
              <Statistic.Label>{t('page.home.importExport', "Import/Export")}</Statistic.Label>
            </Statistic>
            <Statistic onClick={(e) => route(e, "/printing")} style={{ cursor: "pointer" }}>
              <Statistic.Value>
                <Icon name="print" />
              </Statistic.Value>
              <Statistic.Label>{t('page.home.printLabels', "Print Labels")}</Statistic.Label>
            </Statistic>
            <Statistic onClick={(e) => route(e, "/tools")} style={{ cursor: "pointer" }}>
              <Statistic.Value>
                <Icon name="wrench" />
              </Statistic.Value>
              <Statistic.Label>{t('page.home.tools', "Tools")}</Statistic.Label>
            </Statistic>
            <Statistic onClick={(e) => route(e, "/settings")} style={{ cursor: "pointer" }}>
              <Statistic.Value>
                <Icon name="cog" />
              </Statistic.Value>
              <Statistic.Label>{t('page.home.settings', "Settings")}</Statistic.Label>
            </Statistic>
            {isAdmin() && <Statistic onClick={(e) => route(e, "/admin")} style={{ cursor: "pointer" }}>
              <Statistic.Value>
                <Icon name="lock open" />
              </Statistic.Value>
              <Statistic.Label>{t('page.home.admin', "Admin")}</Statistic.Label>
            </Statistic>}
          </Statistic.Group>
        </Segment>

        <h2>{t('page.home.yourOverview', "Your Overview")}</h2>
        <Segment inverted loading={loading} textAlign="center">
          <Statistic.Group widths="five">
            <Statistic color="red" inverted title="Total of part numbers that are running on low stock">
              <Statistic.Value>
                <Icon name="battery low" />
                {summary.lowStockCount}
              </Statistic.Value>
              <Statistic.Label>{t('page.home.lowStock', "Low Stock")}</Statistic.Label>
            </Statistic>
            <Statistic color="orange" inverted title="Total number of individual parts in inventory">
              <Statistic.Value>
                <Icon name="microchip" />
                {summary.partsCount}
              </Statistic.Value>
              <Statistic.Label>{t('page.home.parts', "Parts")}</Statistic.Label>
            </Statistic>
            <Statistic color="orange" inverted title="Total number of unique part numbers in inventory">
              <Statistic.Value>
                <Icon name="microchip" />
                {summary.uniquePartsCount}{summary.uniquePartsMax > 0 ? `/${summary.uniquePartsMax}` : ""}
              </Statistic.Value>
              <Statistic.Label>{t('page.home.uniqueParts', "Unique Parts")}</Statistic.Label>
            </Statistic>
            <Statistic color="green" inverted title="Total value of parts in inventory">
              <Statistic.Value>
                <Icon name="dollar" />
                {(summary.partsCost || 0).toFixed(2)}
              </Statistic.Value>
              <Statistic.Label>{t('page.home.value', "Value")}</Statistic.Label>
            </Statistic>
            <Statistic color="blue" inverted title="Total number of projects">
              <Statistic.Value>
                <Icon name="folder open" />
                {summary.projectsCount}
              </Statistic.Value>
              <Statistic.Label>{t('page.home.projects', "BOM Projects")}</Statistic.Label>
            </Statistic>
          </Statistic.Group>
        </Segment>
      </div>
    </Container>
  );
}
Home.abortController = new AbortController();
