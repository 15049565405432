import ReactGA from "react-ga";

/**
 * Use Google Analytics Event Tracker
 * @param {string} category the category of the event
 * @returns 
 */
const useAnalyticsEventTracker = (category = "Default") => {
	/**
	 * Log an event
	 * @param {string} action name of action
	 * @param {string} label optional label for action
	 */
  const eventTracker = (action = "Unspecified Action", label = "") => {
		console.log('sending event', category, action, label);
    ReactGA.event({category, action, label});
  }
  return eventTracker;
}
export default useAnalyticsEventTracker;