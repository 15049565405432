import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "underscore";
import { Table, Form, Segment, Button, Icon, Confirm, Breadcrumb, Header, Flag } from "semantic-ui-react";
import * as uuid from "uuid";
import { toast } from "react-toastify";
import { config } from "../../../common/config";
import { fetchApi, getErrorsString } from "../../../common/fetchApi";
import { AccountTypes, BooleanTypes, SubscriptionLevels, SubscriptionStates, GetTypeName, GetTypeDropdown } from "../../../common/Types";
import { formatCurrency, formatNumber } from "../../../common/Utils";
import { getFriendlyElapsedTime, getTimeDifference, getFormattedTime } from "../../../common/datetime";
import { ProjectColors } from "../../../common/Types";
import { getProjectColor } from "../../../common/bomTools";
import { FormHeader } from "../../../components/FormHeader";
import "../Admin.css";

export function User(props) {
	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState({
    name: "",
    emailAddress: "",
    isAdmin: false,
    isEmailConfirmed: false,
    isLocked: false,
    partsInventoryCount: 0,
    partTypesCount: 0,
    projects: [],
    subscriptions: [],
    oAuthCredentials: [],
    oAuthRequests: [],
    payments: [],
    userIntegrationConfigurations: [],
    userPrinterConfigurations: [],
    userPrinterTemplateConfigurations: []
  });
  const [isDirty, setIsDirty] = useState(false);
  const [addSubscription, setAddSubscription] = useState({ subscriptionLevel: 0, userId: 0 });
  const [confirmDeleteIsOpen, setConfirmDeleteIsOpen] = useState(false);
  const [confirmDeleteSubscriptionIsOpen, setConfirmDeleteSubscriptionIsOpen] = useState(false);
  const [deleteSelectedItem, setDeleteSelectedItem] = useState(null);
  const [deleteSubscriptionSelectedItem, setDeleteSubscriptionSelectedItem] = useState(null);
  const [addSubscriptionVisible, setAddSubscriptionVisible] = useState(false);

  const accountTypes = GetTypeDropdown(AccountTypes);
  const emailConfirmedTypes = GetTypeDropdown(BooleanTypes);
  const dateLockedTypes = GetTypeDropdown(BooleanTypes);
  const subscriptionLevels = GetTypeDropdown(SubscriptionLevels);

  const params = useParams();
  const { userId } = params;
  const navigate = useNavigate();

	useEffect(() => {
		fetchUser();

    function fetchUser() {
      setLoading(true);
      fetchApi(`api/user?userId=${userId}`).then((response) => {
        const { data } = response;
        if (data) {
          const newUser = {...data, isLocked: data.dateLockedUtc != null};
          setUser(newUser);
	        setLoading(false);
				}
      });
    }
  }, []);

	const updateUser = (e) => {
    if (user.isLocked && user.dateLockedUtc === null) 
      user.dateLockedUtc = new Date();
    else if(!user.isLocked && user.dateLockedUtc !== null)
      user.dateLockedUtc = null;

    const userRequest = {
      ...user,
      isEmailConfirmed: user.isEmailConfirmed,
      isAdmin: user.isAdmin,
      dateLockedUtc: user.dateLockedUtc
    };

    fetchApi(`api/user`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(userRequest)
    }).then((response) => {
      if (response.responseObject.ok) {
        setIsDirty(false);
        toast.success("Saved user!");
        navigate(-1);
      } else {
        const errorMessage = getErrorsString(response);
        console.error(errorMessage);
        toast.error(errorMessage);
      }
    });
  };

  const handleAddSubscription = (e, control) => {
    fetchApi(`api/user/subscription`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ ...addSubscription, userId: user.userId })
    }).then((response) => {
      if (response.responseObject.ok) {
        toast.success("Added subscription!");
        user.subscriptions.push(response.data);
        setUser({...user });
        setAddSubscriptionVisible(false);
      } else {
        const errorMessage = getErrorsString(response);
        console.error(errorMessage);
        toast.error(errorMessage);
      }
    });
  };

  const deleteUser = (e, user) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    fetchApi(`api/user`, {
      method: "DELETE",
      body: user.userId
    }).then(() => {
      setLoading(false);
      setConfirmDeleteIsOpen(false);
      navigate(-1);
    });
  };

  const deleteSubscription = (e, subscription) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    fetchApi(`api/user/subscription`, {
      method: "DELETE",
      body: { subscriptionLevel: subscription.subscriptionType, userId: user.userId }
    }).then(() => {
      const subscriptions = _.filter(user.subscriptions, (item) => item.subscriptionType !== subscription.subscriptionType);
      setUser({...user, subscriptions: subscriptions });

      setLoading(false);
      setConfirmDeleteSubscriptionIsOpen(false);
    });
  };

  const confirmDeleteOpen = (e, user) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteSelectedItem(user);
    setConfirmDeleteIsOpen(true);
  };

  const confirmDeleteSubscriptionOpen = (e, user) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteSubscriptionSelectedItem(user);
    setConfirmDeleteSubscriptionIsOpen(true);
  };

  const confirmDeleteClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteSelectedItem(null);
    setConfirmDeleteIsOpen(false);
  };

  const confirmDeleteSubscriptionClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteSubscriptionSelectedItem(null);
    setConfirmDeleteSubscriptionIsOpen(false);
  };

  const handleChange = (e, control) => {
    user[control.name] = control.value;
    setUser({ ...user });
    setIsDirty(true);
  };

  const handleSubscriptionChange = (e, control) => {
    addSubscription[control.name] = control.value;
    setAddSubscription({ ...addSubscription });
  };

  const handleShowAddSubscription = (e, control) => {
    setAddSubscriptionVisible(!addSubscriptionVisible);
  };

  const generatePassword = () => {
    var length = 8,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$",
        retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  };

	return (
    <div className="admin-container">
			<Breadcrumb>
        <Breadcrumb.Section href="/admin">Admin</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section href="/admin/users">Users</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>User</Breadcrumb.Section>
      </Breadcrumb>
      <FormHeader name="User Management" to="..">
        Administration of users.
      </FormHeader>

			<Segment loading={loading} secondary>
        <Confirm
          open={confirmDeleteIsOpen}
          onCancel={confirmDeleteClose}
          onConfirm={(e) => deleteUser(e, deleteSelectedItem)}
          content="Are you sure you want to delete this user?"
        />
        <Confirm
          open={confirmDeleteSubscriptionIsOpen}
          onCancel={confirmDeleteSubscriptionClose}
          onConfirm={(e) => deleteSubscription(e, deleteSubscriptionSelectedItem)}
          content="Are you sure you want to delete this subscription?"
        />
        <Form onSubmit={updateUser}>
          {user.userId === 1 &&
            <Header as='h4'>Master Admin Account</Header>
          }
          <Form.Input label="Name" required focus placeholder="John Doe" value={user.name || ""} name="name" onChange={handleChange} />
          <Form.Input label="Email" iconPosition="left" required placeholder="john@example.com" value={user.emailAddress || ""} name="emailAddress" onChange={handleChange}>
            <Icon name='at' />
            <input />
          </Form.Input>
          <Form.Input label="Phone" iconPosition="left" placeholder="555-555-1212" value={user.phoneNumber || ""} name="phoneNumber" onChange={handleChange}>
            <Icon name='phone' />
            <input />
          </Form.Input>
          <Form.Input label="Change Password" action value={user.password || ""} name="password" onChange={handleChange}>
            <input />
            <Button onClick={(e) => { e.preventDefault(); setUser({...user, password: generatePassword()}); }}>Generate</Button>
          </Form.Input>
          <Form.Input label="Confirmation Token" action value={user.emailConfirmationToken || ""} name="emailConfirmationToken" onChange={handleChange}>
            <input />
            <Button onClick={(e) => { e.preventDefault(); setUser({...user, emailConfirmationToken: uuid.v4()}); }}>Generate</Button>
          </Form.Input>
          <Form.Dropdown
            label="Account Type"
            placeholder="Account Type"
            selection
            value={user.isAdmin || false}
            className={user.isAdmin ? "blue" : ""}
            name="isAdmin"
            options={accountTypes}
            onChange={handleChange}
          />
          <Form.Dropdown
            label="Email Confirmed"
            placeholder="Email Confirmed"
            selection
            value={user.isEmailConfirmed || false}
            className={user.isEmailConfirmed ? "green" : "red"}
            name="isEmailConfirmed"
            options={emailConfirmedTypes}
            onChange={handleChange}
          />
          <Form.Dropdown
            label="Account Locked"
            placeholder="Account Locked"
            selection
            value={user.isLocked || false}
            className={user.isLocked ? "red" : "green"}
            name="isLocked"
            options={dateLockedTypes}
            onChange={handleChange}
          />
          <Form.Group className="celled">
            <Form.Field>
              <label>User Id</label>
              {user.userId}
            </Form.Field>
            <Form.Field>
              <label>Inventory Count</label>
              {formatNumber(user.partsInventoryCount)}
            </Form.Field>
            <Form.Field>
              <label>Custom Part Types</label>
              {formatNumber(user.partTypesCount)}
            </Form.Field>
            <Form.Field>
              <label>Last Active</label>
              <div>
                {user.dateLastActiveUtc !== null
                  ? getFriendlyElapsedTime(getTimeDifference(Date.now(), Date.parse(user.dateLastActiveUtc)), true)
                  : '(never)'}
              </div>
              <span className="small">{getFormattedTime(user.dateLastActiveUtc)}</span>
            </Form.Field>
            <Form.Field>
              <label>Last Login</label>
              <div>
                {user.dateLastLoginUtc !== null
                  ? getFriendlyElapsedTime(getTimeDifference(Date.now(), Date.parse(user.dateLastLoginUtc)), true)
                  : '(never)'}
              </div>
              <span className="small">{getFormattedTime(user.dateLastLoginUtc)}</span>
            </Form.Field>
            <Form.Field>
              <label>IP</label>
              <div>
                {user.location && user.location.country &&
                  <Flag name={user.location.country.toLowerCase()} />
                }
                {user.ipAddress}
              </div>
              {user.location && <span className="small">{user.location.city}, {user.location.mostSpecificSubdivision}, {user.location.country}, {user.location.continent} {user.location.postal}</span>}
            </Form.Field>
            <Form.Field>
              <label>Date Created</label>
              <div>
                {user.dateCreatedUtc !== null
                  ? getFriendlyElapsedTime(getTimeDifference(Date.now(), Date.parse(user.dateCreatedUtc)), true)
                  : '(never)'}
              </div>
              <span className="small">{getFormattedTime(user.dateCreatedUtc)}</span>
            </Form.Field>
            <Form.Field>
              <label>Date Modified</label>
              <div>
                {user.dateModifiedUtc !== null
                  ? getFriendlyElapsedTime(getTimeDifference(Date.now(), Date.parse(user.dateModifiedUtc)), true)
                  : '(never)'}
              </div>
              <span className="small">{getFormattedTime(user.dateModifiedUtc)}</span>
            </Form.Field>
            <Form.Field>
              <label>Date Locked</label>
              {getFormattedTime(user.dateLockedUtc)}
            </Form.Field>
          </Form.Group>

          <Button type="submit" primary disabled={!isDirty} style={{ marginTop: "10px" }}>
            <Icon name="save" />
            Save
          </Button>
          {user.userId > 1 && (
            <Button type="button" title="Delete" onClick={(e) => confirmDeleteOpen(e, user)}>
              <Icon name="delete" />
              Delete
            </Button>
          )}

        </Form>

			</Segment>

      {/* GOOGLE MAPS */}
      {user.location && user.location.latitude &&
        <iframe
          width="100%"
          height="450"
          style={{border: '0'}}
          loading="lazy"
          allowFullScreen
          referrerPolicy="no-referrer-when-downgrade"
          src={`https://www.google.com/maps/embed/v1/place?key=${config.GOOGLEMAPS_APIKEY}&q=${user.location.latitude},${user.location.longitude}&zoom=2&maptype=satellite`}
          title="Google Maps">
        </iframe>
      }
      {/* END GOOGLE MAPS */}

      <Segment>
        <Header dividing as="h3">
          Subscriptions
        </Header>
        <div style={{textAlign: 'right'}}>
          <Button onClick={handleShowAddSubscription} size='tiny'>Add Subscription</Button>
        </div>
        {addSubscriptionVisible &&
          <Form onSubmit={handleAddSubscription}>
            <Form.Dropdown
                label="Subscription Level"
                placeholder="None"
                selection
                value={addSubscription.subscriptionLevel}
                name="subscriptionLevel"
                options={subscriptionLevels}
                onChange={handleSubscriptionChange}
              />
              <Form.Group>
                <Form.Button primary type="submit" icon>
                  <Icon name="dollar" /> Add Subscription
                </Form.Button>
              </Form.Group>
          </Form>
        }
        <Table compact celled sortable selectable striped unstackable size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Id</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>State</Table.HeaderCell>
              <Table.HeaderCell>Note</Table.HeaderCell>
              <Table.HeaderCell>Expires</Table.HeaderCell>
              <Table.HeaderCell>Date Created</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {user.subscriptions.map((subscription, subscriptionKey) => (
              <Table.Row key={subscriptionKey}>
                <Table.Cell>{subscription.subscriptionId}</Table.Cell>
                <Table.Cell>{GetTypeName(SubscriptionLevels, subscription.subscriptionType)}</Table.Cell>
                <Table.Cell>{subscription.subscriptionState === SubscriptionStates.Active && <Icon name="check circle" color="green" />}{GetTypeName(SubscriptionStates, subscription.subscriptionState)}</Table.Cell>
                <Table.Cell>{subscription.note}</Table.Cell>
                <Table.Cell>{getFormattedTime(subscription.dateExpiredUtc)}</Table.Cell>
                <Table.Cell>{getFormattedTime(subscription.dateCreatedUtc)}</Table.Cell>
                <Table.Cell>
                  <Button size="mini" icon="delete" title="Delete" onClick={(e) => confirmDeleteSubscriptionOpen(e, subscription)} />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment>

      <Segment>
        <Header dividing as="h3">
          Payments
        </Header>
        <Table compact celled sortable selectable striped unstackable size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Id</Table.HeaderCell>
              <Table.HeaderCell>Subscription</Table.HeaderCell>
              <Table.HeaderCell>Amount</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Flags</Table.HeaderCell>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {user.payments.map((payment, paymentKey) => (
              <Table.Row key={paymentKey}>
                <Table.Cell>{payment.paymentId}</Table.Cell>
                <Table.Cell>{payment.subscriptionId}</Table.Cell>
                <Table.Cell>{formatCurrency(payment.paymentAmount)}</Table.Cell>
                <Table.Cell>{payment.stripePaymentStatus}</Table.Cell>
                <Table.Cell>{payment.isTestMode ? "TestMode" : ""}</Table.Cell>
                <Table.Cell>{getFormattedTime(payment.dateCreatedUtc)}</Table.Cell>
                <Table.Cell>
                  <Button size="mini" icon="delete" title="Delete" />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment>

      <Segment>
        <Header dividing as="h3">
          BOM Projects
        </Header>
        <Table compact celled sortable selectable striped unstackable size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Id</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
              <Table.HeaderCell>Location</Table.HeaderCell>
              <Table.HeaderCell>Parts</Table.HeaderCell>
              <Table.HeaderCell>Pcbs</Table.HeaderCell>
              <Table.HeaderCell>Date Created</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {user.projects.map((project, projectKey) => (
              <Table.Row key={projectKey} style={{borderBottom: `4px solid ${getProjectColor(ProjectColors, project).color}`}}>
                <Table.Cell>{project.projectId}</Table.Cell>
                <Table.Cell>{project.name}</Table.Cell>
                <Table.Cell>{project.description}</Table.Cell>
                <Table.Cell>{project.location}</Table.Cell>
                <Table.Cell>{project.partCount}</Table.Cell>
                <Table.Cell>{project.pcbCount}</Table.Cell>
                <Table.Cell>{getFormattedTime(project.dateCreatedUtc)}</Table.Cell>
                <Table.Cell>
                  <Button size="mini" icon="delete" title="Delete" />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment>
      <div style={{height: '50px'}}></div>
		</div>
	);
}