import React from 'react';
import { useTranslation, Trans } from "react-i18next";
import { useLocation } from "react-router-dom";

export function NotFound (props) {
  const { t } = useTranslation();
  const location = useLocation();
  console.log("Not found", location);

  return (
    <div>
      {/* ***** Breadcrumb Area Start ***** */}
      <div className="mosh-breadcumb-area">
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-12">
              <div className="bradcumbContent">
                <h2>Oh My</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ***** Breadcrumb Area End ***** */}

      <div className="centered" style={{minHeight: '200px'}}>
        <div className="notfound">
          <p>
            What you're looking for isn't here. Perhaps someone ate it. Not me probably, but <i>someone</i>.
          </p>
          <a href="/" alt="Have a treat">Better go somewhere safe.</a>
        </div>
      </div>
    </div>
  );
}
