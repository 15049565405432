import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, Link } from "react-router-dom";
import { Button, Segment, Icon, Form, Label } from 'semantic-ui-react';
import { fetchApi } from "../common/fetchApi";

export function Unsubscribe (props) {
	const { t } = useTranslation();
  const params = useParams();
  const { emailAddress } = params;
  const [loading, setLoading] = useState(false);
  const [isUnsubscribed, setIsUnsubscribed] = useState(false);
	const [form, setForm] = useState({
    emailAddress: emailAddress,
  });

	const unsubscribe = useCallback(() => {
		console.log('email', emailAddress, form);

		setLoading(true);
		fetchApi("api/authentication/unsubscribe",
		{
			method: "POST",
			body: JSON.stringify({
				emailAddress: emailAddress || form.emailAddress
			})
		}).then((response) => {
			const { data } = response;
			setLoading(false);
			setIsUnsubscribed(true);
		});
	}, [emailAddress, form]);


  useEffect(() => {
		if (emailAddress) {
    	unsubscribe();
		}
  }, [emailAddress, unsubscribe]);

	
	const handleChange = (e, control) => {
    e.preventDefault();
    e.stopPropagation();
    setForm(form => ({ 
      ...form,
      [e.target.name]: control.value 
    }));
  }

	let viewContents = "";
	if (!emailAddress && !isUnsubscribed){

		viewContents = (
			<Segment raised style={{ width: "40%", margin: "auto" }}>
			<Form onSubmit={unsubscribe} loading={loading} autoComplete="on">
				<Form.Input
					focus
					required
					autoComplete="email"
					name="emailAddress"
					value={form.emailAddress || ""}
					placeholder="Email"
					icon="mail outline"
					iconPosition="left"
					onChange={handleChange}
				/>
				<div className="centered">
					<Button type="submit" primary style={{ marginTop: "10px" }}>
						Unsubscribe
						<Icon name="arrow circle right" />
					</Button>
				</div>
			</Form>
		</Segment>);

	} else {
		viewContents = (
		<div style={{display: 'flex',alignItems: 'center', justifyContent: 'center', height: '200px'}}>
				<div>
					<h2>Your email has been unsubscribed.</h2>
					<Icon name="user outline" color="blue" size="huge" />
					<p style={{marginTop: '50px'}}>You will only receive emails directly related to your account.</p>
				</div>
		</div>);
	}

  return (
    <div className="centered">
      <h1>Unsubscribe Email</h1>
			<Segment loading={loading}>
				{viewContents}
			</Segment>
    
    </div>
  );
}
