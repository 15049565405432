import React, { useState, useEffect, useRef, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import _ from "underscore";
import { Table, Form, Segment, Button, Icon, Input, Popup, Confirm, Breadcrumb, TextArea, Modal } from "semantic-ui-react";
import { fetchApi } from "../../../common/fetchApi";
import { Sources, GetTypeName } from "../../../common/Types";
import { InifiniteScrollTable } from "../../../components/InfiniteScrollTable";
import { getDefaultProductShotImageFromPartNumber } from "../../../common/resources";
import { getFriendlyElapsedTime, getTimeDifference, getFormattedTime, getBasicElapsed } from "../../../common/datetime";
import { FormHeader } from "../../../components/FormHeader";
import "../Admin.css";

export function Pinout(props) {
	
};