import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { Button, Form, Segment, Icon, Label } from "semantic-ui-react";
import { setUserAccount } from "../common/authentication";
import { fetchApi } from "../common/fetchApi";

export function ResetPassword(props) {
  const { t } = useTranslation();
  const params = useParams();
  const { emailAddress, token } = params;
  const [loading, setLoading] = useState(false);
  const [isValidToken, setIsValidToken] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [form, setForm] = useState({
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    setLoading(true);
    confirmPasswordResetToken();

    function confirmPasswordResetToken() {
      return fetchApi("api/authentication/passwordrecovery/validate",
        {
          method: "POST",
          body: JSON.stringify({
            token: token,
            emailAddress: emailAddress
          })
        }).then((response) => {
          const { data } = response;
          setLoading(false);
          setIsValidToken(data.isSuccessful);
        });
    }

  }, []);

  const submitNewPassword = (e) => {
    setLoading(true);
    return fetchApi("api/authentication/passwordrecovery/resetpassword",
      {
        method: "POST",
        body: JSON.stringify({
          token: token,
          emailAddress: emailAddress,
          password: form.password,
          confirmPassword: form.confirmPassword
        })
      }).then((response) => {
        const { data } = response;
        setLoading(false);

        if (data.isAuthenticated) {
          // user is logged in, redirect
          // set the current user account
          setUserAccount({
            id: data.id,
            isAuthenticated: data.isAuthenticated,
            name: data.name,
            subscriptionLevel: data.subscriptionLevel,
            accessToken: data.jwtToken,
            imagesToken: data.imagesToken,
            isAdmin: data.isAdmin
          });
          toast.success("Your new password was saved successfully and are now logged in!");
          navigate("/", { replace: true, state: {} });
        } else {
          toast.error(data.message);
          setErrorMessage(data.message);
        }
      });
  }

  const handleChange = (e, control) => {
    e.preventDefault();
    e.stopPropagation();
    setForm(form => ({
      ...form,
      [e.target.name]: control.value
    }));
  }

  return (
    <div>
      {/* ***** Breadcrumb Area Start ***** */}
      <div className="mosh-breadcumb-area">
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-12">
              <div className="bradcumbContent">
                <h2>Support</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ***** Breadcrumb Area End ***** */}

      <div className="centered">
        <h1>Password Recovery</h1>

        {!isValidToken
          ? (
            <div>
              <h2>Invalid Reset Token</h2>
              <p>
                Something doesn't look right. The password reset token you provided is expired or invalid.<br /><br />
                Please <a href="/passwordrecovery">try again</a>.
              </p>
            </div>
          )
          : (
            <div>
              <p>
                Everything looks good! Choose your new password and you will be logged in immediately.
              </p>

              <Segment raised style={{ width: "40%", margin: "auto", marginBottom: '50px' }}>
                <Form onSubmit={submitNewPassword} loading={loading} autoComplete="on">
                  <Form.Input
                    focus
                    required
                    type="password"
                    autoComplete="password"
                    name="password"
                    value={form.password || ""}
                    placeholder="Password"
                    icon="lock"
                    iconPosition="left"
                    onChange={handleChange}
                  />
                  <Form.Input
                    required
                    type="password"
                    autoComplete="password"
                    name="confirmPassword"
                    value={form.confirmPassword || ""}
                    placeholder="Confirm Password"
                    icon="lock"
                    iconPosition="left"
                    onChange={handleChange}
                  />
                  <div className="centered">
                    {errorMessage && errorMessage.length > 0 && (
                      <div>
                        <Label basic pointing color="red" style={{ marginTop: -10 }}>
                          {errorMessage}
                        </Label>
                      </div>
                    )}
                    <Button type="submit" primary style={{ marginTop: "10px" }}>
                      Save New Password
                      <Icon name="arrow circle right" />
                    </Button>
                  </div>
                </Form>
              </Segment>
            </div>
          )}
      </div>
    </div>
  );
}
