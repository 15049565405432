/**
 * Environment configuration
 */
export const config = {
	ENVIRONMENT: process.env.REACT_APP_ENV,
	API_URL: process.env.REACT_APP_API_URL,
	STATIC_URL: process.env.REACT_APP_STATIC_URL,
	MAKER_MONTHLY_PRICEID: process.env.REACT_APP_STRIPE_MAKER_MONTHLY_PRICEID,
	MAKER_ANNUALLY_PRICEID: process.env.REACT_APP_STRIPE_MAKER_ANNUALLY_PRICEID,
	PROFESSIONAL_MONTHLY_PRICEID: process.env.REACT_APP_STRIPE_PROFESSIONAL_MONTHLY_PRICEID,
	PROFESSIONAL_ANNUALLY_PRICEID: process.env.REACT_APP_STRIPE_PROFESSIONAL_ANNUALLY_PRICEID,
	COMMERCIAL_MONTHLY_PRICEID: process.env.REACT_APP_STRIPE_COMMERCIAL_MONTHLY_PRICEID,
	COMMERCIAL_ANNUALLY_PRICEID: process.env.REACT_APP_STRIPE_COMMERCIAL_ANNUALLY_PRICEID,
	RECAPTCHA_SITEKEY: process.env.REACT_APP_RECAPTCHA_SITEKEY,
  TURNSTILE_SITEKEY: process.env.REACT_APP_TURNSTILE_SITEKEY,
	GOOGLEMAPS_APIKEY: process.env.REACT_APP_GOOGLEMAPS_APIKEY,
	GOOGLE_ANALYTICS_TRACKINGID: process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKINGID,
};
