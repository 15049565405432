import React, { useState, useEffect } from "react";
import { Route, Routes, Outlet } from "react-router";
import { useLocation } from "react-router-dom";
import ReactGA from 'react-ga';
import { createBrowserHistory as createHistory } from "history";
import { config } from "./common/config";
import { isAuthenticated, isAdmin } from "./common/authentication";
import { toast } from "react-toastify";

// routing
import PageWrapper from "./routes/PageWrapper";
import AdminWrapper from "./routes/AdminWrapper";

// layouts
import { FancyLayout } from "./layouts/FancyLayout";
import { BasicLayout } from "./layouts/BasicLayout";

// components
import ScrollToTop from "./components/ScrollToTop";
import ErrorModal from "./components/ErrorModal";
import LicenseErrorModal from "./components/LicenseErrorModal";

// styles
import "./custom.css";
import './bootstrap.css'; /* needed for the carousel control */

// pages
import { LandingPage } from "./pages/LandingPage";
import { Home } from "./pages/Home";
import { Login } from "./pages/Login";
import { Signup } from "./pages/Signup";
import { Features } from "./pages/Features";
import { InventoryManagement } from "./pages/InventoryManagement";
import { DataIntegrations } from "./pages/DataIntegrations";
import { ContactUs } from "./pages/ContactUs";
import { Community } from "./pages/Community";
import { Support } from "./pages/Support";
import { ConfirmEmail } from "./pages/ConfirmEmail";
import { Unsubscribe } from "./pages/Unsubscribe";
import { PasswordRecovery } from "./pages/PasswordRecovery";
import { ResetPassword } from "./pages/ResetPassword";
import { BinnerBin } from "./pages/BinnerBin";
import Inventory from "./pages/Inventory";
import Search from "./pages/Search";
import Boms from "./pages/Boms";
import Bom from "./pages/Bom";
import { Datasheets } from "./pages/Datasheets";
import { AddDatasheet } from "./pages/datasheets/AddDatasheet";
import LowInventory from "./pages/LowInventory";
import { OrderImport } from "./pages/OrderImport";
import { PartTypes } from "./pages/PartTypes";
import Project from "./pages/Project";
import { ExportData } from "./pages/ExportData";
import { Printing } from "./pages/printing/Home";
import { PrintLabels } from "./pages/printing/PrintLabels";
import { PrintLabels2 } from "./pages/printing/PrintLabels2";
import { BulkPrint } from "./pages/printing/BulkPrint";
import { Tools } from "./pages/Tools";
import { Swarm } from "./pages/Swarm";
import { Api } from "./pages/Api";
import { Settings } from "./pages/Settings";
import { Account } from "./pages/Account";
import { Success } from "./pages/payments/Success";
import { Cancelled } from "./pages/payments/Cancelled";
import { Subscriptions } from "./pages/Subscriptions";
import { OhmsLawCalculator } from "./pages/tools/OhmsLawCalculator";
import { ResistorColorCodeCalculator } from "./pages/tools/ResistorColorCodeCalculator";
import { VoltageDividerCalculator } from "./pages/tools/VoltageDividerCalculator";
import { BarcodeScanner } from "./pages/tools/BarcodeScanner";
import { KnowledgeBase } from "./pages/KnowledgeBase";
import { NotFound } from "./pages/NotFound";
import { AccessDenied } from "./pages/AccessDenied";

// help
import { Help } from './pages/help/Home';
import { Scanning } from './pages/help/Scanning';
import { ApiIntegrations } from './pages/help/ApiIntegrations';

// admin
import { HomeAdmin } from "./pages/admin/HomeAdmin";
import { DatasheetsAdmin } from "./pages/admin/datasheets/DatasheetsAdmin";
import { DatasheetsAdminEdit } from "./pages/admin/datasheets/DatasheetsAdminEdit";
import { Crawler } from "./pages/admin/datasheets/Crawler";
import { CrawlerFullHistory } from "./pages/admin/datasheets/CrawlerFullHistory";
import { PartNumbers } from "./pages/admin/partNumbers/PartNumbers";
import { PartNumber } from "./pages/admin/partNumbers/PartNumber";
import { PartNumberManufacturer } from "./pages/admin/partNumbers/PartNumberManufacturer";
import { PartNumberSupplier } from "./pages/admin/partNumbers/PartNumberSupplier";
import { Pinouts } from "./pages/admin/pinouts/Pinouts";
import { Pinout } from "./pages/admin/pinouts/Pinout";
import { Users } from "./pages/admin/users/Users";
import { User } from "./pages/admin/users/User";
import { BulkEmail } from "./pages/admin/bulkEmail/BulkEmail";
import { Stats } from "./pages/admin/Stats";

// initialize google analytics with our tracking id
ReactGA.initialize(config.GOOGLE_ANALYTICS_TRACKINGID);

export const App = (props) => {
  const history = createHistory(props);
  const location = useLocation();
  const [modalTitle, setModalTitle] = useState("");
  const [url, setUrl] = useState("");
  const [header, setHeader] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [stackTrace, setStackTrace] = useState("");
  const [status, setStatus] = useState("");
  const [userIsAdmin] = useState(isAdmin());
  const [error, setError] = useState({
    modalTitle: "",
    url: "",
    header: "",
    errorMessage: "",
    stackTrace: ""
  });
  const [licenseError, setLicenseError] = useState({
    modalTitle: "",
    url: "",
    header: "",
    errorMessage: ""
  });
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [documentSize, setDocumentSize] = useState([
    document.documentElement.scrollWidth,
    document.documentElement.scrollHeight,
  ]);

  useEffect(() => {
    // track each route change with Google Analytics
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [location]);

  const updateView = () => {
    // used for activating avatar images on largely vertical pages
    setWindowSize([window.innerWidth, window.innerHeight]);
    setDocumentSize([document.documentElement.scrollWidth, document.documentElement.scrollHeight]);
    //console.log('window size', window.innerWidth, window.innerHeight);
    //console.log('document size', document.documentElement.scrollWidth, document.documentElement.scrollHeight);
    if (document.documentElement.scrollHeight > window.innerHeight) {
      // enable avatar
      window.avatar = true;
    } else {
      window.avatar = false;
    }
  };

  useEffect(() => {
    updateView();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  useEffect(() => {
    const handleWindowResize = () => {
      updateView();
    };
    window.addEventListener('resize', handleWindowResize);

    window.showErrorWindow = showErrorWindow;
    window.showLicenseErrorWindow = showLicenseErrorWindow;

    // provide a UI toast when we have authenticated with DigiKey
    if (props.searchParams) {
      const [searchParams] = props.searchParams;
      const apiAuthSuccess = searchParams.get("api-authenticate") || "";
      if (apiAuthSuccess !== "") {
        let apiName = searchParams.get("api") || "External Api";
        // validate the name
        switch (apiName.toLowerCase()) {
          case "digikey":
          case "mouser":
          case "swarm":
          case "octopart":
          case "arrow":
            break;
          default:
            apiName = "External Api";
            break;
        }
        toast.dismiss();
        if (apiAuthSuccess) toast.success(`Successfully authenticated with ${apiName}!`);
        else toast.error(`Failed to authenticate with ${apiName}!`);
      }
    }
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const showErrorWindow = (errorObject) => {
    if (errorObject && Object.prototype.toString.call(errorObject) === "[object String]") {
      setError({ modalTitle: "Error", url: "", header: "", errorMessage: errorObject, stackTrace: "" });
    } else if (errorObject)
      setError({ modalTitle: "API Error", url: errorObject.url, header: errorObject.exceptionType, errorMessage: errorObject.message, stackTrace: errorObject.stackTrace });
    else setError({ modalTitle: "API Error", url: "", header: "", errorMessage: "", stackTrace: "" });
  };

  const showLicenseErrorWindow = (errorObject) => {
    if (errorObject && Object.prototype.toString.call(errorObject) === "[object String]") {
      setLicenseError({ modalTitle: "License Limitation", url: "", header: "", errorMessage: errorObject });
    } else if (errorObject) this.setState({ licenseError: { modalTitle: "License Limitation", url: errorObject.url, header: errorObject.exceptionType, errorMessage: errorObject.message } });
    else setLicenseError({ modalTitle: "License Limitation", url: "", header: "", errorMessage: "" });
  };

  window.showErrorWindow = showErrorWindow;
  // provide a UI toast when we have authenticated with DigiKey
  if (props.searchParams) {
    const [searchParams] = props.searchParams;
    const apiAuthSuccess = searchParams.get("api-authenticate") || '';
    if (apiAuthSuccess !== '') {
      let apiName = searchParams.get("api") || 'External Api';
      // validate the name
      switch(apiName.toLowerCase()){
        case "digikey":
        case "mouser":
        case "swarm":
        case "octopart":
        case "arrow":
          break;
        default:
          apiName = 'External Api';
          break;
      }
      if (apiAuthSuccess)
        toast.success(`Successfully authenticated with ${apiName}!`);
      else
        toast.error(`Failed to authenticate with ${apiName}!`);
    }
  }

  return (
    <div>
      <Routes>
        <Route exact path="/" element={isAuthenticated() ? <BasicLayout><Home/></BasicLayout> : <FancyLayout><LandingPage /></FancyLayout>}  />
        {/* Use the fancy layout template for public pages */}
        <Route element={<FancyLayout history={history}><Outlet /></FancyLayout>}>
          <Route exact path="/login" element={<Login/>} />
          <Route exact path="/signup" element={<Signup/>} />
          <Route exact path="/features" element={<Features/>} />
          <Route exact path="/inventory-management" element={<InventoryManagement/>} />
          <Route exact path="/data-integrations" element={<DataIntegrations/>} />
          <Route exact path="/contact" element={<ContactUs/>} />
          <Route exact path="/community" element={<Community/>} />
          <Route exact path="/swarm" element={<Swarm/>} />
          <Route exact path="/support" element={<Support/>} />
          <Route exact path="/support/help" element={<Support/>} />
          <Route exact path="/confirm/:confirmationCode" element={<ConfirmEmail/>} />
          <Route exact path="/passwordrecovery" element={<PasswordRecovery/>} />
          <Route exact path="/resetpassword/:token/:emailAddress" element={<ResetPassword/>} />
          <Route exact path="/unsubscribe" element={<Unsubscribe/>} />
          <Route exact path="/unsubscribe/:emailAddress" element={<Unsubscribe/>} />
          <Route exact path="/payments/cancelled" element={<Cancelled/>} />
          <Route exact path="/binnerbin" element={<BinnerBin/>} />
          <Route exact path="/binnerbins" element={<BinnerBin/>} />
          <Route exact path="/binner-bin" element={<BinnerBin/>} />
          <Route exact path="/binner-bins" element={<BinnerBin/>} />
          <Route exact path="/kb/:kbNumber" element={<KnowledgeBase/>} />
          <Route path="*" element={<NotFound/>}/>
        </Route>
        {/* Use the basic layout template */}
        <Route element={<BasicLayout history={history}><Outlet /></BasicLayout>}>
          <Route path="/accessdenied" element={<PageWrapper><AccessDenied/></PageWrapper>}/>
          <Route exact path="/inventory/add/:partNumberToAdd" element={<PageWrapper><Inventory /></PageWrapper>} />
          <Route exact path="/inventory/add" element={<PageWrapper><Inventory/></PageWrapper>} />
          <Route exact path="/inventory/:partNumber" element={<PageWrapper><Inventory/></PageWrapper>} />
          <Route exact path="/inventory" element={<PageWrapper><Search/></PageWrapper>} />
          <Route exact path="/datasheets" element={<PageWrapper><Datasheets/></PageWrapper>} />
          <Route exact path="/datasheets/add" element={<PageWrapper><AddDatasheet/></PageWrapper>} />
          <Route exact path="/subscriptions" element={<PageWrapper><Subscriptions/></PageWrapper>} />
          <Route path="/payments/success" element={<PageWrapper><Success/></PageWrapper>} />
          <Route path="/lowstock" element={<PageWrapper><LowInventory/></PageWrapper>} />
          <Route path="/import" element={<PageWrapper><OrderImport/></PageWrapper>} />
          <Route path="/partTypes" element={<PageWrapper><PartTypes/></PageWrapper>} />
          <Route exact path="/project/:project" element={<PageWrapper><Project /></PageWrapper>} />
          <Route exact path="/bom/:project" element={<PageWrapper><Bom /></PageWrapper>} />
          <Route path="/bom" element={<PageWrapper><Boms /></PageWrapper>} />
          <Route path="/exportData" element={<PageWrapper><ExportData/></PageWrapper>} />
          <Route path="/printing" element={<PageWrapper><Printing /></PageWrapper>} />
          <Route path="/printing/printLabels" element={<PageWrapper><PrintLabels /></PageWrapper>} />
          <Route path="/printing/labelTemplates" element={<PageWrapper><PrintLabels2 /></PageWrapper>} />
          <Route path="/printing/bulkPrint" element={<PageWrapper><BulkPrint /></PageWrapper>} />

          <Route exact path="/tools" element={<PageWrapper><Tools/></PageWrapper>} />
          <Route path="/settings" element={<PageWrapper><Settings/></PageWrapper>} />
          <Route exact path="/account" element={<PageWrapper><Account/></PageWrapper>} />
          <Route exact path="/api" element={<PageWrapper><Api/></PageWrapper>} />
          <Route path="/tools/ohmslaw" element={<PageWrapper><OhmsLawCalculator/></PageWrapper>} />
          <Route path="/tools/resistor" element={<PageWrapper><ResistorColorCodeCalculator/></PageWrapper>} />
          <Route path="/tools/voltagedivider" element={<PageWrapper><VoltageDividerCalculator/></PageWrapper>}/>
          <Route path="/tools/barcodescanner" element={<PageWrapper><BarcodeScanner/></PageWrapper>}/>
          <Route exact path="/help" element={<PageWrapper><Help /></PageWrapper>} />
          <Route path="/help/scanning" element={<PageWrapper><Scanning /></PageWrapper>} />
          <Route path="/help/api-integrations" element={<PageWrapper><ApiIntegrations /></PageWrapper>} />

          {/** Admin */}
          <Route exact path="/admin" element={<AdminWrapper><HomeAdmin/></AdminWrapper>} />
          <Route exact path="/admin/datasheets" element={<AdminWrapper><DatasheetsAdmin/></AdminWrapper>} />
          <Route exact path="/admin/datasheets/:datasheetId" element={<AdminWrapper><DatasheetsAdminEdit/></AdminWrapper>} />
          <Route exact path="/admin/datasheets/crawler" element={<AdminWrapper><Crawler/></AdminWrapper>} />
          <Route exact path="/admin/datasheets/crawler/:urlHistoryId" element={<AdminWrapper><CrawlerFullHistory/></AdminWrapper>} />
          <Route exact path="/admin/partnumbers" element={<AdminWrapper><PartNumbers/></AdminWrapper>} />
          <Route exact path="/admin/partnumbers/:partNumberId" element={<AdminWrapper><PartNumber/></AdminWrapper>} />
          <Route exact path="/admin/partnumbers/:partNumberId/:partNumberManufacturerId" element={<AdminWrapper><PartNumberManufacturer/></AdminWrapper>} />
          <Route exact path="/admin/partnumbers/:partNumberId/:partNumberManufacturerId/:partNumberManufacturerSupplierId" element={<AdminWrapper><PartNumberSupplier/></AdminWrapper>} />
          <Route exact path="/admin/pinouts" element={<AdminWrapper><Pinouts/></AdminWrapper>} />
          <Route exact path="/admin/pinouts/:pinoutId" element={<AdminWrapper><Pinout/></AdminWrapper>} />
          <Route exact path="/admin/users/" element={<AdminWrapper><Users/></AdminWrapper>} />
          <Route exact path="/admin/users/:userId" element={<AdminWrapper><User/></AdminWrapper>} />
          <Route exact path="/admin/bulkEmail" element={<AdminWrapper><BulkEmail/></AdminWrapper>} />
          <Route exact path="/admin/stats" element={<AdminWrapper><Stats/></AdminWrapper>} />
        </Route>
      </Routes>
      <ErrorModal context={error} />
      <LicenseErrorModal context={licenseError} />
    </div>
  );
}

export default App;
