import React from "react";
import { useNavigate } from "react-router-dom";

export function PublicFooter(props) {
  const navigate = useNavigate();
  return (
    <footer className="footer-area clearfix">
      <div className="top-footer-area section_padding_50_0">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="single-footer-widget mb-100">
                <a href="/" className="mb-50 d-block" onClick={(e) => { e.preventDefault(); navigate('/'); }}>
                  <img src="img/core-img/logo.png" alt="" />
                </a>
                <p>Binner was created by a maker, for makers. Inventory management, part metadata and so much more!</p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="single-footer-widget mb-100">
                <h5>Fast links</h5>
                <ul>
                  <li>
                    <a href="/" onClick={(e) => { e.preventDefault(); navigate('/'); }}>Home</a>
                  </li>
                  <li>
                    <a href="/features" onClick={(e) => { e.preventDefault(); navigate('/features'); }}>Product Features</a>
                  </li>
                  <li>
                    <a href="/signup" onClick={(e) => { e.preventDefault(); navigate('/signup'); }}>Create a Free Account</a>
                  </li>
                  <li>
                    <a href="/contact" onClick={(e) => { e.preventDefault(); navigate('/contact'); }}>Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="single-footer-widget mb-100">
                {/*<h5>Blog</h5>
                <div className="footer-single--blog-post">
                  <a href="#" className="blog-post-date">
                    <p>23 September, 2017</p>
                  </a>
                  <a href="#" className="blog-post-title">
                    <h6>Why we love stock photos</h6>
                  </a>
                </div>
                <div className="footer-single--blog-post">
                  <a href="#" className="blog-post-date">
                    <p>22 September, 2017</p>
                  </a>
                  <a href="#" className="blog-post-title">
                    <h6>Designin on grid. A few rules. </h6>
                  </a>
                </div>
                <div className="footer-single--blog-post">
                  <a href="#" className="blog-post-date">
                    <p>20 September, 2017</p>
                  </a>
                  <a href="#" className="blog-post-title">
                    <h6>2017 World Design Congress</h6>
                  </a>
                </div>
                */}
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3">
              <div className="single-footer-widget mb-100">
                <h5>Contact Info</h5>
                <div className="footer-single-contact-info d-flex">
                  <div className="contact-icon">
                    <img src="img/core-img/map.png" alt="" />
                  </div>
                  <p>Vancouver, Canada</p>
                </div>
                <div className="footer-single-contact-info d-flex">
                  <div className="contact-icon">
                    <img src="img/core-img/message.png" alt="" />
                  </div>
									<div className="single-footer-widget mb-100">
										<ul>
											<li><a href="mailto:support@binner.io">support@binner.io</a></li>
										</ul>
									</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="centered" style={{color: '#636468', paddingBottom: '20px'}}>{window.version && (<div>Version: {window.version}</div>)}</div>
      </div>
      <div className="footer-bottom-area">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-12 h-100">
              <div className="footer-bottom-content h-100 d-md-flex justify-content-between align-items-center">
                <div className="copyright-text"></div>
                <div className="footer-social-info">
									{/* 
                  <a href="#">
                    <i className="fa fa-pinterest" aria-hidden="true"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-facebook" aria-hidden="true"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-twitter" aria-hidden="true"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-dribbble" aria-hidden="true"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-behance" aria-hidden="true"></i>
                  </a>
                  <a href="#">
                    <i className="fa fa-linkedin" aria-hidden="true"></i>
                  </a>
									*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
