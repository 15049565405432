import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { PublicFooter } from "./PublicFooter";
import { PublicHeader } from "./PublicHeader";
import customEvents from '../common/customEvents';
import $ from "jquery";
import useScript from "../hooks/useScript";
import 'react-toastify/dist/ReactToastify.css';

export function FancyLayout(props) {
  const { t } = useTranslation('en');
  const location = useLocation();
  const noop = t('noop', "-do-not-translate-");
  const [version, setVersion] = useState(null);

  const updateVersion = useCallback((data) => {
    setVersion(data.version);
  }, [setVersion]);

  useEffect(() => {
    customEvents.subscribe("version", (data) => updateVersion(data));
  }, [updateVersion]);

  useScript("js/plugins.js");
  useScript("js/active.js");

  useEffect(() => {
    // fade out the preloader
    $("#preloader").fadeOut("slow", function () {
      $(this).remove();
    });
  }, [location]);

  return (
    <div className="layout">
      <div id="preloader">
        <div className="mosh-preloader"></div>
      </div>
      <PublicHeader />
      <div>{props.children}</div>
      <PublicFooter />
    </div>
  );
}
