import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "underscore";
import { Table, Form, Segment, Button, Icon, Confirm, Breadcrumb, TextArea, Grid, Card, Placeholder, Image, Header } from "semantic-ui-react";
import { toast } from "react-toastify";
import Carousel from "react-bootstrap/Carousel";
import { fetchApi, getErrorsString } from "../../../common/fetchApi";
import { viewDatasheet } from "../../../common/visitors";
import { getFormattedTime } from "../../../common/datetime";
import { getProductShotImage, getResourceImageUrl, getResourceDatasheetUrl } from "../../../common/resources";
import { Sources, GetTypeDropdown } from "../../../common/Types";
import { FormHeader } from "../../../components/FormHeader";
import "../Admin.css";

const ProductImageIntervalMs = 10000;

export function PartNumber(props) {
  const [loading, setLoading] = useState(true);
  const [loadingPartTypes, setLoadingPartTypes] = useState(true);
  const [confirmDeleteIsOpen, setConfirmDeleteIsOpen] = useState(false);
  const [confirmDeletePartNumberManufacturerIsOpen, setConfirmDeletePartNumberManufacturerIsOpen] = useState(false);
  const [deleteSelectedItem, setDeleteSelectedItem] = useState(null);
  const [deletePartNumberManufacturerSelectedItem, setDeletePartNumberManufacturerSelectedItem] = useState(null);
  const [currentProductImage, setCurrentProductImage] = useState(null);
  const [currentDatasheet, setCurrentDatasheet] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentDatasheetIndex, setCurrentDatasheetIndex] = useState(0);
  const [datasheetTitle, setDatasheetTitle] = useState(null);
  const [datasheetPartName, setDatasheetPartName] = useState(null);
  const [datasheetDescription, setDatasheetDescription] = useState(null);
  const [datasheetManufacturer, setDatasheetManufacturer] = useState(null);
  const [partNumber, setPartNumber] = useState({
    partNumberId: 0,
    name: "",
    description: "",
    alternateNames: "",
    partTypeId: 0,
    source: 0,
    partNumberManufacturers: []
  });
  const [sources] = useState(GetTypeDropdown(Sources));
  const [partTypes, setPartTypes] = useState([]);
  const navigate = useNavigate();
  const params = useParams();

  const { partNumberId } = params;

  useEffect(() => {
    fetchPartTypes().then(() => {
      fetchPartNumber();
    });

    function fetchPartNumber() {
      setLoading(true);
      fetchApi(`api/partnumber?partNumberId=${partNumberId}`).then((response) => {
        const { data } = response;
        if (data) {
          // update the page of data, as long as its not already in the data
          setPartNumber(data);
          const allImageMetadata = data.partNumberManufacturers.flatMap((pm) => pm.imageMetadata.map((im) => im));
          if (allImageMetadata.length > 0) {
            setCurrentProductImage(allImageMetadata[0]);
          }
          const allDatasheets = data.partNumberManufacturers.flatMap((pm) => pm.datasheets.map((im) => im));
          if (allDatasheets.length > 0) {
            setCurrentDatasheet(allDatasheets[0]);
            // set the first datasheet meta display, because the carousel component doesnt fire the first event
            setDatasheetMeta(allDatasheets[0]);
          }
        }
        setLoading(false);
      });
    }

    function fetchPartTypes() {
      setLoadingPartTypes(true);
      return fetchApi("api/partType/list").then((response) => {
        const { data } = response;
        const partTypes = _.sortBy(
          data.map((item) => {
            return {
              key: item.partTypeId,
              value: item.partTypeId,
              text: item.name
            };
          }),
          "text"
        );
        setPartTypes(partTypes);
        setLoadingPartTypes(false);
      });
    }
  }, []);

  const onSubmit = (e) => {
    fetchApi(`api/partnumber`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(partNumber)
    }).then((response) => {
      if (response.responseObject.ok) {
        toast.success("Saved part number!");
        navigate(-1);
      } else {
        const errorMessage = getErrorsString(response);
        console.error(errorMessage);
        toast.error(errorMessage);
      }
    });
  };

  const deletePartNumber = (e, partNumber) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    fetchApi(`api/partnumber`, {
      method: "DELETE",
      body: partNumber.partNumberId
    }).then(() => {
      setLoading(false);
      setConfirmDeleteIsOpen(false);
    });
  };

  const deletePartNumberManufacturer = (e, partNumber) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    fetchApi(`api/partnumber/partnumbermanufacturer`, {
      method: "DELETE",
      body: partNumber.partNumberId
    }).then(() => {
      setLoading(false);
      setConfirmDeleteIsOpen(false);
    });
  };

  const handleSetDefaultImage = (e, productImage) => {
    e.preventDefault();
    e.stopPropagation();
    fetchApi(`api/partnumber/defaultimage`, {
      method: "PUT",
      body: {
        partNumberId: partNumber.partNumberId,
        imageId: productImage.imageId
      }
    }).then(() => {
      setPartNumber({...partNumber, 
        defaultImageId: productImage.imageId
      });
      setCurrentImageIndex(0);
      toast.success('Default image updated!');
    });
  };

  const handleSetPrimaryDatasheet = (e, datasheet) => {
    e.preventDefault();
    e.stopPropagation();
    fetchApi(`api/partnumber/primarydatasheet`, {
      method: "PUT",
      body: {
        partNumberId: partNumber.partNumberId,
        datasheetId: datasheet.datasheetId
      }
    }).then(() => {
      setPartNumber({...partNumber, 
        primaryDatasheetId: datasheet.datasheetId
      });
      setCurrentDatasheetIndex(0);
      toast.success('Primary datasheet updated!');
    });
  };

  const handleChange = (e, control) => {
    partNumber[control.name] = control.value;
    setPartNumber({ ...partNumber });
  };

  const confirmDeleteOpen = (e, partNumber) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteSelectedItem(partNumber);
    setConfirmDeleteIsOpen(true);
  };

  const confirmDeleteClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteSelectedItem(null);
    setConfirmDeleteIsOpen(false);
  };

  const confirmDeletePartNumberManufacturerOpen = (e, partNumberManufacturer) => {
    e.preventDefault();
    e.stopPropagation();
    setDeletePartNumberManufacturerSelectedItem(partNumberManufacturer);
    setConfirmDeletePartNumberManufacturerIsOpen(true);
  };

  const confirmDeletePartNumberManufacturerClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDeletePartNumberManufacturerSelectedItem(null);
    setConfirmDeletePartNumberManufacturerIsOpen(false);
  };

  const getSupplier = (supplierId) => {
    switch (supplierId) {
      case 1:
        return "DigiKey";
      case 2:
        return "Mouser";
      default:
        return "";
    }
  };

  const handleVisitLink = (e, url) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('open', url);
    window.open(url, "_blank");
  };

  const getDatasheetUrl = (datasheet) => {
    return getResourceDatasheetUrl(datasheet.resourceSourceUrl, datasheet.resourcePath);
  };

  const onCurrentImageChanged = (activeIndex, control) => {
    // we need to convert activeIndex to the manufacturer and image being referenced
    const allImageMetadata = getImagesFlat(partNumber);
    setCurrentProductImage(allImageMetadata[activeIndex]);
    setCurrentImageIndex(activeIndex);
  }

  const onCurrentDatasheetChanged = (activeIndex, control) => {
    // we need to convert activeIndex to the manufacturer and image being referenced
    const allDatasheets = getDatasheetsFlat(partNumber);
    setCurrentDatasheet(allDatasheets[activeIndex]);
    setCurrentDatasheetIndex(activeIndex);
    setDatasheetMeta(allDatasheets[activeIndex]);
  }

  const setDatasheetMeta = (datasheet) => {
    setDatasheetPartName("partname");
    setDatasheetTitle(datasheet.title);
    setDatasheetDescription(datasheet.shortDescription);
    setDatasheetManufacturer(datasheet.manufacturerName);
  };

  const getImagesFlat = (part) => {
    let allImageMetadata = part.partNumberManufacturers.flatMap((pm) => pm.imageMetadata.map((im) => im));
    allImageMetadata = _.uniq(allImageMetadata, p => p.imageId);
    // sort by which one is default
    return allImageMetadata.sort((a,b) => {
      return Number(b.imageId === partNumber.defaultImageId) - Number(a.imageId === partNumber.defaultImageId);
    });
  };

  const getDatasheetsFlat = (part) => {
    let allDatasheets = part.partNumberManufacturers.flatMap((pm) => pm.datasheets.map((im) => im));
    allDatasheets = _.uniq(allDatasheets, p => p.datasheetId);
    // sort by which one is default
    return allDatasheets.sort((a,b) => {
      return Number(b.datasheetId === partNumber.primaryDatasheetId) - Number(a.datasheetId === partNumber.primaryDatasheetId);
    });
  };

  const handleOpenPartNumberManufacturer = (e, partNumberManufacturer) => {
    navigate(`${partNumberManufacturer.partNumberManufacturerId}`);
  };

  const allImages = getImagesFlat(partNumber);
  const allDatasheets = getDatasheetsFlat(partNumber);
  return (
    <div className="admin-container">
      <Breadcrumb>
        <Breadcrumb.Section href="/admin">Admin</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section href="/admin/partnumbers">Part Numbers</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>Part Number</Breadcrumb.Section>
      </Breadcrumb>
      <FormHeader name="Part Numbers Management" to="..">
        Edit an existing Part Number.
      </FormHeader>
      <div>
        <Confirm
          open={confirmDeleteIsOpen}
          onCancel={confirmDeleteClose}
          onConfirm={(e) => deletePartNumber(e, deleteSelectedItem)}
          content="Are you sure you want to delete this part number?"
        />
        <Confirm
          open={confirmDeletePartNumberManufacturerIsOpen}
          onCancel={confirmDeletePartNumberManufacturerClose}
          onConfirm={(e) => deletePartNumberManufacturer(e, deletePartNumberManufacturerSelectedItem)}
          content="Are you sure you want to delete this part number manufacturer?"
        />
        <Segment loading={loading} secondary>
            <Grid celled>
              <Grid.Row>
                <Grid.Column width={11}>
                  <Form onSubmit={onSubmit}>

                    <Form.Input label="Part Name" required focus placeholder="LM358" value={partNumber.name || ""} name="name" onChange={handleChange} />
                    <Form.Input
                      label="Description"
                      control={TextArea}
                      placeholder="The LM358 is a dual-operational amplifier"
                      value={partNumber.description || ""}
                      name="description"
                      onChange={handleChange}
                    />

                    <Form.Input
                      label="Alternate Names"
                      placeholder="BLM358,SLM358-T"
                      value={partNumber.alternateNames || ""}
                      name="alternateNames"
                      onChange={handleChange}
                    />
                    <Form.Dropdown
                      label="Part Type"
                      placeholder="Part Type"
                      loading={loadingPartTypes}
                      search
                      selection
                      value={partNumber.partTypeId || ""}
                      name="partTypeId"
                      options={partTypes}
                      onChange={handleChange}
                    />
                    <Form.Dropdown
                      label="Source"
                      placeholder="Source"
                      search
                      selection
                      value={partNumber.source}
                      name="source"
                      options={sources}
                      onChange={handleChange}
                    />
                    <Form.Group className="celled">
                      <Form.Field>
                        <label>Swarm Part Number Id</label>
                        {partNumber.swarmPartNumberId || "(none)"}
                      </Form.Field>
                      <Form.Field>
                        <label>Created By Supplier</label>
                        {getSupplier(partNumber.createdFromSupplierId)}
                      </Form.Field>
                      <Form.Field>
                        <label>Date Created</label>
                        {getFormattedTime(partNumber.dateCreatedUtc)}
                      </Form.Field>
                      <Form.Field>
                        <label>Date Pruned</label>
                        {partNumber.datePrunedUtc ? getFormattedTime(partNumber.datePrunedUtc) : "(none)"}
                      </Form.Field>
                    </Form.Group>
                    <Button type="submit" primary style={{ marginTop: "10px" }}>
                      <Icon name="save" />
                      Save
                    </Button>
                    {partNumber.partNumberId > 0 && (
                      <Button type="button" title="Delete" onClick={(e) => confirmDeleteOpen(e, partNumber)}>
                        <Icon name="delete" />
                        Delete
                      </Button>
                    )}
                </Form>

                </Grid.Column>
                <Grid.Column width={5} style={{ textAlign: "center" }}>
                  
                  { /* Product Images Carousel */ }
                  <Card color="blue">
                    <Card.Content>
                      <Header as='h4'><Icon name='images' />Product Images</Header>
                    </Card.Content>

                    {allImages && allImages.length > 0 ? (
                      <Carousel variant="dark" interval={ProductImageIntervalMs} className="centered" activeIndex={currentImageIndex} onSelect={onCurrentImageChanged}>
                      {allImages.map((imageMetadata, imageKey) => (
                        <Carousel.Item key={imageKey}>
                          <Image src={getResourceImageUrl(imageMetadata.resourceSourceUrl, imageMetadata.resourcePath, imageMetadata.imageId)} size="large" />
                          <Carousel.Caption>
                            <h5 className="defaultOverlay">{imageMetadata.imageId === partNumber.defaultImageId ? "Default" : ""}</h5>
                          </Carousel.Caption>
                        </Carousel.Item>
                      ))}
                      </Carousel> 
                    ) : (
                      <Placeholder>
                        <img src='/image/microchip.png' className="square" alt="" />
                      </Placeholder>
                    )}

                    <Card.Content extra>
                      <Button primary icon="photo" label="Set as Default" disabled={partNumber.partNumberManufacturers.length === 0} onClick={(e) => handleSetDefaultImage(e, currentProductImage)} />
                    </Card.Content>
                  </Card>

                  { /* Datasheets Carousel */ }

                  <Card id="datasheets" color="green">
                    <Card.Content>
                      <Header as='h4'><Icon name='file pdf' />Datasheets</Header>
                    </Card.Content>

                    {allDatasheets && allDatasheets.length > 0 ? (
                    <div>
                      <Carousel variant="dark" interval={null} style={{cursor: 'pointer'}} activeIndex={currentDatasheetIndex} onSelect={onCurrentDatasheetChanged}>
                      {allDatasheets.map((datasheet, datasheetKey) => (
                        <Carousel.Item key={datasheetKey} onClick={(e)=> handleVisitLink(e, getResourceDatasheetUrl(datasheet.resourceSourceUrl, datasheet.resourcePath))} data={datasheet}>
                          {datasheet.imageCount > 0
                            ? <Image src={getResourceImageUrl(datasheet.resourceSourceUrl, datasheet.resourcePath, 1)} size="large" />
                            : <Image src={getResourceImageUrl(datasheet.resourceSourceUrl, "datasheetcover")} size="large" />
                          }
                          <Carousel.Caption>
                            <h5 className="defaultOverlay">{datasheet.datasheetId === partNumber.primaryDatasheetId ? "Default" : ""}</h5>
                          </Carousel.Caption>
                        </Carousel.Item>
                      ))}
                      </Carousel>
                      <Card.Content style={{textAlign: 'left'}}>
                        <Card.Header>{datasheetTitle}</Card.Header>
                        <Card.Meta>{datasheetPartName}, {datasheetManufacturer}</Card.Meta>
                        <Card.Description>{datasheetDescription}</Card.Description>
                      </Card.Content>
                    </div>
                    ) : (
                      <Placeholder>
                        <img src='/image/datasheet.png' className="square" alt="" />
                        <Placeholder.Header>
                          <Placeholder.Line length='very long' />
                          <Placeholder.Line length='medium' />
                          <Placeholder.Line length='short' />
                        </Placeholder.Header>
                      </Placeholder>
                    )}
                    <Card.Content extra>
                      <Button primary icon="file" label="Set as Primary" disabled={allDatasheets.length === 0} onClick={(e) => handleSetPrimaryDatasheet(e, currentDatasheet)} />
                    </Card.Content>
                  </Card>
                  
                </Grid.Column>
              </Grid.Row>
            </Grid>
            
        </Segment>

        <Segment secondary loading={loading}>
          <Table compact celled selectable size="small" className="partstable">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Part Name</Table.HeaderCell>
                <Table.HeaderCell>Manufacturer</Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>
                <Table.HeaderCell>Alternate Names</Table.HeaderCell>
                <Table.HeaderCell>Created Via</Table.HeaderCell>
                <Table.HeaderCell>Suppliers</Table.HeaderCell>
                <Table.HeaderCell>Image</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell style={{ width: "60px" }}></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {partNumber &&
                partNumber.partNumberManufacturers &&
                partNumber.partNumberManufacturers.map((partNumberManufacturer, i) => (
                  <Table.Row key={i} onClick={(e) => handleOpenPartNumberManufacturer(e, partNumberManufacturer)}>
                    <Table.Cell>{partNumberManufacturer.name}</Table.Cell>
                    <Table.Cell>{partNumberManufacturer.manufacturerName}</Table.Cell>
                    <Table.Cell>{partNumberManufacturer.description}</Table.Cell>
                    <Table.Cell>{partNumberManufacturer.alternateNames}</Table.Cell>
                    <Table.Cell>{getSupplier(partNumberManufacturer.createdFromSupplierId)}</Table.Cell>
                    <Table.Cell>{partNumberManufacturer.suppliers.length}</Table.Cell>
                    <Table.Cell>
                      {partNumberManufacturer.imageMetadata && partNumberManufacturer.imageMetadata.length > 0 && (
                        <img
                          src={getProductShotImage(partNumberManufacturer.imageMetadata)}
                          alt={partNumberManufacturer.name}
                          className="product productshot"
                        />
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {partNumberManufacturer.datasheets.map((datasheet, key) => (
                        <Button key={key} size="mini" icon="file pdf" title="View Datasheet" onClick={(e) => viewDatasheet(e, datasheet)} label="Datasheet" />
                      ))}
                    </Table.Cell>
                    <Table.Cell>
                      <Button size="mini" icon="delete" onClick={(e) => confirmDeletePartNumberManufacturerOpen(e, partNumberManufacturer)} />
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </Segment>
      </div>
    </div>
  );
}
