import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Form, Segment, Icon, Label, Popup, TextArea, Menu, List, Card, Search } from "semantic-ui-react";
import _ from "underscore";
import { escapeRegex } from "../common/Utils";
import { fetchApi } from "../common/fetchApi";
import { KBs } from "./KBs";

const MaxMessageLength = 512;

const source = _.map(KBs, (kb, key) => ({
  key,
  title: kb.q,
  tag: kb.tag,
  description: kb.a && kb.a.length > 0 ? kb.a.substring(0, 32) + "..." : "",
}));

const initialState = {
  loading: false,
  results: [],
  value: "",
};

export function Support(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  let defaultPage = "knowledgebase";
  if (location.pathname === '/support/help')
    defaultPage = "support";

  const searchReducer = (state, action) => {
    switch (action.type) {
      case "CLEAN_QUERY":
        return initialState;
      case "START_SEARCH":
        return { ...state, loading: true, value: action.query };
      case "FINISH_SEARCH":
        return { ...state, loading: false, results: action.results };
      case "UPDATE_SELECTION":
        navigate(`/kb/${action.selection.tag}`);
        return { ...state, value: action.selection.title };
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = React.useReducer(searchReducer, initialState);
  const { results, value } = state;
  const [loading, setLoading] = useState(false);
  const [ticketId, setTicketId] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [activePage, setActivePage] = useState(defaultPage);
  const [topicOptions] = useState([
    {
      key: 1,
      value: 1,
      text: "I have a question",
    },
    {
      key: 2,
      value: 2,
      text: "Suggest a new feature",
    },
    {
      key: 3,
      value: 3,
      text: "Billing",
    },
    {
      key: 4,
      value: 4,
      text: "Bug Report",
    },
    {
      key: 5,
      value: 5,
      text: "Feedback",
    },
    {
      key: 6,
      value: 6,
      text: "Other",
    },
  ]);
  const [form, setForm] = useState({
    topic: 0,
    message: "",
  });
  const timeoutRef = React.useRef();
  const handleSearchChange = React.useCallback((e, data) => {
    clearTimeout(timeoutRef.current);
    dispatch({ type: "START_SEARCH", query: data.value });

    timeoutRef.current = setTimeout(() => {
      if (data.value.length === 0) {
        dispatch({ type: "CLEAN_QUERY" });
        return;
      }

      const re = new RegExp(escapeRegex(data.value), "i");
      const isMatch = (result) => re.test(result.title);

      dispatch({
        type: "FINISH_SEARCH",
        results: _.filter(source, isMatch),
      });
    }, 300);
  }, []);
  React.useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const submitContactMessage = (e) => {
    if (form.message.length <= 0) {
      setErrorMessage(`Message must be specified.`);
      return;
    }
    if (form.message.length > MaxMessageLength) {
      setErrorMessage(
        `Message length must be less than ${MaxMessageLength} characters.`
      );
      return;
    }

    setLoading(true);
    return fetchApi("api/support/ticket", {
      method: "POST",
      body: JSON.stringify({
        topic: _.find(topicOptions, (i) => i.value === form.topic).text,
        message: form.message,
      }),
    }).then((response) => {
      const { data } = response;
      setLoading(false);

      if (data.isSuccessful) {
        toast.success("Your message was sent!");
        setTicketId(data.ticketId);
      } else {
        toast.error(data.message);
        setErrorMessage(data.message);
      }
    });
  };

  const handleChange = (e, control) => {
    e.preventDefault();
    e.stopPropagation();

    setForm((form) => ({
      ...form,
      [control.name]: control.value,
    }));
  };

  const handleMenuClick = (e, control) => {
    e.preventDefault();
    e.stopPropagation();
    setActivePage(control.name);
  };

  const renderKnowledgebasePage = () => {
    return (
      <div>
        <Card.Group>
          {/* Questions block 1 */}
          <Card style={{ width: "500px" }}>
            <Card.Content>
              <Card.Header>Popular Questions</Card.Header>
            </Card.Content>
            <Card.Content>
              <List divided verticalAlign="middle" className="faqList">
                <List.Item onClick={(e) => navigate("/kb/configure-digikey")}>
                  <List.Content floated="right">
                    <Icon name="angle right" size="large" />
                  </List.Content>
                  <List.Content>How do I configure DigiKey?</List.Content>
                </List.Item>
                <List.Item onClick={(e) => navigate("/kb/print-a-label")}>
                  <List.Content floated="right">
                    <Icon name="angle right" size="large" />
                  </List.Content>
                  <List.Content>How do I print a label?</List.Content>
                </List.Item>
                <List.Item onClick={(e) => navigate("/kb/import-parts")}>
                  <List.Content floated="right">
                    <Icon name="angle right" size="large" />
                  </List.Content>
                  <List.Content>
                    How do I import parts from my order?
                  </List.Content>
                </List.Item>
                <List.Item onClick={(e) => navigate("/kb/barcode-support")}>
                  <List.Content floated="right">
                    <Icon name="angle right" size="large" />
                  </List.Content>
                  <List.Content>Does Binner have barcode support?</List.Content>
                </List.Item>
              </List>
            </Card.Content>
          </Card>

          {/* Questions block 2 */}
          <Card style={{ width: "500px" }}>
            <Card.Content>
              <Card.Header>Frequently Asked</Card.Header>
            </Card.Content>
            <Card.Content>
              <List divided verticalAlign="middle" className="faqList">
                <List.Item onClick={(e) => navigate("/kb/multiple-languages")}>
                  <List.Content floated="right">
                    <Icon name="angle right" size="large" />
                  </List.Content>
                  <List.Content>
                    Does Binner support multiple languages?
                  </List.Content>
                </List.Item>
                <List.Item onClick={(e) => navigate("/kb/export-data")}>
                  <List.Content floated="right">
                    <Icon name="angle right" size="large" />
                  </List.Content>
                  <List.Content>
                    Can I export my data to self-host Binner?
                  </List.Content>
                </List.Item>
                <List.Item onClick={(e) => navigate("/kb/suggest-new-feature")}>
                  <List.Content floated="right">
                    <Icon name="angle right" size="large" />
                  </List.Content>
                  <List.Content>
                    I'd like to suggest a new feature.
                  </List.Content>
                </List.Item>
                <List.Item onClick={(e) => navigate("/kb/supplier-api-keys")}>
                  <List.Content floated="right">
                    <Icon name="angle right" size="large" />
                  </List.Content>
                  <List.Content>
                    Do I need to get my own API keys for each supplier?
                  </List.Content>
                </List.Item>
              </List>
            </Card.Content>
          </Card>

          {/* Questions block 3 */}
          <Card style={{ width: "500px" }}>
            <Card.Content>
              <Card.Header>Your Membership</Card.Header>
            </Card.Content>
            <Card.Content>
              <List divided verticalAlign="middle" className="faqList">
                <List.Item onClick={(e) => navigate("/kb/cancel-subscription")}>
                  <List.Content floated="right">
                    <Icon name="angle right" size="large" />
                  </List.Content>
                  <List.Content>
                    How can I cancel my paid subscription?
                  </List.Content>
                </List.Item>
                <List.Item onClick={(e) => navigate("/kb/pause-subscription")}>
                  <List.Content floated="right">
                    <Icon name="angle right" size="large" />
                  </List.Content>
                  <List.Content>
                    Can I pause my membership for a month?
                  </List.Content>
                </List.Item>
                <List.Item
                  onClick={(e) => navigate("/kb/downgrade-subscription")}
                >
                  <List.Content floated="right">
                    <Icon name="angle right" size="large" />
                  </List.Content>
                  <List.Content>
                    If I downgrade my membership, do I lose my parts?
                  </List.Content>
                </List.Item>
              </List>
            </Card.Content>
          </Card>
        </Card.Group>
      </div>
    );
  };

  const renderKnowledgebase = () => {
    return (
      <div>
        <div className="centered">
          <Form style={{ marginBottom: "50px" }}>
            <Search
              loading={loading}
              placeholder="Type your question..."
              onResultSelect={(e, data) =>
                dispatch({ type: "UPDATE_SELECTION", selection: data.result })
              }
              onSearchChange={handleSearchChange}
              results={results}
              value={value}
              className="kbSearch"
            />
          </Form>
        </div>
        <div>
          <Segment raised style={{ width: "70%", margin: "0 auto" }}>
            {renderKnowledgebasePage()}
          </Segment>
        </div>

        <div style={{ height: "50px" }}></div>
      </div>
    );
  };

  const renderContactSupport = () => {
    return (
      <div>
        <div className="centered">
          <p>
            Need help? Use the follo wing form to get a response within 24
            hours.
          </p>
        </div>

        <Segment
          raised
          style={{ margin: "50px auto", width: "60%", textAlign: "left" }}
        >
          {ticketId && ticketId.length > 0 ? (
            <div className="centered">
              <h2>Support Ticket</h2>
              <h3>
                Your Ticket Id: <span className="blue">#{ticketId}</span>
              </h3>

              <p>
                We will respond to you within 24 hours regarding your ticket via
                email.
              </p>
            </div>
          ) : (
            <Form
              onSubmit={submitContactMessage}
              loading={loading}
              autoComplete="on"
            >
              <Popup
                hideOnScroll
                content="Specifying the topic helps us get your message to the right person."
                trigger={
                  <Form.Field>
                    <Form.Dropdown
                      label="What can we help you with today?"
                      placeholder="Select a topic..."
                      selection
                      value={form.topic || 0}
                      name="topic"
                      options={topicOptions}
                      onChange={handleChange}
                    />
                  </Form.Field>
                }
              />

              <div>
                <Form.Field
                  control={TextArea}
                  label="Message"
                  value={form.message || ""}
                  onChange={handleChange}
                  name="message"
                  className={
                    form.message.length > MaxMessageLength ? "error" : ""
                  }
                  style={{ minHeight: "150px" }}
                />
                <div style={{ textAlign: "right", fontSize: "0.8em" }}>
                  {form.message.length} of {MaxMessageLength}
                </div>
              </div>

              <div className="centered">
                {errorMessage && errorMessage.length > 0 && (
                  <div>
                    <Label
                      basic
                      pointing
                      color="red"
                      style={{ marginTop: -10 }}
                    >
                      {errorMessage}
                    </Label>
                  </div>
                )}
                <Button type="submit" primary style={{ marginTop: "10px" }}>
                  Submit
                  <Icon name="arrow circle right" />
                </Button>
              </div>
            </Form>
          )}
        </Segment>
      </div>
    );
  };

  return (
    <div>
      {/* ***** Breadcrumb Area Start ***** */}
      <div className="mosh-breadcumb-area">
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-12">
              <div className="bradcumbContent">
                <h2>Support</h2>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/");
                        }}
                      >
                        Home
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Support
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ***** Breadcrumb Area End ***** */}

      <div>
        <Menu icon="labeled" widths={2}>
          <Menu.Item
            name="knowledgebase"
            active={activePage === "knowledgebase"}
            onClick={handleMenuClick}
          >
            <Icon name="help" />
            Support Knowledge Base
          </Menu.Item>

          <Menu.Item
            name="support"
            active={activePage === "support"}
            onClick={handleMenuClick}
          >
            <Icon name="at" />
            Ask for Help
          </Menu.Item>
        </Menu>

        <div style={{ margin: "0 auto" }}>
          {activePage === "support" ? renderContactSupport() : ""}
          {activePage === "knowledgebase" ? renderKnowledgebase() : ""}
        </div>
      </div>
    </div>
  );
}
