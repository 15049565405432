import React, { useRef } from 'react';
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { fetchApi, getErrorsString } from "../common/fetchApi";
import { toast } from "react-toastify";
import "./Swarm.css";

export function Swarm (props) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const formRef = useRef();

  return (
    <div className='swarm'>
			{/* ***** Breadcrumb Area Start ***** */}
			<div className="mosh-breadcumb-area">
					<div className="container h-100">
							<div className="row h-100 align-items-center">
									<div className="col-12">
											<div className="bradcumbContent">
													<h2>Swarm Api</h2>
													<nav aria-label="breadcrumb">
															<ol className="breadcrumb">
																	<li className="breadcrumb-item"><a href="/" onClick={(e) => { e.preventDefault(); navigate('/'); }}>Home</a></li>
																	<li className="breadcrumb-item active" aria-current="page">Swarm Api</li>
															</ol>
													</nav>
											</div>
									</div>
							</div>
					</div>
			</div>
			{/* ***** Breadcrumb Area End ***** */}

			{/* ***** About Us Area Start ***** */}
			<section className="mosh-aboutUs-area section_padding_100_0">
					<div className="container">
							<div className="row align-items-center">
									<div className="col-12 col-md-6">
											<div className="mosh-about-us-content dark">
													<div className="section-heading">
															<p>Description</p>
															<h2>Open Parts Api</h2>
													</div>
													<p>
														The Swarm api provides free programmatic access to part information, datasheets, schematics, pinouts and product images. Swarm is a collection of data from part manufacturers collated from many sources.
													</p>
													<p>
														No account is required to access the Swarm api, however you will be subject to access frequency restrictions. The open source project <a href="https://github.com/replaysMike/Binner">Binner</a> has built-in access to the Swarm api included with it's distribution. If you need higher api limits for your use, signing up for a <a href="/signup">free account</a> and getting your own api access key you will receive increased limits. Binner paid subscription levels receive even higher limits.
													</p>
											</div>
									</div>
									<div className="col-12 col-md-6">
											<img src="img/bg-img/api.jpg" width={700} height={464} alt="" />
									</div>
							</div>
							<div className='api-details'>
								Api & Documentation: <a href="https://swarm.binner.io"><span>https://swarm.binner.io</span></a><br/><br/>
							</div>
					</div>
			</section>
			{/* ***** About Us Area End ***** */}

			<section className="mosh-about-features-area section_padding_100">
					<div className="container">
							<div className="row align-items-center">

									<div className="col-12 col-md-4">
											<div className="mosh-features-thumb wow fadeIn" data-wow-delay="0.5s">
													<img src="img/bg-img/speedlimit.png" alt="" />
											</div>
									</div>

									<div className="col-12 col-md-8">
											<div className="mosh-about-us-content">
													<div className="section-heading">
														<p>API</p>
														<h2>Access Limits</h2>
													</div>
													<div className="row">
														<div className="col-12 col-sm-6">
																<div className="single-feature-area d-flex">
																	<div className="feature-icon mr-30">
																		<img src="img/core-img/settings-2.png" alt="" />
																	</div>
																	<div className="feature-content">
																		<h5>Unregistered</h5>
																		<span>Applies to open source <a href="https://github.com/replaysMike/Binner">Binner</a></span>
																		<ul>
																			<li><b>Max Requests</b></li>
																			<li>Per minute: <span>15</span></li>
																			<li>Per hour: <span>150</span></li>
																			<li>Per day: <span>450</span></li>
																		</ul>
																	</div>
																</div>
															</div>
															<div className="col-12 col-sm-6">
																<div className="single-feature-area d-flex">
																	<div className="feature-icon mr-30">
																			<img src="img/core-img/settings-2.png" alt="" />
																	</div>
																	<div className="feature-content">
																		<h5>Beginner (Free)</h5>
																		<span>&nbsp;</span>
																		<ul>
																			<li><b>Max Requests</b></li>
																			<li>Per minute: <span>30</span></li>
																			<li>Per hour: <span>250</span></li>
																			<li>Per day: <span>750</span></li>
																		</ul>
																	</div>
																</div>
															</div>
															<div className="col-12 col-sm-6">
																<div className="single-feature-area d-flex">
																	<div className="feature-icon mr-30">
																		<img src="img/core-img/settings-2.png" alt="" />
																	</div>
																	<div className="feature-content">
																		<h5>Maker</h5>
																		<span>&nbsp;</span>
																		<ul>
																			<li><b>Max Requests</b></li>
																			<li>Per minute: <span>45</span></li>
																			<li>Per hour: <span>400</span></li>
																			<li>Per day: <span>1000</span></li>
																		</ul>
																	</div>
																</div>
															</div>
															<div className="col-12 col-sm-6">
																<div className="single-feature-area d-flex">
																	<div className="feature-icon mr-30">
																		<img src="img/core-img/settings-2.png" alt="" />
																	</div>
																	<div className="feature-content">
																		<h5>Professional</h5>
																		<span>&nbsp;</span>
																		<ul>
																			<li><b>Max Requests</b></li>
																			<li>Per minute: <span>50</span></li>
																			<li>Per hour: <span>600</span></li>
																			<li>Per day: <span>3000</span></li>
																		</ul>
																	</div>
																</div>
															</div>
													</div>
													<a href="/signup" className="btn mosh-btn mt-50" onClick={(e) => { e.preventDefault(); navigate('/signup'); }}>Signup</a>
											</div>
									</div>
							</div>
					</div>
			</section>
			{/* ***** Features Area End ***** */}
		</div>
  );
}
