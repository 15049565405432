import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "underscore";
import { Table, Form, Segment, Button, Icon, Confirm, Breadcrumb, Header, Dropdown, Popup } from "semantic-ui-react";
import { fetchApi, getErrorsString } from "../../common/fetchApi";
import { formatCurrency, formatNumber } from "../../common/Utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FormHeader } from "../../components/FormHeader";
import "./Admin.css";
import "./Stats.css";

export function Stats(props) {
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({ membership: { newRevenue: 0}, data: {}});
	const [monthStats, setMonthStats] = useState({ membership: { newRevenue: 0}, data: {}});
  const [startDate, setStartDate] = useState(new Date());
	const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const navigate = useNavigate();
	const monthOptions = [
		{ key: 0, text: 'January', value: 0},
		{ key: 1, text: 'Febuary', value: 1},
		{ key: 2, text: 'March', value: 2},
		{ key: 3, text: 'April', value: 3},
		{ key: 4, text: 'May', value: 4},
		{ key: 5, text: 'June', value: 5},
		{ key: 6, text: 'July', value: 6},
		{ key: 7, text: 'August', value: 7},
		{ key: 8, text: 'September', value: 8},
		{ key: 9, text: 'October', value: 9},
		{ key: 10, text: 'November', value: 10},
		{ key: 11, text: 'December', value: 11},
	];

  useEffect(() => {
    fetchStats();

    function fetchStats() {
      setLoading(true);
      fetchApi(`api/stats?start=${new Date(startDate.toDateString()).toJSON()}`).then((response) => {
				if (response.responseObject.ok) {
					const { data } = response;
					if (data) {
						setStats(data);
						setLoading(false);
					}
				}
      });
    }
  }, [startDate]);

	useEffect(() => {
    fetchStats();

    function fetchStats() {
      setLoading(true);
			const now = new Date();
			const start = new Date(now.getFullYear(), selectedMonth, 1, 0, 0, 0, 0);
			const end = new Date(now.getFullYear(), selectedMonth, new Date(now.getFullYear(), selectedMonth, 0).getDate() - 1, 23, 59, 59, 999);
      fetchApi(`api/stats?start=${start.toJSON()}&end=${end.toJSON()}`).then((response) => {
				if (response.responseObject.ok) {
					const { data } = response;
					if (data) {
						setMonthStats(data);
						setLoading(false);
					}
				}
      });
    }
  }, [selectedMonth]);

	const handleDateChange = (date) => {
		setStartDate(date);
	};

	const handleMonthChange = (e, control) => {
		setSelectedMonth(control.value);
	};

	const getColor = (value) => {
		if (value > 0)
			return { color: "blue" };
		return { color: "grey" };
	};

  return (
    <div className="admin-container">
      <Breadcrumb>
        <Breadcrumb.Section href="/admin">Admin</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>Stats</Breadcrumb.Section>
      </Breadcrumb>
      <FormHeader name="Statistics" to="..">
        All system statistics.
      </FormHeader>

      <Segment raised>
        <Header>Membership</Header>

				{/* BY DATE */}

        <Segment color="green">
					<h4 style={{display: 'inline-block'}}>By Date</h4>
          <div style={{ float: "right", display: "flex", padding: "8px 4px" }}>
            <div style={{ marginRight: "5px" }}>
              <b>Select date:</b>
            </div>
            <div>
              <DatePicker selected={startDate} onChange={handleDateChange} />
            </div>
          </div>
          <Table className="stats">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Registrations</Table.HeaderCell>
                <Table.HeaderCell>Subscriptions</Table.HeaderCell>
                <Table.HeaderCell>New Revenue</Table.HeaderCell>
                <Table.HeaderCell>Support Tickets</Table.HeaderCell>
                <Table.HeaderCell>S.T Messages</Table.HeaderCell>
								<Table.HeaderCell>Email List Subscribers</Table.HeaderCell>
								<Table.HeaderCell>Logins</Table.HeaderCell>
								<Table.HeaderCell>Active</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell><Popup content={<p>Created / Confirmed</p>} trigger={<span><Icon name="user" {...getColor(stats.membership.usersCreated)} />{stats.membership.usersCreated} / {stats.membership.emailConfirmedCount}</span>} /></Table.Cell>
                <Table.Cell>
                  <Popup content={<p>Created / Cancelled</p>} trigger={<span><Icon name="rss" {...getColor(stats.membership.subscriptionsCreated)} />{stats.membership.subscriptionsCreated} / {stats.membership.subscriptionsCancelled}</span>} />
                </Table.Cell>
                <Table.Cell><Popup content={<p>The amount of new revenue created by new subscriptions</p>} trigger={<span>{formatCurrency(stats.membership.newRevenue)}</span>} /></Table.Cell>
                <Table.Cell><Icon name="ticket" {...getColor(stats.membership.supportTicketsCreated)} />{stats.membership.supportTicketsCreated}</Table.Cell>
                <Table.Cell><Icon name="mail" {...getColor(stats.membership.supportTicketMessagesCreated)} />{stats.membership.supportTicketMessagesCreated}</Table.Cell>
								<Table.Cell><Icon name="mail" {...getColor(stats.membership.emailSubscriberCount)} />{stats.membership.emailSubscriberCount}</Table.Cell>
								<Table.Cell><Icon name="key" {...getColor(stats.membership.loginsCount)} />{stats.membership.loginsCount}</Table.Cell>
								<Table.Cell><Popup content={<p>The number of currently active users</p>} trigger={<span><Icon name="users" {...getColor(stats.membership.activeUsersCount)} />{stats.membership.activeUsersCount}</span>}/></Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>

					<Table className="stats">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Parts Created</Table.HeaderCell>
                <Table.HeaderCell>Projects Created</Table.HeaderCell>
                <Table.HeaderCell>PCBs Produced</Table.HeaderCell>
								<Table.HeaderCell>Api Searches</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell><Icon name="microchip" {...getColor(stats.data.partsCreated)} />{stats.data.partsCreated}</Table.Cell>
                <Table.Cell><Icon name="folder" {...getColor(stats.data.projectsCreated)} />{stats.data.projectsCreated}</Table.Cell>
                <Table.Cell><i className="pcb-icon tiny" />{stats.data.pcbsProduced}</Table.Cell>
								<Table.Cell><Icon name="search" {...getColor(stats.data.apiSearchHistoryCount)} />{stats.data.apiSearchHistoryCount}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Segment>

				{/* MONTH TOTAL */}

				<Segment color="blue">
					<h4 style={{display: 'inline-block'}}>By Month - {_.find(monthOptions, x => x.value === selectedMonth).text}</h4>
          <div style={{ float: "right", display: "flex", padding: "8px 4px" }}>
            <div style={{ marginRight: "5px" }}>
              <b>Select month:</b>
            </div>
            <div>
              <Dropdown 
								value={selectedMonth}
								options={monthOptions}
								onChange={handleMonthChange}
							/>
            </div>
          </div>
          <Table className="stats">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Registrations</Table.HeaderCell>
                <Table.HeaderCell>Subscriptions</Table.HeaderCell>
                <Table.HeaderCell>New Revenue</Table.HeaderCell>
                <Table.HeaderCell>Support Tickets</Table.HeaderCell>
                <Table.HeaderCell>S.T Messages</Table.HeaderCell>
								<Table.HeaderCell>Email List Subscribers</Table.HeaderCell>
								<Table.HeaderCell>Logins</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
							<Table.Cell><Popup content={<p>Created / Confirmed</p>} trigger={<span><Icon name="user" {...getColor(monthStats.membership.usersCreated)} />{monthStats.membership.usersCreated} / {monthStats.membership.emailConfirmedCount}</span>} /></Table.Cell>
                <Table.Cell>
                  <Popup content={<p>Created / Cancelled</p>} trigger={<span><Icon name="rss" {...getColor(monthStats.membership.subscriptionsCreated)} />{monthStats.membership.subscriptionsCreated} / {monthStats.membership.subscriptionsCancelled}</span>} />
                </Table.Cell>
                <Table.Cell><Popup content={<p>The amount of new revenue created by new subscriptions</p>} trigger={<span>{formatCurrency(monthStats.membership.newRevenue)}</span>} /></Table.Cell>
                <Table.Cell><Icon name="ticket" {...getColor(monthStats.membership.supportTicketsCreated)} />{monthStats.membership.supportTicketsCreated}</Table.Cell>
                <Table.Cell><Icon name="mail" {...getColor(monthStats.membership.supportTicketMessagesCreated)} />{monthStats.membership.supportTicketMessagesCreated}</Table.Cell>
								<Table.Cell><Icon name="mail" {...getColor(monthStats.membership.emailSubscriberCount)} />{monthStats.membership.emailSubscriberCount}</Table.Cell>
								<Table.Cell><Icon name="key" {...getColor(monthStats.membership.loginsCount)} />{monthStats.membership.loginsCount}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>

					<Table className="stats">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Parts Created</Table.HeaderCell>
                <Table.HeaderCell>Projects Created</Table.HeaderCell>
                <Table.HeaderCell>PCBs Produced</Table.HeaderCell>
								<Table.HeaderCell>Api Searches</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell><Icon name="microchip" {...getColor(monthStats.data.partsCreated)} />{monthStats.data.partsCreated}</Table.Cell>
                <Table.Cell><Icon name="folder" {...getColor(monthStats.data.projectsCreated)} />{monthStats.data.projectsCreated}</Table.Cell>
                <Table.Cell><i className="pcb-icon tiny" />{monthStats.data.pcbsProduced}</Table.Cell>
								<Table.Cell><Icon name="search" {...getColor(monthStats.data.apiSearchHistoryCount)} />{monthStats.data.apiSearchHistoryCount}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Segment>
      </Segment>
    </div>
  );
}
