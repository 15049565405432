export const KBs = [
  { id: 1000, s: 1, tag: 'configure-digikey', q: 'How do I configure DigiKey?', a: 'DigiKey comes pre-configured with Binner Cloud. Enabled by default, it can be enabled/disabled via the Settings page. DigiKey also requires a login session when using it to search parts or access your orders. You will be prompted to login to DigiKey and authorize the Binner application when a login session is not available.' },
  { id: 1001, s: 1, tag: 'print-a-label', q: 'How do I print a label?', a: (<div>Labels can be printed in the inventory screen, or via the <a href="/print">Print Labels</a> section found on the main page.<br/><br/>To customize how labels are printed, such as the visual template design or the label paper source type visit the <a href="/settings">Settings</a> Part Label Template section.</div>) },
  { id: 1002, s: 1, tag: 'import-parts', q: 'How do I import parts from my order?', a: (<div>Importing parts from your DigiKey, Mouser or Arrow order in some cases require an API Key depending on the supplier. The <a href="/import">Order Import</a> page allows you to enter an Order # from the appropriate supplier and you will be presented with a screen that lets you select which part you would like to import as well as other Order details.<br/><div className="image"><img src="/image/kb/kb-1002-order-import.png" alt="kb-1002-order-import" /><span>Figure 1 - Importing an Order</span></div><br/><br/><h3>Importing a DigiKey Order</h3><p>Specify your DigiKey order # and click Search.</p><h3>Importing a Mouser Order</h3><p>Specify your Mouser Web Order # and click Search. Note that Mouser only supports Web Order #'s, so ensure you are using the correct order # otherwise no data will be found.</p><h3>Importing an Arrow Order</h3><p>Specify your Arrow order # and click Search.</p></div>) },
  { id: 1003, s: 1, tag: 'barcode-support', q: 'Does Binner have barcode support?', a: (<div>Yes Binner supports part search by barcode, printing UPC barcode labels, and inventory quantity updates. Almost any wireless/bluetooth/usb barcode will function with Binner.<br/><br/><h3>Scanning a Part</h3><p>On the <a href="/inventory">Inventory</a> page, scan your part. When the scan is received your part will become the active part on the screen.</p><h3>Bulk Scan</h3><p>On the <a href="/inventory">Inventory</a> page, click the UPC barcode image at the top of the page. You will be presented with a Bulk Scan dialog and can proceed scanning. Each part you scan will appear on the screen and you can mass update quantities and bin locations as needed to prevent lots of trips back and forth to the computer.</p><h3>Printing a Barcode Label</h3><p>The default part template includes a scannable barcode using your part's part number. You can also print custom labels on the <a href="/print">Print Labels</a> page and encode any value you like to the barcode.</p></div>) },

  { id: 2001, s: 2, tag: 'multiple-languages', q: 'Does Binner support multiple languages?', a: (<div>Yes Binner now supports multiple languages and we will keep adding more support. To change your language, visit your <a href="/account">Account Settings</a>.<br/><br/>If you would like to suggest a language or contribute a translation, <a href="/support/help">contact support</a>.</div>) },
  { id: 2002, s: 2, tag: 'export-data', q: 'Can I export my data to self-host Binner?', a: (<div>Yes you can export all of your data no matter your subscription level. We currently support export via to Excel, CSV or MS SQL formats. After exporting your preferred format you can then import the data to your local Binner installation via the same section.<br/><br/>Visit the <a href="/exportData">Import/Export</a> section to export your data. </div>) },
  { id: 2003, s: 2, tag: 'suggest-new-feature', q: 'I\'d like to suggest a new feature.', a: (<div>We love feature requests! Anything we can do that makes it more useful to you we will happily work it into the development schedule.<br/><br/>Send us your feature request by <a href="/support/help">contacting support</a>, or create a <a href="https://github.com/replaysMike/Binner/issues">GitHub issue</a>.</div>) },
  { id: 2004, s: 2, tag: 'supplier-api-keys', q: 'Do I need to get my own API keys for each supplier?', a: (<div>The short answer is <i>it depends</i>.<br/><br/>DigiKey and Mouser don't require your own api keys for the part search APIs. However, for Mouser if you wish to access your order history they currently require an Order API key which is tied to your account. Octopart requires a dedicated API key for each user currently, if you wish to use it for additional part data.<br/><br/>If you are using the open-source <a href="https://github.com/replaysMike/Binner">Binner</a> then you need your own API keys for all integrations with DigiKey, Mouser and Octopart. You do not however require any API keys for using Binner Swarm as your free part data source.</div>) },

  { id: 3001, s: 3, tag: 'cancel-subscription', q: 'How can I cancel my paid subscription?', a: (<div>We are sorry to see you go! You can cancel your subscription via <a href="/subscriptions">Manage Subscription</a> located in the user menu. If you encounter any trouble doing so, please <a href="/support/help">contact support</a> and we will help you out immediately.</div>) },
  { id: 3002, s: 3, tag: 'pause-subscription', q: 'Can I pause my membership for a month?', a: (<div>Yes! If you need to pause your subscription for a while you can do so via <a href="/subscriptions">Manage Subscription</a> located in the user menu. If you encounter any trouble doing so, please <a href="/support/help">contact support</a> and we will help you out immediately.</div>) },
  { id: 3003, s: 3, tag: 'downgrade-subscription', q: 'If I downgrade my membership, do I lose my parts?', a: (<div>No! If you stop your subscription you will still have access to your inventory, but won't be able to add new parts if you are outside of the current part limit of your active (or free) subscription. You can always <a href="/exportData">export</a> your data too if you decide you want to use it elsewhere.</div>) },
];