import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { PacmanLoader } from "react-spinners";
import _ from "underscore";
import { Table, Form, Segment, Button, Icon, Label, Input, Popup, Confirm, Breadcrumb } from "semantic-ui-react";
import { fetchApi } from "../../../common/fetchApi";
import { isAdmin } from "../../../common/authentication";
import { Sources, GetTypeName } from "../../../common/Types";
import { CrawlerStatistics } from "../../../components/CrawlerStatistics";
import { InifiniteScrollTable } from "../../../components/InfiniteScrollTable";
import { getFriendlyElapsedTime, getTimeDifference, getFormattedTime, getBasicElapsed } from "../../../common/datetime";
import { FormHeader } from "../../../components/FormHeader";
import "../Admin.css";

export function Crawler(props) {
  const maxResults = 20;
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const urlHistoryDataRef = useRef([]);
  const [addVisible, setAddVisible] = useState(false);
  const [confirmDeleteIsOpen, setConfirmDeleteIsOpen] = useState(false);
  const [deleteSelectedItem, setDeleteSelectedItem] = useState(null);
  const [addUrlHistory, setAddUrlHistory] = useState({
    url: "",
    crawlDelay: null,
    maxCrawlDepth: null,
    maxPagesToCrawl: null
  });
  const [column, setColumn] = useState(null);
  const [direction, setDirection] = useState(null);
  const [urlLabel, setUrlLabel] = useState("http://");
  const navigate = useNavigate();

  useEffect(() => {
    fetchUrlHistory(currentPage);
    function fetchUrlHistory(page = 1) {
      if (hasMoreData) {
        setLoading(true);
        fetchApi(`api/crawler/urlHistory?page=${page}&results=${maxResults}&includeChildren=false`).then((response) => {
          const { data } = response;
          if (data && data.length > 0) {
            // update the page of data, as long as its not already in the data
            let newData = [...urlHistoryDataRef.current];
            for (let i = 0; i < data.length; i++) {
              const index = newData.findIndex((x) => x.urlHistoryId === data[i].urlHistoryId);
              if (index === -1) {
                newData.push(data[i]);
              }
            }
            urlHistoryDataRef.current = newData;
          }
          if (data.length < maxResults) {
            // no more data, received back 0 or less than maxResults
            setHasMoreData(false);
          }
          setLoading(false);
        });
      }
    }
  }, [currentPage, hasMoreData]);

  const fetchNextPage = () => {
    if (hasMoreData) setCurrentPage(currentPage + 1);
  };

  const handleSort = (clickedColumn) => () => {
    if (column !== clickedColumn) {
      setColumn(clickedColumn);
      urlHistoryDataRef.current = _.sortBy(urlHistoryDataRef.current, [clickedColumn]);
      setDirection("ascending");
    } else {
      urlHistoryDataRef.current = urlHistoryDataRef.current.reverse();
      setDirection(direction === "ascending" ? "descending" : "ascending");
    }
  };

  const handleVisitLink = (e, url) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(`${urlLabel}${url}`, "_blank");
  };

  const queueUrl = (e, urlHistoryRequest) => {
    setLoading(true);
    const request = {
      url: `${urlLabel}${urlHistoryRequest.url}`,
      crawlDelay: urlHistoryRequest.crawlDelay, // '00:00:00'
      maxPagesToCrawl: urlHistoryRequest.maxPagesToCrawl, // 10
      maxCrawlDepth: urlHistoryRequest.maxCrawlDepth // 10
    };
    fetchApi(`api/crawler/urlHistory`, {
      method: "POST",
      body: JSON.stringify(request)
    }).then(() => {
      setLoading(false);
      setAddVisible(false);
      refreshClean();
    });
  };

  const refreshClean = () => {
    // refresh
    urlHistoryDataRef.current = [];
    setHasMoreData(true);
    setCurrentPage(1);
  };

  const requeueUrl = (e, urlHistory) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    // only dateQueuedUtc=null is required
    const request = {
      ...urlHistory,
      dateQueuedUtc: null,
      elapsed: null,
      lastCrawlTimeUtc: null,
      pdfCount: 0,
      urlCount: 0
    };
    fetchApi(`api/crawler/urlHistory`, {
      method: "PUT",
      body: JSON.stringify(request)
    }).then(() => {
      setLoading(false);
    });
  };

  const deleteUrl = (e, urlHistory) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    fetchApi(`api/crawler/urlHistory`, {
      method: "DELETE",
      body: urlHistory.urlHistoryId
    }).then(() => {
      const newUrlHistoryData = _.filter(urlHistoryDataRef.current, (item) => item.urlHistoryId !== urlHistory.urlHistoryId);
      urlHistoryDataRef.current = [...newUrlHistoryData];
      setLoading(false);
      setConfirmDeleteIsOpen(false);
    });
  };

  const handleChange = (e, control) => {
    switch (control.name) {
      case "url":
        if (control.value.startsWith("http://")) {
          control.value = control.value.replace("http://", "");
          setUrlLabel("http://");
        } else if (control.value.startsWith("https://")) {
          control.value = control.value.replace("https://", "");
          setUrlLabel("https://");
        }
        break;
      default:
        break;
    }
    addUrlHistory[control.name] = control.value;
    setAddUrlHistory({ ...addUrlHistory });
  };

  const handleShowAdd = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAddVisible(!addVisible);
  };

  const getUrlHistoryState = (urlHistory) => {
    if (urlHistory.dateQueuedUtc === null) {
      return "Queued";
    } else if (urlHistory.elapsed === null) {
      return "Crawling";
    } else if (urlHistory.errorMessage !== null) {
      return "Error";
    }
    return "Finished";
  };

  const getUrlHistoryLabel = (urlHistory) => {
    var state = getUrlHistoryState(urlHistory);
    switch (state) {
      case "Crawling":
        return (
          <div style={{ marginLeft: "-50px" }} title={state}>
            <PacmanLoader color="#06bcee" size="12px" />
          </div>
        );
      case "Finished":
        return <Icon name="check circle" title={state} />;
      case "Queued":
        return <Icon name="plus" title={state} />;
      default:
        return state;
    }
  };

  const getRowClassname = (urlHistory) => {
    var state = getUrlHistoryState(urlHistory);
    switch (state) {
      case "Crawling":
        return "running";
      case "Error":
        return "error";
      case "Finished":
        return "complete";
      case "Queued":
      default:
        return "";
    }
  };

  const openUrlHistory = (e, urlHistory) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`${urlHistory.urlHistoryId}`);
  };

  const confirmDeleteOpen = (e, urlHistory) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteSelectedItem(urlHistory);
    setConfirmDeleteIsOpen(true);
  };

  const confirmDeleteClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteSelectedItem(null);
    setConfirmDeleteIsOpen(false);
  };

  const headerRow = (
    <Table.Row>
      <Table.HeaderCell style={{ maxWidth: "200px" }} sorted={column === "url" ? direction : null} onClick={handleSort("url")}>
        Url
      </Table.HeaderCell>
      <Table.HeaderCell style={{ maxWidth: "130px" }} sorted={column === "dateQueuedUtc" ? direction : null} onClick={handleSort("dateQueuedUtc")}>
        Date Queued
      </Table.HeaderCell>
      <Table.HeaderCell style={{ maxWidth: "130px" }} sorted={column === "lastCrawlTimeUtc" ? direction : null} onClick={handleSort("lastCrawlTimeUtc")}>
        Last Crawl Time
      </Table.HeaderCell>
      <Table.HeaderCell sorted={column === "pdfCount" ? direction : null} onClick={handleSort("pdfCount")}>
        Pdfs
      </Table.HeaderCell>
      <Table.HeaderCell sorted={column === "urlCount" ? direction : null} onClick={handleSort("urlCount")}>
        # Crawled
      </Table.HeaderCell>
      <Table.HeaderCell sorted={column === "httpResponseCode" ? direction : null} onClick={handleSort("httpResponseCode")}>
        Http Code
      </Table.HeaderCell>
      <Table.HeaderCell sorted={column === "source" ? direction : null} onClick={handleSort("source")}>
        Source
      </Table.HeaderCell>
      <Table.HeaderCell sorted={column === "state" ? direction : null} onClick={handleSort("state")}>
        State
      </Table.HeaderCell>
      <Table.HeaderCell style={{ maxWidth: "130px" }} sorted={column === "elapsed" ? direction : null} onClick={handleSort("elapsed")}>
        Elapsed
      </Table.HeaderCell>
      <Table.HeaderCell style={{ maxWidth: "130px" }} sorted={column === "retryCount" ? direction : null} onClick={handleSort("retryCount")}>
        RetryCount
      </Table.HeaderCell>
      <Table.HeaderCell></Table.HeaderCell>
    </Table.Row>
  );

  const renderQueue = (data, column, direction) => {
    return (
      <div className="admin-container">
        <Confirm
          open={confirmDeleteIsOpen}
          onCancel={confirmDeleteClose}
          onConfirm={(e) => deleteUrl(e, deleteSelectedItem)}
          content="Are you sure you want to delete?"
        />
        <Form onSubmit={(e) => queueUrl(e, addUrlHistory)}>
          <Form.Group>
            <Button type="button" onClick={handleShowAdd} icon size="mini">
              <Icon name="file" /> Add Url
            </Button>
          </Form.Group>
          {addVisible && (
            <Segment secondary>
              <Popup
                hideOnScroll
                content="Specify the full url to crawl. The url can be a direct link to a PDF, or any website url."
                trigger={
                  <Form.Field width={10}>
                    <label>Url</label>
                    <Input
                      action
                      required
                      className="labeled"
                      placeholder="www.ti.com/lit/ds/symlink/lm2904-n.pdf"
                      value={addUrlHistory.url}
                      onChange={handleChange}
                      name="url"
                    >
                      <Label>{urlLabel}</Label>
                      <input />
                      <Button onClick={(e) => handleVisitLink(e, addUrlHistory.url)} disabled={!addUrlHistory.url || addUrlHistory.url.length === 0}>
                        View
                      </Button>
                    </Input>
                  </Form.Field>
                }
              />
              <Form.Group>
                <Popup
                  hideOnScroll
                  content="Specify the crawl delay to use before every page request, as a timespan. Example :'00:00:00.750' for 750 ms"
                  trigger={
                    <Form.Input
                      width={3}
                      label="Crawl Delay"
                      placeholder="00:00:00.000"
                      focus
                      value={addUrlHistory.crawlDelay || ""}
                      onChange={handleChange}
                      name="crawlDelay"
                    />
                  }
                />
                <Popup
                  hideOnScroll
                  content="Specify the maximum url crawl depth. Specifying 5 would mean only crawl 5 levels deep."
                  trigger={
                    <Form.Input
                      width={2}
                      label="Max Depth"
                      placeholder="5"
                      focus
                      value={addUrlHistory.maxCrawlDepth || ""}
                      onChange={handleChange}
                      name="maxCrawlDepth"
                    />
                  }
                />
                <Popup
                  hideOnScroll
                  content="The maximum total pages to crawl. Default: 0 (no limit)"
                  trigger={
                    <Form.Input
                      width={2}
                      label="Max Pages"
                      placeholder="0"
                      focus
                      value={addUrlHistory.maxPagesToCrawl || ""}
                      onChange={handleChange}
                      name="maxPagesToCrawl"
                    />
                  }
                />
              </Form.Group>
              <Form.Button primary type="submit" icon>
                <Icon name="save" /> Add to Queue
              </Form.Button>
            </Segment>
          )}
        </Form>
        <Segment loading={loading}>
          <CrawlerStatistics />
          <InifiniteScrollTable
            id="urlHistoryTable"
            compact
            celled
            sortable
            selectable
            striped
            unstackable
            size="small"
            className="queueTable"
            headerRow={headerRow}
            nextPage={() => fetchNextPage()}
          >
            {data.map((urlHistory, i) => (
              <Table.Row key={i} className={getRowClassname(urlHistory)} onClick={(e) => openUrlHistory(e, urlHistory)}>
                <Table.Cell style={{ maxWidth: "200px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} title={urlHistory.url}>
                  {urlHistory.url}
                </Table.Cell>
                <Table.Cell style={{ maxWidth: "130px" }}>{getFormattedTime(urlHistory.dateQueuedUtc)}</Table.Cell>
                <Table.Cell style={{ maxWidth: "130px" }}>{getFormattedTime(urlHistory.lastCrawlTimeUtc)}</Table.Cell>
                <Table.Cell>{urlHistory.pdfCount}</Table.Cell>
                <Table.Cell>{urlHistory.childrenCount}</Table.Cell>
                <Table.Cell>{urlHistory.httpResponseCode}</Table.Cell>
                <Table.Cell>{GetTypeName(Sources, urlHistory.source)}</Table.Cell>
                <Table.Cell title={urlHistory.errorMessage} style={{ textAlign: "center" }}>
                  {getUrlHistoryLabel(urlHistory)}
                </Table.Cell>
                <Table.Cell style={{ maxWidth: "130px" }}>
                  {urlHistory.elapsed === null && urlHistory.dateQueuedUtc !== null
                    ? getFriendlyElapsedTime(getTimeDifference(Date.now(), Date.parse(urlHistory.dateQueuedUtc) - new Date().getTimezoneOffset() * 60 * 1000))
                    : getBasicElapsed(urlHistory.elapsed)}
                </Table.Cell>
                <Table.Cell>{urlHistory.retryCount}</Table.Cell>
                <Table.Cell>
                  <Button size="mini" icon="delete" onClick={(e) => confirmDeleteOpen(e, urlHistory)} />
                  <Button size="mini" icon="redo" onClick={(e) => requeueUrl(e, urlHistory)} />
                </Table.Cell>
              </Table.Row>
            ))}
          </InifiniteScrollTable>
        </Segment>
      </div>
    );
  };
    
  let contents = renderQueue(urlHistoryDataRef.current, column, direction);

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Section href="/admin">Admin</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section href="/admin/datasheets">Datasheets</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section href="/admin/datasheets/admin">Admin</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>Crawler</Breadcrumb.Section>
      </Breadcrumb>
      <FormHeader name="Crawler Management" to="..">
        Manage the PDF Crawler queue.
      </FormHeader>
      {contents}
    </div>
  );
}
