import React, { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Icon, Button, Table, Segment, Header, Popup, Pagination } from "semantic-ui-react";
import { fetchApi, doNothing } from "../common/fetchApi";
import { SubscriptionOptions } from "../components/SubscriptionOptions";
import { config } from "../common/config";
import { toast } from "react-toastify";
import { format, parseJSON } from "date-fns";
import { FormatShortDate, FormatDateOnly, getDifferenceInDays } from "../common/datetime";
import { formatCurrency } from "../common/Utils";
import { SubscriptionAction, SubscriptionLevels, SubscriptionStates, PaymentStatus, ChargeType, GetTypeName } from "../common/Types";
import "./Subscriptions.css";

/**
 * Subscription management for a user
 * @param {*} props 
 * @returns 
 */
export function Subscriptions(props) {
  const { t } = useTranslation();
  const pageSize = 5;
  const [showSubscriptionOptions, setShowSubscriptionOptions] = useState(false);
  const [message, setMessage] = useState(null);
  const [activeSubscriptions, setActiveSubscriptions] = useState([]);
  const [subscriptionHistory, setSubscriptionHistory] = useState([]);
  const [paymentsHistory, setPaymentsHistory] = useState([]);
  const [paymentsPage, setPaymentsPage] = useState(1);
  const [paymentsTotalPages, setPaymentsTotalPages] = useState(1);
  const [subscriptionHistoryPage, setSubscriptionHistoryPage] = useState(1);
  const [subscriptionHistoryTotalPages, setSubscriptionHistoryTotalPages] = useState(1);
  const [btnManageDisabled, setBtnManageDisabled] = useState(false);

  useEffect(() => {
    // get a list of all active subscriptions, and payment history
    const getSubscriptions = async () => {
      const response = await fetchApi("api/payments/subscriptions").catch((error) => {
        toast.error("The server responded with an error. We apologize for the inconvenience.", { autoClose: 10000 });
      });

      if (response.responseObject.status === 200) {
        const { data } = response;

        if (!data) {
          toast.error("Unexpected response from server.");
          return;
        }

        if (data.activeSubscriptions) setActiveSubscriptions(data.activeSubscriptions);
        if (data.historicalSubscriptions) setSubscriptionHistory(data.historicalSubscriptions);
        if (data.payments) setPaymentsHistory(data.payments);

        // the backend will tell us what actions to perform for the user
        switch (data.subscriptionAction) {
          default:
          case SubscriptionAction.Error:
            // error occurred, display it
            setMessage(data.message);
            break;
          case SubscriptionAction.CreateSubscription:
            // show subscription options for signup
            setShowSubscriptionOptions(true);
            break;
          case SubscriptionAction.ManageExistingSubscription:
            // allow managing of existing subscriptions
            break;
        }

        setPaymentsTotalPages(Math.ceil(data.payments.length / pageSize));
        setSubscriptionHistoryTotalPages(Math.ceil(data.historicalSubscriptions.length / pageSize));
      }
    };

    getSubscriptions();
  }, [setShowSubscriptionOptions]);

  /**
   * Create a checkout session and sign the customer up for a new subscription
   * @param {*} e
   * @param {*} t
   * @param {*} subscriptionLevel
   */
  const onSubmitSubscriptionLevel = async (e, t, subscriptionLevel) => {
    // get the stripe price id that tells us which subscription the customer wants to signup
    let stripePriceId = "";
    switch (subscriptionLevel) {
      case SubscriptionLevels.Maker:
        stripePriceId = config.MAKER_MONTHLY_PRICEID;
        break;
      case SubscriptionLevels.Professional:
        stripePriceId = config.PROFESSIONAL_MONTHLY_PRICEID;
        break;
      case SubscriptionLevels.Commercial:
        stripePriceId = config.COMMERCIAL_MONTHLY_PRICEID;
        break;
      default:
        break;
    }
    if (stripePriceId && stripePriceId.length > 0) {
      
      // create a stripe checkout session and redirect the user

      const request = {
        priceId: stripePriceId
      };
      await fetchApi("api/payments/create-checkout-session", {
        method: "POST",
        body: JSON.stringify(request)
      })
        .then((configResponse) => {
          if (configResponse.data.url && configResponse.data.url.length > 0) {

            // send to stripe payment provider
            window.location.replace(configResponse.data.url);

          } else {
            toast.error("Error: Unexpected server response!");
            console.error("Unexpected response from server, no redirection_uri provided!", configResponse);
          }
        })
        .catch((error) => {
          toast.error("Error: Failed to create a checkout session!");
          console.error("Stripe Error", error);
        });
    } else {
      toast.error("Error: No stripe price set!");
    }
  };

  /**
   * Manage the existing subscription on Stripe's payment gateway.
   * Allows users to cancel, pause or renew a subscription.
   * @param {*} e
   * @param {*} subscription
   * @returns
   */
  const handleManageSubscription = async (e, subscription) => {
    setBtnManageDisabled(true);
    const request = { ...subscription };
    const response = await fetchApi("api/payments/manage", {
      method: "PUT",
      body: JSON.stringify(request)
    });
    if (response) {
      if (response.responseObject.status === 200) {
        const { data } = response;
        if (data.url && data.url.length > 0 && data.url.startsWith("http")) {
          window.location.replace(data.url);
          return;
        } else {
          toast.error("Unexpected response from server!");
        }
      } else if (response.responseObject.status === 404) {
        toast.error("Subscription not found!");
      } else if (response.responseObject.status === 400) {
        // bad request
        toast.error(response.data);
      }
    } else {
      toast.error("Server did not respond!");
    }
    setBtnManageDisabled(false);
  };

  const handleVisitLink = (e, url) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(url, "_blank");
  };

  const handlePaymentsPageChange = (e, control) => {
    setPaymentsPage(control.activePage);
  };

  const handleSubscriptionHistoryPageChange = (e, control) => {
    setSubscriptionHistoryPage(control.activePage);
  };

  const getPaymentsPage = (page, recordCount) => {
    const start = (page - 1) * recordCount;
    const dataPage = [];
    for (let i = start; i < start + recordCount; i++) {
      if (i < paymentsHistory.length) dataPage.push(paymentsHistory[i]);
    }
    return dataPage;
  };

  const getSubscriptionHistoryPage = (page, recordCount) => {
    const start = (page - 1) * recordCount;
    const dataPage = [];
    for (let i = start; i < start + recordCount; i++) {
      if (i < subscriptionHistory.length) dataPage.push(subscriptionHistory[i]);
    }
    return dataPage;
  };

  const getSubscriptionStateIcon = (subscriptionState, dateExpiredUtc) => {
    switch (subscriptionState) {
      case SubscriptionStates.Active:
        if (dateExpiredUtc)
          return <Popup position="top center" wide content={<span>Subscription is active, but will revert to the Free plan on <b>{format(parseJSON(dateExpiredUtc), FormatShortDate)}</b>.</span>} trigger={<Icon name="check circle" color="yellow" />} />;  
        else
          return <Popup position="top center" wide content="Subscription is active" trigger={<Icon name="check circle" color="green" />} />;        
      case SubscriptionStates.Paused:
        return <Popup position="top center" wide content="Subscription is currently paused" trigger={<Icon name="warning circle" color="blue" />} />;
      case SubscriptionStates.PaymentExpired:
        return <Popup position="top center" wide content="Requires updated payment method" trigger={<Icon name="warning sign" color="yellow" />} />;
      default:
        return <Popup position="top center" wide content="Unknown state" trigger={<Icon name="question circle" color="red" />} />;
    }
  };

  return (
    <div>
      {message && (
        <div className="centered">
          <h2>{message}</h2>
        </div>
      )}

      {showSubscriptionOptions && <div className="centered">{<SubscriptionOptions onSubmit={onSubmitSubscriptionLevel} />}</div>}

      <Segment color="green">
        <Header dividing as="h3">
          Active Subscriptions
        </Header>
        <Table className="active-subscriptions">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Subscription</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>Amount</Table.HeaderCell>
              <Table.HeaderCell>Next Payment Due</Table.HeaderCell>
              <Table.HeaderCell>Expires On</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {activeSubscriptions && activeSubscriptions.length > 0 ? (
              activeSubscriptions.map((s, key) => (
                <React.Fragment key={key}>
                  <Table.Row>
                    <Table.Cell>{GetTypeName(SubscriptionLevels, s.subscriptionType)}</Table.Cell>
                    <Table.Cell textAlign="center">
                      <div>
                        {getSubscriptionStateIcon(s.subscriptionState, s.dateExpiredUtc)}
                        {GetTypeName(SubscriptionStates, s.subscriptionState)}
                      </div>
                    </Table.Cell>
                    <Table.Cell>{format(parseJSON(s.dateCreatedUtc), FormatDateOnly)}</Table.Cell>
                    <Table.Cell>{formatCurrency(s.amount, s.currency)}/mo</Table.Cell>
                    <Table.Cell>{s.dateNextPaymentUtc && format(parseJSON(s.dateNextPaymentUtc), FormatShortDate)}</Table.Cell>
                    <Table.Cell>{s.dateExpiredUtc && format(parseJSON(s.dateExpiredUtc), FormatDateOnly)}</Table.Cell>
                    <Table.Cell>
                      <Button primary onClick={(e) => handleManageSubscription(e, s)} disabled={btnManageDisabled}>
                        <Icon name="edit" /> Manage
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                  {s.dateExpiredUtc && (
                    <Table.Row key={9998} className="cancelled">
                      <Table.Cell colSpan={7} textAlign="center" style={{ backgroundColor: "#eee" }}>
                        <p>
                          <Icon name="exclamation" color="blue" />
                          You have requested to cancel your subscription.
                        </p>
                        <p>
                          Your <b>{GetTypeName(SubscriptionLevels, s.subscriptionType)}</b> plan will revert to the Free plan 
                          on <b>{format(parseJSON(s.dateExpiredUtc), FormatShortDate)}</b>.
                          <br/>
                          <span className="small">You will not lose access to your parts, however you will only have access to features and limits available on your plan.</span>
                        </p>
                      </Table.Cell>
                    </Table.Row>
                  )}
                  {s.datePauseRequestedUtc && s.subscriptionState === SubscriptionStates.Active && (
                    <Table.Row key={9998} className="cancelled">
                      <Table.Cell colSpan={7} textAlign="center" style={{ backgroundColor: "#eee" }}>
                        <p>
                          <Icon name="exclamation" color="blue" />
                          You have requested to pause your subscription.
                        </p>
                        <p>
                          Your <b>{GetTypeName(SubscriptionLevels, s.subscriptionType)}</b> plan will revert to the Free plan 
                          on <b>{format(parseJSON(s.periodEnd), FormatShortDate)}</b>.
                          <br/>
                          Your subscription will be resumed on <b>{format(parseJSON(s.dateResumesAtUtc), FormatShortDate)}</b> automatically or should you decide to resume it earlier.
                          <br/>
                          <span className="small">You will not lose access to your parts, however you will only have access to features and limits available on your plan.</span>
                        </p>
                      </Table.Cell>
                    </Table.Row>
                  )}
                  {s.subscriptionState === SubscriptionStates.Paused && (
                    <Table.Row key={9999} className="paused">
                      <Table.Cell colSpan={7} textAlign="center" style={{ backgroundColor: "#eee" }}>
                        <p>
                          <Icon name="exclamation" color="blue" />
                          Your current plan is paused and your account is on the Free plan.
                        </p>
                        {s.dateResumesAtUtc && (
                          <p>
                            Your <b>{GetTypeName(SubscriptionLevels, s.subscriptionType)}</b> plan will resume on{" "}
                            <b>{format(parseJSON(s.dateResumesAtUtc), FormatShortDate)}</b>.
                          </p>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  )}
                </React.Fragment>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={6} textAlign="center">
                  You have no current subscriptions.
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Segment>

      <Segment secondary color="blue">
        <Header dividing as="h3">
          Subscription History
        </Header>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Subscription</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>Date Expired</Table.HeaderCell>
              <Table.HeaderCell style={{width: '250px'}}>Note</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {subscriptionHistory && subscriptionHistory.length > 0 ? (
              getSubscriptionHistoryPage(subscriptionHistoryPage, pageSize).map((s, key) => (
                <Table.Row key={key}>
                  <Table.Cell>{GetTypeName(SubscriptionLevels, s.subscriptionType)}</Table.Cell>
                  <Table.Cell>{GetTypeName(SubscriptionStates, s.subscriptionState)}</Table.Cell>
                  <Table.Cell>{format(parseJSON(s.dateCreatedUtc), FormatDateOnly)}</Table.Cell>
                  <Table.Cell>{s.dateExpiredUtc && format(parseJSON(s.dateExpiredUtc), FormatDateOnly)}</Table.Cell>
                  <Table.Cell className="overflow"><div style={{maxWidth: '250px'}}>{s.note && <Popup wide hoverable content={s.note} trigger={<span>{s.note}</span>} />}</div></Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={5} textAlign="center">
                  You have no past subscriptions.
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        <Pagination
          activePage={subscriptionHistoryPage}
          totalPages={subscriptionHistoryTotalPages}
          firstItem={null}
          lastItem={null}
          onPageChange={handleSubscriptionHistoryPageChange}
          size="mini"
        />
      </Segment>

      <Segment secondary color="violet">
        <Header dividing as="h3">
          Payment History
        </Header>
        <Table className="payment-history">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={2}>Date</Table.HeaderCell>
              <Table.HeaderCell>Amount</Table.HeaderCell>
              <Table.HeaderCell>Subscription</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Payment Method</Table.HeaderCell>
              <Table.HeaderCell>Period</Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {paymentsHistory && paymentsHistory.length > 0 ? (
              getPaymentsPage(paymentsPage, pageSize).map((s, key) => (
                <Table.Row key={key}>
                  <Table.Cell>
                    {getDifferenceInDays(parseJSON(s.dateCreatedUtc), new Date()) > 90
                      ? format(parseJSON(s.dateCreatedUtc), FormatDateOnly)
                      : format(parseJSON(s.dateCreatedUtc), FormatShortDate)}
                  </Table.Cell>
                  <Table.Cell>
                    <div className={s.chargeType === 0 ? "charge-amount" : "refund-amount"}>
                      {s.chargeType === 0 ? "" : "-"}{formatCurrency(s.amount, s.currency)}
                    </div>
                  </Table.Cell>
                  <Table.Cell>{GetTypeName(SubscriptionLevels, s.subscriptionType)}</Table.Cell>
                  <Table.Cell>
                    <div>{GetTypeName(PaymentStatus, s.paymentStatus)}</div>
                    <div className="tiny" style={{ color: "rgb(0,0,0,0.5)", padding: "0", margin: "0", marginTop: "-5px" }}>{s.description}</div>
                  </Table.Cell>
                  <Table.Cell><span className={s.chargeType === 0 ? "charge" : "refund"}>{GetTypeName(ChargeType, s.chargeType)}</span></Table.Cell>
                  <Table.Cell>
                    <div>{s.last4Digits && `**** **** **** ${s.last4Digits}`}</div>
                    <div className="small" style={{ color: "rgb(0,0,0,0.5)", padding: "0", margin: "0", marginTop: "-10px" }}>
                      {s.cardBrand}
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    {s.periodStart && format(parseJSON(s.periodStart), FormatDateOnly)} - {s.periodEnd && format(parseJSON(s.periodEnd), FormatDateOnly)}
                  </Table.Cell>
                  <Table.Cell>
                    {s.receiptUrl && (
                      <Popup
                        content="View receipt"
                        trigger={<Button circular size="mini" icon="dollar" title="View Receipt" onClick={(e) => handleVisitLink(e, s.receiptUrl)} />}
                      />
                    )}
                    {s.invoicePdfUrl && (
                      <Popup
                        content="Download invoice"
                        trigger={
                          <Button circular size="mini" icon="file pdf outline" title="Download Invoice" onClick={(e) => handleVisitLink(e, s.invoicePdfUrl)} />
                        }
                      />
                    )}
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={5} textAlign="center">
                  You have no past payments.
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        <Pagination
          activePage={paymentsPage}
          totalPages={paymentsTotalPages}
          firstItem={null}
          lastItem={null}
          onPageChange={handlePaymentsPageChange}
          size="mini"
        />
      </Segment>
    </div>
  );
}
