import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Icon, Loader, Dimmer } from 'semantic-ui-react';
import { fetchApi } from "../common/fetchApi";

export function ConfirmEmail (props) {
  const { t } = useTranslation();
  const params = useParams();
  const { confirmationCode } = params;
  const [loading, setLoading] = useState(true);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // fade out the preloader
    setLoading(true);
    confirmEmail();

    function confirmEmail() {
      return fetchApi("api/authentication/confirm",
      {
        method: "POST",
        body: JSON.stringify({
          emailConfirmationToken: confirmationCode
        })
      }).then((response) => {
        const { data } = response;
        setLoading(false);
        setIsConfirmed(data.isSuccessful);
        setMessage(data.message);
      });
    }

  }, []);

  return (
    <div>
      {/* ***** Breadcrumb Area Start ***** */}
      <div className="mosh-breadcumb-area">
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-12">
              <div className="bradcumbContent">
                <h2>Confirm Email</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ***** Breadcrumb Area End ***** */}

      <div className="centered">
        <Dimmer active={loading}>
          <Loader />
        </Dimmer>
          {!loading
          ? isConfirmed 
            ? (
                <div style={{marginBottom: '50px'}}>
                  <h1>Your account is now activated.</h1>
                  <div>
                    <Icon name="user outline" color="blue" size="massive" style={{marginTop: '15px'}} />
                  </div>
                  <div>
                    <Button primary onClick={(e) => navigate("/login")} style={{marginTop: '50px'}}>Login</Button>
                  </div>
                </div>
            )
            : (<div style={{marginBottom: '50px'}}>
                <h1>Aw, shucks!</h1>
                <p className='emphasis'>
                  {message}
                </p>
                <p style={{marginTop: '20px'}}>
                  Sometimes these things happen, we were unable to activate your account.
                </p>
                <p>Please check that you have clicked the correct account verification link.<br/>
                  If you are still having trouble please <a href="mailto:support@binner.io?subject=Problem with confirming my email">contact support</a>
                </p>
              </div>
              )
          : ""}
      </div>
    </div>
  );
}
