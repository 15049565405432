import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "underscore";
import { Table, Form, Segment, Button, Icon, Confirm, Breadcrumb, TextArea, Grid, Card, Placeholder, Image, Header } from "semantic-ui-react";
import { toast } from "react-toastify";
import Carousel from "react-bootstrap/Carousel";
import { FormError } from "../../../components/FormError";
import { getFormattedTime } from "../../../common/datetime";
import { fetchApi, getErrorsString } from "../../../common/fetchApi";
import { formatNumber, formatCurrency } from "../../../common/Utils";
import { Sources, ImageTypes, GetTypeDropdown } from "../../../common/Types";
import { getResourceImageUrl, getDefaultProductShotImageFromPartNumberManufacturer, getResourceDatasheetUrl } from "../../../common/resources";
import { FormHeader } from "../../../components/FormHeader";
import "../Admin.css";

const ProductImageIntervalMs = 10000;

export function PartNumberManufacturer(props) {
  const [loading, setLoading] = useState(false);
  const [confirmDeleteIsOpen, setConfirmDeleteIsOpen] = useState(false);
  const [confirmSupplierDeleteIsOpen, setConfirmSupplierDeleteIsOpen] = useState(false);
  const [deleteSelectedItem, setDeleteSelectedItem] = useState(null);
  const [deleteSupplierSelectedItem, setSupplierDeleteSelectedItem] = useState(null);
  const [isDirty, setIsDirty] = useState(false);
  const [error, setError] = useState(null);
  const [currentProductImage, setCurrentProductImage] = useState(null);
  const [currentDatasheet, setCurrentDatasheet] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentDatasheetIndex, setCurrentDatasheetIndex] = useState(0);
  const [datasheetTitle, setDatasheetTitle] = useState(null);
  const [datasheetPartName, setDatasheetPartName] = useState(null);
  const [datasheetDescription, setDatasheetDescription] = useState(null);
  const [datasheetManufacturer, setDatasheetManufacturer] = useState(null);
  const [partNumberManufacturer, setPartNumberManufacturer] = useState({
    partNumberManufacturerId: 0,
    name: "",
    description: "",
    alternateNames: "",
    partTypeId: 0,
    source: 0,
    manufacturerId: 0,
    keywords: [],
    suppliers: [],
    datasheets: [],
    imageMetadata: []
  });
  const [partTypes, setPartTypes] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [keywordOptions, setKeywordOptions] = useState([]);
  const [sources] = useState(GetTypeDropdown(Sources));
  const [loadingPartTypes, setLoadingPartTypes] = useState(true);
  const [loadingManufacturers, setLoadingManufacturers] = useState(true);
  const navigate = useNavigate();
  const params = useParams();

  const { partNumberManufacturerId, partNumberId } = params;

  useEffect(() => {
    fetchPartTypes().then(() => {
      fetchManufacturers().then(() => {
        fetchPartNumber();
      });
    });

    function fetchPartNumber() {
      setLoading(true);
      fetchApi(`api/partnumber/manufacturer?partNumberManufacturerId=${partNumberManufacturerId}`).then((response) => {
        const { data } = response;
        if (data) {
          // update the page of data, as long as its not already in the data
          setPartNumberManufacturer(data);
          if (data.imageMetadata.length > 0) {
            setCurrentProductImage(data.imageMetadata[0]);
          }
          const allDatasheets = data.datasheets;
          if (allDatasheets.length > 0) {
            setCurrentDatasheet(allDatasheets[0]);
            // set the first datasheet meta display, because the carousel component doesnt fire the first event
            setDatasheetMeta(allDatasheets[0]);
          }
        }

        // for tag-style dropdowns, we need to prepopulate its options or it won't fill existing values correctly
      const newKeywordOptions = data.keywords.map((k) => ({ key: k.name, text: k.name, value: k.name }));
      setKeywordOptions(newKeywordOptions);

        setLoading(false);
      });
    }

    function fetchPartTypes() {
      setLoadingPartTypes(true);
      return fetchApi("api/partType/list?results=1000").then((response) => {
        const { data } = response;
        const partTypes = _.sortBy(
          data.map((item) => {
            return {
              key: item.partTypeId,
              value: item.partTypeId,
              text: item.name
            };
          }),
          "text"
        );
        setPartTypes(partTypes);
        setLoadingPartTypes(false);
      });
    }

    function fetchManufacturers() {
      setLoadingManufacturers(true);
      return fetchApi("api/manufacturer/list?results=1000").then((response) => {
        const { data } = response;
        const manufacturers = _.sortBy(
          data.map((item) => {
            return {
              key: item.manufacturerId,
              value: item.manufacturerId,
              text: item.name
            };
          }),
          "text"
        );
        setManufacturers(manufacturers);
        setLoadingManufacturers(false);
      });
    }
  }, []);

  const onSubmit = (e) => {
    fetchApi(`api/partnumber/manufacturer`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(partNumberManufacturer)
    }).then((response) => {
      if (response.responseObject.ok) {
        toast.success("Saved manufacturer part!");
        setIsDirty(false);
        navigate(-1);
      } else{
        const errorMessage = getErrorsString(response);
        setError(errorMessage);
        toast.error(`Failed to save manufacturer part. ${errorMessage}`);
      }
    });
  };

  const deletePartNumberManufacturer = (e, partNumberManufacturer) => {
    e.preventDefault();
    e.stopPropagation();
    if (!partNumberManufacturer)
      return;
    setLoading(true);
    fetchApi(`api/partnumber/manufacturer`, {
      method: "DELETE",
      body: partNumberManufacturer.partNumberManufacturerId
    }).then(() => {
      setLoading(false);
      setConfirmDeleteIsOpen(false);
      navigate(-1);
    });
  };

  const deleteSupplier = (e, supplier) => {
    e.preventDefault();
    e.stopPropagation();
    if (!supplier)
      return;
    setLoading(true);
    fetchApi(`api/partnumber/manufacturer/supplier`, {
      method: "DELETE",
      body: supplier.partNumberManufacturerSupplierId
    }).then(() => {
      setLoading(false);
      setConfirmSupplierDeleteIsOpen(false);
    });
  };

  const handleChange = (e, control) => {
    partNumberManufacturer[control.name] = control.value;
    setPartNumberManufacturer({ ...partNumberManufacturer });
  };

  const confirmDeleteOpen = (e, partNumber) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteSelectedItem(partNumber);
    setConfirmDeleteIsOpen(true);
  };

  const confirmDeleteClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteSelectedItem(null);
    setConfirmDeleteIsOpen(false);
  };

  const confirmSupplierDeleteOpen = (e, supplier) => {
    e.preventDefault();
    e.stopPropagation();
    setSupplierDeleteSelectedItem(supplier);
    setConfirmSupplierDeleteIsOpen(true);
  };

  const confirmSupplierDeleteClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSupplierDeleteSelectedItem(null);
    setConfirmSupplierDeleteIsOpen(false);
  };

  const addNewKeywordOption = (event, { value }) => {
    setKeywordOptions((prevOptions) => [{ text: value, value }, ...prevOptions]);
  };

  const handleKeywordChange = (e, { value }) => {
    // value = []
    let currentKeywordAssignments = partNumberManufacturer.keywords;
    // handle additions
    for (let i = 0; i < value.length; i++) {
      // for each value that doesn't exist in the array, add it
      if (!_.find(currentKeywordAssignments, (x) => x.name === value[i])) {
        currentKeywordAssignments.push({ keywordId: 0, name: value[i] });
      }
    }
    // handle deletions
    for (let i = 0; i < currentKeywordAssignments.length; i++) {
      if (!_.find(value, (x) => x === currentKeywordAssignments[i].name)) {
        currentKeywordAssignments.splice(i, 1);
      }
    }

    // update the datasheet keywords with the new assignments
    setPartNumberManufacturer((data) => ({
      ...data,
      keywordAssignments: currentKeywordAssignments,
    }));
  };

  const getSupplier = (supplierId) => {
    switch(supplierId){
      case 1:
        return "DigiKey";
      case 2:
        return "Mouser";
      default:
        return "";
    }
  }

  const handleVisitLink = (e, url) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('open', url);
    window.open(url, "_blank");
  };

  const handleSetDefaultImage = (e, productImage) => {
    e.preventDefault();
    e.stopPropagation();
    fetchApi(`api/partnumber/manufacturer/defaultimage`, {
      method: "PUT",
      body: {
        partNumberManufacturerId: partNumberManufacturer.partNumberManufacturerId,
        partNumberManufacturerImageMetadataId: productImage.partNumberManufacturerImageMetadataId
      }
    }).then(() => {
      const imageMetadata = partNumberManufacturer.imageMetadata;
      for(let i = 0; i < imageMetadata.length; i++) {
        imageMetadata[i].isDefault = false;
      }
      const imageRecord = imageMetadata.find(x => x.partNumberManufacturerImageMetadataId === productImage.partNumberManufacturerImageMetadataId);
      imageRecord.isDefault = true;
      setPartNumberManufacturer({...partNumberManufacturer, imageMetadata: [...imageMetadata]});
      setCurrentIndex(0);
      toast.success('Default image updated!');
    });
  };

  const handleSetPrimaryDatasheet = (e, datasheet) => {
    e.preventDefault();
    e.stopPropagation();
    fetchApi(`api/partnumber/manufacturer/primarydatasheet`, {
      method: "PUT",
      body: {
        partNumberManufacturerId: partNumberManufacturer.partNumberManufacturerId,
        datasheetId: datasheet.datasheetId
      }
    }).then(() => {
      setPartNumberManufacturer({...partNumberManufacturer, 
        primaryDatasheetId: datasheet.datasheetId
      });
      setCurrentDatasheetIndex(0);
      toast.success('Primary datasheet updated!');
    });
  };

  const onCurrentDatasheetChanged = (activeIndex, control) => {
    setCurrentProductImage(partNumberManufacturer.imageMetadata[activeIndex]);
    setCurrentIndex(activeIndex);
  }

  const setDatasheetMeta = (datasheet) => {
    setDatasheetPartName("partname");
    setDatasheetTitle(datasheet.title);
    setDatasheetDescription(datasheet.shortDescription);
    setDatasheetManufacturer(datasheet.manufacturerName);
  };

  const handleOpenPartNumberSupplier = (e, supplier) => {
    navigate(`${supplier.partNumberManufacturerSupplierId}`);
  };

  const getImagesFlat = (part) => {
    // sort by which one is default
    return part.imageMetadata.sort((a,b) => {
      return Number(b.isDefault) - Number(a.isDefault);
    });
  };

  const getDatasheetsFlat = (part) => {
    let allDatasheets = part.datasheets;
    allDatasheets = _.uniq(allDatasheets, p => p.datasheetId);
    // sort by which one is default
    return allDatasheets.sort((a,b) => {
      return Number(b.datasheetId === partNumberManufacturer.primaryDatasheetId) - Number(a.datasheetId === partNumberManufacturer.primaryDatasheetId);
    });
  };

  const allImages = getImagesFlat(partNumberManufacturer);
  const allDatasheets = getDatasheetsFlat(partNumberManufacturer);
  return (
    <div className="admin-container">
      <Breadcrumb>
        <Breadcrumb.Section href="/admin">Admin</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section href="/admin/partnumbers">Part Numbers</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section href={`/admin/partnumbers/${partNumberId}`}>Part Number</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>Part Number Manufacturer</Breadcrumb.Section>
      </Breadcrumb>
      <FormHeader name="Part Numbers Management" to="..">
        Edit an existing Part Number Manufacturer.
      </FormHeader>
      <div>
        <Confirm
          open={confirmDeleteIsOpen}
          onCancel={confirmDeleteClose}
          onConfirm={(e) => deletePartNumberManufacturer(e, deleteSelectedItem)}
          content="Are you sure you want to delete this part number manufacturer?"
        />
        <Confirm
          open={confirmSupplierDeleteIsOpen}
          onCancel={confirmSupplierDeleteClose}
          onConfirm={(e) => deleteSupplier(e, deleteSupplierSelectedItem)}
          content="Are you sure you want to delete this supplier part?"
        />
        <Segment loading={loading} secondary>
            <Grid celled>
              <Grid.Row>
                <Grid.Column width={11}>
                  <Form onSubmit={onSubmit}>
                  <Form.Dropdown
                    label="Manufacturer"
                    placeholder="Manufacturer"
                    loading={loadingManufacturers}
                    search
                    selection
                    value={partNumberManufacturer.manufacturerId}
                    name="manufacturerId"
                    options={manufacturers}
                    onChange={handleChange}
                  />

                  <Form.Input
                    label="Part Name"
                    required
                    focus
                    placeholder="LM358"
                    value={partNumberManufacturer.name || ""}
                    name="name"
                    onChange={handleChange}
                  />
                  <Form.Input
                    label="Description"
                    control={TextArea}
                    placeholder="The LM358 is a dual-operational amplifier"
                    value={partNumberManufacturer.description || ""}
                    name="description"
                    onChange={handleChange}
                  />

                  <Form.Input
                    label="Alternate Names"
                    placeholder="BLM358,SLM358-T"
                    value={partNumberManufacturer.alternateNames || ""}
                    name="alternateNames"
                    onChange={handleChange}
                  />
                  <Form.Dropdown
                    label="Part Type"
                    placeholder="Part Type"
                    loading={loadingPartTypes}
                    search
                    selection
                    value={partNumberManufacturer.partTypeId || ""}
                    name="partTypeId"
                    options={partTypes}
                    onChange={handleChange}
                  />
                  <Form.Dropdown
                        placeholder="Enter some keywords..."
                        label="Keywords"
                        fluid
                        multiple
                        search
                        selection
                        allowAdditions
                        clearable
                        value={partNumberManufacturer.keywords && partNumberManufacturer.keywords.map((i, k) => i.name)}
                        name="keywordId"
                        onChange={handleKeywordChange}
                        options={keywordOptions}
                        onAddItem={addNewKeywordOption}
                      />
                  <Form.Dropdown
                    label="Source"
                    placeholder="Source"
                    search
                    selection
                    value={partNumberManufacturer.source}
                    name="source"
                    options={sources}
                    onChange={handleChange}
                  />
                  <Form.Group className="celled">
                    <Form.Field>
                      <label>Swarm Part Number Id</label>
                      {partNumberManufacturer.swarmPartNumberId || "(none)"}
                    </Form.Field>
                    <Form.Field>
                        <label>Created By Supplier</label>
                        {getSupplier(partNumberManufacturer.createdFromSupplierId)}
                      </Form.Field>
                    <Form.Field>
                      <label>Date Created</label>
                      {getFormattedTime(partNumberManufacturer.dateCreatedUtc)}
                    </Form.Field>
                    <Form.Field>
                      <label>Date Pruned</label>
                      {partNumberManufacturer.datePrunedUtc ? getFormattedTime(partNumberManufacturer.datePrunedUtc) : "(none)"}
                    </Form.Field>
                  </Form.Group>

                  {FormError(error)}

                  <Button type="submit" primary style={{ marginTop: "10px" }}>
                    <Icon name="save" />
                    Save
                  </Button>
                  {partNumberManufacturer.partNumberManufacturerId > 0 && (
                    <Button type="button" title="Delete" onClick={(e) => confirmDeleteOpen(e, partNumberManufacturer)}>
                      <Icon name="delete" />
                      Delete
                    </Button>
                  )}
                </Form>
                </Grid.Column>
                <Grid.Column width={5} style={{ textAlign: "center" }}>

                  { /* Product Images Carousel */ }
                  <Card color="blue">
                    <Card.Content>
                      <Header as='h4'><Icon name='images' />Product Images</Header>
                    </Card.Content>

                    {allImages && allImages.length > 0 ? (
                      <Carousel variant="dark" interval={ProductImageIntervalMs} className="centered" activeIndex={currentIndex} onSelect={onCurrentDatasheetChanged}>
                      {allImages.map((imageMetadata, imageKey) => (
                        <Carousel.Item key={imageKey}>
                          <Image src={getResourceImageUrl(imageMetadata.resourceSourceUrl, imageMetadata.resourcePath, imageMetadata.imageId)} size="large" />
                          <Carousel.Caption>
                            <h5 className="defaultOverlay">{imageMetadata.isDefault ? "Default" : ""}</h5>
                          </Carousel.Caption>
                        </Carousel.Item>
                      ))}
                      </Carousel> 
                    ) : (
                      <Placeholder>
                        <img src='/image/microchip.png' className="square" alt="" />
                      </Placeholder>
                    )}

                    <Card.Content extra>
                      <Button primary icon="photo" label="Set Default" disabled={partNumberManufacturer.imageMetadata.length === 0} onClick={(e) => handleSetDefaultImage(e, currentProductImage)} />
                    </Card.Content>
                  </Card>

                  { /* Datasheets Carousel */ }

                  <Card id="datasheets" color="green">
                    <Card.Content>
                      <Header as='h4'><Icon name='file pdf' />Datasheets</Header>
                    </Card.Content>

                    {allDatasheets && allDatasheets.length > 0 ? (
                    <div>
                      <Carousel variant="dark" interval={null} style={{cursor: 'pointer'}} activeIndex={currentDatasheetIndex} onSelect={onCurrentDatasheetChanged}>
                      {allDatasheets.map((datasheet, datasheetKey) => (
                        <Carousel.Item key={datasheetKey} onClick={(e)=> handleVisitLink(e, getResourceDatasheetUrl(datasheet.resourceSourceUrl, datasheet.resourcePath))} data={datasheet}>
                          {datasheet.imageCount > 0
                            ? <Image src={getResourceImageUrl(datasheet.resourceSourceUrl, datasheet.resourcePath, 1)} size="large" />
                            : <Image src={getResourceImageUrl(datasheet.resourceSourceUrl, "datasheetcover")} size="large" />
                          }
                          <Carousel.Caption>
                            <h5 className="defaultOverlay">{datasheet.datasheetId === partNumberManufacturer.primaryDatasheetId ? "Default" : ""}</h5>
                          </Carousel.Caption>
                        </Carousel.Item>
                      ))}
                      </Carousel>
                      <Card.Content style={{textAlign: 'left'}}>
                        <Card.Header>{datasheetTitle}</Card.Header>
                        <Card.Meta>{datasheetPartName}, {datasheetManufacturer}</Card.Meta>
                        <Card.Description>{datasheetDescription}</Card.Description>
                      </Card.Content>
                    </div>
                    ) : (
                      <Placeholder>
                        <img src='/image/datasheet.png' className="square" alt="" />
                        <Placeholder.Header>
                          <Placeholder.Line length='very long' />
                          <Placeholder.Line length='medium' />
                          <Placeholder.Line length='short' />
                        </Placeholder.Header>
                      </Placeholder>
                    )}
                    <Card.Content extra>
                      <Button primary icon="file" label="Set as Primary" disabled={allDatasheets.length === 0} onClick={(e) => handleSetPrimaryDatasheet(e, currentDatasheet)} />
                    </Card.Content>
                  </Card>

                </Grid.Column>
              </Grid.Row>
            </Grid>
            
        </Segment>

        <Segment secondary loading={loading}>
          <Table compact celled selectable striped size="small" className="partstable">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Supplier</Table.HeaderCell>
                <Table.HeaderCell>Part Number</Table.HeaderCell>
                <Table.HeaderCell>Cost</Table.HeaderCell>
                <Table.HeaderCell>Available</Table.HeaderCell>
                <Table.HeaderCell>Min Order Quantity</Table.HeaderCell>
                <Table.HeaderCell>Packaging</Table.HeaderCell>
                <Table.HeaderCell>Image</Table.HeaderCell>
                <Table.HeaderCell>Url</Table.HeaderCell>
                <Table.HeaderCell style={{width: '60px'}}></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {partNumberManufacturer.suppliers.map((supplier, key) => (
                <Table.Row key={key} onClick={(e) => handleOpenPartNumberSupplier(e, supplier)}>
                  <Table.Cell>{supplier.supplierName}</Table.Cell>
                  <Table.Cell>{supplier.supplierPartNumber}</Table.Cell>
                  <Table.Cell>{formatCurrency(supplier.cost)}</Table.Cell>
                  <Table.Cell>{supplier.quantityAvailable ? formatNumber(supplier.quantityAvailable) : "-"}</Table.Cell>
                  <Table.Cell>{supplier.minimumOrderQuantity ? formatNumber(supplier.minimumOrderQuantity) : "-"}</Table.Cell>
                  <Table.Cell>{supplier.packaging}</Table.Cell>
                  <Table.Cell>{getDefaultProductShotImageFromPartNumberManufacturer(partNumberManufacturer, "product productshot", (s) => s.imageType === ImageTypes.ProductShot && s.createdFromSupplierId === supplier.supplierId)}</Table.Cell>
                  <Table.Cell><a href={supplier.productUrl} title="Visit Supplier" target="_blank" rel="noreferrer" onClick={(e) => e.stopPropagation()}>Visit</a></Table.Cell>
                  <Table.Cell><Button size="mini" icon="delete" onClick={(e) => confirmSupplierDeleteOpen(e, supplier)} /></Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment>
      </div>
    </div>
  );
}
