import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import _ from "underscore";
import { Table, Form, Segment, Button,Icon,Input,Popup,Breadcrumb,Grid,Placeholder,Image,Dropdown} from "semantic-ui-react";
import { fetchApi } from "../../../common/fetchApi";
import { getResourceImageUrl } from "../../../common/resources";
import { FormHeader } from "../../../components/FormHeader";
import "../Admin.css";

export function Pinouts(props) {
  const maxResults = 20;
	const maxSearchResults = 100;
  const defaultAddPinout = {
    partNumberManufacturerId: null,
    pinCount: 8,
		hasPin1Indicator: true,
		pin1: "VCC",
		pin2: "",
		pin3: "",
		pin4: "GND",
		pin5: "",
		pin6: "",
		pin7: "",
		pin8: "",
  };
  const [loading, setLoading] = useState(true);
  const [partSearchLoading, setPartSearchLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [addVisible, setAddVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [addPinout, setAddPinout] = useState(defaultAddPinout);
  const [pinCountOptions, setPinCountOptions] = useState([]);
  const pinoutsDataRef = useRef([]);
  const partSearchDataRef = useRef([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchPinouts(currentPage);

    const pco = [];
    for (let i = 2; i < 64; i++) {
      pco.push({
        key: i,
        value: i,
        text: `${i}`
      });
    }
    setPinCountOptions(pco);

    function fetchPinouts(page = 1) {
      if (hasMoreData) {
        setLoading(true);
        fetchApi(`api/pinout/list?page=${page}&results=${maxResults}`).then((response) => {
          const { data } = response;
          if (data && data.length > 0) {
            // update the page of data, as long as its not already in the data
            let newData = [...pinoutsDataRef.current];
            for (let i = 0; i < data.length; i++) {
              const index = newData.findIndex((x) => x.partNumberId === data[i].partNumberId);
              if (index === -1) {
                newData.push(data[i]);
              }
            }
            pinoutsDataRef.current = newData;
          }
          if (data.length < maxResults) {
            // no more data, received back 0 or less than maxResults
            setHasMoreData(false);
          }
          setLoading(false);
        });
      }
    }
  }, [currentPage, hasMoreData]);

  const createPinout = (e, addPinoutRequest) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    const pinDefinition = {
      pins: []
    };
    for (let i = 1; i <= addPinoutRequest.pinCount; i++) {
      pinDefinition.pins.push({
        pin: i,
        label: addPinoutRequest[`pin${i}`]
      });
    }
    const request = {
      pinCount: addPinoutRequest.pinCount,
      partNumberManufacturerId: addPinoutRequest.partNumberManufacturerId,
      pinoutDefinition: JSON.stringify(pinDefinition)
    };
    fetchApi(`api/pinout`, {
      method: "POST",
      body: JSON.stringify(request)
    }).then(() => {
      setLoading(false);
      setAddVisible(false);
      refreshClean();
    });
  };

  const searchManufacturerParts = (e, name) => {
		setPartSearchLoading(true);
    fetchApi(`api/partnumber/manufacturer/search?name=${name}&results=${maxSearchResults}`).then((response) => {
      const { data } = response;
      if (data && data.length > 0) {
        // update the page of data, as long as its not already in the data
        let newData = data.map((val, key) => ({
          key,
          value: val.partNumberManufacturerId,
          text: val.name,
					description: val.manufacturerName
        }));
        partSearchDataRef.current = newData;
      }
      setPartSearchLoading(false);
    });
  };

  const previewPinout = (e, addPinoutRequest) => {
    e.preventDefault();
    e.stopPropagation();
    const pinDefinition = {
      pins: []
    };
    for (let i = 1; i <= addPinoutRequest.pinCount; i++) {
      pinDefinition.pins.push({
        pin: i,
        label: addPinoutRequest[`pin${i}`]
      });
    }
    const request = {
      pinCount: addPinoutRequest.pinCount,
			hasPin1Indicator: addPinoutRequest.hasPin1Indicator,
      partNumberManufacturerId: addPinoutRequest.partNumberManufacturerId,
      pinoutDefinition: JSON.stringify(pinDefinition)
    };
    fetchApi(`api/pinout/preview`, {
      method: "PUT",
      body: JSON.stringify(request)
    })
      .then(async (response) => {
        const imageBlob = await response.responseObject.blob();
        const imageObjectUrl = URL.createObjectURL(imageBlob);
        setPreviewImage(imageObjectUrl);
      })
      .catch((err) => {
        console.error("Image preview error!", err);
      });
  };

  const refreshClean = () => {
    // refresh
    pinoutsDataRef.current = [];
    setAddPinout(defaultAddPinout);
    setHasMoreData(true);
    setCurrentPage(1);
  };

  const handleShowAdd = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAddVisible(!addVisible);
  };

  const handleChange = (e, control) => {
		if (control.type === 'checkbox')
			addPinout[control.name] = control.checked;
		else
    	addPinout[control.name] = control.value;
    setAddPinout({ ...addPinout });
  };

  const handleSearchChange = (e, control) => {
		if (control.searchQuery.length > 2)
    	searchManufacturerParts(e, control.searchQuery);
  };

  const renderPinCountInputs = () => {
    const leftControls = [];
    const rightControls = [];
    const halfPinCount = Math.ceil(addPinout.pinCount / 2.0);
    for (let i = 1; i <= halfPinCount; i++) {
      const input = (
        <Input label={`#${i}`} key={i} name={`pin${i}`} value={addPinout[`pin${i}`] || ""} onChange={handleChange} width={6} style={{ marginBottom: "20px" }} />
      );
      leftControls.push(input);
    }

		for (let i = addPinout.pinCount; i > halfPinCount; i--) {
      const input = (
        <Input label={`#${i}`} key={i} name={`pin${i}`} value={addPinout[`pin${i}`] || ""} onChange={handleChange} width={6} style={{ marginBottom: "20px" }} />
      );
      rightControls.push(input);
    }

    return (
      <Grid celled columns={3}>
        <Grid.Row>
          <Grid.Column style={{ textAlign: "right", paddingRight: "65px" }}>{leftControls.map((input, key) => input)}</Grid.Column>
          <Grid.Column style={{ textAlign: "center" }}>
            {previewImage ? (
              <Image src={previewImage} style={{ width: "100%", height: "auto" }} />
            ) : (
              <Placeholder>
                <Placeholder.Image square />
              </Placeholder>
            )}
            <Button type="button" icon labelPosition="right" onClick={(e) => previewPinout(e, addPinout)}>
              <Icon name="photo" />
              Preview
            </Button>
          </Grid.Column>
          <Grid.Column style={{ textAlign: "left" }}>{rightControls.map((input, key) => input)}</Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  const openPinout = (e, pinout) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`${pinout.pinoutId}`);
  };

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Section href="/admin">Admin</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>Pinouts</Breadcrumb.Section>
      </Breadcrumb>
      <FormHeader name="Pinouts Management" to="..">
        Manage the pinout images custom to Binner.
      </FormHeader>

      <Form onSubmit={(e) => createPinout(e, addPinout)}>
        <Form.Group>
          <Button type="button" onClick={handleShowAdd} icon size="mini">
            <Icon name="file" /> Add Pinout
          </Button>
        </Form.Group>
        {addVisible && (
          <Segment secondary>
						<Form.Group>
							<Popup
								hideOnScroll
								content="Specify the part name"
								trigger={
									<Form.Field width={6}>
										<label>Part Name</label>
										<Form.Dropdown
											loading={partSearchLoading}
											icon="search"
											search
											selection
											required
											placeholder="Search for parts"
											noResultsMessage="Try another search."
											value={addPinout.partNumberManufacturerId}
											options={partSearchDataRef.current}
											onSearchChange={handleSearchChange}
											onChange={handleChange}
											name="partNumberManufacturerId"
										/>
									</Form.Field>
								}
							/>
							<Popup
								hideOnScroll
								content="Specify the number of pins for the pinout."
								trigger={
									<Form.Field width={2}>
										<Form.Dropdown
											label="Pin Count"
											placeholder="Pin Count"
											search
											selection
											value={addPinout.pinCount || 8}
											name="pinCount"
											options={pinCountOptions}
											onChange={handleChange}
										/>
									</Form.Field>
								}
							/>
						</Form.Group>

						<Popup
              hideOnScroll
              content="Select if a small circle should indicate pin 1."
              trigger={
                <Form.Field>
                  <Form.Checkbox
                    label="Pin 1 Indicator"
										checked={addPinout.hasPin1Indicator}
                    name="hasPin1Indicator"
                    onChange={handleChange}
                  />
                </Form.Field>
              }
            />

            {renderPinCountInputs()}

            <Form.Group>
              <Form.Button primary type="submit" icon>
                <Icon name="save" /> Add Pinout
              </Form.Button>
            </Form.Group>
          </Segment>
        )}
      </Form>

      <h5>Pinouts</h5>

      <div>
        <Segment>
          <Table compact celled sortable selectable striped unstackable size="small" className="queueTable">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Part Name</Table.HeaderCell>
                <Table.HeaderCell>Pin Count</Table.HeaderCell>
                <Table.HeaderCell>Input Image</Table.HeaderCell>
                <Table.HeaderCell>Rendered Image</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {pinoutsDataRef.current.map((pinout, key) => (
                <Table.Row key={key} onClick={(e) => openPinout(e, pinout)}>
                  <Table.Cell>{pinout.partNumberManufacturer.name}</Table.Cell>
                  <Table.Cell>{pinout.pinCount}</Table.Cell>
                  <Table.Cell>{getResourceImageUrl(pinout.inputImage.resourceSourceUrl, pinout.inputImage.resourcePath, pinout.inputImage.imageId)}</Table.Cell>
                  <Table.Cell>
                    {getResourceImageUrl(pinout.exportImage.resourceSourceUrl, pinout.exportImage.resourcePath, pinout.exportImage.imageId)}
                  </Table.Cell>
                  <Table.Cell>
                    <Button size="mini" icon="delete" title="Delete" />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment>
      </div>
    </div>
  );
}
