import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Segment } from "semantic-ui-react";
import _ from "underscore";
import { KBs } from "./KBs";

export function KnowledgeBase(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const { kbNumber } = params;
  let kb = null;
  if (!isNaN(kbNumber)){
    // load KB by number
    kb = _.find(KBs, x => x.id === parseInt(kbNumber));
  }else {
    // load KB by tag
    kb = _.find(KBs, x => x.tag === kbNumber);
  }

  const renderKnowledgebaseAnswer = () => {
    
    if (kb) {
      return (<div>
        <h4>{kb.q}</h4>
        <div className="kbContent">{kb.a}</div>
        <div style={{fontSize: '0.9em', marginTop: '50px'}}><a href={`/kb/${kb.id}`}>KB{kb.id}</a></div>
      </div>);
    }

    return (<div><h2>KB {kbNumber} article not found!</h2></div>);
  };

  const renderKnowledgebasePage = () => {
      return (
        <div>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/" onClick={(e) => { e.preventDefault(); navigate("/"); }}>Home</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page"><a href="/" onClick={(e) => { e.preventDefault(); navigate("/support"); }}>Support</a></li>
              {kb && <li className="breadcrumb-item active" aria-current="page">KB{kb.id}</li>}
            </ol>
          </nav>
          {renderKnowledgebaseAnswer()}
        </div>
      );
  };

  const renderKnowledgebase = () => {
    return (
      <div>
        <div className="centered">
          <Form style={{ marginBottom: "50px" }}>
            <Form.Input
              icon="search"
              placeholder="Type your question..."
              style={{ margin: "0 auto", width: "50%" }}
            />
          </Form>
        </div>
        <div>
          <Segment raised style={{ width: "70%", margin: "0 auto" }}>
            {renderKnowledgebasePage()}
          </Segment>
        </div>

        <div style={{ height: "50px" }}></div>
      </div>
    );
  };

  return (
    <div>
      {/* ***** Breadcrumb Area Start ***** */}
      <div className="mosh-breadcumb-area">
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-12">
              <div className="bradcumbContent">
                <h2>Support</h2>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/" onClick={(e) => { e.preventDefault(); navigate("/"); }}>Home</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Support</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ***** Breadcrumb Area End ***** */}

      <div>
        <div style={{ margin: "0 auto" }}>
          {renderKnowledgebase()}
        </div>
      </div>
    </div>
  );
}
