import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import _ from "underscore";
import debounce from "lodash.debounce";
import { Table, Form, Segment, Button, Icon, Input, Popup, Confirm, Breadcrumb, Flag, Pagination, Dropdown, Checkbox } from "semantic-ui-react";
import { Clipboard } from "../../../components/Clipboard";
import { format, parseJSON } from 'date-fns';
import { fetchApi } from "../../../common/fetchApi";
import { AccountTypes, BooleanTypes, SubscriptionLevels, SubscriptionStates, GetTypeName, GetTypeDropdown } from "../../../common/Types";
import { getFriendlyElapsedTime, getTimeDifference, getFormattedTime, FormatShortDateTime, FormatFullDateTime } from "../../../common/datetime";
import { getLocalData, setLocalData } from "../../../common/storage";
import { FormHeader } from "../../../components/FormHeader"; 
import { SortDirection } from "../../../common/SortDirection";
import { toast } from "react-toastify";
import "../Admin.css";

export function Users(props) {
  const DefaultRecordsPerPage = 10;
  const DebounceTimeMs = 400;

  const getViewPreference = (preferenceName) => {
    return getLocalData(preferenceName, { settingsName: "users" });
  };

  const setViewPreference = (preferenceName, value) => {
    setLocalData(preferenceName, value, { settingsName: "users" });
  };

  const defaultAddUser = {
    name: "",
    emailAddress: "",
    phoneNumber: "",
    isAdmin: false,
    isEmailConfirmed: false,
    subscriptionLevel: 0,
  };
  const [loading, setLoading] = useState(true);
  const [addVisible, setAddVisible] = useState(false);
  const [addUser, setAddUser] = useState(defaultAddUser);
  const [column, setColumn] = useState(null);
  const [direction, setDirection] = useState(null);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [confirmDeleteIsOpen, setConfirmDeleteIsOpen] = useState(false);
  const [deleteSelectedItem, setDeleteSelectedItem] = useState(null);
  const accountTypes = GetTypeDropdown(AccountTypes);
  const emailConfirmedTypes = GetTypeDropdown(BooleanTypes);
  const subscriptionLevels = GetTypeDropdown(SubscriptionLevels);
  const [pageSize, setPageSize] = useState(getViewPreference("pageSize") || DefaultRecordsPerPage);
  const [page, setPage] = useState(getViewPreference("page") || 1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortBy, setSortBy] = useState('DateCreatedUtc');
  const [sortDirection, setSortDirection] = useState(SortDirection.None);
  const [filter, setFilter] = useState('');
  const [filters, setFilters] = useState({ hasLoggedIn: false, hasLoggedInRecently: false, hasConfirmedEmail: false, hasSubscription: false, hasCancelled: false, isLocked: false });
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const itemsPerPageOptions = [
    { key: 1, text: "10", value: 10 },
    { key: 2, text: "25", value: 25 },
    { key: 3, text: "50", value: 50 },
    { key: 4, text: "100", value: 100 },
    { key: 5, text: "200", value: 200 }
  ];

  const fetchUsers = useCallback(async (page = 1, filter, sortBy, sortDirection, pageSize, filters) => {
    setLoading(true);
    if (sortDirection === SortDirection.None) {
      sortBy = '';
      sortDirection = 0;
    }
    await fetchApi(`api/user/list?page=${page}&results=${pageSize}&by=Name&value=${filter || ''}&orderBy=${sortBy}&direction=${sortDirection}&hasLoggedIn=${filters.hasLoggedIn}&hasLoggedInRecently=${filters.hasLoggedInRecently}&hasConfirmedEmail=${filters.hasConfirmedEmail}&hasSubscription=${filters.hasSubscription}&hasCancelled=${filters.hasCancelled}&isLocked=${filters.isLocked}&`).then((response) => {
      const { data } = response;
      if (data) {
        // update the page of data, as long as its not already in the data
        setUsers(data.items || []);
        setTotalPages(data.totalItems / pageSize);
        setTotalRecords(data.totalItems);
      }
      setLoading(false);
    });
  }, []);

  const fetchUsersDebounced = useMemo(() => debounce(fetchUsers, DebounceTimeMs), []);

  useEffect(() => {
    fetchUsers(page, filter, sortBy, sortDirection, pageSize, filters);

  }, []);

  const deleteUser = (e, user) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    fetchApi(`api/user`, {
      method: "DELETE",
      body: user.userId
    }).then(() => {
      const newUsersData = _.filter(users, (item) => item.userId !== user.userId);
      users = [...newUsersData];
      setLoading(false);
      setConfirmDeleteIsOpen(false);
    });
  };

  const handleAddUser = (e, user) => {
    setLoading(true);
    const request = {
      name: user.name,
      emailAddress: user.emailAddress,
      phoneNumber: user.phoneNumber,
      isAdmin: user.isAdmin === 1 ? true : false,
      isEmailConfirmed: user.isEmailConfirmed === 1 ? true : false,
      subscriptionLevel: user.subscriptionLevel
    };
    fetchApi(`api/user`, {
      method: "POST",
      body: JSON.stringify(request)
    }).then(() => {
      setLoading(false);
      setAddVisible(false);
      refreshClean();
      toast.success(`User ${user.emailAddress} added!`);
    });
  };

  const refreshClean = () => {
    // refresh
    setUsers([]);
    setAddUser(defaultAddUser);
    setHasMoreData(true);
    setCurrentPage(1);
  };

  const openUser = (e, user) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`${user.userId}`);
  };

  const handleSort = (clickedColumn) => async () => {
    let newSortBy = clickedColumn;
    let newSortDirection = sortDirection;
    switch(sortDirection) {
      case SortDirection.Ascending:
        newSortDirection = SortDirection.Descending;
        newSortBy = clickedColumn;
        break;
      case SortDirection.Descending:
        newSortDirection = SortDirection.None;
        newSortBy = '';
        break;
      default:
      case SortDirection.None:
        newSortDirection = SortDirection.Ascending;
        newSortBy = clickedColumn;
        break;
    }
    setSortBy(newSortBy);
    setSortDirection(newSortDirection);
    fetchUsers(page, filter, newSortBy, newSortDirection, pageSize, filters);
  };

  const confirmDeleteOpen = (e, user) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteSelectedItem(user);
    setConfirmDeleteIsOpen(true);
  };

  const confirmDeleteClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteSelectedItem(null);
    setConfirmDeleteIsOpen(false);
  };

  const handleChange = (e, control) => {
    addUser[control.name] = control.value;
    setAddUser({ ...addUser });
  };

  const handleShowAdd = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAddVisible(!addVisible);
  };

  const handleInternalPageChange = async (e, control) => {
    setPage(control.activePage);
    setViewPreference("page", control.activePage);
    await fetchUsers(control.activePage, filter, sortBy, sortDirection, pageSize, filters);
  };

  const handleFilterChange = async (e, control) => {
    setFilter(control.value);
    await fetchUsersDebounced(page, control.value, sortBy, sortDirection, pageSize, filters);
  };

  const handleFilterOptionChange = async (e, control) => {
    filters[control.name] = control.checked;
    setFilters({ ...filters});
    await fetchUsers(control.activePage, filter, sortBy, sortDirection, pageSize, filters);
  };

  const handlePageSizeChange = async (e, control) => {
    setPage(1);
    setPageSize(control.value);
    await fetchUsers(1, filter, sortBy, sortDirection, control.value, filters);
  };

  return (
    <div className="admin-container">
      <Breadcrumb>
        <Breadcrumb.Section href="/admin">Admin</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>Users</Breadcrumb.Section>
      </Breadcrumb>
      <FormHeader name="User Management" to="..">
        Administration of users.
      </FormHeader>

      <Confirm
        open={confirmDeleteIsOpen}
        onCancel={confirmDeleteClose}
        onConfirm={(e) => deleteUser(e, deleteSelectedItem)}
        content="Are you sure you want to delete this user?"
      />
      <Form onSubmit={(e) => handleAddUser(e, addUser)}>
        <Form.Group>
          <Button type="button" onClick={handleShowAdd} icon size="mini">
            <Icon name="file" /> Add User
          </Button>
        </Form.Group>
        {addVisible && (
          <Segment secondary>
            <Popup
              hideOnScroll
              content="Specify the user's first and last name"
              trigger={
                <Form.Field width={10}>
                  <label>Name</label>
                  <Input action required className="labeled" placeholder="John Doe" value={addUser.name} onChange={handleChange} name="name" />
                </Form.Field>
              }
            />
            <Popup
              hideOnScroll
              content="Specify the user's email"
              trigger={
                <Form.Field width={10}>
                  <label>Email</label>
                  <Input action required className="labeled" placeholder="john@example.com" value={addUser.emailAddress} onChange={handleChange} name="emailAddress" />
                </Form.Field>
              }
            />
            <Popup
              hideOnScroll
              content="Specify the user's phone number"
              trigger={
                <Form.Field width={10}>
                  <label>Phone</label>
                  <Input action className="labeled" placeholder="555-555-1212" value={addUser.phoneNumber} onChange={handleChange} name="phoneNumber" />
                </Form.Field>
              }
            />
            <Form.Dropdown
              label="Account Type"
              placeholder="Account Type"
              selection
              value={addUser.isAdmin ? 1 : 0}
              className={addUser.isAdmin ? "blue" : ""}
              name="isAdmin"
              options={accountTypes}
              onChange={handleChange}
            />
            <Form.Dropdown
              label="Email Confirmed"
              placeholder="Email Confirmed"
              selection
              value={addUser.isEmailConfirmed ? 1 : 0}
              className={addUser.isEmailConfirmed ? "green" : "red"}
              name="isEmailConfirmed"
              options={emailConfirmedTypes}
              onChange={handleChange}
            />
            <Form.Dropdown
              label="Subscription Level"
              placeholder="None"
              selection
              value={addUser.subscriptionLevel}
              name="subscriptionLevel"
              options={subscriptionLevels}
              onChange={handleChange}
            />
            <Form.Group>
              <Form.Button primary type="submit" icon>
                <Icon name="save" /> Add User
              </Form.Button>
            </Form.Group>
          </Segment>
        )}
      </Form>

      <Segment loading={loading} secondary>
        <div style={{ display: 'flex' }}>
          <Form.Field>
            <Form.Input
              name='filter'
              icon='filter'
              placeholder='Filter...'
              value={filter || ''}
              onChange={handleFilterChange}
            />
          </Form.Field>
          <div style={{flex: '1', margin: '0 10px'}} className="checkbox-group">
            <Form.Group>
              <Checkbox label="Has Logged In" checked={filters.hasLoggedIn} name="hasLoggedIn" onChange={handleFilterOptionChange} />
              <Checkbox label="Has Recent Login" checked={filters.hasLoggedInRecently} name="hasLoggedInRecently" onChange={handleFilterOptionChange} />
              <Checkbox label="Has Confirmed Email" checked={filters.hasConfirmedEmail} name="hasConfirmedEmail" onChange={handleFilterOptionChange} />
              <Checkbox label="Has Subscription" checked={filters.hasSubscription} name="hasSubscription" onChange={handleFilterOptionChange} />
              <Checkbox label="Has Cancelled Subscription" checked={filters.hasCancelled} name="hasCancelled" onChange={handleFilterOptionChange} />
              <Checkbox label="Is Locked" checked={filters.isLocked} name="isLocked" onChange={handleFilterOptionChange} />
            </Form.Group>
          </div>
          <Dropdown 
            selection 
            options={itemsPerPageOptions} 
            value={pageSize} 
            className="labeled" 
            onChange={handlePageSizeChange} 
            style={{ width: '75px', minWidth: '75px', marginRight: '10px' }} 
          />
          <div style={{lineHeight: '2.5em'}}>
            <span>records per page</span>
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <Pagination
            activePage={page}
            totalPages={totalPages}
            firstItem={null}
            lastItem={null}
            onPageChange={handleInternalPageChange}
            size="mini"
            style={{marginTop: '5px'}}
          />
          <div style={{ flex: '1', textAlign: 'right', marginTop: '30px' }}>
            <span>{totalRecords} users.</span>
          </div>
        </div>
        <Table
          id="usersTable"
          compact
          celled
          sortable
          selectable
          striped
          unstackable
          size="small"
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className={sortBy === "userId" ? `sorted ${GetTypeName(SortDirection, sortDirection)}` : null} onClick={handleSort("userId")}>
                Id
              </Table.HeaderCell>
              <Table.HeaderCell className={sortBy === "isAdmin" ? `sorted ${GetTypeName(SortDirection, sortDirection)}` : null} onClick={handleSort("isAdmin")}>
                Type
              </Table.HeaderCell>
              <Table.HeaderCell className={sortBy === "dateLockedUtc" ? `sorted ${GetTypeName(SortDirection, sortDirection)}` : null} onClick={handleSort("dateLockedUtc")}>
                Locked
              </Table.HeaderCell>
              <Table.HeaderCell className={sortBy === "isEmailConfirmed" ? `sorted ${GetTypeName(SortDirection, sortDirection)}` : null} onClick={handleSort("isEmailConfirmed")}>
                Confirmed
              </Table.HeaderCell>
              <Table.HeaderCell>
                Country
              </Table.HeaderCell>
              <Table.HeaderCell className={sortBy === "name" ? `sorted ${GetTypeName(SortDirection, sortDirection)}` : null} onClick={handleSort("name")}>
                Name
              </Table.HeaderCell>
              <Table.HeaderCell className={sortBy === "emailAddress" ? `sorted ${GetTypeName(SortDirection, sortDirection)}` : null} onClick={handleSort("emailAddress")}>
                Email
              </Table.HeaderCell>
              <Table.HeaderCell className={sortBy === "phoneNumber" ? `sorted ${GetTypeName(SortDirection, sortDirection)}` : null} onClick={handleSort("phoneNumber")}>
                Phone
              </Table.HeaderCell>
              <Table.HeaderCell className={sortBy === "subscriptionLevel" ? `sorted ${GetTypeName(SortDirection, sortDirection)}` : null} onClick={handleSort("subscriptionLevel")}>
                Subscription
              </Table.HeaderCell>
              <Table.HeaderCell>
                Sub. Status
              </Table.HeaderCell>
              <Table.HeaderCell className={sortBy === "dateLastActiveUtc" ? `sorted ${GetTypeName(SortDirection, sortDirection)}` : null} onClick={handleSort("dateLastActiveUtc")}>
                Last Active
              </Table.HeaderCell>
              <Table.HeaderCell className={sortBy === "dateCreatedUtc" ? `sorted ${GetTypeName(SortDirection, sortDirection)}` : null} onClick={handleSort("dateCreatedUtc")}>
                Date Added
              </Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {users?.map((user, i) => (
            <Table.Row key={i} onClick={(e) => openUser(e, user)}>
              <Table.Cell>{user.userId}</Table.Cell>
              <Table.Cell style={{ textAlign: 'center' }}>{user.isAdmin ? (<Icon name="user secret" title="Admin" color="red" size="large" />) : (<Icon name="user" title="Normal Account" />)}</Table.Cell>
              <Table.Cell style={{ textAlign: 'center' }}>{user.dateLockedUtc ? (<Icon name="remove circle" color="red" title="Is Locked" />) : (<Icon name="check circle" color="green" title="Not Locked" />)}</Table.Cell>
              <Table.Cell style={{ textAlign: 'center' }}>{user.isEmailConfirmed ? (<Icon name="check circle" color="green" title="Email is confirmed" />) : (<Icon name="remove circle" color="red" title="Email is not confirmed" />)}</Table.Cell>
              <Table.Cell style={{ textAlign: 'center', maxWidth: '100px' }}>{user.location && user.location.country && <Flag name={user.location.country.toLowerCase()} />}</Table.Cell>
              <Table.Cell style={{ minWidth: "110px", maxWidth: "140px" }}><Clipboard text={user.name} /><Popup wide position="top center" positionFixed hoverable content={<div><Clipboard text={user.name} /> {user.name}</div>} trigger={<span>{user.name}</span>} /></Table.Cell>
              <Table.Cell style={{ minWidth: "180px", maxWidth: "210px" }}><Clipboard text={user.emailAddress} /><Popup wide position="top center" positionFixed hoverable content={<div><Clipboard text={user.emailAddress} /> {user.emailAddress}</div>} trigger={<span>{user.emailAddress}</span>} /></Table.Cell>
              <Table.Cell style={{ minWidth: "120px", maxWidth: '150px' }}>{user.phoneNumber && <Clipboard text={user.phoneNumber} />} {user.phoneNumber}</Table.Cell>
              <Table.Cell>{user.subscriptions.map((subscription, subscriptionKey) => (
                <div key={subscriptionKey}>
                  {GetTypeName(SubscriptionLevels, subscription.subscriptionType)}
                </div>
              ))}</Table.Cell>
              <Table.Cell>{user.subscriptions.map((subscription, subscriptionKey) => (
                <div key={subscriptionKey}>
                  {GetTypeName(SubscriptionStates, subscription.subscriptionState)}
                </div>
              ))}</Table.Cell>
              <Table.Cell style={{ minWidth: "130px" }}>
                {user.dateLastActiveUtc !== null
                  ? getFriendlyElapsedTime(getTimeDifference(Date.now(), Date.parse(user.dateLastActiveUtc)), true)
                  : '(never)'}
              </Table.Cell>
              <Table.Cell style={{ minWidth: "170px" }}><span title={`${format(parseJSON(user.dateCreatedUtc), FormatFullDateTime)}`}>{format(parseJSON(user.dateCreatedUtc), FormatShortDateTime)}</span></Table.Cell>
              <Table.Cell>
                {user.userId > 1 &&
                  <Link onClick={(e) => confirmDeleteOpen(e, user)} title="Delete"><Icon name="trash alternate" /></Link>
                }
              </Table.Cell>
            </Table.Row>
          ))}
        </Table>
        <Pagination
          activePage={page}
          totalPages={totalPages}
          firstItem={null}
          lastItem={null}
          onPageChange={handleInternalPageChange}
          size="mini"
        />
      </Segment>

    </div>
  );
}