import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "underscore";
import { Table, Form, Segment, Button, Icon, Confirm, Breadcrumb, Popup, TextArea, Tab } from "semantic-ui-react";
import { toast } from "react-toastify";
import { fetchApi, getErrorsString } from "../../../common/fetchApi";
import { FilterTypes, GetAdvancedTypeDropdown } from "../../../common/Types";
import { FormHeader } from "../../../components/FormHeader";
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import "../Admin.css";

export function BulkEmail(props) {
  const defaultBulkEmail = {
    filter: "",
    subject: "",
    bulkEmailHeader: "Binner Announcement",
    bulkEmailContent: "",
    previewRecipientList: true,
    isTest: true,
    testEmailAddress: ""
  };
  const [loading, setLoading] = useState(false);
  const [bulkEmail, setBulkEmail] = useState(defaultBulkEmail);
  const [bulkEmailResponse, setBulkEmailResponse] = useState({
    errors: [],
    recipients: [],
    isSuccessful: false,
    errorMessage: null,
    emailsSent: 0,
  });
  const [isDirty, setIsDirty] = useState(false);
  const [confirmBulkEmailIsOpen, setConfirmBulkEmailIsOpen] = useState(false);
  const [confirmSendBulkEmailContent, setConfirmSendBulkEmailContent] = useState("Are you sure you want to send this bulk email?");
  const [previewContent, setPreviewContent] = useState('');
  const filterTypes = GetAdvancedTypeDropdown(FilterTypes, true);
  const [editorInstance, setEditorInstance] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const params = useParams();
  const { userId } = params;
  const navigate = useNavigate();

  const template = `<!DOCTYPE html>
<html>
<head>
    <title></title>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="color-scheme" content="light dark">
    <meta name="supported-color-schemes" content="light dark">
    <style type="text/css">
        /* FONTS */
        @@media screen {
            @@font-face {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v11/qIIYRU-oROkIk8vfvxw6QvesZW2xOQ-xsNqO47m55DA.woff) format('woff');
            }
            @@font-face {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 700;
                src: local('Lato Bold'), local('Lato-Bold'), url(https://fonts.gstatic.com/s/lato/v11/qdgUG4U09HnJwhYI-uK18wLUuEpTyoUstqEm5AMlJo4.woff) format('woff');
            }
            @@font-face {
                font-family: 'Lato';
                font-style: italic;
                font-weight: 400;
                src: local('Lato Italic'), local('Lato-Italic'), url(https://fonts.gstatic.com/s/lato/v11/RYyZNoeFgb0l7W3Vu1aSWOvvDin1pK8aKteLpeZ5c0A.woff) format('woff');
            }
            @@font-face {
                font-family: 'Lato';
                font-style: italic;
                font-weight: 700;
                src: local('Lato Bold Italic'), local('Lato-BoldItalic'), url(https://fonts.gstatic.com/s/lato/v11/HkF_qI1x_noxlxhrhMQYELO3LdcAZYWl9Si6vvxL-qU.woff) format('woff');
            }
        }
        /* CLIENT-SPECIFIC STYLES */
        body, table, td, a {
            -webkit-text-size-adjust: 100%;
            -ms-text-size-adjust: 100%;
        }
        table, td {
            mso-table-lspace: 0pt;
            mso-table-rspace: 0pt;
        }
        img {
            -ms-interpolation-mode: bicubic;
        }
        /* RESET STYLES */
        img {
            border: 0;
            height: auto;
            line-height: 100%;
            outline: none;
            text-decoration: none;
        }
        table {
            border-collapse: collapse !important;
        }
        body {
            height: 100% !important;
            margin: 0 !important;
            padding: 0 !important;
            width: 100% !important;
        }
        /* iOS BLUE LINKS */
        a[x-apple-data-detectors] {
            color: inherit !important;
            text-decoration: none !important;
            font-size: inherit !important;
            font-family: inherit !important;
            font-weight: inherit !important;
            line-height: inherit !important;
        }
        /* DARK MODE STYLES */
        @@media (prefers-color-scheme: dark ) {
          .bg-light { background-color: #000000 !important; }
          .bg-medium { background-color: #121212 !important; }
          .bg-blue { background-color: #125cdc !important; }
          .text-color { color: #f5f5f5 !important; }
          .text-medium-color { color: #d8d8d8 !important; }  
          h1, h2, p, span, a, b { color: #f5f5f5 !important; }
        }
        [data-ogsc] .bg-light { background-color: #000000 !important; }
        [data-ogsc] .bg-medium { background-color: #121212 !important; }
        [data-ogsc] .bg-blue { background-color: #125cdc !important; }
        [data-ogsc] .text-color { color: #f5f5f5 !important; }
        [data-ogsc] .text-medium-color { color: #d8d8d8 !important; }  
        [data-ogsc] h1, [data-ogsc] h2, [data-ogsc] p, [data-ogsc] span, [data-ogsc] a, [data-ogsc] b { color: #f5f5f5 !important; }
        /* END DARK MODE STYLES */
        /* MOBILE STYLES */
        @@media screen and (max-width:600px) {
            h1 {
                font-size: 32px !important;
                line-height: 32px !important;
            }
        }
        /* TARGET GMAIL */
        u + .body .gmail-blend-screen { background:#000; mix-blend-mode:screen; }
        u + .body .gmail-blend-difference { background:#000; mix-blend-mode:difference; }
        u + .body .logo { background-image: url(https://binner.io/image/logo-light-200x54.png) !important; mix-blend-mode: difference !important; }
        /* END TARGET GMAIL */
        /* ANDROID CENTER FIX */
        div[style*="margin: 16px 0;"] {
            margin: 0 !important;
        }
        h1, h2, p, span, a, b { color: #111111; }
        .logo {
            background-image: url(https://binner.io/image/logo-light-200x54.png);
            background-size: cover;
            color: #fff;
            width: 200px;
            height: 54px;
            max-width: 200px;
            min-width: 200px;
        }
        .text-color { color: #111111; }
        .text-medium-color { color: #666666; }
        .bg-blue {
            background-color: #125cdc;
            background-image:linear-gradient(#125cdc,#125cdc);
            color: #fff;
        }
        .bg-light {
            background-color: #ffffff;
        }
        .bg-medium {
            background-color: #f4f4f4;
        }
        .blue {
            color: #125cdc
        }
        .centered {
            margin: 0 auto;
            text-align: center;
        }
    </style>
</head>
<body class="body bg-medium" style="margin: 0 !important; padding: 0 !important;">

    <div class="bg-medium" style="display: none; font-size: 1px; line-height: 1px; font-family: 'Lato', Helvetica, Arial, sans-serif; max-height: 0px; max-width: 0px; opacity: 0; overflow: hidden;">
        We're thrilled to have you here! Get ready to dive into your new account.
    </div>

    <table border="0" cellpadding="0" cellspacing="0" width="100%">
        <tr>
            <td align="center">
                <div class="bg-blue">
                    <div class="gmail-blend-screen">
                        <div class="gmail-blend-difference">
                            <table align="center" border="0" cellspacing="0" cellpadding="0" width="600">
                            <tr>
                            <td align="center" valign="top" width="600">
                            <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">
                                <tr>
                                    <td align="center" valign="top" style="padding: 15px 10px 15px 10px;">
                                        <a href="https://binner.io" target="_blank">
                                            <div class="logo" />
                                        </a>
                                    </td>
                                </tr>
                            </table>
                            </td>
                            </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td align="center" style="padding: 0px 10px 0px 10px;">
                <table align="center" border="0" cellspacing="0" cellpadding="0" width="700">
                    <tr>
                        <td align="center" valign="top" width="700">
                        <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 700px;">
                            <tr>
                                <td class="bg-light text-color" align="center" valign="top" style="padding: 40px 20px 20px 20px; border-radius: 8px 8px 0px 0px; font-family: 'Lato', Helvetica, Arial, sans-serif; font-size: 48px; font-weight: 400; letter-spacing: 4px; line-height: 48px;">
                                    <h1 style="font-size: 24px; font-weight: 400; margin: 0;">{{header}}</h1>
                                </td>
                            </tr>
                        </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr>
            <td class="bg-medium" align="center" style="padding: 0px 10px 0px 10px;">
                <table align="center" border="0" cellspacing="0" cellpadding="0" width="700">
                <tr>
                <td align="center" valign="top" width="700">
                <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 700px;">
                    <tr>
                        <td class="bg-light text-medium-color" align="left" style="padding: 20px 30px 20px 30px; font-family: 'Lato', Helvetica, Arial, sans-serif; font-size: 18px; font-weight: 400; line-height: 25px; border-radius: 0px 0px 8px 8px;">
                            {{body}}
                        </td>
                    </tr>
                </table>
                </td>
                </tr>
                </table>
            </td>
        </tr>
        <tr>
            <td class="bg-medium" align="center" style="padding: 0px 10px 0px 10px;">
                <table align="center" border="0" cellspacing="0" cellpadding="0" width="600">
                <tr>
                <td align="center" valign="top" width="600">
                <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">
                    <tr>
                        <td class="bg-medium text-medium-color" align="left" style="padding: 20px 20px 20px 20px; font-family: 'Lato', Helvetica, Arial, sans-serif; font-size: 14px; font-weight: 400; line-height: 18px; text-align: center">
                            <p style="margin: 0;">
                                <a href="https://binner.io" target="_blank" class="text-color" style="font-weight: 700;">Dashboard</a> –
                                <a href="https://binner.io/support" target="_blank" class="text-color" style="font-weight: 700;">Support</a>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-medium text-medium-color" align="left" style="padding: 0px 30px 30px 30px; font-family: 'Lato', Helvetica, Arial, sans-serif; font-size: 14px; font-weight: 400; line-height: 18px; text-align: center">
                            <p style="margin: 0;">If these emails get annoying, please feel free to <a href="https://binner.io/unsubscribe" target="_blank" class="text-medium-color" style="font-weight: 700;">unsubscribe</a>.</p>
                        </td>
                    </tr>
                    <tr>
                        <td class="bg-medium text-medium-color" align="left" style="padding: 0px 30px 30px 30px; color: #666666; font-family: 'Lato', Helvetica, Arial, sans-serif; font-size: 14px; font-weight: 400; line-height: 18px; text-align: center">
                            <p style="margin: 0;"><a href="https://binner.io" target="_blank" class="text-color" style="font-weight: 700;">Binner</a> – Vancouver, BC Canada</p>
                        </td>
                    </tr>
                </table>
                </td>
                </tr>
                </table>
            </td>
        </tr>
    </table>

</body>
</html>`;

  useEffect(() => {

  }, []);

  const resetForm = (e) => {
    setIsDirty(false);
    setLoading(false);
    setBulkEmail(defaultBulkEmail);
  };

  const sendBulkEmail = (e) => {
    const bulkEmailRequest = {
      ...bulkEmail,
      sendEmails: !bulkEmail.previewRecipientList
    };
    setLoading(true);
    fetchApi(`api/email/bulk`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(bulkEmailRequest)
    }).then((response) => {
      if (response.responseObject.ok) {
        const { data } = response;
        setBulkEmailResponse(data);
        if (data.isSuccessful) {
          if (bulkEmail.previewRecipientList)
            toast.info(`${response.data.recipients.length} email addresses will be sent to (see email recipients at the bottom).`);
          else if (bulkEmail.isTest)
            toast.info(`Sent ${response.data.emailsSent} test emails to '${bulkEmail.testEmailAddress}'!`);
          else
            toast.success(`Sent ${response.data.emailsSent} bulk emails!`);
        } else {
          if (data.errors.length > 0) {
            console.error('Email send errors', data.errors);
            toast.warn(`There were ${data.errors.length} errors during send, ${data.emailsSent} emails were successful.`);
          } else {
            const errorMessage = response.data.errorMessage;
            console.error(errorMessage);
            toast.error(errorMessage);
          }
        }
        setLoading(false);
        setIsDirty(true);
      } else {
        const errorMessage = getErrorsString(response);
        console.error(errorMessage);
        toast.error(errorMessage);
        setLoading(false);
        setIsDirty(true);
      }
    });
  };

  const validateSend = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (bulkEmail.bulkEmailHeader.length === 0) {
      toast.error('You must specify an email header for the template!');
      return;
    }
    if (bulkEmail.bulkEmailContent.length === 0) {
      toast.error('You must specify an email content for the template!');
      return;
    }
    if (bulkEmail.subject.length === 0) {
      toast.error('You must specify an email subject!');
      return;
    }
    if (bulkEmail.filter.length === 0) {
      toast.error('You must specify an email filter!');
      return;
    }
    if (bulkEmail.isTest && bulkEmail.testEmailAddress.length === 0) {
      toast.error("You must specify a test email address when Is 'Test Email' is enabled!");
      return;
    }
    confirmSendBulkEmail(e);
  }

  const confirmSendBulkEmail = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (bulkEmail.isTest === false && bulkEmail.previewRecipientList === false) {
      setConfirmBulkEmailIsOpen(true);
      setConfirmSendBulkEmailContent(<p>You are about to send a <b>production email</b>, are you absolutely sure you want to send now?</p>);
    } else {
      sendBulkEmail(e);
    }
  };

  const confirmSendBulkEmailClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setConfirmBulkEmailIsOpen(false);
  };

  const handleChange = (e, control) => {
    bulkEmail[control.name] = control.value;
    if (control.type === 'checkbox') {
      bulkEmail[control.name] = control.checked;
    }
    setBulkEmail({ ...bulkEmail });
    setIsDirty(true);
  };

  const handlePreviewEmailContent = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const content = template
      .replace('{{header}}', bulkEmail.bulkEmailHeader)
      .replace('{{body}}', bulkEmail.bulkEmailContent);
    setPreviewContent(content);
  };

  const resizeIFrame = () => {
    const obj = document.getElementById('preview');
    if (obj) {
      obj.style.height = obj.contentWindow?.document?.body?.scrollHeight + 10 + 'px';
    }
  };

  const updatePreview = (event, editor) => {
    if (!editor) {
      editor = editorInstance;
    }

    if (editor) {
        const content = template
          .replace('{{header}}', bulkEmail.bulkEmailHeader)
          .replace('{{body}}', bulkEmail.bulkEmailContent);

        setPreviewContent(content);
        return content;
    } else {
      console.error('updatePreview: No editor instance available!', editor, editorInstance);
    }
  };

  const handleTabChange = (event, data) => {
    const newActiveIndex = data.activeIndex;
    setActiveIndex(newActiveIndex);
    if (newActiveIndex === 1) {
      // preview tab
      updatePreview();
    }
  };

  const panes = [
    {
      menuItem: { key: 'template', icon: <Icon name='file alternate outline' color='blue' />, content: 'Edit', className: 'edit-tab' },
      pane: <Tab.Pane attached key={'pane-editor'}>
        <CKEditor
          editor={Editor}
          config={{
            htmlSupport: {
              allow: [
                {
                  name: /.*/,
                  attributes: true,
                  classes: true,
                  styles: true
                },
              ],
              disallow: [
                {
                  name: 'script',
                },
                {
                  attributes: {
                    onclick: false,
                  }
                }
              ],
              allowEmpty: ['i', 'b', 'span', 'p', 'div', 'td', 'th', 'strong', 'h1', 'h2', 'h3', 'h4', 'h5', 'li']
            },
          }}
          data={bulkEmail.bulkEmailContent || ""}
          onReady={editor => {
            setEditorInstance(editor);
          }}
          onChange={(event, editor) => {
            const newValue = editor.getData();
            if (newValue.length > 0) setIsDirty(true);
            setBulkEmail({ ...bulkEmail, bulkEmailContent: newValue });
          }}
          onBlur={(event, editor) => {
          }}
          onFocus={(event, editor) => {
          }}
        />
      </Tab.Pane>
    },
    {
      menuItem: { key: 'preview', icon: <Icon name='eye' color='grey' />, content: 'Preview', className: 'preview-tab' },
      pane: <Tab.Pane attached key={'pane-preview'}>
        {previewContent?.length > 0 && <iframe width="100%" height="600px" style={{ border: 'none' }} srcDoc={previewContent} onLoad={resizeIFrame}>
        </iframe>}
      </Tab.Pane>
    },
  ];

  const isValid = bulkEmail.subject?.length > 3 && bulkEmail.bulkEmailContent?.length > 5 && bulkEmail.filter?.length > 0;

  return (
    <div className="admin-container">
      <Breadcrumb>
        <Breadcrumb.Section href="/admin">Admin</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>Bulk Email</Breadcrumb.Section>
      </Breadcrumb>
      <FormHeader name="Bulk Email" to="..">
        Send bulk emails to users.
      </FormHeader>
      <Confirm
        open={confirmBulkEmailIsOpen}
        onCancel={confirmSendBulkEmailClose}
        onConfirm={sendBulkEmail}
        content={confirmSendBulkEmailContent}
      />

      <Segment loading={loading} secondary>
        <Form>
          <Popup
            content={<p>Enter the email subject</p>}
            trigger={<Form.Input label="Subject" required focus placeholder="Important Announcement" value={bulkEmail.subject || ""} name="subject" onChange={handleChange} />}
          />
          <Popup
            content={<p>Specify a short header title for the email. HTML is <i>not</i> ok.</p>}
            trigger={<Form.Field control={TextArea} rows={2} label="Bulk Email Header" required placeholder="Binner Announcement." value={bulkEmail.bulkEmailHeader || ""} name="bulkEmailHeader" onChange={handleChange} />}
          />

          <Form.Field>
            <label>Bulk Email Content (html ok)</label>

            <Tab
              panes={panes}
              onTabChange={handleTabChange}
              /* renderActiveOnly - we want to render all tabs so the editor doesn't lose its state */
              renderActiveOnly={false}
              activeIndex={activeIndex}
              className="admin-tab"
            />
            
          </Form.Field>

          <div style={{textAlign: 'right', width: '100%'}}>
            <Button onClick={handlePreviewEmailContent} color="black" disabled={(bulkEmail.subject?.length || 0) <= 3 || (bulkEmail.bulkEmailContent?.length || 0) <= 5}>
              <Icon name="eye" color="blue" />
              Preview Email Content
            </Button>
          </div>

          <Popup
            content={<p>Choose the filter to use for selecting which users get emailed</p>}
            trigger={<Form.Dropdown
              label="Recipient Filter"
              placeholder="Choose a filter"
              selection
              required
              value={bulkEmail.filter || ""}
              name="filter"
              options={filterTypes}
              onChange={handleChange}
            />}
          />

          <Popup
            content={<p>Check this option to preview the email addresses that will be sent. No emails will be sent.</p>}
            trigger={<Form.Checkbox
              toggle
              label="Preview recipient list"
              checked={bulkEmail.previewRecipientList}
              style={{ padding: '4px' }}
              className={!bulkEmail.previewRecipientList ? "red" : ""}
              name="previewRecipientList"
              onChange={handleChange}
            />}
          />

          <Popup
            content={<p>Check this option to send all emails to the Test Email address (max of 3 will be sent to this address)</p>}
            trigger={<Form.Checkbox
              toggle
              label="Is Test Email"
              checked={bulkEmail.isTest}
              style={{ padding: '4px' }}
              className={!bulkEmail.isTest ? "red" : ""}
              name="isTest"
              onChange={handleChange}
            />}
          />

          <Popup
            wide
            content={<p>The email address to send test emails to</p>}
            trigger={<Form.Input label="Test Email" iconPosition="left" placeholder="mikeb@binner.io" value={bulkEmail.testEmailAddress || ""} name="testEmailAddress" onChange={handleChange}>
              <Icon name='at' />
              <input />
            </Form.Input>}
          />

          <div style={{ border: !bulkEmail.previewRecipientList && !bulkEmail.isTest ? '8px dashed #c00' : '8px dashed #333', backgroundColor: !bulkEmail.previewRecipientList && !bulkEmail.isTest ? '#fcc' : '#ccc', padding: '10px', marginBottom: '20px' }}>
            <div style={{ padding: '50px', textAlign: 'center', width: '100%' }}>

              {!bulkEmail.previewRecipientList && !bulkEmail.isTest && 
                <div style={{ marginBottom: '25px', fontSize: '1.5em' }}><Icon name="warning sign" color="red" /> <b>Danger zone</b> <Icon name="warning sign" color="red" /></div>
              }
              
              {bulkEmail.previewRecipientList 
                ? <Popup
                  wide='very'
                  position="top center"
                  content={<p>Clicking Preview will display the list of users who will be emailed.<br /><br /><Icon name="check circle" color="green" /> <i>No emails will be sent.</i></p>}
                  trigger={<Button disabled={!isValid} size="large" style={{ margin: '0 20px' }} onClick={validateSend} color="black">
                    <Icon name="eye" />
                    Preview Address List
                  </Button>}
                />
                : bulkEmail.isTest 
                  ? <Popup
                      wide='very'
                      position="top center"
                    content={<p>Ready to send the test emails? Click Send Test Emails, and check that you received the test emails at <b>{bulkEmail.testEmailAddress}</b>!<br /><br /><Icon name="warning sign" color="blue" /><i>Up to 3 test emails will be sent.</i></p>}
                      trigger={<Button disabled={!isValid} size="large" style={{ margin: '0 20px' }} onClick={validateSend} color="green">
                        <Icon name="mail" />
                        Send Test Emails
                      </Button>}
                    />
                  : <Popup
                    wide='very'
                    position="top center"
                    content={<p>Ready to send? Click Send Emails, confirm the dialog and wait for the page to finish!<br /><br /><Icon name="warning sign" color="red" /> <i>All emails will be sent!</i> <Icon name="warning sign" color="red" /></p>}
                    trigger={<Button disabled={!isValid} size="large" style={{ margin: '0 20px' }} onClick={validateSend} color="green">
                      <Icon name="mail" />
                      Send Emails
                    </Button>}
                  />
                  }
              
              
              <Popup 
                wide
                position="top center"
                content={<p>Reset the form back to default values</p>}
                trigger={<Button disabled={!isDirty} size="large" onClick={resetForm}>Reset</Button>}
              />
              
            </div>
          </div>

        </Form>

        <h3>Email Recipients</h3>
        <label>Emails sent:</label> {bulkEmailResponse.emailsSent}<br /><br />
        <Table compact celled sortable selectable striped unstackable size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1}></Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {bulkEmailResponse.recipients.map((emailAddress, key) => (
              <Table.Row key={key}>
                <Table.Cell textAlign="center">{_.find(bulkEmailResponse.errors, x => x === emailAddress) === undefined ? <Icon name="check circle" color="green" /> : <Icon name="times circle" color="red" />}</Table.Cell>
                <Table.Cell>{emailAddress}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Segment>

      <div style={{ height: '50px' }}></div>
    </div>
  );
}