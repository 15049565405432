import React from "react";
import { BrowserRouter } from "react-router-dom";
import { App } from "./App";

export const AppRouter = () => {
  return (
		<BrowserRouter>
			<App />
		</BrowserRouter>
  );
}

export default AppRouter;
