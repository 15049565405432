import React from 'react';
import { useNavigate } from "react-router-dom";

export function DataIntegrations (props) {
	const navigate = useNavigate();

  return (
    <div>
			{/* ***** Breadcrumb Area Start ***** */}
			<div className="mosh-breadcumb-area">
					<div className="container h-100">
							<div className="row h-100 align-items-center">
									<div className="col-12">
											<div className="bradcumbContent">
													<h2>More About Binner</h2>
													<nav aria-label="breadcrumb">
															<ol className="breadcrumb">
																	<li className="breadcrumb-item"><a href="/" onClick={(e) => { e.preventDefault(); navigate('/'); }}>Home</a></li>
																	<li className="breadcrumb-item"><a href="/features" onClick={(e) => { e.preventDefault(); navigate('/features'); }}>Features</a></li>
																	<li className="breadcrumb-item active" aria-current="page">Data Integrations</li>
															</ol>
													</nav>
											</div>
									</div>
							</div>
					</div>
			</div>
			{/* ***** Breadcrumb Area End ***** */}

			{/* ***** Swarm Area Start ***** */}
			<section className="few-words-from-ceo d-md-flex">
					<div className="few-words-altcontents d-flex align-items-center justify-content-center wow fadeInLeftBig" data-wow-delay="0.1s">
							<div className="container-fluid">
									<div className="row justify-content-center">
											<div className="col-12 col-md-11 col-lg-9 col-xl-7">
													<div className="few-words-alttext dark">
															<div className="section-heading">
																	<p>Data Integrations</p>
																	<h2>Swarm API</h2>
															</div>
															<p>
																Swarm provides all of the important part metadata in your inventory.
															</p>
															<p>
																Swarm is an AI powered data processing service exclusive to Binner. It scours over data found from part manufacturers websites, technical drawings, forums and API partners to give you as much information as we can about a specified part.
															</p>
															<p>
																Swarm is also responsible for aggregating multimedia content about a specific part such as photos, pinouts, circuit diagrams and PDF's related to the part.
															</p>
													</div>
											</div>
									</div>
							</div>
					</div>
					<div className="few-words-thumb bg-img wow fadeInRightBig" data-wow-delay="1.1s" style={{backgroundPositionX: '-100px', backgroundImage: 'url(img/bg-img/swarm-cloud.png)'}}></div>
			</section>
			{/* ***** Swarm Area End ***** */}

			{/* ***** Our Partners Area End ***** */}
			<section className="few-words-from-ceo d-md-flex">
				<div className="few-words-thumb bg-img wow fadeInLeftBig" data-wow-delay="1.1s" style={{backgroundPositionX: '-100px', backgroundImage: 'url(img/bg-img/cta-partnerships.jpg)'}}></div>
					<div className="few-words-contents d-flex align-items-center justify-content-center wow fadeInRightBig" data-wow-delay="0.1s">
							<div className="container-fluid">
									<div className="row justify-content-center">
											<div className="col-12 col-md-11 col-lg-9 col-xl-7">
													<div className="few-words-text">
															<div className="section-heading">
																	<p>Data Integrations</p>
																	<h2>Our Partners</h2>
															</div>
															<p>
																Binner uses other APIs to help connect your data from suppliers such as <a href="https://digikey.com" target="_blank" rel="noreferrer">DigiKey</a>, <a href="https://mouser.com" target="_blank" rel="noreferrer">Mouser</a>, <a href="https://arrow.com" target="_blank" rel="noreferrer">Arrow</a> and other popular part APIs such as <a href="https://octopart.com" target="_blank" rel="noreferrer">OctoPart</a>.
															</p>
															<br/>
															<p>
															In the near future we also plan to offer integrations with other international services such as AliExpress, as better APIs become available to us.
															</p>
															<br/>
															<p>
															If you are or know of an API service we should integrate with, please <a href="/contact">let us know</a>.
														</p>
													</div>
											</div>
									</div>
							</div>
					</div>
			</section>
			{/* ***** Our Partners Area End ***** */}

			<section className="mosh-subscribe-newsletter-area bg-img bg-overlay-white section_padding_100" style={{backgroundImage: 'url(img/bg-img/sub-1.jpg)'}}>
					<div className="container">
							<div className="row">
									<div className="col-12">
											<div className="subscribe-newsletter-content text-center wow fadeIn" data-wow-delay="0.5s">
													<p>give us a shout</p>
													<h2>Subscribe to our newsletter</h2>
													<form action="#">
															<input type="email" name="email" id="Email" placeholder="Your e-mail here" />
															<button type="submit">Subscribe</button>
													</form>
											</div>
									</div>
							</div>
					</div>
			</section>			
		</div>
  );
}
