import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import $ from 'jquery';
import useScript from '../hooks/useScript';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

/** Original Template: https://colorlib.com/wp/template/mosh/ */
export function LandingPage(props) {
	const barfillerStatus = useScript('js/barfiller.js');
  const pluginsStatus = useScript('js/plugins.js');
	let navigate = useNavigate();

	useEffect(() => {
		var $window = $(window);
		if (pluginsStatus === 'ready') {
			if ($window.width() > 767) {
					// WOW provides subtle animations as you scroll, using the .wow class modifier.
					// for example, the bargraphs will slowly appear one by one instead of all at once
					new global.WOW().init();
			}
		}
	}, [pluginsStatus]);

  useEffect(() => {
    if ($.fn.barfiller) {
        $('#bar1').barfiller({
            tooltip: true,
            duration: 1000,
            barColor: '#4a7aec',
            animateOnResize: true
        });
        $('#bar2').barfiller({
            tooltip: true,
            duration: 1000,
            barColor: '#4a7aec',
            animateOnResize: true
        });
        $('#bar3').barfiller({
            tooltip: true,
            duration: 1000,
            barColor: '#4a7aec',
            animateOnResize: true
        });
        $('#bar4').barfiller({
            tooltip: true,
            duration: 1000,
            barColor: '#4a7aec',
            animateOnResize: true
        });
    }    
  }, [barfillerStatus]);

	return (
		<div>
			{/* Welcome Area Start */}
			<section className="welcome_area clearfix" id="home" style={{backgroundImage: 'url(img/bg-img/welcome-bg.png)'}}>
					<OwlCarousel className="hero-slides owl-carousel" loop autoplay autoplayTimeout={98000} smartSpeed={800} dots={false} items={1} margin={0} nav 
							navText={['<i class="fa fa-chevron-left" aria-hidden="true" />', '<i class="fa fa-chevron-right" aria-hidden="true" />']} 
							>
							<div className="single-hero-slide d-flex align-items-end justify-content-center">
									<div className="hero-slide-content text-center">
											<h2>Parts Inventory for Makers</h2>
											<h4>Keep track of your component inventory for free</h4>
											<img className="slide-img" src="img/bg-img/device_screenshot.png" alt="" />
									</div>
							</div>
							<div className="single-hero-slide d-flex align-items-end justify-content-center">
									<div className="hero-slide-content text-center">
											<h2>Electronic Bin Support</h2>
											<h4>Make sense of the clutter and find your parts fast</h4>
											<img className="slide-img" src="img/bg-img/storagebin_bg_animated.png" alt="" />
											{/*
											<img className="slide-img bin-animation" src="img/bg-img/blank.png" alt="" />
											<div style={{ width: '1154px', height: '709px', textAlign: 'center', backgroundImage: 'url(img/bg-img/storagebin_bg.png)'}}>
												<div className="slide-img bin-animation" />
											</div>
											*/}
									</div>
							</div>
							<div className="single-hero-slide d-flex align-items-end justify-content-center">
									<div className="hero-slide-content text-center">
											<h2>Based on open-source</h2>
											<h4>Self-host Binner on your Raspberry PI, PC or on the cloud</h4>
											<img className="slide-img" src="img/bg-img/opensource.png" alt="" />
									</div>
							</div>
							<div className="single-hero-slide d-flex align-items-end justify-content-center">
									<div className="hero-slide-content text-center">
											<h2>Numerous Integrations</h2>
											<h4>AI powered data processing to give you better datasheets, pinouts and circuits via the Swarm API</h4>
											<img className="slide-img" src="img/bg-img/partnerships.png" alt="" />
									</div>
							</div>
					</OwlCarousel>
			</section>
			{/* Welcome Area End */}


			{/* Service Area Start */}
			<section className="mosh-service-area clearfix">
					<div className="container">
							<div className="row">
									<div className="col-12">
											<OwlCarousel className="mosh-service-slides owl-carousel" items={3} loop autoplay autoplayTimeout={6000} smartSpeed={800} margin={30} center dots={false} nav startPosition={1} 
													navText={['<i class="fa fa-chevron-left" aria-hidden="true" />', '<i class="fa fa-chevron-right" aria-hidden="true" />']} 
													responsive={{
															0: {
																	items: 1
															},
															576: {
																	items: 2
															},
															768: {
																	items: 3
															}
													}}>
													<div className="single-service-area text-center">
															<h2>01.</h2>
															<h4>Track your inventory</h4>
															<p>Get alerts on items you are low on or out of.</p>
													</div>
													<div className="single-service-area text-center">
															<h2>02.</h2>
															<h4>Datasheets matter</h4>
															<p>Cloud-based Swarm API provides you with accurate information on your parts right out of the gate.</p>
													</div>
													<div className="single-service-area text-center">
															<h2>03.</h2>
															<h4>Label Printing</h4>
															<p>Print labels for your existing parts bins with barcode support.</p>
													</div>
													<div className="single-service-area text-center">
															<h2>04.</h2>
															<h4>Projects</h4>
															<p>Use projects to track part usage so you can re-order more when you go to produce your design.</p>
													</div>
													<div className="single-service-area text-center">
															<h2>05.</h2>
															<h4>Pinouts</h4>
															<p>Get access to pro-pinouts for easier to read and consistent pinout information.</p>
													</div>
													<div className="single-service-area text-center">
															<h2>06.</h2>
															<h4>Contribute!</h4>
															<p>Offer your own solutions to our open-source platform on GitHub.</p>
													</div>
											</OwlCarousel>
									</div>
									<div className="col-12 text-center mt-100" style={{marginBottom: '50px'}}>
											<a href="/features" className="btn mosh-btn" onClick={(e) => { e.preventDefault(); navigate('/features'); }}>Discover More About Binner</a>
									</div>
							</div>
					</div>
			</section>
			{/* Service Area End */}

			{/* Clients Area Start */}
			{/*<section className="mosh-clients-area section_padding_100 clearfix">
					<div className="container">
							<div className="row">
									<div className="col-12">
											<div className="section-heading text-center">
													<p>clients</p>
													<h2>All our clients love our work</h2>
											</div>
									</div>
									<div className="col-12">
											<div className="clients-logo-area d-sm-flex align-items-center justify-content-between">
													<a href="#"><img src="img/clients-img/1.png" alt="" /></a>
													<a href="#"><img src="img/clients-img/2.png" alt="" /></a>
													<a href="#"><img src="img/clients-img/3.png" alt="" /></a>
													<a href="#"><img src="img/clients-img/4.png" alt="" /></a>
													<a href="#"><img src="img/clients-img/5.png" alt="" /></a>
											</div>
									</div>
							</div>
					</div>
			</section>*/}
			{/* Clients Area End */}

			{/* Features Area Start */}
			<section className="mosh-features-area section_padding_100 clearfix">
					<div className="container">
							<div className="row justify-content-end">
									<div className="col-12 col-md-6">
											<div className="section-heading">
													<p>made for you</p>
													<h2>Binner is designed for makers, hobbyists and professionals alike. </h2>
											</div>
											<div className="features-content">
													<p>
														At the heart of Binner is the desire to make making things more productive. This meant staying organized and getting easy access to information on parts. Binner 
														gives you all of the most important data at your fingertips so you don't have to dig around for the best resources.
													</p>
													{/* Progress Bar Content Area */}
													<div className="features-progress-bar mt-50">
															{/* Single Progres Bar */}
															<div className="single_progress_bar mb-15 wow fadeInUp" data-wow-delay="0.2s">
																	<p>Electronics</p>
																	<div id="bar1" className="barfiller">
																			<div className="tipWrap">
																					<span className="tip"></span>
																			</div>
																			<span className="fill" data-percentage="90"></span>
																	</div>
															</div>
															{/* Single Progres Bar */}
															<div className="single_progress_bar mb-15 wow fadeInUp" data-wow-delay="0.4s">
																	<p>Chemistry</p>
																	<div id="bar2" className="barfiller">
																			<div className="tipWrap">
																					<span className="tip"></span>
																			</div>
																			<span className="fill" data-percentage="30"></span>
																	</div>
															</div>
															{/* Single Progres Bar */}
															<div className="single_progress_bar mb-15 wow fadeInUp" data-wow-delay="0.6s">
																	<p>Pharmaceutical</p>
																	<div id="bar3" className="barfiller">
																			<div className="tipWrap">
																					<span className="tip"></span>
																			</div>
																			<span className="fill" data-percentage="25"></span>
																	</div>
															</div>
													</div>
											</div>
									</div>
							</div>
					</div>
					<div className="features-img">
							<img src="img/bg-img/features.png" alt="" />
					</div>
			</section>
			{/* Feature Area End */}

			{/* Service Area Start */}
			<section className="mosh-more-services-area d-sm-flex clearfix">
					<div className="single-more-service-area">
							<div className="more-service-content text-center wow fadeInUp" data-wow-delay=".1s">
									<img src="img/core-img/trophy.png" alt="" />
									<h4>Open Source First</h4>
									<p>The core functionality is available on <a href="https://github.com/replaysMike/Binner" target="_blank" rel="noreferrer">GitHub</a>.</p>
							</div>
					</div>
					<div className="single-more-service-area">
							<div className="more-service-content text-center wow fadeInUp" data-wow-delay=".4s">
									<img src="img/core-img/edit.png" alt="" />
									<h4>Documentation</h4>
									<p>Binner is liberally documented as well as easy to use.</p>
							</div>
					</div>
					<div className="single-more-service-area">
							<div className="more-service-content text-center wow fadeInUp" data-wow-delay=".7s">
									<img src="img/core-img/video-camera.png" alt="" />
									<h4>AI/ML Data Processing</h4>
									<p>Technical part data is processed from many sources and filtered to give you the best information.</p>
							</div>
					</div>
					<div className="single-more-service-area">
							<div className="more-service-content text-center wow fadeInUp" data-wow-delay="1s">
									<img src="img/core-img/presentation.png" alt="" />
									<h4>Swarm API</h4>
									<p>Integrate Binner's public API into your own projects to access part metadata, datasheets and images.</p>
							</div>
					</div>
			</section>
			{/* Service Area End */}

			{/* Portfolio Area Start */}
			{/*<section className="mosh-portfolio-area section_padding_100_0 clearfix">
					<div className="container">
							<div className="row">
									<div className="col-12">
											<div className="section-heading text-center">
													<p>Our Work</p>
													<h2>See our Online Portfolio</h2>
											</div>
									</div>
							</div>
					</div>

					<div className="mosh-projects-menu">
							<div className="text-center portfolio-menu">
									<p className="active" data-filter="*">All</p>
									<p data-filter=".gd">Graphic Design</p>
									<p data-filter=".bi">Brand Identity</p>
									<p data-filter=".pho">Photography</p>
									<p data-filter=".wd">Web Design</p>
									<p data-filter=".pc">Printing Colaterall</p>
							</div>
					</div>

					<div className="mosh-portfolio">
							<div className="single_gallery_item gd">
									<img src="img/portfolio-img/1.jpg" alt="" />
									<div className="gallery-hover-overlay d-flex align-items-center justify-content-center">
											<div className="port-hover-text text-center">
													<h4>DFR Corp. Branding</h4>
													<a href="#">Brand Identity</a>
											</div>
									</div>
							</div>
							<div className="single_gallery_item bi">
									<img src="img/portfolio-img/2.jpg" alt="" />
									<div className="gallery-hover-overlay d-flex align-items-center justify-content-center">
											<div className="port-hover-text text-center">
													<h4>DFR Corp. Branding</h4>
													<a href="#">Brand Identity</a>
											</div>
									</div>
							</div>
							<div className="single_gallery_item gd bi">
									<img src="img/portfolio-img/3.jpg" alt="" />
									<div className="gallery-hover-overlay d-flex align-items-center justify-content-center">
											<div className="port-hover-text text-center">
													<h4>DFR Corp. Branding</h4>
													<a href="#">Brand Identity</a>
											</div>
									</div>
							</div>
							<div className="single_gallery_item pho">
									<img src="img/portfolio-img/4.jpg" alt="" />
									<div className="gallery-hover-overlay d-flex align-items-center justify-content-center">
											<div className="port-hover-text text-center">
													<h4>DFR Corp. Branding</h4>
													<a href="#">Brand Identity</a>
											</div>
									</div>
							</div>
							<div className="single_gallery_item pho">
									<img src="img/portfolio-img/5.jpg" alt="" />
									<div className="gallery-hover-overlay d-flex align-items-center justify-content-center">
											<div className="port-hover-text text-center">
													<h4>DFR Corp. Branding</h4>
													<a href="#">Brand Identity</a>
											</div>
									</div>
							</div>
							<div className="single_gallery_item wd pc">
									<img src="img/portfolio-img/6.jpg" alt="" />
									<div className="gallery-hover-overlay d-flex align-items-center justify-content-center">
											<div className="port-hover-text text-center">
													<h4>DFR Corp. Branding</h4>
													<a href="#">Brand Identity</a>
											</div>
									</div>
							</div>
							<div className="single_gallery_item wd">
									<img src="img/portfolio-img/7.jpg" alt="" />
									<div className="gallery-hover-overlay d-flex align-items-center justify-content-center">
											<div className="port-hover-text text-center">
													<h4>DFR Corp. Branding</h4>
													<a href="#">Brand Identity</a>
											</div>
									</div>
							</div>
							<div className="single_gallery_item pc">
									<img src="img/portfolio-img/8.jpg" alt="" />
									<div className="gallery-hover-overlay d-flex align-items-center justify-content-center">
											<div className="port-hover-text text-center">
													<h4>DFR Corp. Branding</h4>
													<a href="#">Brand Identity</a>
											</div>
									</div>
							</div>
					</div>
			</section>*/}
			{/* Portfolio Area End */}

			{/* Workflow Area End */}
			<section className="mosh-workflow-area section_padding_100_0 clearfix">
					<div className="container">
							<div className="row">
									<div className="col-12">
											<div className="section-heading text-center mb-0">
													<p>Maker Community</p>
													<h2>Need help with your project?</h2>
											</div>
									</div>
							</div>
					</div>

					<div className="workflow-img">
							<img src="img/core-img/work-progress.png" alt="" />
					</div>

					<div className="workflow-slides-area">
							<div className="container">
									<div className="row">
											<div className="col-12">
													<OwlCarousel className="mosh-workflow-slides owl-carousel" items={3} loop autoplay smartSpeed={800} margin={30} center dots 
															startPosition={1}
															responsive={{
																	0: {
																			items: 1
																	},
																	576: {
																			items: 2
																	},
																	768: {
																			items: 3
																	}
															}}
													>
															<div className="single-workflow-area d-flex">
																	<h2>1.</h2>
																	<div className="workflow-content ml-15">
																			<h4>Research</h4>
																			<p>Search out which parts you may need. Use the circuits repository to find something similar to what you want to build.</p>
																	</div>
															</div>
															<div className="single-workflow-area d-flex">
																	<h2>2.</h2>
																	<div className="workflow-content ml-15">
																			<h4>Plan</h4>
																			<p>Decide how you want to build your project and your budget. Use BOM tools to help you figure out project costs.</p>
																	</div>
															</div>
															<div className="single-workflow-area d-flex">
																	<h2>3.</h2>
																	<div className="workflow-content ml-15">
																			<h4>Reach out</h4>
																			<p>Ask the community for advice, direction or wisdom regarding your design.</p>
																	</div>
															</div>
															<div className="single-workflow-area d-flex">
																	<h2>4.</h2>
																	<div className="workflow-content ml-15">
																			<h4>Succeed</h4>
																			<p>Get building! List your product in the Binner marketplace and other commerce websites.</p>
																	</div>
															</div>
													</OwlCarousel>
											</div>
									</div>
							</div>
					</div>
			</section>
			{/* Service Area End */}

			{/* CTA Area Start */}
			<section className="mosh-call-to-action-area bg-img bg-overlay section_padding_100" style={{backgroundImage: 'url(img/bg-img/cta.jpg)' }}>
					<div className="container">
							<div className="row">
									<div className="col-12">
											<div className="cta-content text-center wow fadeIn" data-wow-delay="0.5s">
													<div className="section-heading">
															<p>get started</p>
															<h2>What are you waiting for? It's free!</h2>
															<a href="/signup" className="btn mosh-btn" onClick={(e) => { e.preventDefault(); navigate('/signup'); }}>Create a Free Account</a>
													</div>
											</div>
									</div>
							</div>
					</div>
			</section>
			{/* CTA Area End */}
		</div>
	);
};