import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { fetchApi } from "../common/fetchApi";

export function ContactUs(props) {
  const [formMessage, setFormMessage] = useState(null);
  const [formNameMessage, setFormNameMessage] = useState(null);
  const [formEmailMessage, setFormEmailMessage] = useState(null);
  const [formMessageMessage, setFormMessageMessage] = useState(null);

  const navigate = useNavigate();
  const formRef = useRef();

  const submitContact = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setFormNameMessage(null);
    setFormEmailMessage(null);
    setFormMessageMessage(null);
    setFormMessage(null);

    if (formRef.current.name.value.length <= 0) {
      toast.error(`Name must be specified.`);
      setFormNameMessage("*Name must be specified.");
      return;
    }

    if (formRef.current.emailAddress.value.length <= 0) {
      toast.error(`Email address must be specified.`);
      setFormEmailMessage("*Email address must be specified.");
      return;
    }

    if (formRef.current.message.value.length <= 0) {
      toast.error(`Message must be specified.`);
      setFormMessageMessage("*Please provide a message.");
      return;
    }

    return fetchApi("api/support/contact", {
      method: "POST",
      body: JSON.stringify({
        name: formRef.current.name.value,
        emailAddress: formRef.current.emailAddress.value,
        subject: formRef.current.subject.value,
        message: formRef.current.message.value
      })
    }).then((response) => {
      const { data } = response;
      toast.success("Message sent!");
      formRef.current.name.value = "";
      formRef.current.emailAddress.value = "";
      formRef.current.subject.value = "";
      formRef.current.message.value = "";
      setFormMessage("Your message was sent. You will receive a reply to your message within 24 hours.");
    });
  };

  return (
    <div>
      {/* ***** Breadcrumb Area Start ***** */}
      <div className="mosh-breadcumb-area">
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-12">
              <div className="bradcumbContent">
                <h2>Contact</h2>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/");
                        }}
                      >
                        Home
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Say Hello
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ***** Breadcrumb Area End ***** */}

      {/* ***** Contact Area Start ***** */}
      <section className="contact-area section_padding_20">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-8">
              <div className="contact-form-area">
                <h2>Get in touch</h2>
                <form onSubmit={submitContact} ref={formRef} autoComplete="on">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <input type="text" className="form-control" id="name" placeholder="Name" />
                      <span className="error">{formNameMessage}</span>
                    </div>
                    <div className="col-12 col-md-6">
                      <input type="email" className="form-control" id="emailAddress" placeholder="E-mail" />
                      <span className="error">{formEmailMessage}</span>
                    </div>
                    <div className="col-12">
                      <input type="text" className="form-control" id="subject" placeholder="Subject" />
                    </div>
                    <div className="col-12">
                      <textarea name="message" className="form-control" id="message" cols="30" rows="10" placeholder="Message"></textarea>
                      <span className="error">{formMessageMessage}</span>
                    </div>
                    <div className="col-12">{formMessage}</div>
                    <button className="btn mosh-btn mt-50" type="submit">
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="contact-information">
                <h2>Contact</h2>
                <div className="single-contact-info d-flex">
                  <div className="contact-icon mr-15">
                    <img src="img/core-img/map.png" alt="" />
                  </div>
                  <p>Vancouver, Canada</p>
                </div>
                <div className="single-contact-info d-flex">
                  <div className="contact-icon mr-15">
                    <img src="img/core-img/message.png" alt="" />
                  </div>
                  <p>
                    <a href="mailto: support@binner.io">support@binner.io</a>
                  </p>
                </div>
                {/*<div className="contact-social-info mt-50">
                            <a href="#"><i className="fa fa-pinterest" aria-hidden="true"></i></a>
                            <a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a>
                            <a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a>
                            <a href="#"><i className="fa fa-dribbble" aria-hidden="true"></i></a>
                            <a href="#"><i className="fa fa-behance" aria-hidden="true"></i></a>
                            <a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                        </div>*/}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ***** Contact Area End ***** */}
    </div>
  );
}
