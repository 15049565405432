import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { PacmanLoader } from "react-spinners";
import _ from "underscore";
import { Table, Form, Segment, Button, Icon, Confirm, Modal, Breadcrumb, Checkbox, Label } from "semantic-ui-react";
import { toast } from "react-toastify";
import { ImageTypes, Sources, GetTypeName } from "../../../common/Types";
import { fetchApi } from "../../../common/fetchApi";
import { InifiniteScrollTable } from "../../../components/InfiniteScrollTable";
import { getFriendlyElapsedTime, getTimeDifference, getFormattedTime, getBasicElapsed } from "../../../common/datetime";
import { FormHeader } from "../../../components/FormHeader";
import "../Admin.css";

export function CrawlerFullHistory(props) {
  const maxResults = 20;
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const urlHistoryDataRef = useRef([]);
  const [confirmDeleteIsOpen, setConfirmDeleteIsOpen] = useState(false);
  const [deleteSelectedItem, setDeleteSelectedItem] = useState(null);
  const [pdfModalOpen, setPdfModalOpen] = useState(false);
  const [selectedUrlHistory, setSelectedUrlHistory] = useState(null);
  const [column, setColumn] = useState(null);
  const [direction, setDirection] = useState(null);
  const [filterHasChildrenOrPDFs, setFilterHasChildrenOrPDFs] = useState(false);
  const [filterHasChildren, setFilterHasChildren] = useState(false);
  const [filterHasPDFs, setFilterHasPDFs] = useState(false);
  const [filterHasError, setFilterHasError] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const { urlHistoryId } = params;

  // handle url location changes
  useEffect(() => {
    urlHistoryDataRef.current = [];
    setHasMoreData(true);
    setCurrentPage(1);
  }, [location]);

  useEffect(() => {
    fetchUrlHistory(currentPage);

    function fetchUrlHistory(page = 1) {
      if (hasMoreData) {
        setLoading(true);
        fetchApi(`api/crawler/urlHistory?urlHistoryId=${urlHistoryId}&page=${page}&results=${maxResults}&includeChildren=true`).then((response) => {
          const { data } = response;
          if (data && data.length > 0) {
            // update the page of data, as long as its not already in the data
            let newData = [...urlHistoryDataRef.current];
            for (let i = 0; i < data.length; i++) {
              const index = newData.findIndex((x) => x.urlHistoryId === data[i].urlHistoryId);
              if (index === -1) {
                newData.push(data[i]);
              }
            }
            urlHistoryDataRef.current = newData;
          }
          if (data.length < maxResults) {
            // no more data, received back 0 or less than maxResults
            setHasMoreData(false);
          }
          setLoading(false);
        });
      }
    }
  }, [currentPage, hasMoreData, urlHistoryId]);

  const fetchNextPage = () => {
    if (hasMoreData)
      setCurrentPage(currentPage + 1);
  };

  const handleSort = (clickedColumn) => () => {
    if (column !== clickedColumn) {
      setColumn(clickedColumn);
      urlHistoryDataRef.current = _.sortBy(urlHistoryDataRef.current, [clickedColumn]);
      setDirection("ascending");
    } else {
      urlHistoryDataRef.current = urlHistoryDataRef.current.reverse();
      setDirection(direction === "ascending" ? "descending" : "ascending");
    }
  };

  const handleVisitLink = (e, url) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(`${url}`, "_blank");
  };

  const requeueUrl = (e, urlHistory) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    // only dateQueuedUtc=null is required
    const request = {
      ...urlHistory,
      dateQueuedUtc: null,
      elapsed: null,
      lastCrawlTimeUtc: null,
      pdfCount: 0,
      urlCount: 0
    };
    fetchApi(`api/crawler/urlHistory`, {
      method: "PUT",
      body: JSON.stringify(request)
    }).then(() => {
      setLoading(false);
    });
  };

  const reprocessPdf = (e, pdfLink) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    // only dateQueuedUtc=null is required
    const request = {
      ...pdfLink,
      dateQueuedForProcessingUtc: null,
      dateProcessedUtc: null,
      processingElapsed: null
    };
    fetchApi(`api/crawler/pdfLink`, {
      method: "PUT",
      body: JSON.stringify(request)
    }).then(() => {
      setLoading(false);
    });
  };

  const deleteUrl = (e, urlHistory) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    fetchApi(`api/crawler/urlHistory`, {
      method: "DELETE",
      body: urlHistory.urlHistoryId
    }).then(() => {
      const newUrlHistoryData = _.filter(urlHistoryDataRef.current, (item) => item.urlHistoryId !== urlHistory.urlHistoryId);
      urlHistoryDataRef.current = [...newUrlHistoryData];
      setLoading(false);
      setConfirmDeleteIsOpen(false);
    });
  };

  const getUrlHistoryState = (urlHistory) => {
    if (urlHistory.dateQueuedUtc === null) {
      return "Queued";
    } else if (urlHistory.elapsed === null) {
      return "Crawling";
    } else if (urlHistory.errorMessage !== null) {
      return "Error";
    }
    return "Finished";
  };

  const getUrlHistoryLabel = (urlHistory) => {
    var state = getUrlHistoryState(urlHistory);
    switch (state) {
      case "Crawling":
        return (
          <div style={{ marginLeft: "-50px" }} title={state}>
            <PacmanLoader color="#06bcee" size="12px" />
          </div>
        );
      case "Finished":
        return <Icon name="check circle" title={state} />;
      case "Queued":
        return <Icon name="plus" title={state} />;
      default:
        return state;
    }
  };

  const getRowClassname = (urlHistory) => {
    var state = getUrlHistoryState(urlHistory);
    if (urlHistory.urlHistoryId === parseInt(urlHistoryId)) return "parent";
    switch (state) {
      case "Crawling":
        return "running";
      case "Error":
        return "error";
      case "Finished":
        return "complete";
      case "Queued":
      default:
        return "";
    }
  };

  const openUrlHistory = (e, urlHistory) => {
    e.preventDefault();
    e.stopPropagation();
    if (urlHistory.urlHistoryId === parseInt(urlHistoryId)) {
      toast.info("This is the parent url, already viewing.");
    } else if (urlHistory.childrenCount > 0) {
      navigate(`/datasheets/admin/crawler/${urlHistory.urlHistoryId}`);
    } else {
      toast.info("Url has no children.");
    }
  };

  const openPdfs = (e, urlHistory) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedUrlHistory(urlHistory);
    setPdfModalOpen(true);
  };

  const confirmDeleteOpen = (e, urlHistory) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteSelectedItem(urlHistory);
    setConfirmDeleteIsOpen(true);
  };

  const confirmDeleteClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteSelectedItem(null);
    setConfirmDeleteIsOpen(false);
  };

  const viewPdfMetadata = (e, pdfLink) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(`/datasheets/admin/${pdfLink.datasheetId}`, "_blank");
  };

  const getCoverImage = (datasheet) => {
    if (datasheet === null)
      return;
    const image = _.find(datasheet.imageMetadata, (s) => s.imageType === ImageTypes.FullPage);
    if (image) {
      const url = `https://${datasheet.resourceSourceUrl}/${datasheet.resourcePath}_${image.imageId}.png`;
      return url;
    } else {
      return null;
    }
  };

  const getProductShotImage = (datasheet) => {
    if (datasheet === null)
      return;
    const image = _.find(datasheet.imageMetadata, (s) => s.imageType === ImageTypes.ProductShot);
    if (!image) return getCoverImage(datasheet);

    const url = `https://${datasheet.resourceSourceUrl}/${datasheet.resourcePath}_${image.imageId}.png`;
    return url;
  };
  
  const handlePdfModalClose = () => {
    setPdfModalOpen(false);
  };

  const handleFilterChanged = (e, control) => {
    switch(control.name) {
      case 'filterChildrenOrPDFs':
        setFilterHasChildrenOrPDFs(control.checked);
        break;
      case 'filterChildren':
        setFilterHasChildren(control.checked);
        break;
      case 'filterPDFs':
        setFilterHasPDFs(control.checked);
        break;
        case 'filterHasError':
        setFilterHasError(control.checked);
        break;
      default:
        break;
    }
  };

  const renderPDFLinks = () => {
    if (selectedUrlHistory === null) return;
    const { pdfLinks } = selectedUrlHistory;
    return (
      <div>
        <div>
          <b>Page Title</b>: {selectedUrlHistory.pageTitle}
        </div>
        <div>
          <b>Url</b>:
          <a href={selectedUrlHistory.url} target="_blank" rel="noreferrer">
            {selectedUrlHistory.url}
          </a>
        </div>
        <Segment loading={loading}>
          <Table compact celled sortable selectable striped unstackable size="small">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Filename</Table.HeaderCell>
                <Table.HeaderCell>S3 Filename</Table.HeaderCell>
                <Table.HeaderCell>Href Text</Table.HeaderCell>
                <Table.HeaderCell>Images</Table.HeaderCell>
                <Table.HeaderCell>Pages</Table.HeaderCell>
                <Table.HeaderCell>Length</Table.HeaderCell>
                <Table.HeaderCell>Date Processed</Table.HeaderCell>
                <Table.HeaderCell style={{width: '150px' }}>Cover</Table.HeaderCell>
                <Table.HeaderCell style={{width: '100px' }}></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {pdfLinks.map((pdfLink, i) => (
                <Table.Row key={i} title={pdfLink.url} onClick={(e) => handleVisitLink(e, pdfLink.url)}>
                  <Table.Cell>{pdfLink.originalFilename}</Table.Cell>
                  <Table.Cell>{pdfLink.s3Filename}</Table.Cell>
                  <Table.Cell>{pdfLink.hrefText}</Table.Cell>
                  <Table.Cell>{pdfLink.datasheet ? pdfLink.datasheet.imageCount : ""}</Table.Cell>
                  <Table.Cell>{pdfLink.datasheet ? pdfLink.datasheet.pageCount : ""}</Table.Cell>
                  <Table.Cell>{pdfLink.length}</Table.Cell>
                  <Table.Cell>{pdfLink.dateProcessedUtc != null ? getFormattedTime(pdfLink.dateProcessedUtc) : "Not processed"}</Table.Cell>
                  <Table.Cell style={{width: '150px' }}>{pdfLink.datasheet && (<img src={getProductShotImage(pdfLink.datasheet)} alt="Cover" className="datasheet cover" />)}</Table.Cell>
                  <Table.Cell style={{width: '100px' }}>
                    <Button size="mini" icon="file pdf" onClick={(e) => viewPdfMetadata(e, pdfLink)} title="View Parsed Datasheet" />
                    <Button size="mini" icon="redo" onClick={(e) => reprocessPdf(e, pdfLink)} title="Reprocess" />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment>
      </div>
    );
  };

  const headerRow = (
    <Table.Row>
      <Table.HeaderCell style={{ maxWidth: "200px" }} sorted={column === "url" ? direction : null} onClick={handleSort("url")}>
        Url
      </Table.HeaderCell>
      <Table.HeaderCell sorted={column === "childrenCount" ? direction : null} onClick={handleSort("childrenCount")}>
        Children
      </Table.HeaderCell>
      <Table.HeaderCell style={{ maxWidth: "200px" }} sorted={column === "dateQueuedUtc" ? direction : null} onClick={handleSort("dateQueuedUtc")}>
        Page Title
      </Table.HeaderCell>
      <Table.HeaderCell style={{ maxWidth: "150px" }} sorted={column === "lastCrawlTimeUtc" ? direction : null} onClick={handleSort("lastCrawlTimeUtc")}>
        Last Crawl Time
      </Table.HeaderCell>
      <Table.HeaderCell sorted={column === "pdfCount" ? direction : null} onClick={handleSort("pdfCount")}>
        Pdfs
      </Table.HeaderCell>
      <Table.HeaderCell sorted={column === "httpResponseCode" ? direction : null} onClick={handleSort("httpResponseCode")}>
        Http Code
      </Table.HeaderCell>
      <Table.HeaderCell sorted={column === "source" ? direction : null} onClick={handleSort("source")}>
        Source
      </Table.HeaderCell>
      <Table.HeaderCell sorted={column === "state" ? direction : null} onClick={handleSort("state")}>
        State
      </Table.HeaderCell>
      <Table.HeaderCell style={{ maxWidth: "130px" }} sorted={column === "elapsed" ? direction : null} onClick={handleSort("elapsed")}>
        Elapsed
      </Table.HeaderCell>
      <Table.HeaderCell></Table.HeaderCell>
    </Table.Row>
  );

  const renderQueue = (data, column, direction) => {
    return (
      <div>
        <Confirm
          open={confirmDeleteIsOpen}
          onCancel={confirmDeleteClose}
          onConfirm={(e) => deleteUrl(e, deleteSelectedItem)}
          content="Are you sure you want to delete?"
        />

        <Modal centered open={pdfModalOpen} onClose={handlePdfModalClose}>
          <Modal.Header>PDF Viewer</Modal.Header>
          <Modal.Content scrolling>
            <Modal.Description>{renderPDFLinks()}</Modal.Description>
          </Modal.Content>
        </Modal>

        <Segment loading={loading}>
          <Form.Group>
            <Label style={{marginRight: '20px'}}>Filters:</Label>
            <Checkbox label="Has Children OR PDFs" name="filterChildrenOrPDFs" onChange={handleFilterChanged} style={{marginRight: '20px'}} />
            <Checkbox label="Has Children" name="filterChildren" onChange={handleFilterChanged} style={{marginRight: '20px'}} />
            <Checkbox label="Has PDFs" name="filterPDFs" onChange={handleFilterChanged} style={{marginRight: '20px'}} />
            <Checkbox label="Has Error" name="filterHasError" onChange={handleFilterChanged} style={{marginRight: '20px'}} />
          </Form.Group>
          <InifiniteScrollTable
            id="urlHistoryTable"
            compact
            celled
            sortable
            selectable
            striped
            unstackable
            size="small"
            headerRow={headerRow}
            className="queueTable"
            nextPage={() => fetchNextPage()}
          >
            {data.map((urlHistory, i) => (
              <Table.Row key={i} className={getRowClassname(urlHistory)} onClick={(e) => openUrlHistory(e, urlHistory)}>
                <Table.Cell style={{ maxWidth: "200px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} title={urlHistory.url}>
                  {urlHistory.url}
                </Table.Cell>
                <Table.Cell>{urlHistory.childrenCount}</Table.Cell>
                <Table.Cell style={{ maxWidth: "500px" }}>
                  <Form.Group>
                    <Form.Field>
                      <b>Parent Href: </b> <span>{urlHistory.hrefText}</span>
                    </Form.Field>
                    <Form.Field>
                      <b>Title: </b> <span>{urlHistory.pageTitle}</span>
                    </Form.Field>
                    <Form.Field>
                      <b>Keywords: </b> <span>{urlHistory.metaKeywords}</span>
                    </Form.Field>
                    <Form.Field>
                      <b>Description: </b> <span>{urlHistory.metaDescription}</span>
                    </Form.Field>
                  </Form.Group>
                </Table.Cell>
                <Table.Cell style={{ maxWidth: "150px" }}>{getFormattedTime(urlHistory.lastCrawlTimeUtc)}</Table.Cell>
                <Table.Cell>{urlHistory.pdfLinks.length}</Table.Cell>
                <Table.Cell title={urlHistory.errorMessage}>{urlHistory.httpResponseCode}</Table.Cell>
                <Table.Cell>{GetTypeName(Sources, urlHistory.source)}</Table.Cell>
                <Table.Cell title={urlHistory.errorMessage} style={{ textAlign: "center" }}>
                  {getUrlHistoryLabel(urlHistory)}
                </Table.Cell>
                <Table.Cell style={{ maxWidth: "130px" }}>
                  {urlHistory.elapsed === null && urlHistory.dateQueuedUtc !== null
                    ? getFriendlyElapsedTime(getTimeDifference(Date.now(), Date.parse(urlHistory.dateQueuedUtc) - new Date().getTimezoneOffset() * 60 * 1000))
                    : getBasicElapsed(urlHistory.elapsed)}
                </Table.Cell>
                <Table.Cell>
                  <Button size="mini" icon="delete" onClick={(e) => confirmDeleteOpen(e, urlHistory)} />
                  {urlHistory.pdfLinks.length > 0 && <Button size="mini" icon="file pdf" onClick={(e) => openPdfs(e, urlHistory)} />}
                  {urlHistory.parentUrlHistoryId === null && <Button size="mini" icon="redo" onClick={(e) => requeueUrl(e, urlHistory)} />}
                </Table.Cell>
              </Table.Row>
            ))}
          </InifiniteScrollTable>
        </Segment>
      </div>
    );
  };

  let filteredData = urlHistoryDataRef.current;
  if (filterHasChildrenOrPDFs){
    // filter by children OR pdfs
    filteredData = filteredData.filter(data => data.childrenCount > 0 || data.pdfCount > 0 || data.urlHistoryId === parseInt(urlHistoryId));
  }
  if (filterHasChildren){
    // filter by children
    filteredData = filteredData.filter(data => data.childrenCount > 0 || data.urlHistoryId === parseInt(urlHistoryId));
  }
  if (filterHasPDFs){
    // filter by pdfs
    filteredData = filteredData.filter(data => data.pdfCount > 0 || data.urlHistoryId === parseInt(urlHistoryId));
  }
  if (filterHasError){
    // filter by errors
    filteredData = filteredData.filter(data => data.errorMessage && data.errorMessage.length > 0);
  }

  let contents = renderQueue(filteredData, column, direction);

  return (
    <div className="admin-container">
      <Breadcrumb>
        <Breadcrumb.Section href="/admin">Admin</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section href="/admin/datasheets">Datasheets</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section href="/admin/datasheets/admin">Admin</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section href="/admin/datasheets/admin/crawler">Crawler</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>Crawled Link</Breadcrumb.Section>
      </Breadcrumb>
      <FormHeader name="Crawler Management" to="..">
        You are viewing the history for root url.
      </FormHeader>
      {contents}
    </div>
  );
}
