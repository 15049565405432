import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from "reactstrap";
import { Form, Input, Menu, Dropdown, Icon, Popup } from "semantic-ui-react";
import { isAuthenticated, logoutUserAccountAsync, deAuthenticateUserAccount } from "../common/authentication";
import ProtectedInput from "../components/ProtectedInput";
import { AppEvents, Events } from "../common/events";
import "./NavMenu.css";

export function NavMenu(props) {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  let navBarClasses = "";

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  const handleChange = (e, control) => {
    switch (control.name) {
      case "searchKeyword":
        setSearchKeyword(control.value);
        break;
      default:
        break;
    }
  };

  const logout = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    await logoutUserAccountAsync().then(async (response) => {
      deAuthenticateUserAccount();
      // navigate("/", { replace: true, state: { } });
      window.location.href = "/";
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSearchKeyword("");
    navigate(`/inventory?keyword=${searchKeyword}`, { replace: true });
  };

  const handleClearFormIfOnPage = (e) => {
    if (location.pathname === e.target.pathname) {
      e.preventDefault();
      // we are already on this page, if a resetForm is delcared in the page component then call it
      if (window.resetForm && typeof window.resetForm === "function") {
        window.resetForm.call(e);
      }
    }
  };

  // only the public home page has a different navbar behavior, others should use a light colored navbar that doesn't float
  navBarClasses = "navbar-expand-sm navbar-toggleable-sm ng-white mb-3 navbar-sticky navbar-light";
  if (location.pathname === "/" && !isAuthenticated()) {
    // page is root, non-authenticated
    navBarClasses = "navbar-expand-sm navbar-toggleable-sm ng-white mb-3 navbar-sticky navbar-floating navbar-dark";
  }

  return (
    <header className="header">
      <Navbar className={navBarClasses} expand="md">
        <NavbarBrand tag={Link} to="/" />
        <NavbarToggler onClick={toggleNavbar} className="mr-2" />
        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
          <Nav className="ml-auto" navbar>
            <Form onSubmit={onSubmit}>
              {isAuthenticated() ? (
                <ul className="navbar-nav flex-grow">
                  <NavItem style={{lineHeight: '3.5em'}}>
                    <Popup 
                      position="left center"
                      content="Help"
                      trigger={<Link to="/help" className="help-icon"><Icon name="help circle" /></Link>}
                    />
                  </NavItem>
                  <NavItem>
                    <ProtectedInput
                      size="mini"
                      icon
                      placeholder={t('comp.navBar.search', "Search")}
                      onChange={handleChange}
                      value={searchKeyword}
                      name="searchKeyword"
                      hideIcon
                    >
                      <input />
                      <Icon name="search" circular link onClick={onSubmit} />
                    </ProtectedInput>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} to="/">
                    {t('comp.navBar.home', "Home")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} to="/inventory/add" onClick={handleClearFormIfOnPage}>
                    {t('comp.navBar.addInventory', "Add Inventory")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} to="/import">
                    {t('comp.navBar.orderImport', "Order Import")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <Menu stackable style={{ minHeight: "2.4em", marginTop: "5px" }}>
                      <Dropdown direction="left" item trigger={<Icon name="user" style={{ color: "#042173" }} />}>
                        <Dropdown.Menu>
                          <Dropdown.Item icon="edit" text="Account Settings" as={Link} to="/account" />
                          <Dropdown.Item icon={<Icon name="ticket alternate" color="blue" />} text="Manage Subscription" as={Link} to="/subscriptions" />
                          <Dropdown.Item icon="database" text="Swarm API" as={Link} to="/api" />
                          <Dropdown.Item icon="help circle" text="Help" as={Link} to="/help" />
                          <Dropdown.Item icon="bug" text="Report a Bug" as={Link} to="https://github.com/replaysMike/Binner/issues" target="_blank" />
                          <Dropdown.Item icon="comment" text="Support" as={Link} to="/support" />
                          <Dropdown.Item icon="sign out" text="Logout" onClick={logout} />
                        </Dropdown.Menu>
                      </Dropdown>
                    </Menu>
                  </NavItem>
                </ul>
              ) : (
                <ul className="navbar-nav flex-grow">
                  <NavItem>
                    <NavLink tag={Link} to="/">
                    {t('comp.navBar.home', "Home")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} to="/signup">
                    {t('comp.navBar.signup', "Sign Up")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} to="/login">
                    {t('comp.navBar.login', "Login")}
                    </NavLink>
                  </NavItem>
                </ul>
              )}
            </Form>
          </Nav>
        </Collapse>
      </Navbar>
    </header>
  );
}
