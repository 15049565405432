import React, { useEffect, useState, useRef } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Segment, Image, Grid, Icon, Dropdown } from "semantic-ui-react";
import { fetchApi, getErrorsString } from "../common/fetchApi";
import { toast } from "react-toastify";

export const BinnerBin = (props) => {
  const { t } = useTranslation();
	const [form, setForm] = useState({
    emailAddress: ""
  });
  const navigate = useNavigate();
	const formRef = useRef();

  const quantityOptions = [
    { key: 1, text: "1", value: 1 },
    { key: 2, text: "2", value: 2 },
    { key: 3, text: "3", value: 3 },
    { key: 4, text: "4", value: 4 },
    { key: 5, text: "5", value: 5 },
    { key: 6, text: "10", value: 10 },
    { key: 7, text: "20", value: 20 }
  ];

	const submitNotify = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (formRef.current.emailAddress.value.length <= 0) {
			toast.error(`Email address must be specified.`);
			return;
		}

    return fetchApi("api/product/notify",
    {
      method: "POST",
      body: JSON.stringify({
        emailAddress: formRef.current.emailAddress.value,
				modelName: "000164"
      })
    }).then((response) => {
      const { data } = response;
      if (data.result === true)
  			toast.success("You will be notified when stock arrives!");
			else if(data.errors)
				toast.error(getErrorsString(response));
			else
				toast.error("Failed to signup for the newsletter.");
    });
  }

  return (
    <div>
      {/* ***** Breadcrumb Area Start ***** */}
      <div className="mosh-breadcumb-area">
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-12">
              <div className="bradcumbContent">
                <h2>Binner Bins</h2>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/");
                        }}
                      >
                        Home
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Electronic Storage Bins
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ***** Breadcrumb Area End ***** */}

      <section className="mosh-aboutUs-area section_padding_20">
        <div className="container">
          <h1>Binner Bin</h1>
          <p className="dark">Binner Bin is an electronics parts organizer that gives you next-level organization over your components!</p>

          <Grid columns={2} divided>
            <Grid.Row>
              <Grid.Column>
                <Image src="/img/bg-img/storagebin_cr_sm_animated.png" size='large' style={{margin: '0 auto'}} />
                {/*<div className="slide-img bin-animation medium" />*/}
              </Grid.Column>
              <Grid.Column>
                <h2 style={{ marginBottom: "0px" }}>Binner Bin</h2>
                <div style={{ marginBottom: "5px", fontSize: "0.9em", color: "#aaa" }}>Product Id: 000164</div>

                <div style={{ fontSize: "2em", fontWeight: "500" }}>$79.00 USD</div>
                <div style={{ fontSize: "1em", color: "red" }}>Pre-order</div>

                <Button primary disabled>
                  <Dropdown pointing compact labeled direction="left" defaultValue={1} options={quantityOptions} />
                  <Icon name="cart" style={{ marginLeft: "20px" }} />
                  Add to Cart
                </Button>

                <h5 style={{ marginTop: "50px" }}>Description</h5>
                <p className="dark">Electronics part storage bin with LED location indicators compatible with Binner inventory management.</p>

                <section className="mosh-subscribe-newsletter-area bg-img bg-overlay-white section_padding_20" style={{ backgroundImage: "url(img/bg-img/sub-1.jpg)" }}>
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <div className="get-notified-content text-center wow fadeIn" data-wow-delay="0.5s">
                          <p>stock notifications</p>
                          <h4>Get Notified</h4>
                          <form onSubmit={submitNotify} ref={formRef} autoComplete="on">
														<input type="email" placeholder="Your e-mail here" name="emailAddress" />
														<button type="submit">Notify Me</button>
													</form>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <Segment raised style={{ marginBottom: "50px", backgroundColor: "#211b31", color: "#ffffff" }}>
            <div className="section-heading" style={{ marginBottom: "20px" }}>
              <p>Info</p>
              <h2 style={{ color: "#ffffff" }}>Product Features</h2>
            </div>
            <ul className="featurelist">
							<li>64 Bins</li>
              <li>Electronic RGB Led's indicate which bin your part is in</li>
              <li>Wireless configuration</li>
              <li>Positive Locking part bins can't spill contents if tipped</li>
              <li>Integrates with both <a href="https://binner.io">Binner Cloud</a> and open-source <a href="https://github.com/replaysMike/Binner">Binner</a> applications</li>
              <li>It looks really neat.</li>
            </ul>
          </Segment>

          <Segment raised style={{ marginBottom: "50px" }}>
            <div className="section-heading" style={{ marginBottom: "20px" }}>
              <p>Info</p>
              <h2>Specifications</h2>
            </div>
            <ul className="featurelist">
							<li>Model Number: 000164</li>
              <li>Made with ESD safe plastic for storing sensitive compoments</li>
              <li>Smooth slide operation</li>
              <li>64 Bins, each with an RGB led</li>
              <li>12VDC Power Supply (included)</li>
              <li>Integrated Atmel ATSAMW25 wireless controller</li>
							<li>Color: Black</li>
							<li>Dimensions: 15.5"H x 20"W x 6.5"D (394mm x 508mm x 165mm)</li>
							<li>Weight: 7 lbs (3.2 kg)</li>
            </ul>
          </Segment>
        </div>
      </section>
    </div>
  );
};
