import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Menu, Dropdown, Icon } from "semantic-ui-react";
import $ from 'jquery';
import useScript from '../hooks/useScript';
import { isAuthenticated, logoutUserAccountAsync, deAuthenticateUserAccount } from "../common/authentication";

export function PublicHeader (props) {
	const [isNavCollapsed, setIsNavCollapsed] = useState(true);
	const navigate = useNavigate();
	const location = useLocation();
	useScript('js/active.js');
	const pluginsStatus = useScript('js/plugins.js');

	useEffect(() => {
		var $window = $(window);
		if (pluginsStatus === 'ready') {
			if ($window.width() > 767) {
					// WOW provides subtle animations as you scroll, using the .wow class modifier.
					// for example, the bargraphs will slowly appear one by one instead of all at once
					new global.WOW().init();
			}
		}
		const handleResize = () => {
			const btn = document.getElementById("navCollapsed");
			if (btn) {
				var style = window.getComputedStyle(btn);
				let menu1 = document.getElementById("menu1");
				let menu2 = document.getElementById("menu2");
				if (menu1 && menu2 && menu1.style && menu2.style) {
					if (style.display === 'none'){
						menu1.style.display = 'none';
						menu2.style.display = 'inline-block';
					} else{
						menu1.style.display = 'inline-block';
						menu2.style.display = 'none';
					}
				}
			}
		};
		handleResize();
		window.addEventListener('resize', () => {
			handleResize();
		});
	}, [pluginsStatus]);

	const logout = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    await logoutUserAccountAsync().then(async (response) => {
      deAuthenticateUserAccount();
      // navigate("/", { replace: true, state: { } });
      window.location.href = "/";
    });
  };

	const getActiveLink = (href) => {
		if (location.pathname === href)
			return 'active';
		return '';
	};

	const handleNavClick = (e, link) => {
		e.preventDefault();
		setIsNavCollapsed(true);
		navigate(link); 
	};

	const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

	return (
				<header className="header_area clearfix">
					<div className="container-fluid h-100">
							<div className="row h-100">
									{/* Menu Area Start */}
									<div className="col-12 h-100">
											<div className="menu_area h-100">
													<nav className="navbar h-100 navbar-expand-lg align-items-center">
															{/* Logo */}
															<a className="navbar-brand" href="/" onClick={(e) => { e.preventDefault(); navigate('/'); }}><img src="img/core-img/logo.png" alt="Binner Logo" /></a>

															{/* Menu Area */}
															<div>
																<div style={{display: 'inline-block'}}>
																	<button id="navCollapsed" onClick={handleNavCollapse} className="navbar-toggler" style={{height: '48px'}} type="button" data-toggle="collapse" data-target="#mosh-navbar" aria-controls="mosh-navbar" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation">
																		<span className="navbar-toggler-icon"></span>
																	</button>
																</div>
																<div id="menu1" style={{display: 'inline-block', marginLeft: '10px'}}>
																	{isAuthenticated() && (
																	<Menu stackable style={{ minHeight: "2.4em", marginTop: "5px" }}>
																		<Dropdown direction="left" item trigger={<Icon name="user" style={{ color: "#042173" }} />}>
																			<Dropdown.Menu>
																				<Dropdown.Item icon="edit" text="Account Settings" as={Link} to="/account" />
																				<Dropdown.Item icon="ticket alternate" text="Manage Subscription" as={Link} to="/subscriptions" />
																				<Dropdown.Item icon="help" text="Support" as={Link} to="/support" />
																				<Dropdown.Item icon="sign out" text="Logout" onClick={logout} />
																			</Dropdown.Menu>
																		</Dropdown>
																	</Menu>)}
																</div>
															</div>
															<div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse justify-content-end`} id="mosh-navbar">
																<ul className="navbar-nav animated" id="nav">
																		<li className={`nav-item ${getActiveLink("/")}`}><a className="nav-link" href="/" onClick={(e) => handleNavClick(e, "/")}>Home</a></li>
																		<li className={`nav-item ${getActiveLink("/features")}`}><a className="nav-link" href="/features" onClick={(e) => handleNavClick(e, "/features")}>Features</a></li>
																		<li className={`nav-item ${getActiveLink("/community")}`}><a className="nav-link" href="/community" onClick={(e) => handleNavClick(e, "/community")}>Community</a></li>
																		<li className={`nav-item ${getActiveLink("/contact")}`}><a className="nav-link" href="/contact" onClick={(e) => handleNavClick(e, "/contact")}>Contact</a></li>
																		{isAuthenticated() 
																		? (<li className={`nav-item ${getActiveLink("/inventory/add")}`}><a className="nav-link" href="/inventory/add" onClick={(e) => handleNavClick(e, "/inventory/add")}>Add Inventory</a></li>)
																		: ""}
																</ul>
																{/* Search Form Area Start */}
																{/*<div className="search-form-area animated">
																		<form action="#" method="post">
																				<input type="search" name="search" id="search" placeholder="Type keywords &amp; hit enter" />
																				<button type="submit" className="d-none"><img src="img/core-img/search-icon.png" alt="Search" /></button>
																		</form>
																</div>*/}
																{/* Login/Register btn */}
																{isAuthenticated() 
																	? (
																	<div className="login-register-btn">
																		<div id="menu2" style={{display: 'inline-block'}}>
																			{isAuthenticated() && (
																			<Menu stackable style={{ minHeight: "2.4em", marginTop: "5px" }}>
																				<Dropdown direction="left" item trigger={<Icon name="user" style={{ color: "#042173" }} />}>
																					<Dropdown.Menu>
																						<Dropdown.Item icon="edit" text="Account Settings" as={Link} to="/account" />
																						<Dropdown.Item icon="ticket alternate" text="Manage Subscription" as={Link} to="/subscriptions" />
																						<Dropdown.Item icon="help" text="Support" as={Link} to="/support" />
																						<Dropdown.Item icon="sign out" text="Logout" onClick={logout} />
																					</Dropdown.Menu>
																				</Dropdown>
																			</Menu>)}
																		</div>
																	</div>)
																	: (
																	<div className="login-register-btn">
																		<a href="/login" className={`${getActiveLink("/login")}`} onClick={(e) => handleNavClick(e, "/login")}>Login</a>
																		<span> / </span>
																		<a href="/signup" className={`${getActiveLink("/signup")}`} onClick={(e) => handleNavClick(e, "/signup")}>Sign Up</a>
																	</div>)}
															</div>
															
													</nav>
											</div>
									</div>
							</div>
					</div>
			</header>
	);
}
