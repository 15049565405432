import React from "react";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";

export function Community(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      {/* ***** Breadcrumb Area Start ***** */}
      <div className="mosh-breadcumb-area">
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-12">
              <div className="bradcumbContent">
                <h2>Community</h2>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a
                        href="/"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/");
                        }}
                      >
                        Home
                      </a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Community
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ***** Breadcrumb Area End ***** */}

      {/* ***** Inventory Management Area Start ***** */}
      <section className="mosh-aboutUs-area section_padding_100_0">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-10">
              <div className="mosh-about-us-content dark">
                <div className="section-heading">
                  <p>Community</p>
                  <h2>Get Help when you need it</h2>
                </div>
                <p>
                  Making things sometimes means working alone, but it doesn't
                  have to be. Tap Binner's community of makers and professionals
                  and get help on your project so roadblocks don't sideline your
                  project.
                </p>
                <p>
                  Got a public project? Post the details on your Community
                  projects page so others can build what you designed!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ***** Inventory Management Area End ***** */}

      {/* ***** Community Area Start ***** */}
      <section
        className="few-words-from-ceo d-md-flex"
        style={{ marginTop: "50px" }}
      >
        <div
          className="few-words-contents2 d-flex align-items-center justify-content-center wow fadeInLeftBig"
          data-wow-delay="0.1s"
        >
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-12 col-md-11 col-lg-9 col-xl-7">
                <div className="few-words-text">
                  <div className="section-heading">
                    <p>Community</p>
                    <h2>Working Together</h2>
                  </div>
                  <p>
                    Binner Community is a place for like-minded individuals to
                    openly discuss about your projects and get help from each
                    other.
                  </p>
                  <br />
                  <p>
                    Integrated directly with your Binner content is a custom
                    community forum that includes live chat!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="few-words-thumb bg-img wow fadeInRightBig"
          data-wow-delay="1.1s"
          style={{
            backgroundPositionX: "-1000px",
            backgroundImage: "url(img/bg-img/community.jpg)",
          }}
        ></div>
      </section>
      {/* ***** Community Area End ***** */}

      <section
        className="mosh-subscribe-newsletter-area bg-img bg-overlay-white section_padding_100"
        style={{ backgroundImage: "url(img/bg-img/sub-1.jpg)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className="subscribe-newsletter-content text-center wow fadeIn"
                data-wow-delay="0.5s"
              >
                <p>we're getting ready</p>
                <h2>
                  Binner Community will be available soon.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
