import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Button, Form, Segment, Icon, Message } from "semantic-ui-react";
import useAnalyticsEventTracker from "../components/useAnalyticsEventTracker";
import { fetchApi, getErrors } from "../common/fetchApi";
import { config } from "../common/config";
import { Turnstile } from '@marsidev/react-turnstile'
//import { ReCaptchaActions, GetTypeName } from "../common/Types";
//import { loadReCaptcha, unloadReCaptcha } from "../common/recaptcha";

export function PasswordRecovery (props) {
  const { t } = useTranslation();
  const gaEventTracker = useAnalyticsEventTracker('Forgot Password');
  const [loading, setLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [form, setForm] = useState({
    emailAddress: "",
  });
  const [token, setToken] = useState();

  /*useEffect(() => {
    loadReCaptcha();
    return () => {
      unloadReCaptcha();
    };
  }, []);*/

  const onSubmit = async (e) => {
    e.preventDefault();
    gaEventTracker('Forgot Password Button');
    setLoading(true);

    // generate reCaptcha v3 token before calling api
    /*window.grecaptcha.ready(() => {
      window.grecaptcha.execute(config.RECAPTCHA_SITEKEY, {
        // set the token action
        action: GetTypeName(ReCaptchaActions, ReCaptchaActions.PasswordReset)
      }).then(async token => {
        await submitPasswordRecovery(e, token);
      });
    });*/

    await submitPasswordRecovery(e, token);
  };

  const submitPasswordRecovery = async (e, token) => {
    setLoading(true);
    return await fetchApi("api/authentication/passwordrecovery",
    {
      method: "POST",
      body: JSON.stringify({
        emailAddress: form.emailAddress,
        token
      })
    }).then((response) => {
      const { data } = response;
      if (response.responseObject.status === 400) {
        // validation errors
        let errors = getErrors(response);
        setValidationErrors(errors);
      } else if(data.isSuccessful) {
        setIsSuccessful(true);
      }
      setLoading(false);
    });
  }

  const handleChange = (e, control) => {
    e.preventDefault();
    e.stopPropagation();
    setForm(form => ({ 
      ...form,
      [e.target.name]: control.value 
    }));
  }

  return (
    <div>
      {/* ***** Breadcrumb Area Start ***** */}
      <div className="mosh-breadcumb-area">
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-12">
              <div className="bradcumbContent">
                <h2>Support</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ***** Breadcrumb Area End ***** */}

      <div className="centered">
        <h1>Password Recovery</h1>

          {isSuccessful
          ? (
          <div>
            <Segment raised style={{ width: "60%", margin: "auto", marginBottom: '50px', minHeight: '200px' }}>
              <div style={{display: 'flex',alignItems: 'center', justifyContent: 'center', height: '200px'}}>
                <div>
                  <h2>Check your email!</h2>
                  <Icon name="mail outline" color="blue" size="huge" style={{margin: '20px'}} />
                  <p>
                    We've sent you a special link you can use to reset your password.
                  </p>
                </div>
              </div>
            </Segment>
          </div>
          ) 
          : (
          <div>
            <p>
              Forgot or lost your password? No problem, it happens all the time!<br/><br/>
              Enter your email and we will send you a link to reset your password.
            </p>

            <Segment raised style={{ width: "40%", margin: "auto", marginBottom: '50px' }}>
              <Message negative hidden={validationErrors.length === 0}>
                <Message.Header>Please correct the following</Message.Header>
                <Message.Content>
                  <ul>
                    {validationErrors.map((error, key) => (
                      <li key={key}>{error.field}: {error.value}</li>
                    ))}
                  </ul>
                </Message.Content>
              </Message>

              <Form onSubmit={onSubmit} loading={loading} autoComplete="on">
                <Form.Input
                  focus
                  required
                  autoComplete="email"
                  name="emailAddress"
                  value={form.emailAddress || ""}
                  placeholder="Email"
                  icon="mail outline"
                  iconPosition="left"
                  onChange={handleChange}
                />
                <div className="centered">
                  <Turnstile siteKey={config.TURNSTILE_SITEKEY} onSuccess={setToken} options={{
                      theme: 'light',
                      appearance: 'always',
                      size: 'normal',
                      language: 'en',
                    }}
                  />
                  <Button type="submit" primary style={{ marginTop: "10px" }}>
                    Reset Password
                    <Icon name="arrow circle right" />
                  </Button>
                </div>
              </Form>
            </Segment>
          </div>
        )}
      </div>
    </div>
  );
}
