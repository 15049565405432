
/**
 * Open a datasheet PDF
 * @param {any} e event
 * @param {object} datasheet the datasheet to view
 * @returns 
 */
export const viewDatasheet = (e, datasheet, target = "_blank") => {
	e.preventDefault();
	e.stopPropagation();
	if (!datasheet)
		return;
	const url = `https://${datasheet.resourceSourceUrl}/${datasheet.resourcePath}.pdf`;
	window.open(url, target);
};