import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "reactstrap";
import { Statistic, Segment, Icon } from "semantic-ui-react";
import { getUserAccount } from "../../common/authentication";
import { fetchApi } from "../../common/fetchApi";
import { FormHeader } from "../../components/FormHeader";

export function HomeAdmin(props) {
  const [summary, setSummary] = useState({});
  const [loading, setLoading] = useState(true);
  const [userAccount] = useState(getUserAccount());
  let navigate = useNavigate();

  useEffect(() => {

    async function load() {
      HomeAdmin.abortController = new AbortController();
      if (userAccount.isAuthenticated) {
        if (HomeAdmin.abortController) {
          HomeAdmin.abortController.abort(); // Cancel the previous request
        }
        HomeAdmin.abortController = new AbortController();
        setLoading(true);
        await fetchApi(`api/admin/summary`, {
          signal: HomeAdmin.abortController.signal,
        }).then((response) => {
          const { data } = response;
          setSummary(data || {});
          setLoading(false);
        });
      }

      return async function cleanup() {
        await HomeAdmin.abortController.abort();
      };
    }

    load();
  }, [userAccount, setLoading, setSummary]);

  const route = (e, url) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(url);
  };

  return (
    <Container className="admin-container">
      <div className="centered contentwrapper">
        <FormHeader name="Admin Dashboard" to="/">
          Binner Administration
        </FormHeader>
        <Segment>
          <Statistic.Group widths="four">
            <Statistic onClick={(e) => route(e, "/admin/partNumbers")} style={{ cursor: "pointer" }}>
              <Statistic.Value>
                <Icon name="search" />
              </Statistic.Value>
              <Statistic.Label>Part Numbers</Statistic.Label>
            </Statistic>
            <Statistic onClick={(e) => route(e, "/admin/datasheets")} style={{ cursor: "pointer" }}>
              <Statistic.Value>
                <Icon name="file alternate" />
              </Statistic.Value>
              <Statistic.Label>Datasheets</Statistic.Label>
            </Statistic>
            <Statistic onClick={(e) => route(e, "/admin/datasheets/crawler")} style={{ cursor: "pointer" }}>
              <Statistic.Value>
                <Icon name="searchengin" />
              </Statistic.Value>
              <Statistic.Label>Crawler</Statistic.Label>
            </Statistic>
            <Statistic onClick={(e) => route(e, "/admin/users")} style={{ cursor: "pointer" }}>
              <Statistic.Value>
                <Icon name="user" />
              </Statistic.Value>
              <Statistic.Label>Users</Statistic.Label>
            </Statistic>
          </Statistic.Group>
          <Statistic.Group widths="three">
            <Statistic onClick={(e) => route(e, "/admin/pinouts")} style={{ cursor: "pointer" }}>
              <Statistic.Value>
                <Icon name="microchip" />
              </Statistic.Value>
              <Statistic.Label>Pinouts</Statistic.Label>
            </Statistic>
            <Statistic onClick={(e) => route(e, "/admin/stats")} style={{ cursor: "pointer" }}>
              <Statistic.Value>
                <Icon name="eye" />
              </Statistic.Value>
              <Statistic.Label>Stats</Statistic.Label>
            </Statistic>
            <Statistic onClick={(e) => route(e, "/admin/bulkemail")} style={{ cursor: "pointer" }}>
              <Statistic.Value>
                <Icon name="mail" />
              </Statistic.Value>
              <Statistic.Label>Bulk Email</Statistic.Label>
            </Statistic>
          </Statistic.Group>
        </Segment>

        <h2>System Overview</h2>
        <Segment inverted loading={loading} textAlign="center">
          <Statistic.Group widths="three">
            <Statistic color="red" inverted>
              <Statistic.Value>
                <Icon name="microchip" />
                {summary.totalPartNumbersPending}
              </Statistic.Value>
              <Statistic.Label>Pending Part Numbers</Statistic.Label>
            </Statistic>
            <Statistic color="orange" inverted>
              <Statistic.Value>
                <Icon name="file" />
                {summary.totalPdfLinksPending}
              </Statistic.Value>
              <Statistic.Label>Pending PDFs</Statistic.Label>
            </Statistic>
            <Statistic color="blue" inverted>
              <Statistic.Value>
                <Icon name="searchengin" />
                {summary.totalUrlHistoryPending}
              </Statistic.Value>
              <Statistic.Label>Pending Crawler</Statistic.Label>
            </Statistic>
          </Statistic.Group>
        </Segment>

        <h2>Data Overview</h2>
        <Segment inverted loading={loading} textAlign="center">
          <Statistic.Group widths="five">
            <Statistic color="purple" inverted>
              <Statistic.Value>
                <Icon name="microchip" />
                {summary.totalPartNumbers}
              </Statistic.Value>
              <Statistic.Label>Part Numbers</Statistic.Label>
            </Statistic>
            <Statistic color="purple" inverted>
              <Statistic.Value>
                <Icon name="file" />
                {summary.totalDatasheets}
              </Statistic.Value>
              <Statistic.Label>Datasheets</Statistic.Label>
            </Statistic>
            <Statistic color="purple" inverted>
              <Statistic.Value>
                <Icon name="image" />
                {summary.totalPinouts}
              </Statistic.Value>
              <Statistic.Label>Pinouts</Statistic.Label>
            </Statistic>
            <Statistic color="purple" inverted>
              <Statistic.Value>
                <Icon name="image" />
                {summary.totalCircuits}
              </Statistic.Value>
              <Statistic.Label>Circuits</Statistic.Label>
            </Statistic>
            <Statistic color="purple" inverted>
              <Statistic.Value>
                <Icon name="image" />
                {summary.totalImages}
              </Statistic.Value>
              <Statistic.Label>Images</Statistic.Label>
            </Statistic>
          </Statistic.Group>
        </Segment>

        <h2>User Overview</h2>
        <Segment inverted loading={loading} textAlign="center">
          <Statistic.Group widths="five">
            <Statistic color="blue" inverted>
              <Statistic.Value>
                <Icon name="inbox" />
                {summary.totalActiveSupportTickets}
              </Statistic.Value>
              <Statistic.Label>Active Support Tickets</Statistic.Label>
            </Statistic>
            <Statistic color="green" inverted>
              <Statistic.Value>
                <Icon name="user" />
                {summary.totalUsers}
              </Statistic.Value>
              <Statistic.Label>Users</Statistic.Label>
            </Statistic>
            <Statistic color="green" inverted>
              <Statistic.Value>
                <Icon name="dollar" />
                {summary.totalActiveSubscriptions}
              </Statistic.Value>
              <Statistic.Label>Active Subscriptions</Statistic.Label>
            </Statistic>
            <Statistic color="green" inverted>
              <Statistic.Value>
                <Icon name="user" />
                {summary.total24hrSignups}
              </Statistic.Value>
              <Statistic.Label>24hr Signups</Statistic.Label>
            </Statistic>
            <Statistic color="green" inverted>
              <Statistic.Value>
                <Icon name="dollar" />
                {summary.total24hrSubscriptions}
              </Statistic.Value>
              <Statistic.Label>24hr Subscriptions</Statistic.Label>
            </Statistic>
          </Statistic.Group>
        </Segment>

      </div>
    </Container>
  );
};