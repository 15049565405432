import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Form, Label, Segment, Icon, Divider, Message, Card } from "semantic-ui-react";
import _ from "underscore";
import useAnalyticsEventTracker from "../components/useAnalyticsEventTracker";
import { fetchApi, doNothing } from "../common/fetchApi";
import { config } from "../common/config";
import { setUserAccount, deAuthenticateUserAccount, decodeJwt } from "../common/authentication";
import { SubscriptionLevels, PaymentFrequency, /*ReCaptchaActions,*/ GetTypeName } from "../common/Types";
import { SubscriptionOptions } from "../components/SubscriptionOptions";
import { Turnstile } from '@marsidev/react-turnstile'
//import { loadReCaptcha, unloadReCaptcha } from "../common/recaptcha";

const SCREEN = {
  SignupOptions: 0,
  Signup: 1,
  ConfirmationEmail: 2
};

export function Signup(props) {
  const { t } = useTranslation();
  const gaEventTracker = useAnalyticsEventTracker('Register');

  const [screenNumber, setScreenNumber] = useState(SCREEN.SignupOptions);
  const [subscriptionLevel, setSubscriptionLevel] = useState(SubscriptionLevels.Free);
  const [paymentFrequency, setPaymentFrequency] = useState(PaymentFrequency.Annually);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [validationErrors, setValidationErrors] = useState([]);
  const [stripePriceId, setStripePriceId] = useState("");
  const [form, setForm] = useState({
    email: "",
    name: "",
    password: "",
    confirmPassword: ""
  });
  const [token, setToken] = useState();

  /*useEffect(() => {
    loadReCaptcha();
    return () => {
      unloadReCaptcha();
    };
  }, []);*/

  const onSetSubscriptionLevel = (e, target, subscriptionLevel, paymentFrequency) => {
    let stripePriceId = "";
    if (paymentFrequency === PaymentFrequency.Annually) {
      switch (subscriptionLevel) {
        case SubscriptionLevels.Maker:
          stripePriceId = config.MAKER_ANNUALLY_PRICEID;
          break;
        case SubscriptionLevels.Professional:
          stripePriceId = config.PROFESSIONAL_ANNUALLY_PRICEID;
          break;
        case SubscriptionLevels.Commercial:
          //stripePriceId = config.COMMERCIAL_ANNUALLY_PRICEID;
          break;
        default:
          break;
      }
    } else {
      switch (subscriptionLevel) {
        case SubscriptionLevels.Maker:
          stripePriceId = config.MAKER_MONTHLY_PRICEID;
          break;
        case SubscriptionLevels.Professional:
          stripePriceId = config.PROFESSIONAL_MONTHLY_PRICEID;
          break;
        case SubscriptionLevels.Commercial:
          //stripePriceId = config.COMMERCIAL_MONTHLY_PRICEID;
          break;
        default:
          break;
      }
    }
    console.log("setting stripePriceId", GetTypeName(SubscriptionLevels, subscriptionLevel), GetTypeName(PaymentFrequency, paymentFrequency), stripePriceId);
    setLoading(false);
    setSubscriptionLevel(subscriptionLevel);
    setScreenNumber(SCREEN.Signup);
    setStripePriceId(stripePriceId);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    gaEventTracker('Register Button');

    setLoading(true);

    // generate reCaptcha v3 token before calling api
    /*window.grecaptcha.ready(() => {
      window.grecaptcha.execute(config.RECAPTCHA_SITEKEY, {
        // set the token action
        action: GetTypeName(ReCaptchaActions, ReCaptchaActions.Register)
      }).then(async token => {
        await handleRegister(e, token);
      });
    });*/

    await handleRegister(e, token);
  };

  const handleRegister = async (e, token) => {
    const request = {
      ...form,
      subscriptionLevel,
      token
    };
    setLoading(true);

    await fetchApi("api/authentication/register", {
      method: "POST",
      body: request
    })
      .then(async (response) => {
        const { data } = response;
        if (response.responseObject.status === 400) {
          // validation errors
          let errors = [];
          _.map(data.errors, (i) => {
            errors.push(i[0]);
          });
          setLoading(false);
          setValidationErrors(errors);
        } else {
          if (data.isSuccessful) {
            // succeeded creating account
            if (data.isAuthenticated) {
              const jwt = decodeJwt(data.jwtToken);
              setUserAccount({
                id: data.id,
                isAuthenticated: data.isAuthenticated,
                canLogin: false,
                name: data.name,
                subscriptionLevel: 0,
                accessToken: data.jwtToken,
                imagesToken: data.imagesToken,
              });
            } else {
              deAuthenticateUserAccount();
            }

            switch (subscriptionLevel) {
              case SubscriptionLevels.Free:
                // ask user to confirm email address
                setScreenNumber(SCREEN.ConfirmationEmail);
                break;
              default:
                // send to payments
                console.log("creating payment session", stripePriceId);

                if (!stripePriceId) console.error("stripePriceId is null and must be set for this operation.", stripePriceId);
                await fetchApi("api/payments/create-checkout-session", {
                  method: "POST",
                  body: {
                    priceId: stripePriceId
                  }
                }).then((configResponse) => {
                  // send to payment provider
                  console.log("sending to payment provider", configResponse);
                  window.location.replace(configResponse.data.url);
                });

                break;
            }
            setSuccessMessage(data.message);
          } else {
            setErrorMessage(data.message);
          }
        }
      })
      .catch(doNothing);

    setLoading(false);
  };

  const handleChange = (e, control) => {
    e.preventDefault();
    e.stopPropagation();
    form[control.name] = control.value;
    setForm({ ...form });
  };

  const GetSubscriptionLevelName = (subscriptionLevel) => {
    switch (subscriptionLevel) {
      case SubscriptionLevels.Free:
      default:
        return "Free";
      case SubscriptionLevels.Maker:
        return "Maker";
      case SubscriptionLevels.Professional:
        return "Professional";
      case SubscriptionLevels.Commercial:
        return "Commercial";
    }
  };

  const getSubscriptionLevelDescription = (subscriptionLevel) => {
    switch (subscriptionLevel) {
      case SubscriptionLevels.Free:
      default:
          return (
          <div>
            <ul className="subscription-details-horizontal">
              <li>Max 128 unique parts</li>
              <li>Datasheets repository</li>
              <li>API integrations</li>
              <li>Design Tools</li>
              <li>Label Printing</li>
              <li>Electronic Bin Support</li>
            </ul>
          </div>
        );
      case SubscriptionLevels.Maker:
        return (
          <div>
            <ul className="subscription-details-horizontal">
              <li>Max 512 unique parts</li>
              <li>Datasheets repository</li>
              <li>API integrations</li>
              <li>Design Tools</li>
              <li>Label Printing</li>
              <li>Electronic Bin Support</li>
              <li>Premium Pinouts</li>
              <li>Circuits repository</li>
            </ul>
          </div>
        );
      case SubscriptionLevels.Professional:
        return (
          <div>
            <ul className="subscription-details-horizontal">
              <li>Max 2048 unique parts</li>
              <li>Datasheets repository</li>
              <li>API integrations</li>
              <li>Design Tools</li>
              <li>Label Printing</li>
              <li>Electronic Bin Support</li>
              <li>Premium Pinouts</li>
              <li>Circuits repository</li>
              <li>BOM Management</li>
            </ul>
          </div>
        );
        case SubscriptionLevels.Commercial:
        return (
          <div>
            <ul className="subscription-details-horizontal">
              <li>Unlimited parts</li>
              <li>Datasheets repository</li>
              <li>API integrations</li>
              <li>Design Tools</li>
              <li>Label Printing</li>
              <li>Electronic Bin Support</li>
              <li>Premium Pinouts</li>
              <li>Circuits repository</li>
              <li>BOM Management</li>
            </ul>
          </div>
        );
    }
  };

  const handlePaymentFrequencyButton = (e, paymentFrequency) => {
    e.preventDefault(); 
    setPaymentFrequency(paymentFrequency);
    onSetSubscriptionLevel(e, e.target, subscriptionLevel, paymentFrequency);
  };

  const GetSubscriptionLevelCompactDescription = (subscriptionLevel) => {
    switch (subscriptionLevel) {
      case SubscriptionLevels.Free:
        default:
          return (
          <Card centered className="subscription-option-single">
            <Card.Content>
              <Card.Description>{getSubscriptionLevelDescription(SubscriptionLevels.Free)}</Card.Description>
            </Card.Content>
            <Card.Content extra>
              <h3>FREE</h3>
            </Card.Content>
          </Card>
        );
      case SubscriptionLevels.Maker:
        return (
          <Card centered className="subscription-option-single">
            <Card.Content>
              <Card.Description>{getSubscriptionLevelDescription(SubscriptionLevels.Maker)}</Card.Description>
            </Card.Content>
            <Card.Content extra>
              <h5>Choose your payment preference</h5>
              <Button.Group>
                <Button primary={paymentFrequency === PaymentFrequency.Monthly} onClick={e => handlePaymentFrequencyButton(e, PaymentFrequency.Monthly)}>$5.00 monthly</Button>
                <Button.Or text='or' />
                <Button primary={paymentFrequency === PaymentFrequency.Annually} onClick={e => handlePaymentFrequencyButton(e, PaymentFrequency.Annually)}>$57.00 annually</Button>
              </Button.Group>
            </Card.Content>
          </Card>
        );
      case SubscriptionLevels.Professional:
        return (
          <Card centered className="subscription-option-single">
            <Card.Content>
              <Card.Description>{getSubscriptionLevelDescription(SubscriptionLevels.Professional)}</Card.Description>
            </Card.Content>
            <Card.Content extra>
              <h3>$19.00/mo</h3>
              <div>or $217.00/year</div>
            </Card.Content>
          </Card>
        );
      case SubscriptionLevels.Commercial:
        return (
          <Card centered className="subscription-option-single">
            <Card.Content>
              <Card.Description>{getSubscriptionLevelDescription(SubscriptionLevels.Commercial)}</Card.Description>
            </Card.Content>
            <Card.Content extra>
              <h3>$99.00/mo</h3>
              <div>or $1128.00/year</div>
            </Card.Content>
          </Card>
        );
    }
  };

  const renderSignupOptions = () => {
    return <SubscriptionOptions onSubmit={onSetSubscriptionLevel} />;
  };

  const renderSignup = () => {
    return (
      <div className="centered">
        <Form onSubmit={onSubmit} loading={loading} autoComplete="on">
          <script src="https://js.stripe.com/v3/"></script>
          <script src="./script.js" defer></script>
          <h1>{GetSubscriptionLevelName(subscriptionLevel)} subscription</h1>
          <div style={{ backgroundColor: "#e9ecef", padding: "10px 0 10px 0", marginBottom: "10px" }}>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                setScreenNumber(SCREEN.SignupOptions);
              }}
            >
              <Icon name="arrow alternate circle left outline" />
              Return to Subscriptions
            </a>
          </div>
          <div style={{ marginBottom: "15px" }}>{GetSubscriptionLevelCompactDescription(subscriptionLevel)}</div>
          <Segment raised secondary className="half-width" style={{ margin: "0 auto" }}>
            <Message negative hidden={validationErrors.length === 0}>
              <Message.Header>Please correct the following</Message.Header>
              <Message.Content>
                <ul>
                  {validationErrors.map((error, key) => (
                    <li key={key}>{error}</li>
                  ))}
                </ul>
              </Message.Content>
            </Message>
            <Form.Input
              autoComplete="name"
              focus
              required
              name="name"
              value={form.name || ""}
              placeholder="Your Name"
              icon="user outline"
              iconPosition="left"
              onChange={handleChange}
            />
            <Form.Input
              autoComplete="email"
              required
              name="email"
              value={form.email || ""}
              placeholder="Email"
              icon="mail outline"
              iconPosition="left"
              onChange={handleChange}
            />
            <Form.Input
              autoComplete="new-password"
              required
              type="password"
              name="password"
              value={form.password || ""}
              placeholder="Password"
              icon="lock"
              iconPosition="left"
              onChange={handleChange}
            />
            <Form.Input
              autoComplete="new-password"
              required
              type="password"
              name="confirmPassword"
              value={form.confirmPassword || ""}
              placeholder="Confirm Password"
              icon="lock"
              iconPosition="left"
              onChange={handleChange}
            />

            <div className="centered">
              {errorMessage.length > 0 && (
                <div>
                  <Label basic pointing color="red" style={{ marginTop: -10 }}>
                    {errorMessage}
                  </Label>
                </div>
              )}
              <Turnstile siteKey={config.TURNSTILE_SITEKEY} onSuccess={setToken} options={{
                  theme: 'light',
                  appearance: 'always',
                  size: 'normal',
                  language: 'en',
                }}
              />
              {subscriptionLevel === SubscriptionLevels.Free ? (
                <Button type="submit" primary style={{ marginTop: "10px" }}>
                  Create Account
                  <Icon name="arrow circle right" />
                </Button>
              ) : (
                <Button type="submit" primary style={{ marginTop: "10px" }}>
                  PROCEED TO PAYMENT
                  <Icon name="arrow circle right" />
                </Button>
              )}
            </div>

            <Divider horizontal>Or</Divider>
            <div className="centered">
              Already a member? <Link to="/login">Login</Link> instead
            </div>
          </Segment>
        </Form>
      </div>
    );
  };

  const renderConfirmationEmail = () => {
    return (
      <div className="centered">
        <h1>You're almost there!</h1>
        <Segment raised style={{ width: "60%", margin: "40px auto 80px auto" }}>
          <p>{successMessage}</p>
        </Segment>
      </div>
    );
  };

  let body = "";
  switch (screenNumber) {
    case SCREEN.ConfirmationEmail:
      body = renderConfirmationEmail();
      break;
    case SCREEN.Signup:
      body = renderSignup();
      break;
    case SCREEN.SignupOptions:
    default:
      body = renderSignupOptions();
      break;
  }

  return (
    <div>
      {/* ***** Breadcrumb Area Start ***** */}
      <div className="mosh-breadcumb-area">
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-12">
              <div className="bradcumbContent">
                <h2>Get Started</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ***** Breadcrumb Area End ***** */}

      <div className="centered" style={{marginBottom: '50px'}}>{body}</div>
    </div>
  );
}
