import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import _ from "underscore";
import { Table, Label, Segment, Button, Dropdown, Icon, Breadcrumb } from "semantic-ui-react";
import { fetchApi } from "../../../common/fetchApi";
import { InifiniteScrollTable } from "../../../components/InfiniteScrollTable";
import { ImageTypes, DocumentTypes, GetTypeName, GetTypeDropdown } from "../../../common/Types";
import { FormHeader } from "../../../components/FormHeader";
import "../Admin.css";

export function DatasheetsAdmin(props) {
  const maxResults = 10;
  const [loading, setLoading] = useState(false);
  const [datasheets, setDatasheets] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [filters, setFilters] = useState([]);
  const [documentOptions] = useState(GetTypeDropdown(DocumentTypes, 1, { type: 'documentType' }));
  const [prunedOptions] = useState([
    {
      key: 21,
      value: 21,
      text: 'Not Pruned',
      type: 'pruned'
    },
    {
      key: 22,
      value: 22,
      text: 'Pruned',
      type: 'pruned'
    },
  ]);
  const [filterOptions] = useState([...documentOptions, ...prunedOptions]);
  let navigate = useNavigate();
  const fetchDatasheets = useCallback((page = 1) => {
    if (hasMoreData) {
      setLoading(true);
      fetchApi(`api/datasheet/list?page=${page}&results=${maxResults}`).then((response) => {
        const { data } = response;
        if (data && data.length > 0) {
          setCurrentPage(page);
          // update the page of data, as long as its not already in the data
          let newData = [...datasheets];
          for(let i = 0; i < data.length; i++)
          {
            const index = newData.findIndex(x => x.datasheetId === data[i].datasheetId);
            if (index === -1){
              newData.push(data[i]);
            }
          }
          setDatasheets(newData); 
        } 
        if (data.length < maxResults) {
          // no more data, received back 0 or less than maxResults
          setHasMoreData(false);
        }
        setLoading(false);
      });
    }
  }, [maxResults, datasheets, setLoading, setDatasheets, setCurrentPage, hasMoreData]);

  useEffect(() => {
    fetchDatasheets(currentPage);
  }, []);

  const fetchNextPage = () => {
    fetchDatasheets(currentPage + 1);
  };

  const handleLoadPartClick = (event, target) => {
    navigate(`${target.datasheetId}`);
  };

  const getCoverImageUrl = (datasheet) => {
    const image = _.find(datasheet.imageMetadata, (s) => s.imageType === ImageTypes.FullPage);
    if (image) {
      const url = `https://${datasheet.resourceSourceUrl}/${datasheet.resourcePath}_${image.sequenceId}.png`;
      return url;
    }
    return `https://${datasheet.resourceSourceUrl}/datasheetcover.png`;
  };

  const setFilter = (e, control) => {
    // control.value is an array of filter values
    setFilters(control.value)
  };

  const handleManageCrawler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate('crawler');
  }

  const headerRow = (<Table.Row>
    <Table.HeaderCell width={2}>Part Number</Table.HeaderCell>
    <Table.HeaderCell width={2}>Document</Table.HeaderCell>
    <Table.HeaderCell>Short Description</Table.HeaderCell>
    <Table.HeaderCell width={3}>Manufacturer</Table.HeaderCell>
    <Table.HeaderCell>Processed</Table.HeaderCell>
    <Table.HeaderCell width={2}>Image</Table.HeaderCell>
  </Table.Row>);

  let filteredDatasheets = datasheets;
  const filterValues = filterOptions.filter(filterOption => _.find(filters, (filter) => filter === filterOption.value));
  const documentTypeFilters = filterValues.filter(f => f.type === 'documentType');
  const prunedFilters = filterValues.filter(f => f.type === 'pruned');
  if (documentTypeFilters.length > 0)
    filteredDatasheets = filteredDatasheets.filter(datasheet => _.find(documentTypeFilters, (f) => f.value === datasheet.documentType));
  if(prunedFilters.length > 0)
    filteredDatasheets = filteredDatasheets.filter(datasheet => datasheet.datePrunedUtc !== null);

  return (
    <div className="admin-container">
      <Breadcrumb>
        <Breadcrumb.Section href="/admin">Admin</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section href="/admin/datasheets">Datasheets</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>Admin</Breadcrumb.Section>
      </Breadcrumb>
      <FormHeader name="Datasheet Admin" to="..">
        Search for existing datasheets, add new ones.
      </FormHeader>
      <Button onClick={handleManageCrawler} icon size='mini'><Icon name='file' /> Manage Crawler</Button>
      <Segment loading={loading}>
        <Segment>
          <div style={{verticalAlign: 'middle'}}>
            <Icon name='filter' />
            <Dropdown name="filters" value={filters} options={filterOptions} multiple selection text="Filter" onChange={setFilter} />
          </div>
        </Segment>
        <InifiniteScrollTable id="partsGrid" compact celled sortable selectable striped unstackable size="small" headerRow={headerRow} nextPage={() => fetchNextPage()}>
          {filteredDatasheets.map((d) => (
                <Table.Row key={d.datasheetId} onClick={(e) => handleLoadPartClick(e, d)}>
                  <Table.Cell textAlign="center">
                    <Label>{d.basePartNumber}</Label>
                  </Table.Cell>
                  <Table.Cell textAlign="center">{GetTypeName(DocumentTypes, d.documentType)}</Table.Cell>
                  <Table.Cell>{d.shortDescription || "(none)"}</Table.Cell>
                  <Table.Cell textAlign="center">{(d.manufacturer && d.manufacturer.name) || "(unknown)"}</Table.Cell>
                  <Table.Cell textAlign="center">{d.pdfLinkId > 0 ? (<Icon name="check circle" color="green" />) : (<Icon name="arrow circle up" color="blue" />)}</Table.Cell>
                  <Table.Cell>
                    <div className="centered">
                      {d && d.imageMetadata && d.imageMetadata.length > 0 && 
                        <img src={getCoverImageUrl(d)} alt="Cover" className="datasheet cover" />
                      }
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
        </InifiniteScrollTable>
      </Segment>
    </div>
  );
}
