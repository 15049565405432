import React, { useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { fetchApi, getErrorsString } from "../common/fetchApi";
import { toast } from "react-toastify";

export function Features (props) {
	const navigate = useNavigate();
	const formRef = useRef();

	const submitSubscribe = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (formRef.current.emailAddress.value.length <= 0) {
			toast.error(`Email address must be specified.`);
			return;
		}

    return fetchApi("api/newsletter/subscribe",
    {
      method: "POST",
      body: JSON.stringify({
        emailAddress: formRef.current.emailAddress.value
      })
    }).then((response) => {
      const { data } = response;
			if (data.result === true)
				toast.success("You will receive the next newsletter!");
			else if(data.errors)
				toast.error(getErrorsString(response));
			else
				toast.error("Failed to signup for the newsletter.");
    });
  }

  return (
    <div>
			{/* ***** Breadcrumb Area Start ***** */}
			<div className="mosh-breadcumb-area">
					<div className="container h-100">
							<div className="row h-100 align-items-center">
									<div className="col-12">
											<div className="bradcumbContent">
													<h2>Binner</h2>
													<nav aria-label="breadcrumb">
															<ol className="breadcrumb">
																	<li className="breadcrumb-item"><a href="/" onClick={(e) => { e.preventDefault(); navigate('/'); }}>Home</a></li>
																	<li className="breadcrumb-item active" aria-current="page">Full Feature List</li>
															</ol>
													</nav>
											</div>
									</div>
							</div>
					</div>
			</div>
			{/* ***** Breadcrumb Area End ***** */}

			{/* ***** About Us Area Start ***** */}
			<section className="mosh-aboutUs-area section_padding_100_0">
					<div className="container">
							<div className="row align-items-center">
									<div className="col-12 col-md-6">
											<div className="mosh-about-us-content dark">
													<div className="section-heading">
															<p>Features</p>
															<h2>Inventory Management</h2>
													</div>
													<p>
														Binner was built for makers, hobbyists and professionals to keep track of your electronic parts inventory.
														It is based on the popular open-source project <a href="https://github.com/replaysMike/Binner">Binner</a> which you can self-host for free on a <a href="https://www.raspberrypi.org/">Raspberry-Pi</a> or your PC.
														With Binner Cloud we can host all of your data and offer you an already configured instance of Binner with built-in integrations for DigiKey, Mouser, Arrow and more.
														You can export your data at any time to your local Binner instance or keep it in the cloud.
													</p>
													<p>
														If you combine Binner with our electronic storage bins you get super easy access to where your parts are stored and keep track of your stock.
													</p>
													<a href="/inventory-management" className="btn mosh-btn mt-50" onClick={(e) => { e.preventDefault(); navigate('/inventory-management'); }}>Read More</a>
											</div>
									</div>
									<div className="col-12 col-md-6">
											<div className="mosh-about-us-thumb wow fadeInUp" data-wow-delay="0.5s" style={{ height: '350px'}}>
												<div className="slide-img bin-animation medium" />
												<img src="img/bg-img/storagebin_shadow.png" width={620} height={72} alt="" />
												<div style={{backgroundImage: 'url(img/bg-img/raspberry-pi-small.png)', backgroundSize: '225px 147px', position: 'relative', top: '-50px', left: '300px', width: '225px', height: '147px'}}></div>
												<a href="/binnerbin" className="btn mosh-btn mt-50" onClick={(e) => { e.preventDefault(); navigate('/binnerbin'); }} style={{position: 'relative', top: '-330px', left: '170px'}}>Buy Storage Bins</a>
											</div>
									</div>
							</div>
					</div>
			</section>
			{/* ***** About Us Area End ***** */}

			{/* ***** Features Area End ***** */}
			<section className="feature-cloud d-md-flex">
					<div className="feature-cloud-contents d-flex align-items-center justify-content-center wow fadeInLeftBig" data-wow-delay="0.2s" style={{backgroundImage: 'url(img/bg-img/bg-circuit.png)', backgroundRepeatY: 'no-repeat'}}>
						<div className="container-fluid">
								<div className="section-heading">
									<p>Features</p>
									<h2>Feature Cloud</h2>
								</div>
								<div className="centered">
									<img src="img/core-img/featurecloud.svg" alt="Feature Cloud" style={{maxHeight: '450px'}} />
								</div>
						</div>
					</div>
			</section>
			{/* ***** Features Area End ***** */}

			{/* ***** Features Area End ***** */}
			<section className="mosh-about-features-area section_padding_100">
					<div className="container">
							<div className="row align-items-center">

									<div className="col-12 col-md-4">
											<div className="mosh-features-thumb wow fadeIn" data-wow-delay="0.5s">
													<img src="img/bg-img/data-integrations.jpg" alt="" />
											</div>
									</div>

									<div className="col-12 col-md-8">
											<div className="mosh-about-us-content">
													<div className="section-heading">
														<p>Features</p>
														<h2>Data Integrations</h2>
													</div>
													<div className="row">
														<div className="col-12 col-sm-6">
																<div className="single-feature-area d-flex">
																	<div className="feature-icon mr-30">
																		<img src="img/core-img/settings-2.png" alt="" />
																	</div>
																	<div className="feature-content">
																		<h5>Swarm</h5>
																		<p>Parametrics, Datasheets, Pinouts, Circuits examples</p>
																	</div>
																</div>
															</div>
															<div className="col-12 col-sm-6">
																<div className="single-feature-area d-flex">
																	<div className="feature-icon mr-30">
																			<img src="img/core-img/settings-2.png" alt="" />
																	</div>
																	<div className="feature-content">
																		<h5>DigiKey</h5>
																		<p>Import your orders, additional parametrics, ordering</p>
																	</div>
																</div>
															</div>
															<div className="col-12 col-sm-6">
																<div className="single-feature-area d-flex">
																	<div className="feature-icon mr-30">
																		<img src="img/core-img/settings-2.png" alt="" />
																	</div>
																	<div className="feature-content">
																		<h5>Mouser</h5>
																		<p>Order Import, additional parametrics, ordering</p>
																	</div>
																</div>
															</div>
															<div className="col-12 col-sm-6">
																<div className="single-feature-area d-flex">
																	<div className="feature-icon mr-30">
																		<img src="img/core-img/settings-2.png" alt="" />
																	</div>
																	<div className="feature-content">
																		<h5>Arrow</h5>
																		<p>Order Import, additional parametrics, ordering</p>
																	</div>
																</div>
															</div>
													</div>
													<a href="/data-integrations" className="btn mosh-btn mt-50" onClick={(e) => { e.preventDefault(); navigate('/data-integrations'); }}>Read More</a>
											</div>
									</div>
							</div>
					</div>
			</section>
			{/* ***** Features Area End ***** */}

			{/* ***** Few Words Area Start ***** */}
			<section className="few-words-from-ceo d-md-flex">
					<div className="few-words-contents d-flex align-items-center justify-content-center wow fadeInLeftBig" data-wow-delay="0.1s">
							<div className="container-fluid">
									<div className="row justify-content-center">
											<div className="col-12 col-md-11 col-lg-9 col-xl-7">
													<div className="few-words-text">
															<div className="section-heading">
																	<p>Features</p>
																	<h2>Automatic Part Parametrics</h2>
															</div>
															<p>When you enter your part number into Binner we automatically find the most accurate part details, datasheets and available costs so you always have it on hand. Search through your inventory to find out if you already have the part you need and locate where it is. Re-Order parts when stock gets low. Track BOM by Project. Print labels for your part drawers or catalogues. Use barcoding to identify part data instantly.</p>
															<div className="ceo-meta-data d-flex align-items-center mt-50">
																	<div className="ceo-thumb bg-img" style={{backgroundImage: 'url(img/bg-img/ceo.jpg)'}}></div>
																	<div className="ceo-name">
																			<h6>Michael.</h6>
																			<p>Fellow Maker and Author of Binner</p>
																	</div>
															</div>
													</div>
											</div>
									</div>
							</div>
					</div>
					<div className="few-words-thumb bg-img wow fadeInRightBig" data-wow-delay="1.1s" style={{backgroundImage: 'url(img/bg-img/part-parametrics.jpg)'}}></div>
			</section>
			{/* ***** Few Words Area End ***** */}

			<section className="mosh-subscribe-newsletter-area bg-img bg-overlay-white section_padding_100" style={{backgroundImage: 'url(img/bg-img/sub-1.jpg)'}}>
					<div className="container">
							<div className="row">
									<div className="col-12">
											<div className="subscribe-newsletter-content text-center wow fadeIn" data-wow-delay="0.5s">
													<p>give us a shout</p>
													<h2>Subscribe to our newsletter</h2>
													<form onSubmit={submitSubscribe} ref={formRef} autoComplete="on">
															<input type="email" name="emailAddress" placeholder="Your e-mail here" />
															<button type="submit">Subscribe</button>
													</form>
											</div>
									</div>
							</div>
					</div>
			</section>			
		</div>
  );
}
