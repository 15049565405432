import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Form, Segment, Icon, Divider, Label } from "semantic-ui-react";
import useAnalyticsEventTracker from "../components/useAnalyticsEventTracker";
import { config } from "../common/config";
//import { loadReCaptcha, unloadReCaptcha } from "../common/recaptcha";
//import { ReCaptchaActions, GetTypeName } from "../common/Types";
import { setUserAccount, deAuthenticateUserAccount } from "../common/authentication";
import { fetchApi, doNothing } from "../common/fetchApi";
import { Turnstile } from '@marsidev/react-turnstile'

export function Login () {
  const { t } = useTranslation();
  const gaEventTracker = useAnalyticsEventTracker('Login');

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [form, setForm] = useState({
    username: "",
    password: ""
  });
  const [token, setToken] = useState();
  let navigate = useNavigate();

  /*useEffect(() => {
    loadReCaptcha();
    return () => {
      unloadReCaptcha();
    };
  }, []);*/

  const onSubmit = async (e) => {
    e.preventDefault();
    gaEventTracker('Login Button');

    setLoading(true);
    setErrorMessage("");

    // generate reCaptcha v3 token before calling api
    /*window.grecaptcha.ready(() => {
      window.grecaptcha.execute(config.RECAPTCHA_SITEKEY, {
        // set the token action
        action: GetTypeName(ReCaptchaActions, ReCaptchaActions.Login)
      }).then(async token => {
        await handleLogin(e, token);
      });
    });*/

    await handleLogin(e, token);
  }

  const handleLogin = async (e, token) => {
    const request = {
      ...form,
      token
    };
    await fetchApi("api/authentication/login", { 
      method: "POST", 
      body: request
    })
    .then((response) => {
      const { data } = response;
      if (data.isAuthenticated) {
        // set the current user account
        setUserAccount({
          id: data.id,
          isAuthenticated: data.isAuthenticated,
          name: data.name,
          subscriptionLevel: data.subscriptionLevel,
          accessToken: data.jwtToken,
          imagesToken: data.imagesToken,
          isAdmin: data.isAdmin
        });
        navigate("/", { replace: true, state: { } });
      } else {
        deAuthenticateUserAccount();
        setErrorMessage(data.message);
        setLoading(false);
      }
    })
    .catch(doNothing);
  };

  const handleChange = (e, control) => {
    e.preventDefault();
    e.stopPropagation();
    setForm(form => ({ 
      ...form,
      [e.target.name]: control.value 
    }));
  }

  return (
    <div>
      {/* ***** Breadcrumb Area Start ***** */}
      <div className="mosh-breadcumb-area">
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-12">
              <div className="bradcumbContent">
                <h2>Welcome Back</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ***** Breadcrumb Area End ***** */}

      <div className="centered">
        <h1>Login</h1>
        <Segment raised className="half-width" style={{ margin: "auto", marginBottom: '50px' }}>
          <Form onSubmit={onSubmit} loading={loading} autoComplete="on">
            <Form.Input
              focus
              required
              autoComplete="email"
              name="username"
              value={form.username || ""}
              placeholder="Email"
              icon="mail outline"
              iconPosition="left"
              onChange={handleChange}
            />
            <Form.Input
              required
              autoComplete="current-password"
              type="password"
              name="password"
              value={form.password || ""}
              placeholder="Password"
              icon="lock"
              iconPosition="left"
              onChange={handleChange}
            />
            <div className="centered">
              {errorMessage && errorMessage.length > 0 && (
                <div>
                  <Label basic pointing color="red" style={{ marginTop: -10 }}>
                    {errorMessage}
                  </Label>
                </div>
              )}

              <Turnstile siteKey={config.TURNSTILE_SITEKEY} onSuccess={setToken} options={{
                  theme: 'light',
                  appearance: 'always',
                  size: 'normal',
                  language: 'en',
                }}
              />

              <Button type="submit" primary style={{ marginTop: "10px" }}>
                LOGIN
                <Icon name="arrow circle right" />
              </Button>
            </div>
            <div style={{ marginTop: "15px" }}>
              <Link to="/passwordrecovery" className="small">Forgot your password?</Link>
            </div>
          </Form>
          <Divider horizontal>Or</Divider>
          <div className="centered">
            Not a member? <Link to="/signup">Create an Account</Link> instead
          </div>
        </Segment>
      </div>
    </div>
  );
}
