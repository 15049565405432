import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Segment, Button, Icon, Confirm, Breadcrumb, Grid, Label } from "semantic-ui-react";
import { toast } from "react-toastify";
import { ImageTypes } from "../../../common/Types";
import { FormError } from "../../../components/FormError";
import { viewDatasheet } from "../../../common/visitors";
import { getFormattedTime } from "../../../common/datetime";
import { fetchApi, getErrorsString } from "../../../common/fetchApi";
import { getDefaultProductShotImageFromPartNumberManufacturer } from "../../../common/resources";
import { FormHeader } from "../../../components/FormHeader";
import "../Admin.css";

export function PartNumberSupplier(props) {
  const [loading, setLoading] = useState(false);
  const [confirmDeleteIsOpen, setConfirmDeleteIsOpen] = useState(false);
  const [deleteSelectedItem, setDeleteSelectedItem] = useState(null);
  const [isDirty, setIsDirty] = useState(false);
  const [error, setError] = useState(null);
  const [partNumberSupplier, setPartNumberManufacturer] = useState({
    partNumberManufacturerId: 0,
    partNumberManufacturerSupplierId: 0,
    cost: 0,
    currency: "",
    quantityAvailable: null,
    supplierName: "",
    supplierPartNumber: "",
    supplierId: 0,
    partNumberManufacturer: {}
  });
  const navigate = useNavigate();
  const params = useParams();

  const { partNumberManufacturerSupplierId, partNumberManufacturerId, partNumberId } = params;

  useEffect(() => {
    fetchPartSupplier();

    function fetchPartSupplier() {
      setLoading(true);
      fetchApi(`api/partnumber/manufacturer/supplier?partNumberManufacturerSupplierId=${partNumberManufacturerSupplierId}`).then((response) => {
        const { data } = response;
        if (data) {
          // update the page of data, as long as its not already in the data
          setPartNumberManufacturer(data);
        }

        setLoading(false);
      });
    }
  }, []);

  const onSubmit = (e) => {
    fetchApi(`api/partnumber/manufacturer/supplier`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(partNumberSupplier)
    }).then((response) => {
      if (response.responseObject.ok) {
        toast.success("Saved supplier part!");
        setIsDirty(false);
        navigate(-1);
      } else{
        const errorMessage = getErrorsString(response);
        setError(errorMessage);
        toast.error(`Failed to save supplier part. ${errorMessage}`);
      }
    });
  };

  const refreshFromSupplier = (e, partNumberManufacturerSupplier) => {
    e.preventDefault();
    e.stopPropagation();

    if (!partNumberManufacturerSupplier) return;

    setLoading(true);
    fetchApi(`api/partnumber/manufacturer/supplier/refresh`, {
      method: "POST",
      body: { supplierPartNumber: partNumberManufacturerSupplier.supplierPartNumber }
    }).then((response) => {
      const { data } = response;
      if (data.requiresAuthentication) {
        // redirect for authentication
        window.open(data.redirectUrl, "_blank");
        return;
      } else {
        if (response.responseObject.ok) {
          setPartNumberManufacturer(data.response);
          toast.success("Supplier part was updated!");
        } else {
          const errorMessage = getErrorsString(response);
          setError(errorMessage);
          toast.error(`Failed to update Supplier part. ${errorMessage}`);
        }
      }
      setLoading(false);
    });
  };

  const deletePartNumberSupplier = (e, partNumberManufacturerSupplier) => {
    e.preventDefault();
    e.stopPropagation();

    if (!partNumberManufacturerSupplier) return;

    setLoading(true);
    fetchApi(`api/partnumber/manufacturer/supplier`, {
      method: "DELETE",
      body: partNumberManufacturerSupplier.partNumberManufacturerSupplierId
    }).then(() => {
      setLoading(false);
      setConfirmDeleteIsOpen(false);
      navigate(-1);
    });
  };

  const handleChange = (e, control) => {
    partNumberSupplier[control.name] = control.value;
    setPartNumberManufacturer({ ...partNumberSupplier });
    setIsDirty(true);
  };

  const confirmDeleteOpen = (e, partNumber) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteSelectedItem(partNumber);
    setConfirmDeleteIsOpen(true);
  };

  const confirmDeleteClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteSelectedItem(null);
    setConfirmDeleteIsOpen(false);
  };

  return (
    <div className="admin-container">
      <Breadcrumb>
        <Breadcrumb.Section href="/admin">Admin</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section href="/admin/partnumbers">Part Numbers</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section href={`/admin/partnumbers/${partNumberId}`}>Part Number</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section href={`/admin/partnumbers/${partNumberId}/${partNumberManufacturerId}`}>Part Number Manufacturer</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>Supplier</Breadcrumb.Section>
      </Breadcrumb>
      <FormHeader name="Part Numbers Management" to="..">
        Edit an existing Part Number Supplier.
      </FormHeader>

      <div>
        <Confirm
          open={confirmDeleteIsOpen}
          onCancel={confirmDeleteClose}
          onConfirm={(e) => deletePartNumberSupplier(e, deleteSelectedItem)}
          content="Are you sure you want to delete this part number supplier?"
        />
        <Segment loading={loading} secondary>
          <Form onSubmit={onSubmit} loading={loading}>
            <Form.Field>
              <label>Supplier</label>
              {partNumberSupplier.supplierName}
            </Form.Field>
            <Grid celled>
              <Grid.Row>
                <Grid.Column width={11}>
                  <Form.Input
                    label="Supplier Part Number"
                    placeholder="497-12007-2-ND"
                    value={partNumberSupplier.supplierPartNumber || ""}
                    name="supplierPartNumber"
                    onChange={handleChange}
                  />
                  <Form.Input
                    label="Cost"
                    icon="dollar"
                    iconPosition="left"
                    required
                    focus
                    placeholder="$0.000"
                    value={partNumberSupplier.cost || 0}
                    name="cost"
                    onChange={handleChange}
                  />
                  <Form.Input label="Currency" placeholder="USD" value={partNumberSupplier.currency || ""} name="currency" onChange={handleChange} />
                  <Form.Input
                    label="Quantity Available"
                    placeholder="0"
                    value={partNumberSupplier.quantityAvailable || 0}
                    name="quantityAvailable"
                    onChange={handleChange}
                  />
                  <Form.Input
                    label="Min Order Quantity"
                    placeholder="0"
                    value={partNumberSupplier.minimumOrderQuantity || 0}
                    name="minimumOrderQuantity"
                    onChange={handleChange}
                  />
                  <Form.Input label="Packaging" placeholder="Tube" value={partNumberSupplier.packaging || ""} name="packaging" onChange={handleChange} />
                  <Form.Input
                    label="Factory Lead Time"
                    placeholder="30 weeks"
                    value={partNumberSupplier.factoryLoadTime || ""}
                    name="factoryLoadTime"
                    onChange={handleChange}
                  />
                  <Form.Input
                    label="Factory Stock Available"
                    placeholder="0"
                    value={partNumberSupplier.factoryStockAvailable || 0}
                    name="factoryStockAvailable"
                    onChange={handleChange}
                  />
                </Grid.Column>
                <Grid.Column width={5} style={{ textAlign: "center" }}>
                  {getDefaultProductShotImageFromPartNumberManufacturer(
                    partNumberSupplier.partNumberManufacturer,
                    "product productshot large",
                    (s) => s.imageType === ImageTypes.ProductShot && s.createdFromSupplierId === partNumberSupplier.supplierId
                  )}
                  <div style={{ marginTop: "15px" }}>
                    {partNumberSupplier.partNumberManufacturer &&
                      partNumberSupplier.partNumberManufacturer.datasheets &&
                      partNumberSupplier.partNumberManufacturer.datasheets.map((datasheet, key) => (
                        <Button
                          key={key}
                          size="mini"
                          icon="file pdf"
                          title="View Datasheet"
                          style={{ marginTop: "5px" }}
                          onClick={(e) => viewDatasheet(e, datasheet)}
                          label="View Datasheet"
                        />
                      ))}
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Form.Group className="celled">
              <Form.Field>
                <label>Id</label>
                {partNumberSupplier.partNumberManufacturerSupplierId}
              </Form.Field>
              <Form.Field>
                <label>Date Created</label>
                {getFormattedTime(partNumberSupplier.dateCreatedUtc)}
              </Form.Field>
              <Form.Field>
                <label>Stock Last Updated</label>
                {getFormattedTime(partNumberSupplier.stockLastUpdatedUtc)}
              </Form.Field>
            </Form.Group>

            {FormError(error)}
            
            <Button type="submit" primary style={{ marginTop: "10px" }} disabled={!isDirty}>
              <Icon name="save" />
              Save
            </Button>
            <Button type="button" title="Refresh this record from an integration API" onClick={(e) => refreshFromSupplier(e, partNumberSupplier)}>
              <Icon name="refresh" />
              Refresh from Supplier
            </Button>
            {partNumberSupplier.partNumberManufacturerSupplierId > 0 && (
              <Button type="button" title="Delete" onClick={(e) => confirmDeleteOpen(e, partNumberSupplier)}>
                <Icon name="delete" />
                Delete
              </Button>
            )}
          </Form>
        </Segment>
      </div>
    </div>
  );
}
