import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Icon } from "semantic-ui-react";
import { fetchApi } from "../../common/fetchApi";
import { Progress } from "reactstrap";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAuthToken } from "../../common/authentication";

export function AddDatasheet(props) {
  const [selectedFile, setSelectedFile] = useState("");
  const [loaded, setLoaded] = useState(0);
  const [fileIsOk, setFileIsOk] = useState(false);
	const filesInputRef = useRef();
	const navigate = useNavigate();

  const handleChange = (event) => {
    const files = event.target.files;
    if (maxSelectFile(event) && checkFileSize(event) && checkMimeType(event)) {
      setSelectedFile(files[0]);
      setFileIsOk(true);
    } else {
      setFileIsOk(false);
    }
  };

  const maxSelectFile = (event) => {
    const files = event.target.files;
    if (files.length > 1) {
      const error = "Only 1 image can be uploaded at a time!";
      resetForm();
      toast.error(error);
      return false;
    }
    return true;
  };

  const checkFileSize = (event) => {
    const files = event.target.files;
    const maxSize = 64 * 1024 * 1024;
    let error = "";
    for (let x = 0; x < files.length; x++) {
      if (files[x].size > maxSize) {
        error += `${files[x].name} is too large. Max 64MB.\n`;
      }
    }
    if (error !== "") {
      resetForm();
      toast.error(error);
      return false;
    }
    return true;
  };

  const checkMimeType = (event) => {
    const files = event.target.files;
    const types = ["application/pdf"];
    let error = "";
    for (let x = 0; x < files.length; x++) {
      if (types.every((type) => files[x].type !== type)) {
        error += `${files[x].name} is not a supported format '${files[x].type}'. Only PDF files are accepted.\n`;
      }
    }
    if (error !== "") {
      resetForm();
      toast.error(error);
      return false;
    }
    return true;
  };
	
	const resetForm = () => {
		setFileIsOk(false);
		filesInputRef.current.value = null;
		setLoaded(0);
	};

	const handleReturnToList = () => {
    navigate(-1);
  };

  const onFileUpload = () => {
    const formData = new FormData();
    formData.append("file", selectedFile, selectedFile.name);
    // first fetch some data using fetchApi, to leverage 401 token refresh
    fetchApi("api/authentication/identity").then((_) => {
      axios
        .request({
          method: "post",
          url: "api/datasheet/upload",
          data: formData,
          headers: { Authorization: `Bearer ${getAuthToken()}` },
          onUploadProgress: (p) => {
            const perc = (p.loaded / p.total) * 100;
						setLoaded(perc);
						if (perc >= 100) {
							toast.info("Processing...", { autoClose: false });
						}
          },
        })
        .then((data) => {
					toast.dismiss();
          toast.success("upload successful!");
					resetForm();
        })
        .catch((error) => {
					toast.dismiss();
					console.error('error', error);
          toast.error(`upload failed!`);
					resetForm();
        });
    });
  };

  return (
    <div>
      <h1>Add Datasheet</h1>
      <p>Search for existing datasheets, add new ones.</p>
			<Button onClick={handleReturnToList}>
        Return
      </Button>
      <Form>
				<Progress max="100" color="success" value={loaded} style={{ width: '50%', margin: '10px auto' }}>
          {Math.round(loaded, 2)}%
        </Progress>
        <div className="files" style={{ width: "50%", margin: "0 auto" }}>
          <Form.Input type="file" placeholder="datasheet.pdf" focus onChange={handleChange} name="file">
						<input ref={filesInputRef} />
					</Form.Input>
          <Icon hidden={!fileIsOk} name="check circle" color="green" size="large" />
          <Button onClick={onFileUpload}>
            <Icon name="upload" />
            Upload
          </Button>
        </div>
      </Form>
    </div>
  );
}
