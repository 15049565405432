import React, { useState }  from 'react';
import { Button, Form, Icon, Card } from "semantic-ui-react";
import { SubscriptionLevels, PaymentFrequency } from "../common/Types";
import { getUserAccount, isAuthenticated } from "../common/authentication";

export function SubscriptionOptions (props) {
  const [loading, setLoading] = useState(false);

  const getSubscriptionLevelDescription = (subscriptionLevel) => {
    switch (subscriptionLevel) {
      case SubscriptionLevels.Free:
        return (
          <div>
            <ul className="subscription-details">
              <li>Max 192 part bins</li>
              <li>Datasheets repository</li>
              <li>API integrations</li>
              <li>Design Tools</li>
              <li>Label Printing</li>
              <li>Electronic Bin Support</li>
            </ul>
          </div>
        );
      case SubscriptionLevels.Maker:
        return (
          <div>
            <ul className="subscription-details">
              <li>Max 1216 part bins</li>
              <li>Datasheets repository</li>
              <li>API integrations</li>
              <li>Design Tools</li>
              <li>Label Printing</li>
              <li>Electronic Bin Support</li>
              <li>Premium Pinouts</li>
              <li>Circuits repository</li>
            </ul>
          </div>
        );
      case SubscriptionLevels.Professional:
      default:
        return (
          <div>
            <ul className="subscription-details">
              <li>Unlimited bins</li>
              <li>Datasheets repository</li>
              <li>API integrations</li>
              <li>Design Tools</li>
              <li>Label Printing</li>
              <li>Electronic Bin Support</li>
              <li>Premium Pinouts</li>
              <li>Circuits repository</li>
              <li>BOM Management</li>
            </ul>
          </div>
        );
    }
  }

	const userAccount = getUserAccount();
  return (
    <div className="centered">
        <h1>Account Options</h1>
        <p className="centered">Choose your account level</p>
        <Form loading={loading} autoComplete="on">
          <Card.Group centered>
            <Card raised={userAccount.HasFreeSubscription()} className="subscription-option">
              <Card.Content>
                <Card.Header><h2>Beginner</h2></Card.Header>               
                <Card.Description>
                  {getSubscriptionLevelDescription(SubscriptionLevels.Free)}
                </Card.Description>
              </Card.Content>
              <Card.Content extra style={{height: '130px', display: 'flex'}}>
                <div style={{marginTop: 'auto', width: '100%'}}>
                  <h3 style={{paddingBottom: '10px'}}>FREE</h3>
                  <Button primary disabled={userAccount.HasFreeSubscription()} onClick={(e, t) => props.onSubmit(e, t, SubscriptionLevels.Free)}>
                    SELECT
                    <Icon name="arrow circle right" />
                  </Button>
                </div>
              </Card.Content>
            </Card>
            <Card raised={userAccount.HasMakerSubscription()} className="subscription-option">
              <Card.Content>
                <Card.Header><h2>Maker</h2></Card.Header>
                <Card.Description>
                  {getSubscriptionLevelDescription(SubscriptionLevels.Maker)}
                </Card.Description>
              </Card.Content>
              <Card.Content extra style={{height: '130px', display: 'flex'}}>
                <div style={{marginTop: 'auto', width: '100%'}}>
                  <h3>$5.00/mo</h3>
                  <div>or $57.00/year</div>
                  <Button primary disabled={userAccount.HasMakerSubscription()} onClick={(e, t) => props.onSubmit(e, t, SubscriptionLevels.Maker, PaymentFrequency.Annually)}>
                    SELECT
                    <Icon name="arrow circle right" />
                  </Button>
                </div>
              </Card.Content>
            </Card>
            <Card raised={userAccount.HasFreeSubscription()} className="subscription-option">
              <Card.Content>
                <Card.Header><h2>Professional</h2></Card.Header>
                <Card.Description>
                  {getSubscriptionLevelDescription(SubscriptionLevels.Professional)}
                </Card.Description>
              </Card.Content>
              <Card.Content extra style={{height: '130px', display: 'flex'}}>
                <div style={{marginTop: 'auto', width: '100%'}}>
                  <h3>$19.00/mo</h3>
                  <div>or $217.00/year</div>
                  <Button primary disabled={userAccount.HasProfessionalSubscription()} onClick={(e, t) => props.onSubmit(e, t, SubscriptionLevels.Professional, PaymentFrequency.Annually)}>
                    SELECT
                    <Icon name="arrow circle right" />
                  </Button>
                </div>
              </Card.Content>
            </Card>
          </Card.Group>
          <p style={{padding: '20px 0 20px 0'}}>Binner gives you feature-packed services across all subscription options.</p>
        </Form>
      </div>
  );

}
