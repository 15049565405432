import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "underscore";
import { Grid,Image,Input,Label,Button,Icon,Modal,Header,Form,Segment,TextArea,Dropdown } from "semantic-ui-react";
import { toast } from "react-toastify";
import axios from "axios";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import AceEditor from "react-ace";
import "brace/mode/text";
import "brace/theme/github";
import { ImageTypes, ImageSizes, DocumentTypes, GetTypeDropdown, GetTypeName } from "../../../common/Types";
import { fetchApi } from "../../../common/fetchApi";
import { formatNumber } from "../../../common/Utils";
import { getAuthToken } from "../../../common/authentication";
import { getResourceImageUrl } from "../../../common/resources";
import { getFriendlyElapsedTime, getTimeDifference, getFormattedTime } from "../../../common/datetime";
import { FormHeader } from "../../../components/FormHeader";
import "../Admin.css";

export function DatasheetsAdminEdit(props) {
  let params = useParams();
  let navigate = useNavigate();
  const { datasheetId } = params;
  const [loading, setLoading] = useState(false);
  const [datasheetLoaded, setDatasheetLoaded] = useState(false);
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [textModalOpen, setTextModalOpen] = useState(false);
  const [filters, setFilters] = useState([]);
  const [selectedImage, setSelectedImage] = useState({});
  const [selectedText, setSelectedText] = useState({ pageNumber: 0, text: "", target: null, targetOptions: {} });
  const [exportImage, setExportImage] = useState({
    imageType: ImageTypes.Pinout,
    context: "",
  });
  const [selectedImageIsDirty, setSelectedImageIsDirty] = useState(false);

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const editorRef = useRef(null);
  const [crop, setCrop] = useState({ unit: "%", width: 30 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [manufacturerOptions, setManufacturerOptions] = useState([]);
  const [keywordOptions, setKeywordOptions] = useState([]);
  const [partNumberOptions, setPartNumberOptions] = useState([]);
  const [partTypes, setPartTypes] = useState([]);
  const [loadingPartTypes, setLoadingPartTypes] = useState(false);
  const [datasheet, setDatasheet] = useState({
    basePartNumber: "",
    title: "",
    shortDescription: "",
    description: "",
    originalUrl: "",
    productUrl: "",
    manufacturer: {
      manufacturerId: 0
    },
    keywords: "",
    revisionNumber: "",
    originalFilename: "",
    pageCount: 0,
    author: "",
    creator: "",
    producer: "",
    subject: "",
    datasheetRevisionDateUtc: "",
    creationDate: "",
    modifiedDate: "",
    imageMetadata: [],
    textMetadata: [],
    bookmarks: [],
    keywordAssignments: [],
    partNumberAssignments: [],
  });
  const imageTypeOptions = GetTypeDropdown(ImageTypes);
  const documentTypeOptions = GetTypeDropdown(DocumentTypes);
  const imageTypeKeys = Object.keys(ImageTypes);
  const imageTypes = [
    {
      key: 1,
      value: ImageTypes.Unknown,
      text: imageTypeKeys[ImageTypes.Unknown],
    },
    {
      key: 2,
      value: ImageTypes.FullPage,
      text: imageTypeKeys[ImageTypes.FullPage],
    },
    {
      key: 3,
      value: ImageTypes.ProductShot,
      text: imageTypeKeys[ImageTypes.ProductShot],
    },
    {
      key: 4,
      value: ImageTypes.Pinout,
      text: imageTypeKeys[ImageTypes.Pinout],
    },
    {
      key: 5,
      value: ImageTypes.Schematic,
      text: imageTypeKeys[ImageTypes.Schematic],
    },
    {
      key: 6,
      value: ImageTypes.BlockDiagram,
      text: imageTypeKeys[ImageTypes.BlockDiagram],
    },
    {
      key: 7,
      value: ImageTypes.Characteristics,
      text: imageTypeKeys[ImageTypes.Characteristics],
    },
    {
      key: 8,
      value: ImageTypes.Circuit,
      text: imageTypeKeys[ImageTypes.Circuit],
    },
    {
      key: 9,
      value: ImageTypes.Layout,
      text: imageTypeKeys[ImageTypes.Layout],
    },
    {
      key: 10,
      value: ImageTypes.Dimensions,
      text: imageTypeKeys[ImageTypes.Dimensions],
    },
  ];

  const fetchPartTypes = () => {
    setLoadingPartTypes(true);
    fetchApi("api/partType/list").then((response) => {
      const { data } = response;
      const partTypes = _.sortBy(
        data.map((item) => {
          return {
            key: item.partTypeId,
            value: item.partTypeId,
            text: item.name,
          };
        }),
        "text"
      );
      setLoadingPartTypes(false);
      setPartTypes(partTypes);
    });
  };

  const fetchDatasheet = async () => {
    setLoading(true);
    await fetchApi(`api/datasheet?datasheetId=${datasheetId}`).then((response) => {
      const { data } = response;
      setDatasheetLoaded(true);
      setDatasheet(data);
      setLoading(false);
      const pages = [];
      for (let i = 0; i < data.pageCount; i++) {
        pages.push({
          key: i + 1,
          value: i,
          text: `Page ${i + 1}`,
        });
      }
      setPageNumbers(pages);

      // for tag-style dropdowns, we need to prepopulate its options or it won't fill existing values correctly
      const newKeywordOptions = data.keywordAssignments.map((k) => ({ key: k.name, text: k.name, value: k.name }));
      setKeywordOptions(newKeywordOptions);

      const newPartNumberOptions = data.partNumberManufacturerAssignments.map((k) => ({ key: k.name, text: k.name, value: k.name }));
      setPartNumberOptions(newPartNumberOptions);
    });
  };

  const fetchManufacturers = async () => {
    await fetchApi(`api/manufacturer/list?results=1000`).then((response) => {
      const { data } = response;
      let manufacturers = [];
      for (let i = 0; i < data.length; i++) {
        manufacturers.push({
          key: i + 1,
          value: data[i].manufacturerId,
          text: data[i].name,
          flag: data[i].country,
        });
      }
      setManufacturerOptions(manufacturers);
    });
  };

  useEffect(() => {
    fetchManufacturers();
    fetchPartTypes();
    fetchDatasheet();
    return () => {};
  }, []);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }
    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";
    ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width * scaleX, crop.height * scaleY);
  }, [completedCrop, exportImage, setExportImage]);

  const handleChange = (e, control) => {
    e.preventDefault();
    e.stopPropagation();
    setDatasheet((datasheet) => ({
      ...datasheet,
      [control.name]: control.value,
    }));
  };

  const handleManufacturerChange = (e, control) => {
    e.preventDefault();
    e.stopPropagation();
    setDatasheet((datasheet) => ({
      ...datasheet,
      manufacturer: {
        ...datasheet.manufacturer,
        [control.name]: control.value,
      },
    }));
  };

  const copyText = (e, control) => {
    // it's important we throttle/delay the calls to getSelectedText() to get correct results.
    setTimeout(() => {
      let text = editorRef.current.editor.getSelectedText();
      navigator.clipboard.writeText(text);
      if (selectedText.targetOptions && selectedText.targetOptions.trimLineBreaks) {
        // replace line breaks and extra whitespace
        text = text.replace(/[\r\n]+/g, " ");
        text = text.split(/\s+/).join(" ");
      }
      // also copy it to the target
      if (!!selectedText.target) {
        setDatasheet((datasheet) => ({
          ...datasheet,
          [selectedText.target]: text,
        }));
      }
    }, 500);
  };

  const handleTextModalChange = (e, control) => {
    e.preventDefault();
    e.stopPropagation();

    switch (control.name) {
      case "pageNumber":
        setSelectedText((selectedText) => ({
          ...selectedText,
          text: datasheet.textMetadata[control.value].text,
          [control.name]: control.value,
        }));
        break;
      default:
        setSelectedText((selectedText) => ({
          ...selectedText,
          [control.name]: control.value,
        }));
        break;
    }
  };

  const handleImageModalChange = (e, control) => {
    e.preventDefault();
    e.stopPropagation();

    setSelectedImage((selectedImage) => ({
      ...selectedImage,
      [control.name]: control.value,
    }));
    setSelectedImageIsDirty(true);
  };

  const handleExportChange = (e, control) => {
    e.preventDefault();
    e.stopPropagation();

    setExportImage((exportImage) => ({
      ...exportImage,
      [control.name]: control.value,
    }));
  };

  const getImage = (datasheet, image) => {
    const url = `https://${datasheet.resourceSourceUrl}/${datasheet.resourcePath}_${image.sequenceId}.png`;
    return url;
  };

  const reduceWhitespace = (e, control) => {
    e.target.value = e.target.value.split(/\s+/).join(" ");
    setDatasheet((datasheet) => ({
      ...datasheet,
      [e.target.name]: e.target.value,
    }));
  };

  const handleVisitLink = (e, url) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(`https://${url}`, "_blank");
  };

  const openImageModal = (e, image) => {
    e.preventDefault();
    e.stopPropagation();
    setImageModalOpen(true);
    setSelectedImage(image);
  };

  const handleTextModalClose = (e) => {
    setTextModalOpen(false);
  };

  const handleImageModalClose = (e) => {
    setImageModalOpen(false);
    // if there are changes to the record, save it

    if (selectedImageIsDirty) {
      fetchApi("api/datasheet/image", { method: "PUT", body: selectedImage }).then((response) => {
        const { data } = response;
        const updatedDatasheet = datasheet;
        for (let i = 0; i < datasheet.imageMetadata.length; i++) {
          if (datasheet.imageMetadata[i].datasheetImageMetadataId === data.datasheetImageMetadataId) {
            datasheet.imageMetadata[i] = data;
            break;
          }
        }
        setDatasheet(updatedDatasheet);
        setSelectedImageIsDirty(false);
      });
    }
    // clear the crop area
    setCompletedCrop(null);
    setCrop(null);
  };

  const saveChanges = (e) => {
    toast.info('Saving...', { autoClose: false });
    setLoading(true);
    fetchApi("api/datasheet", { method: "PUT", body: datasheet })
      .then((response) => {
        const { data } = response;
        toast.dismiss();
        setDatasheet(data);
        setLoading(false);
        toast.success("Datasheet saved.");
        navigate(-1);
      })
      .catch((_) => {
        toast.dismiss();
        setLoading(false);
        toast.error("Failed to save Datasheet!");
      });
  };

  const openTextModal = (e, targetName, targetOptions = {}) => {
    setTextModalOpen(true);
    setSelectedText({ pageNumber: 0, text: datasheet.textMetadata[0].text, target: targetName, targetOptions });
  };

  const handleExportImage = (canvas, crop) => {
    if (!crop || !canvas) {
      return;
    }

    // requires access-control-allow-origin: * from CDN
    canvas.toBlob(
      (blob) => {
        // send blob to server
        const formData = new FormData();
        formData.append("file", blob, "export.png");
        formData.append("resourceId", datasheet.resourceId);
        formData.append("imageType", exportImage.imageType);
        formData.append("context", exportImage.context);
        fetchApi("api/authentication/identity").then((_) => {
          axios
            .request({
              method: "post",
              url: "api/datasheet/exportimage",
              data: formData,
              headers: { Authorization: `Bearer ${getAuthToken()}` },
            })
            .then((response) => {
              setDatasheet(response.data);
              toast.dismiss();
              toast.success("upload successful.");
              setImageModalOpen(false);
            })
            .catch((error) => {
              toast.dismiss();
              toast.error(`upload failed!`);
            });
        });
      },
      "image/png",
      1
    );
  };

  const addNewKeywordOption = (event, { value }) => {
    setKeywordOptions((prevOptions) => [{ text: value, value }, ...prevOptions]);
  };

  const addNewPartNumberOption = (event, { value }) => {
    setPartNumberOptions((prevOptions) => [{ text: value, value }, ...prevOptions]);
  };

  const handleKeywordChange = (e, { value }) => {
    // value = []
    let currentKeywordAssignments = datasheet.keywordAssignments;
    // handle additions
    for (let i = 0; i < value.length; i++) {
      // for each value that doesn't exist in the array, add it
      if (!_.find(currentKeywordAssignments, (x) => x.name === value[i])) {
        currentKeywordAssignments.push({ keywordId: 0, name: value[i] });
      }
    }
    // handle deletions
    for (let i = 0; i < currentKeywordAssignments.length; i++) {
      if (!_.find(value, (x) => x === currentKeywordAssignments[i].name)) {
        currentKeywordAssignments.splice(i, 1);
      }
    }

    // update the datasheet keywords with the new assignments
    setDatasheet((datasheet) => ({
      ...datasheet,
      keywordAssignments: currentKeywordAssignments,
    }));
  };

  const handlePartNumberChange = (e, { value }) => {
    // value = []
    let currentPartNumberAssignments = datasheet.partNumberAssignments;
    // handle additions
    for (let i = 0; i < value.length; i++) {
      // for each value that doesn't exist in the array, add it
      if (!_.find(currentPartNumberAssignments, (x) => x.name === value[i])) {
        currentPartNumberAssignments.push({ partNumberId: 0, name: value[i] });
      }
    }
    // handle deletions
    for (let i = 0; i < currentPartNumberAssignments.length; i++) {
      if (!_.find(value, (x) => x === currentPartNumberAssignments[i].name)) {
        currentPartNumberAssignments.splice(i, 1);
      }
    }

    // update the datasheet part numbers with the new assignments
    setDatasheet((datasheet) => ({
      ...datasheet,
      partNumberAssignments: currentPartNumberAssignments,
    }));
  };

  const getCoverImageUrl = (datasheet) => {
    const image = _.find(datasheet.imageMetadata, (s) => s.imageType === ImageTypes.FullPage);
    if (image) {
      const url = `https://${datasheet.resourceSourceUrl}/${datasheet.resourcePath}_${image.sequenceId}.png`;
      return url;
    }
    return `https://${datasheet.resourceSourceUrl}/datasheetcover.png`;
  };

  const setFilter = (e, control) => {
    // control.value is an array of filter values
    setFilters(control.value)
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  let filteredImages = [...datasheet.imageMetadata];
  if (filters.length > 0) {
    filteredImages = filteredImages.filter(i => filters.includes(i.imageType));
  }

  return (
    <div className="admin-container">
      <FormHeader name="Datasheet Admin" to="..">
        Edit Datasheet
      </FormHeader>
      <Segment secondary loading={loading}>
        <Form style={{ minHeight: "300px" }}>
          <Segment loading={loading}>
            <Grid celled>
              <Grid.Row>
                <Grid.Column width={11}>
                  <Form.Input
                    label="Base Part Number"
                    width={3}
                    value={datasheet.basePartNumber || ""}
                    name="basePartNumber"
                    onChange={handleChange}
                    placeholder="LM555"
                  />
                  <Form.Dropdown
                    placeholder="Datasheet"
                    label="Document Type"
                    selection
                    value={datasheet.documentType}
                    name="documentType"
                    onChange={handleChange}
                    options={documentTypeOptions}
                  />
                  <Form.Input label="Title" value={datasheet.title || ""} name="title" onChange={handleChange} placeholder="Datasheet title" />
                  <Form.Input
                    label="Short Description"
                    value={datasheet.shortDescription || ""}
                    name="shortDescription"
                    onChange={handleChange}
                    onBlur={reduceWhitespace}
                    placeholder="Enter a short once sentence description"
                  >
                    <input />
                    <Button
                      onClick={(e) => openTextModal(e, "shortDescription", { trimLineBreaks: true })}
                      disabled={!datasheet.textMetadata || datasheet.textMetadata.length === 0}
                    >
                      Set
                    </Button>
                  </Form.Input>
                  <Form.Group>
                    <Form.Field style={{ width: "100%" }}>
                      <label>Description</label>
                      <TextArea
                        value={datasheet.description || ""}
                        name="description"
                        style={{ minHeight: "120px" }}
                        placeholder="Enter a paragraph describing the part"
                        onChange={handleChange}
                        onBlur={reduceWhitespace}
                      />
                    </Form.Field>
                    <Button
                      onClick={(e) => openTextModal(e, "description", { trimLineBreaks: true })}
                      disabled={!datasheet.textMetadata || datasheet.textMetadata.length === 0}
                    >
                      Set
                    </Button>
                  </Form.Group>

                  <Form.Group className="celled">
                    <Form.Field>
                      <label>Date Created</label>
                      <div>
                        {datasheet.dateCreatedUtc !== null
                          ? getFriendlyElapsedTime(getTimeDifference(Date.now(), Date.parse(datasheet.dateCreatedUtc)), true)
                          : '(never)'}
                      </div>
                      <span className="small">{getFormattedTime(datasheet.dateCreatedUtc)}</span>
                    </Form.Field>
                    <Form.Field>
                      <label>Date Modified</label>
                      <div>
                        {datasheet.dateModifiedUtc !== null
                          ? getFriendlyElapsedTime(getTimeDifference(Date.now(), Date.parse(datasheet.dateModifiedUtc)), true)
                          : '(never)'}
                      </div>
                      <span className="small">{getFormattedTime(datasheet.dateModifiedUtc)}</span>
                    </Form.Field>
                    <Form.Field>
                      <label>Date Pruned</label>
                      <div>
                        {datasheet.datePrunedUtc !== null
                          ? getFriendlyElapsedTime(getTimeDifference(Date.now(), Date.parse(datasheet.datePrunedUtc)), true)
                          : '(never)'}
                      </div>
                      <span className="small">{getFormattedTime(datasheet.datePrunedUtc)}</span>
                    </Form.Field>
                    <Form.Field>
                      <label>Images</label>
                      <div>
                        {formatNumber(datasheet.imageCount || 0)}
                      </div>
                    </Form.Field>
                    <Form.Field>
                      <label>Pages</label>
                      <div>
                        {formatNumber(datasheet.pageCount || 0)}
                      </div>
                    </Form.Field>
                  </Form.Group>

                  <Button primary onClick={() => saveChanges()}>
                    <Icon name="save" />
                    Save
                  </Button>
                </Grid.Column>
                <Grid.Column width={4} className="centered">
                  {datasheet.imageMetadata && datasheet.imageMetadata.length > 0 
                  ? (<Image style={{cursor: 'pointer'}} src={getCoverImageUrl(datasheet)} />)
                  : (<Image src="/image/datasheet.png" />)
                  }
                  <div>
                    ({datasheet.imageMetadata ? datasheet.imageMetadata.length : 0}) exported images
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>

          <Segment color="blue">
            <Header dividing as="h3">
              Basic
            </Header>
            <Form.Field width={10}>
              <label>Original Url</label>
              <Input
                value={datasheet.originalUrl || ""}
                name="originalUrl"
                onChange={handleChange}
                className="labeled"
                placeholder="www.ti.com/lit/ds/symlink/lm2904-n.pdf"
              >
                <Label>http://</Label>
                <input />
                <Button onClick={(e) => handleVisitLink(e, datasheet.originalUrl)} disabled={!datasheet.originalUrl || datasheet.originalUrl.length === 0}>
                  View
                </Button>
              </Input>
            </Form.Field>
            <Form.Field width={10}>
              <label>Product Url</label>
              <Input value={datasheet.productUrl || ""} name="productUrl" onChange={handleChange} className="labeled" placeholder="www.ti.com/products/lm2904">
                <Label>http://</Label>
                <input />
                <Button onClick={(e) => handleVisitLink(e, datasheet.productUrl)} disabled={!datasheet.productUrl || datasheet.productUrl.length === 0}>
                  View
                </Button>
              </Input>
            </Form.Field>
            <Form.Dropdown
              placeholder="Texas Instruments"
              label="Manufacturer"
              fluid
              search
              selection
              value={datasheet.manufacturer.manufacturerId}
              name="manufacturerId"
              onChange={handleManufacturerChange}
              options={manufacturerOptions}
            />
            <Form.Dropdown
              placeholder="IC"
              label="Part Type"
              loading={loadingPartTypes}
              selection
              value={datasheet.partTypeId || 0}
              name="partTypeId"
              onChange={handleChange}
              options={partTypes}
            />
            <Grid>
              <Grid.Column style={{width: '85%'}}>
                <Form.Dropdown
                  placeholder="Enter some keywords..."
                  label="Keywords"
                  fluid
                  multiple
                  search
                  selection
                  allowAdditions
                  clearable
                  value={datasheet.keywordAssignments && datasheet.keywordAssignments.map((i, k) => i.name)}
                  name="keywordId"
                  onChange={handleKeywordChange}
                  options={keywordOptions}
                  onAddItem={addNewKeywordOption}
                />
              </Grid.Column>
              <Grid.Column>
                <Button style={{marginTop: '27px'}} onClick={(e) => openTextModal(e, null, { trimLineBreaks: true })} disabled={!datasheet.textMetadata || datasheet.textMetadata.length === 0}>
                  View
                </Button>
              </Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column style={{width: '85%'}}>
                <Form.Dropdown
                  placeholder="Enter some part numbers..."
                  label="Part Numbers"
                  fluid
                  multiple
                  search
                  selection
                  allowAdditions
                  clearable
                  value={(datasheet.partNumberManufacturerAssignments && datasheet.partNumberManufacturerAssignments.map((i, k) => i.name)) || []}
                  name="partNumberId"
                  onChange={handlePartNumberChange}
                  options={partNumberOptions}
                  onAddItem={addNewPartNumberOption}
                />
              </Grid.Column>
              <Grid.Column>
                <Button style={{marginTop: '27px'}} onClick={(e) => openTextModal(e, null, { trimLineBreaks: true })} disabled={!datasheet.textMetadata || datasheet.textMetadata.length === 0}>
                  View
                </Button>
              </Grid.Column>
            </Grid>
          </Segment>

          <Segment color="green">
            <Header dividing as="h3">
              PDF Metadata
            </Header>
            <Form.Input label="PDF Keywords" value={datasheet.keywords || ""} name="keywords" onChange={handleChange} />
            <Form.Input
              label="OriginalFilename"
              value={datasheet.originalFilename || ""}
              name="originalFilename"
              onChange={handleChange}
              placeholder="LM555.pdf"
            />
            <Form.Input label="PDF PageCount" value={datasheet.pageCount || 0} name="pageCount" onChange={handleChange} />
            <Form.Input label="Author" value={datasheet.author || ""} name="author" onChange={handleChange} />
            <Form.Input label="Creator" value={datasheet.creator || ""} name="creator" onChange={handleChange} />
            <Form.Input label="Producer" value={datasheet.producer || ""} name="producer" onChange={handleChange} />
            <Form.Input label="Subject" value={datasheet.subject || ""} name="subject" onChange={handleChange} />
            <Form.Input label="Revision Number" value={datasheet.revisionNumber || ""} name="revisionNumber" onChange={handleChange} />
            <Form.Input
              label="Datasheet Revision Date"
              value={datasheet.datasheetRevisionDateUtc || ""}
              name="datasheetRevisionDateUtc"
              onChange={handleChange}
            />
            <Form.Input label="Creation Date" value={datasheet.creationDate || ""} name="creationDate" onChange={handleChange} />
            <Form.Input label="Modified Date" value={datasheet.modifiedDate || ""} name="modifiedDate" onChange={handleChange} />

          </Segment>

          <Segment color="red">
            <Header dividing as="h3">
              Exported Images ({datasheet.imageCount})
            </Header>
            <Segment>
              <div style={{verticalAlign: 'middle', height: '45px'}}>
                <div style={{float: 'left'}}>
                  <Icon name='filter' />
                  <Dropdown name="filters" value={filters} options={imageTypeOptions} multiple selection text="Filter" onChange={setFilter} />
                </div>
                <Button hidden={filters.length === 0} style={{marginLeft: '5px'}} onClick={(e) => { e.preventDefault(); setFilters([]); }} size="tiny">Clear</Button>
              </div>
            </Segment>
            <Image.Group size="small">
              {filteredImages.map((image, key) => (
                <div key={key} style={{ border: "1px solid #ddd", height: "250px" }} className="ui tinyheader image centered">
                  <div style={{width: "100%", height: "15px", backgroundColor: '#eeeeee'}}>{image.context}</div>
                  <div style={{height: "210px"}}>
                    <a href="#null" onClick={(e) => openImageModal(e, image)}>
                      <Image src={getImage(datasheet, image)} style={{ padding: "10px", maxHeight: '185px' }} />
                    </a>
                  </div>
                  <div style={{width: '100%', backgroundColor: '#ddcccc', textAlign: 'center', fontWeight: '600'}}>{GetTypeName(ImageTypes, image.imageType)}</div>
                  <div style={{width: '100%', backgroundColor: '#ccccdd', textAlign: 'center'}}>{GetTypeName(ImageSizes, image.imageSize)}</div>
                </div>
              ))}
            </Image.Group>
          </Segment>
        </Form>
      </Segment>

      {/* Text Metadata Modal */}

      <Modal centered open={textModalOpen} onClose={handleTextModalClose}>
        <Modal.Header>Text Metadata</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <Form>
              <Form.Dropdown
                label="Page"
                selection
                value={selectedText.pageNumber || 0}
                name="pageNumber"
                options={pageNumbers}
                onChange={handleTextModalChange}
              />
              {/* https://github.com/securingsincity/react-ace/blob/master/docs/Ace.md */}
              <AceEditor
                ref={editorRef}
                width="100%"
                mode="text"
                theme="github"
                name="text"
                value={selectedText.text}
                onCopy={copyText}
                onSelectionChange={copyText}
                editorProps={{
                  $blockScrolling: true,
                }}
                setOptions={{
                  showInvisibles: true,
                  showPrintMargin: false,
                  tabSize: 2,
                  readOnly: true,
                }}
              />
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>

      {/* Image Metadata Modal */}

      <Modal centered open={imageModalOpen} onClose={handleImageModalClose}>
        <Modal.Header>Image Metadata</Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <Form>
              <Segment color="blue">
                <Form.Input
                  icon="check circle"
                  iconPosition="left"
                  label="Context"
                  value={selectedImage.context || ""}
                  name="context"
                  onChange={handleImageModalChange}
                  placeholder="Figure 1. Description"
                />
                <Form.Group>
                  <Form.Dropdown
                    label="Image Type"
                    selection
                    value={selectedImage.imageType || ImageTypes.Unknown}
                    name="imageType"
                    options={imageTypes}
                    onChange={handleImageModalChange}
                  />
                  <Form.Input
                    label="Part Number"
                    width={3}
                    value={selectedImage.partNumberName || ""}
                    name="partNumberName"
                    onChange={handleImageModalChange}
                    placeholder="LM555"
                  />
                  <Form.Input
                    label="Package Name"
                    width={3}
                    value={selectedImage.packageName || ""}
                    name="packageName"
                    onChange={handleImageModalChange}
                    placeholder="DIP8"
                  />
                  <Form.Input
                    label="Page Number"
                    width={3}
                    value={selectedImage.pageNumber || ""}
                    name="pageNumber"
                    onChange={handleImageModalChange}
                    placeholder="1"
                  />
                </Form.Group>
                <div className="ui input" style={{ width: "49%", marginRight: "20px" }}>
                  <Input
                    label={{ content: "Above" }}
                    labelPosition="left corner"
                    style={{ width: "100%" }}
                    value={selectedImage.aboveContext || ""}
                    name="aboveContext"
                  />
                </div>
                <div className="ui input" style={{ width: "49%" }}>
                  <Input
                    label={{ content: "Below" }}
                    labelPosition="right corner"
                    style={{ width: "100%" }}
                    value={selectedImage.belowContext || ""}
                    name="belowContext"
                  />
                </div>
                <Form.Group className="celled" style={{marginTop: "10px", marginLeft: "5px"}}>
                  <Form.Field>
                      <label>Dimensions</label>
                      {selectedImage.width}W x {selectedImage.height}H
                  </Form.Field>
                  <Form.Field>
                      <label>Dpi</label>
                      {selectedImage.dpiX}X {selectedImage.dpiY}Y
                  </Form.Field>
                  <Form.Field>
                      <label>Image Size</label>
                      {GetTypeName(ImageSizes, selectedImage.imageSize)}
                  </Form.Field>
                  <Form.Field>
                      <label>Crc32</label>
                      {selectedImage.crc32}
                  </Form.Field>
                  <Form.Field>
                      <label>Sequence Id</label>
                      {selectedImage.sequenceId}
                  </Form.Field>
                  <Form.Field>
                      <label>Date Created</label>
                      {getFormattedTime(selectedImage.dateCreatedUtc)}
                  </Form.Field>
                  <Form.Field>
                      <label>Date Modified</label>
                      {getFormattedTime(selectedImage.dateModifiedUtc)}
                  </Form.Field>
                </Form.Group>
              </Segment>
            </Form>
            <Segment color="red">
              <Grid divided>
                <Grid.Column style={{ minWidth: "60%" }}>
                  <ReactCrop
                    src={getImage(datasheet, selectedImage)}
                    crossorigin="anonymous"
                    className="centered"
                    onImageLoaded={onLoad}
                    crop={crop}
                    onChange={(c) => setCrop(c)}
                    onComplete={(c) => setCompletedCrop(c)}
                  />
                </Grid.Column>
                <Grid.Column hidden={completedCrop && completedCrop.width === 0} style={{ width: "40%" }}>
                  <Segment secondary raised>
                    <Grid>
                      <Grid.Row centered>
                        <canvas
                          ref={previewCanvasRef}
                          // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                          style={{
                            width: Math.round(completedCrop?.width ?? 0),
                            height: Math.round(completedCrop?.height ?? 0),
                            marginLeft: "20px",
                            outline: "2px dashed #999",
                            outlineOffset: "2px",
                          }}
                        />
                      </Grid.Row>
                      <Grid.Row centered>
                        <Form>
                          <Form.Dropdown
                            selection
                            value={exportImage.imageType || ImageTypes.Pinout}
                            name="imageType"
                            options={imageTypes}
                            onChange={handleExportChange}
                          />
                          <Form.Input value={exportImage.context || ""} name="context" onChange={handleExportChange} placeholder="Figure 1. Description" />
                          <Button
                            style={{ marginLeft: "30px" }}
                            disabled={!completedCrop?.width || !completedCrop?.height}
                            onClick={() => handleExportImage(previewCanvasRef.current, completedCrop)}
                          >
                            <Icon name="share" />
                            Export
                          </Button>
                        </Form>
                      </Grid.Row>
                    </Grid>
                  </Segment>
                </Grid.Column>
              </Grid>
            </Segment>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={handleImageModalClose}>Close</Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
}
