import React, { useState, useEffect, useRef, useReducer } from "react";
import { Link, useNavigate } from "react-router-dom";
import _ from "underscore";
import { Table, Form, Segment, Button, Icon, Input, Popup, Confirm, Breadcrumb, TextArea, Modal } from "semantic-ui-react";
import { fetchApi } from "../../../common/fetchApi";
import { Sources, GetTypeName } from "../../../common/Types";
import { InifiniteScrollTable } from "../../../components/InfiniteScrollTable";
import { getDefaultProductShotImageFromPartNumber } from "../../../common/resources";
import { getFriendlyElapsedTime, getTimeDifference, getFormattedTime, getBasicElapsed } from "../../../common/datetime";
import { FormHeader } from "../../../components/FormHeader";
import "../Admin.css";

export function PartNumbers(props) {
  const maxResults = 20;
  const defaultAddPartNumber = {
    name: "",
    serializedDataType: "",
    serializedData: ""
  };
  const [loading, setLoading] = useState(true);
  const [loadingPartNumbersQueue, setLoadingPartNumbersQueue] = useState(true);
  const [loadingPartTypes, setLoadingPartTypes] = useState(true);
  const [loadingAddPartNumber, setLoadingAddPartNumber] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const partNumbersDataRef = useRef([]);
  const [addVisible, setAddVisible] = useState(false);
  const [confirmDeleteIsOpen, setConfirmDeleteIsOpen] = useState(false);
  const [deleteSelectedItem, setDeleteSelectedItem] = useState(null);
  const [deleteQueueSelectedItem, setDeleteQueueSelectedItem] = useState(null);
  const [confirmDeleteQueueIsOpen, setConfirmDeleteQueueIsOpen] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [selectedPartsCount, setSelectedPartsCount] = useState(null);
  const [addPartNumber, setAddPartNumber] = useState(defaultAddPartNumber);
  const [partTypes, setPartTypes] = useState([]);
  const [column, setColumn] = useState(null);
  const [direction, setDirection] = useState(null);
  const [previewApiResponseModalOpen, setPreviewApiResponseModalOpen] = useState(false);
  const [partNumbersQueue, setPartNumbersQueue] = useState([]);
  const navigate = useNavigate();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const selectAllRef = useRef();
  const selectNewPartsRef = useRef();

  useEffect(() => {
    fetchPartTypes().then(() => {
      fetchPartNumbers(currentPage);
      fetchPartNumbersQueue();
    });

    function fetchPartNumbers(page = 1) {
      if (hasMoreData) {
        setLoading(true);
        fetchApi(`api/partnumber/list?page=${page}&results=${maxResults}`).then((response) => {
          const { data } = response;
          if (data && data.length > 0) {
            // update the page of data, as long as its not already in the data
            let newData = [...partNumbersDataRef.current];
            for (let i = 0; i < data.length; i++) {
              const index = newData.findIndex((x) => x.partNumberId === data[i].partNumberId);
              if (index === -1) {
                newData.push(data[i]);
              }
            }
            partNumbersDataRef.current = newData;
          }
          if (data.length < maxResults) {
            // no more data, received back 0 or less than maxResults
            setHasMoreData(false);
          }
          setLoading(false);
        });
      }
    }
    function fetchPartTypes() {
      setLoadingPartTypes(true);
      return fetchApi("api/partType/list").then((response) => {
        const { data } = response;
        const partTypes = _.sortBy(
          data.map((item) => {
            return {
              key: item.partTypeId,
              value: item.partTypeId,
              text: item.name
            };
          }),
          "text"
        );
        setPartTypes(partTypes);
        setLoadingPartTypes(false);
      });
    }
  }, [currentPage, hasMoreData]);

  useEffect(() => {
    if (getSelectedParts().length > 0)
      setSelectedPartsCount(getSelectedParts().length);
  }, [previewApiResponseModalOpen]);

  const fetchNextPage = () => {
    if (hasMoreData) setCurrentPage(currentPage + 1);
  };

  const handleSort = (clickedColumn) => () => {
    if (column !== clickedColumn) {
      setColumn(clickedColumn);
      partNumbersDataRef.current = _.sortBy(partNumbersDataRef.current, [clickedColumn]);
      setDirection("ascending");
    } else {
      partNumbersDataRef.current = partNumbersDataRef.current.reverse();
      setDirection(direction === "ascending" ? "descending" : "ascending");
    }
  };

  const queuePartNumber = (e, addPartNumberRequest) => {
    setLoading(true);
    const request = {
      name: addPartNumberRequest.name,
      description: addPartNumberRequest.description,
      serializedDataType: addPartNumberRequest.serializedDataType,
      serializedData: addPartNumberRequest.serializedData // json data
    };
    fetchApi(`api/crawler/partNumber`, {
      method: "POST",
      body: JSON.stringify(request)
    }).then(() => {
      setLoading(false);
      setAddVisible(false);
      refreshClean();
    });
  };

  const fetchPartNumbersQueue = () => {
    setLoadingPartNumbersQueue(true);
    fetchApi(`api/crawler/partNumber?page=1&results=10`, {
      method: "GET",
    }).then((response) => {
      const { data } = response;
      setPartNumbersQueue(data);
      setLoadingPartNumbersQueue(false);
    });
  };

  const refreshClean = () => {
    // refresh
    partNumbersDataRef.current = [];
    setApiResponse({});
    setAddPartNumber(defaultAddPartNumber);
    setSelectedPartsCount(0);
    setHasMoreData(true);
    setCurrentPage(1);
  };

  const deletePartNumber = (e, partNumber) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    fetchApi(`api/partnumber`, {
      method: "DELETE",
      body: partNumber.partNumberId
    }).then(() => {
      const newPartNumbersData = _.filter(partNumbersDataRef.current, (item) => item.partNumberId !== partNumber.partNumberId);
      partNumbersDataRef.current = [...newPartNumbersData];
      setLoading(false);
      setConfirmDeleteIsOpen(false);
    });
  };

  const deletePartNumberQueue = (e, partNumber) => {
    e.preventDefault();
    e.stopPropagation();
    setLoadingPartNumbersQueue(true);
    fetchApi(`api/crawler/partnumber`, {
      method: "DELETE",
      body: partNumber.partNumberId
    }).then(() => {
      const newPartNumbersData = _.filter(partNumbersQueue, (item) => item.partNumberId !== partNumber.partNumberId);
      setPartNumbersQueue([...newPartNumbersData]);
      setLoadingPartNumbersQueue(false);
      setConfirmDeleteQueueIsOpen(false);
    });
  };

  const requeuePartNumber = (e, partNumber) => {
    e.preventDefault();
    e.stopPropagation();
    setLoadingPartNumbersQueue(true);
    // only dateQueuedUtc=null is required
    const request = {
      ...partNumber,
      dateQueuedForProcessingUtc: null,
      processingElapsed: null,
      dateProcessedUtc: null,
      partNumberManufacturersCreated: 0,
    };
    fetchApi(`api/crawler/partNumber`, {
      method: "PUT",
      body: JSON.stringify(request)
    }).then(() => {
      setLoadingPartNumbersQueue(false);
    });
  };

  const handleChange = (e, control) => {
    addPartNumber[control.name] = control.value;
    setAddPartNumber({ ...addPartNumber });
  };

  const handleShowAdd = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAddVisible(!addVisible);
  };

  const openPartNumber = (e, partNumber) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`${partNumber.partNumberId}`);
  };

  const confirmDeleteOpen = (e, partNumber) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteSelectedItem(partNumber);
    setConfirmDeleteIsOpen(true);
  };

  const confirmDeleteClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteSelectedItem(null);
    setConfirmDeleteIsOpen(false);
  };

  const confirmPartNumberQueueDeleteOpen = (e, queueItem) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteQueueSelectedItem(queueItem);
    setConfirmDeleteQueueIsOpen(true);
  };

  const confirmDeleteQueueClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDeleteQueueSelectedItem(null);
    setConfirmDeleteQueueIsOpen(false);
  };

  const getPartType = (partTypeId) => {
    const matchedPartType = _.find(partTypes, (p) => p.value === partTypeId);
    if (matchedPartType) return matchedPartType.text;
    return "";
  };

  const getSupplier = (supplierId) => {
    switch (supplierId) {
      case 1:
        return "DigiKey";
      case 2:
        return "Mouser";
      default:
        return "";
    }
  };

  const getSupplierFromSerializedDataType = (serializedDataType) => {
    switch (serializedDataType) {
      case "KeywordSearchResponse":
        return "DigiKey";
      case "SearchResultsResponse":
        return "Mouser";
      case "PartResults":
        return "Swarm Api";
      default:
        return "";
    }
  };

  const handleQueryDigiKey = (e, control) => {
    setLoadingAddPartNumber(true);
    setApiResponse(null);
    fetchApi(`api/partnumber/digikey?partNumber=${addPartNumber.name}&recordCount=50`, {
      method: "GET"
    }).then((response) => {
      const { data } = response;
      if (data.requiresAuthentication) {
        // redirect for authentication
        window.open(data.redirectUrl, "_blank");
        return;
      }
      setLoadingAddPartNumber(false);
      setApiResponse({
        ...apiResponse,
        serializedDataType: "KeywordSearchResponse",
        serializedData: data.response,
        resultCount: data.response.apiResponse.products.length
      });
      setSelectedPartsCount(data.response.apiResponse.products.length);
      setPreviewApiResponseModalOpen(true);
    });
  };

  const handleQueryMouser = (e, control) => {
    setLoadingAddPartNumber(true);
    setApiResponse(null);
    fetchApi(`api/partnumber/mouser?partNumber=${addPartNumber.name}&recordCount=50`, {
      method: "GET"
    }).then((response) => {
      const { data } = response;
      setLoadingAddPartNumber(false);
      setApiResponse({ ...apiResponse, serializedDataType: "SearchResultsResponse", serializedData: data.response, resultCount: data.response.apiResponse.searchResults.parts.length });
      setSelectedPartsCount(data.response.apiResponse.searchResults.parts.length);
      setPreviewApiResponseModalOpen(true);
    });
  };

  const doesPartExist = (existingParts, partName, manufacturerName) => {
    return _.find(existingParts, existingPartName => existingPartName.name === partName && existingPartName.manufacturerName === manufacturerName);
  };

  const renderPreviewApiResponse = () => {
    if (!apiResponse) return <Table.Row></Table.Row>;
    if (apiResponse.serializedDataType === "KeywordSearchResponse") {
        // digikey
        return _.map(apiResponse.serializedData.apiResponse.products, (item, key) => (
        <Table.Row key={key}>
          <Table.Cell>
            <input
              type="checkbox"
              defaultChecked={true}
              //defaultChecked={!doesPartExist(apiResponse.serializedData.existingPartNumbers, item.manufacturerPartNumber, item.manufacturer.value)}
              name="partChecked"
              value={`${item.manufacturerPartNumber}|${item.manufacturer.value}|${item.digiKeyPartNumber}`}
              onClick={() => setSelectedPartsCount(getSelectedParts().length)}
            />
          </Table.Cell>
          <Table.Cell>{doesPartExist(apiResponse.serializedData.existingPartNumbers, item.manufacturerPartNumber, item.manufacturer.value) ? (<Icon name="check circle" color="red" title="Already Exists" />) : (<Icon name="check circle" color="green" title="Part does not exist" />)}</Table.Cell>
          <Table.Cell>{item.manufacturerPartNumber}</Table.Cell>
          <Table.Cell>{item.manufacturer.value}</Table.Cell>
          <Table.Cell className="small">{item.detailedDescription}</Table.Cell>
          <Table.Cell className="small">{item.category.value}</Table.Cell>
          <Table.Cell className="small">{item.productStatus}</Table.Cell>
          <Table.Cell>{item.unitPrice}</Table.Cell>
          <Table.Cell>{item.quantityAvailable}</Table.Cell>
          <Table.Cell>
            <img src={item.primaryPhoto} alt="" className="product productshot" />
          </Table.Cell>
        </Table.Row>
      ));
    } else if (apiResponse.serializedDataType === "SearchResultsResponse") {
      // mouser
      return _.map(apiResponse.serializedData.apiResponse.searchResults.parts, (item, key) => (
        <Table.Row key={key}>
          <Table.Cell>
          <input
              type="checkbox"
              defaultChecked={true}
              //defaultChecked={!doesPartExist(apiResponse.serializedData.existingPartNumbers, item.manufacturerPartNumber, item.manufacturer)}
              name="partChecked"
              value={`${item.manufacturerPartNumber}|${item.manufacturer}|${item.mouserPartNumber}`}
              onClick={() => setSelectedPartsCount(getSelectedParts().length)}
            />
          </Table.Cell>
          <Table.Cell>{doesPartExist(apiResponse.serializedData.existingPartNumbers, item.manufacturerPartNumber, item.manufacturer) ? (<Icon name="check circle" color="red" title="Already Exists" />) : (<Icon name="check circle" color="green" title="Part does not exist" />)}</Table.Cell>
          <Table.Cell>{item.manufacturerPartNumber}</Table.Cell>
          <Table.Cell>{item.manufacturer}</Table.Cell>
          <Table.Cell className="small">{item.description}</Table.Cell>
          <Table.Cell className="small">{item.category}</Table.Cell>
          <Table.Cell className="small">{item.lifecycleStatus}</Table.Cell>
          <Table.Cell>{item.priceBreaks[0].price}</Table.Cell>
          <Table.Cell>{item.availabilityInteger}</Table.Cell>
          <Table.Cell>
            <img src={item.imagePath} alt="" className="product productshot" />
          </Table.Cell>
        </Table.Row>
      ));
    } else {
      <Table.Row>
        <Table.Cell>Unknown data type!</Table.Cell>
      </Table.Row>;
    }
  };

  const handleModalClose = () => {
    setPreviewApiResponseModalOpen(false);
  };

  const getMatchingItem = (originalParts, serializedDataType, keyPartNumber, keyManufacturer, keySupplierPartNumber) => {
    let result = null;
    if (apiResponse.serializedDataType === "KeywordSearchResponse")
      result = _.filter(originalParts, (p) => p.manufacturerPartNumber === keyPartNumber && p.manufacturer.value === keyManufacturer && p.digiKeyPartNumber === keySupplierPartNumber);
    else if (apiResponse.serializedDataType === "SearchResultsResponse")
      result = _.filter(originalParts, (p) => p.manufacturerPartNumber === keyPartNumber && p.manufacturer === keyManufacturer && p.mouserPartNumber === keySupplierPartNumber);
    
    // sanity check
    if (result.length > 1) console.error(`Parsing error: Expected to find 1 part returned, but received ${result.length} parts! Manufacturer: '${keyManufacturer}', Part: '${keyPartNumber}', Supplier Part: '${keySupplierPartNumber}' `);

    return _.first(result);
  };

  const handlePreviewApiResponseConfirm = () => {
    // filter only the selected parts
    setModalLoading(true);
    const selectedParts = getSelectedParts();
    let originalParts = [];
    let newParts = [];
    const serializedDataType = apiResponse.serializedDataType;
    if (serializedDataType === "KeywordSearchResponse") {
      originalParts = apiResponse.serializedData.apiResponse.products;
    } else if (serializedDataType === "SearchResultsResponse") {
      originalParts = apiResponse.serializedData.apiResponse.searchResults.parts;
    }

    for (let i = 0; i < selectedParts.length; i++) {
      const key = selectedParts[i].value.split('|');
      const keyPartNumber = key[0];
      const keyManufacturer = key[1];
      const keySupplierPartNumber = key[2];
      const item = getMatchingItem(originalParts, serializedDataType, keyPartNumber, keyManufacturer, keySupplierPartNumber);
      if (item) newParts.push(item);
    }
    let newSerializedData = {};
    if (serializedDataType === "KeywordSearchResponse") 
      newSerializedData = { ...apiResponse.serializedData.apiResponse, products: newParts };
    else if (serializedDataType === "SearchResultsResponse") 
      newSerializedData = { ...apiResponse.serializedData.apiResponse, searchResults: { parts: newParts }};
    
    // defer the stringify option a little bit because its cpu intensive
    setTimeout(() => { 
      const newSerializedDataJson = JSON.stringify(newSerializedData);
      setAddPartNumber({ ...addPartNumber, serializedDataType: apiResponse.serializedDataType, serializedData: newSerializedDataJson }); 
      setModalLoading(false);
      setPreviewApiResponseModalOpen(false);
    }, 500);
  };

  const headerRow = (
    <Table.Row>
      <Table.HeaderCell sorted={column === "name" ? direction : null} onClick={handleSort("name")}>
        Part Name
      </Table.HeaderCell>
      <Table.HeaderCell style={{ maxWidth: "250px" }} sorted={column === "description" ? direction : null} onClick={handleSort("description")}>
        Description
      </Table.HeaderCell>
      <Table.HeaderCell sorted={column === "source" ? direction : null} onClick={handleSort("source")}>
        Source
      </Table.HeaderCell>
      <Table.HeaderCell style={{ maxWidth: "130px" }} sorted={column === "dateAddedUtc" ? direction : null} onClick={handleSort("dateAddedUtc")}>
        Date Added
      </Table.HeaderCell>
      <Table.HeaderCell sorted={column === "alternateNames" ? direction : null} onClick={handleSort("alternateNames")}>
        Alternate Names
      </Table.HeaderCell>
      <Table.HeaderCell sorted={column === "partTypeId" ? direction : null} onClick={handleSort("partTypeId")}>
        Part Type
      </Table.HeaderCell>
      <Table.HeaderCell sorted={column === "datePrunedUtc" ? direction : null} onClick={handleSort("datePrunedUtc")}>
        Is Pruned
      </Table.HeaderCell>
      <Table.HeaderCell sorted={column === "createdBySupplierId" ? direction : null} onClick={handleSort("createdBySupplierId")}>
        Created Via
      </Table.HeaderCell>
      <Table.HeaderCell>Manufacturer Parts</Table.HeaderCell>
      <Table.HeaderCell>Image</Table.HeaderCell>
      <Table.HeaderCell></Table.HeaderCell>
    </Table.Row>
  );

  const selectAll = () => {
    if (selectAllRef.current) {
      selectNewPartsRef.current.checked = false;
      document.querySelectorAll("input[name=partChecked]").forEach(e => e.checked = selectAllRef.current.checked);
      setSelectedPartsCount(getSelectedParts().length);
    }
  };

  const selectNewParts = () => {
    if (selectNewPartsRef.current) {
      selectAllRef.current.checked = false;
      const partsChecked = document.querySelectorAll("input[name=partChecked]");
      for(let i = 0; i < partsChecked.length; i++){
        const parts = partsChecked[i].value.split('|');
        const partExists = doesPartExist(apiResponse.serializedData.existingPartNumbers, parts[0], parts[1]);
        if (partExists) 
          partsChecked[i].checked = false;
        else
          partsChecked[i].checked = selectNewPartsRef.current.checked;
      }
      setSelectedPartsCount(getSelectedParts().length);
    }
  };

  const getSelectedParts = () => {
    return document.querySelectorAll("input[name=partChecked]:checked");
  };

  const getPartNumberQueueState = (urlHistory) => {
    if (urlHistory.errorMessage !== null) {
      return "Error";
    }
    if (urlHistory.dateQueuedForProcessingUtc === null) {
      return "Queued";
    }
    if (urlHistory.processingElapsed === null) {
      return "Processing";
    } 
    return "Finished";
  };

  const getRowClassname = (partNumber) => {
    var state = getPartNumberQueueState(partNumber);
    switch (state) {
      case "Crawling":
        return "running";
      case "Error":
        return "error";
      case "Finished":
        return "complete";
      case "Queued":
      default:
        return "";
    }
  };

  const renderPartNumbers = (data, column, direction) => {
    return (
      <div>
        <Confirm
          open={confirmDeleteIsOpen}
          onCancel={confirmDeleteClose}
          onConfirm={(e) => deletePartNumber(e, deleteSelectedItem)}
          content="Are you sure you want to delete?"
        />
        <Modal centered open={previewApiResponseModalOpen} onClose={handleModalClose}>
          <Modal.Header>Api Response Preview</Modal.Header>
          <Modal.Content scrolling>
            <Modal.Description>
              <p>
                {apiResponse && apiResponse.resultCount} part(s) returned. {selectedPartsCount} part(s) selected.
              </p>
              <Segment loading={modalLoading}>
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell><input type="checkbox" defaultChecked name="selectall" ref={selectAllRef} onClick={selectAll} title="Select all" /></Table.HeaderCell>
                      <Table.HeaderCell><input type="checkbox" name="selectnew" ref={selectNewPartsRef} onClick={selectNewParts} title="Select only new parts" /></Table.HeaderCell>
                      <Table.HeaderCell>Part Number</Table.HeaderCell>
                      <Table.HeaderCell>Manufacturer</Table.HeaderCell>
                      <Table.HeaderCell>Description</Table.HeaderCell>
                      <Table.HeaderCell>Category</Table.HeaderCell>
                      <Table.HeaderCell>Status</Table.HeaderCell>
                      <Table.HeaderCell>Cost</Table.HeaderCell>
                      <Table.HeaderCell>Available</Table.HeaderCell>
                      <Table.HeaderCell>Image</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>{renderPreviewApiResponse()}</Table.Body>
                </Table>
              </Segment>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={handleModalClose}>Cancel</Button>
            <Button primary onClick={handlePreviewApiResponseConfirm}>
              OK
            </Button>
          </Modal.Actions>
        </Modal>
        <Segment loading={loading}>
          <InifiniteScrollTable
            id="partNumbersTable"
            compact
            celled
            sortable
            selectable
            striped
            unstackable
            size="small"
            headerRow={headerRow}
            nextPage={() => fetchNextPage()}
          >
            {data.map((partNumber, i) => (
              <Table.Row key={i} onClick={(e) => openPartNumber(e, partNumber)}>
                <Table.Cell title={partNumber.name}>{partNumber.name}</Table.Cell>
                <Table.Cell style={{ maxWidth: "250px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} title={partNumber.description}>
                  {partNumber.description}
                </Table.Cell>
                <Table.Cell style={{ maxWidth: "130px" }}>{GetTypeName(Sources, partNumber.source)}</Table.Cell>
                <Table.Cell>{getFormattedTime(partNumber.dateCreatedUtc)}</Table.Cell>
                <Table.Cell>{partNumber.alternateNames}</Table.Cell>
                <Table.Cell>{getPartType(partNumber.partTypeId)}</Table.Cell>
                <Table.Cell>{partNumber.datePrunedUtc === null ? "False" : "True"}</Table.Cell>
                <Table.Cell>{getSupplier(partNumber.createdFromSupplierId)}</Table.Cell>
                <Table.Cell>{partNumber.partNumberManufacturers && partNumber.partNumberManufacturers.length}</Table.Cell>
                <Table.Cell>{getDefaultProductShotImageFromPartNumber(partNumber, "product productshot")}</Table.Cell>
                <Table.Cell>
                  <Link onClick={(e) => confirmDeleteOpen(e, partNumber)} title="Delete"><Icon name="trash alternate" /></Link>
                </Table.Cell>
              </Table.Row>
            ))}
          </InifiniteScrollTable>
        </Segment>
      </div>
    );
  };

  const renderPartNumbersQueue = () => {
    return (
      <div>
        <Confirm
          open={confirmDeleteQueueIsOpen}
          onCancel={confirmDeleteQueueClose}
          onConfirm={(e) => deletePartNumberQueue(e, deleteQueueSelectedItem)}
          content="Are you sure you want to delete?"
        />
        <Segment loading={loadingPartNumbersQueue}>
          <Table compact celled sortable selectable striped unstackable size="small" className="queueTable">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Part Name</Table.HeaderCell>
                <Table.HeaderCell>Date Queued</Table.HeaderCell>
                <Table.HeaderCell>Date Processed</Table.HeaderCell>
                <Table.HeaderCell>Parts Created</Table.HeaderCell>
                <Table.HeaderCell>Source</Table.HeaderCell>
                <Table.HeaderCell>Format</Table.HeaderCell>
                <Table.HeaderCell>State</Table.HeaderCell>
                <Table.HeaderCell>Elapsed</Table.HeaderCell>
                <Table.HeaderCell>RetryCount</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {partNumbersQueue.map((queueItem, key) => (
                <Table.Row key={key} className={getRowClassname(queueItem)}>
                  <Table.Cell>{queueItem.name}</Table.Cell>
                  <Table.Cell>{queueItem.dateQueuedForProcessingUtc}</Table.Cell>
                  <Table.Cell>{queueItem.dateProcessedUtc}</Table.Cell>
                  <Table.Cell>{queueItem.partNumbersCreated}</Table.Cell>
                  <Table.Cell>{GetTypeName(Sources, queueItem.source)}</Table.Cell>
                  <Table.Cell>{getSupplierFromSerializedDataType(queueItem.serializedDataType)}</Table.Cell>
                  <Table.Cell title={queueItem.errorMessage}>{getPartNumberQueueState(queueItem)}</Table.Cell>
                  <Table.Cell style={{ maxWidth: "130px" }}>
                  {queueItem.processingElapsed === null && queueItem.dateQueuedForProcessingUtc !== null
                    ? getFriendlyElapsedTime(getTimeDifference(Date.now(), Date.parse(queueItem.dateQueuedForProcessingUtc)))
                    : getBasicElapsed(queueItem.processingElapsed)}
                  </Table.Cell>
                  <Table.Cell>{queueItem.retryCount}</Table.Cell>
                  <Table.Cell className="button-group">
                    <Link onClick={(e) => confirmPartNumberQueueDeleteOpen(e, queueItem)} title="Delete"><Icon name="trash alternate" /></Link>
                    <Link onClick={(e) => requeuePartNumber(e, queueItem)} title="Re-process"><Icon name="redo" /></Link>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment>
      </div>
    );
  };

  let contents = renderPartNumbers(partNumbersDataRef.current, column, direction);
  let queueContents = renderPartNumbersQueue();

  return (
    <div className="admin-container">
      <Breadcrumb>
        <Breadcrumb.Section href="/admin">Admin</Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section active>Part Numbers</Breadcrumb.Section>
      </Breadcrumb>
      <FormHeader name="Part Numbers Management" to="..">
        Manage the part numbers custom to Binner.
      </FormHeader>

      <Form onSubmit={(e) => queuePartNumber(e, addPartNumber)}>
        <Form.Group>
          <Button type="button" onClick={handleShowAdd} icon size="mini">
            <Icon name="file" /> Add Part Number
          </Button>
        </Form.Group>
        {addVisible && (
          <Segment secondary loading={loadingAddPartNumber}>
            <Popup
              hideOnScroll
              content="Specify the part name to add"
              trigger={
                <Form.Field width={10}>
                  <label>Part Name</label>
                  <Input action required className="labeled" placeholder="LM358" value={addPartNumber.name} onChange={handleChange} name="name" />
                </Form.Field>
              }
            />
            <Popup
              hideOnScroll
              content="Specify the optional description of the part. This will only apply to the root part number."
              trigger={
                <Form.Field width={10}>
                  <label>Description</label>
                  <Form.Input
                    control={TextArea}
                    className="labeled"
                    placeholder="The LM358 is a dual-operational amplifier"
                    value={addPartNumber.description}
                    onChange={handleChange}
                    name="description"
                  />
                </Form.Field>
              }
            />
            <Popup
              hideOnScroll
              content="Specify the serialized data type name. If you omit this, Binner will automatically look up the part."
              trigger={
                <Form.Field width={10}>
                  <label>Serialized Data Type</label>
                  <Input
                    action
                    className="labeled"
                    placeholder="PartResults"
                    value={addPartNumber.serializedDataType}
                    onChange={handleChange}
                    name="serializedDataType"
                    required
                  />
                </Form.Field>
              }
            />
            <Popup
              hideOnScroll
              content="Specify the optional json response from a PartResults message. If you omit this, Binner will automatically look up the part."
              trigger={
                <Form.Field width={10}>
                  <label>Serialized Data</label>
                  <Form.Input
                    control={TextArea}
                    className="labeled"
                    placeholder='{ "parts": [] }'
                    value={addPartNumber.serializedData}
                    onChange={handleChange}
                    name="serializedData"
                    required
                  />
                </Form.Field>
              }
            />
            {selectedPartsCount && selectedPartsCount > 0 ? (<p>{selectedPartsCount} part(s) selected.</p>) : ("")}

            <Form.Group>
              <Form.Button primary type="submit" icon>
                <Icon name="save" /> Add to Queue
              </Form.Button>
              <Form.Button type="button" icon onClick={handleQueryDigiKey}>
                <Icon name="paper plane" /> Query DigiKey
              </Form.Button>
              <Form.Button type="button" icon onClick={handleQueryMouser}>
                <Icon name="paper plane" /> Query Mouser
              </Form.Button>
            </Form.Group>
          </Segment>
        )}
      </Form>

      <Segment raised color="green" style={{marginBottom: '30px'}}>
        <h5>Part Numbers Processing Queue</h5>
        {queueContents}
      </Segment>

      <Segment raised color="blue">
        <h5>Part Numbers</h5>
        {contents}
      </Segment>
    </div>
  );
}
