import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { decodeJwt, getUserAccount } from "../../common/authentication";

export function Success(props) {
  const [successMessage, setSuccessMessage] = useState(null);
	const navigate = useNavigate();

  useEffect(() => {
    // see if the user needs to verify their email first
		const userAccount = getUserAccount();
		const jwt = decodeJwt(userAccount.accessToken);
		if (jwt.CanLogin === "True") {
			setSuccessMessage(<div>You're all set. Visit your <a href="/" onClick={(e) => { e.preventDefault(); navigate('/'); }}>dashboard</a></div>);
		} else {
			setSuccessMessage(<div><p>You will need to verify your email address before logging in.</p><p className="emphasis">Check your email for a link that will activate your account.</p></div>);
		}
  }, []);

  return (
    <div>
      {/* ***** Breadcrumb Area Start ***** */}
      {/*<div className="mosh-breadcumb-area">
        <div className="container h-100">
          <div className="row h-100 align-items-center">
            <div className="col-12">
              <div className="bradcumbContent">
                <h2>Subscription</h2>
              </div>
            </div>
          </div>
        </div>
      </div>*/}
      {/* ***** Breadcrumb Area End ***** */}

      <div className="centered" style={{minHeight: '200px', marginTop: '100px'}}>
        <h1>Your subscription was succeessfully added.</h1>

        <div style={{fontSize: '1.5em'}}>
          {successMessage}
        </div>
      </div>
    </div>
  );
}
