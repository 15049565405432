import React, { useState, useEffect, useCallback } from "react";
import { Segment, Table, Grid, Button, Icon } from "semantic-ui-react";
import { fetchApi } from "../common/fetchApi";
import { getTimeDifference } from "../common/datetime";
import { toast } from "react-toastify";

export function CrawlerStatistics(props) {
	const updateInterval = 2000;
  const [statistics, setStatistics] = useState({});

  const fetchStatistics = useCallback(() => {
    fetchApi("api/crawler/stats").then((response) => {
      const { data } = response;
      if (data) {
        setStatistics(data);
      }
    });
  });

  useEffect(() => {
    fetchStatistics();
    const interval = setInterval(fetchStatistics, updateInterval);
    return function cleanup() {
      // clear the interval timer
      clearInterval(interval);
    };
  }, []);

  const getServiceStatusColor = (lastUpdated) => {
    const status = getServiceStatus(lastUpdated);
    switch (status) {
      case "Online":
        return "green";
      default:
        return "red";
    }
  };

  const getServiceStatus = (lastUpdated) => {
    const diffMs = getTimeDifference(Date.parse(lastUpdated), Date.now());
    if (diffMs > 10 * 1000) {
      return "Offline";
    }
    return "Online";
  };

  const shutdownService = (e, serviceName) => {
    e.preventDefault();
    e.stopPropagation();
    let serviceId = 0;
    switch(serviceName) {
      case 'Crawler':
        serviceId = 2;
        break;
      case 'Swarm':
        serviceId = 3;
        break;
      default:
        break;
    }
    if (serviceId > 0) {
      const request = {
        service: serviceId,
        message: 'Shutdown request via Web Admin'
      };
      fetchApi("api/crawler/shutdown", {
        method: "POST",
        body: JSON.stringify(request)
      }).then((response) => {
        if (response.data.result)
          toast.success(`Shutdown of service '${serviceName}' successful.`);
        else
          toast.error(`Failed to shutdown service '${serviceName}'!`);
      }).catch((error) => {
        toast.error(`Error trying to shutdown service '${serviceName}'!`);
        console.error(error);
      });
    }
  };

  return (
    <Segment secondary>
      <Segment.Group horizontal>
        <Segment inverted color={getServiceStatusColor(statistics.crawlerLastUpdatedUtc)} style={{ textAlign: "center" }}>
          Crawler Service {getServiceStatus(statistics.crawlerLastUpdatedUtc)}
        </Segment>
        <Segment inverted color={getServiceStatusColor(statistics.pdfProcessorLastUpdatedUtc)} style={{ textAlign: "center" }}>
          Swarm Service {getServiceStatus(statistics.pdfProcessorLastUpdatedUtc)}
        </Segment>
      </Segment.Group>
      <Segment>
        <Grid columns='three' divided>
          <Grid.Row>
            <Grid.Column>
              <h6>Crawler</h6>
              <Button onClick={e => shutdownService(e, 'Crawler')} size="tiny" color="red"><Icon name="power off" /> Shutdown</Button>
              <Table>
                <Table.Body style={{fontSize: '0.8em'}}>
									<Table.Row>
                    <Table.Cell>URLs being crawled</Table.Cell>
                    <Table.Cell><b>{statistics.currentUrlsCrawledCount}</b></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>URLs pending</Table.Cell>
                    <Table.Cell>{statistics.currentUrlsPendingProcessingCount}</Table.Cell>
                  </Table.Row>
									<Table.Row>
                    <Table.Cell>Total URLs (1 week)</Table.Cell>
                    <Table.Cell>{statistics.urlsPerWeek}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Total URLs (24 hours)</Table.Cell>
                    <Table.Cell>{statistics.urlsPerDay}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Total URLs (last hour)</Table.Cell>
                    <Table.Cell>{statistics.urlsPerHour}</Table.Cell>
                  </Table.Row>
									<Table.Row>
                    <Table.Cell>PDFs Found (1 week)</Table.Cell>
                    <Table.Cell>{statistics.pdfsFoundPerWeek}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>PDFs Found (24 hours)</Table.Cell>
                    <Table.Cell>{statistics.pdfsFoundPerDay}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>PDFs Found (last hour)</Table.Cell>
                    <Table.Cell>{statistics.pdfsFoundPerHour}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
            <Grid.Column>
							<h6>Swarm</h6>
              <Button onClick={e => shutdownService(e, 'Swarm')} size="tiny" color="red"><Icon name="power off" /> Shutdown</Button>
              <Table>
                <Table.Body style={{fontSize: '0.8em'}}>
									<Table.Row>
                    <Table.Cell>PDFs being processed</Table.Cell>
                    <Table.Cell><b>{statistics.currentPdfsProcessingCount}</b></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>PDFs pending</Table.Cell>
                    <Table.Cell>{statistics.currentPdfsPendingProcessingCount}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>PDFs Processed (1 week)</Table.Cell>
                    <Table.Cell>{statistics.pdfsProcessedPerWeek}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>PDFs Processed (24 hours)</Table.Cell>
                    <Table.Cell>{statistics.pdfsProcessedPerDay}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>PDFs Processed (last hour)</Table.Cell>
                    <Table.Cell>{statistics.pdfsProcessedPerHour}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
						</Grid.Column>
						<Grid.Column>
							<h6>Part Number Processing</h6>
              <Table>
                <Table.Body style={{fontSize: '0.8em'}}>
									<Table.Row>
                    <Table.Cell>Parts being processed</Table.Cell>
                    <Table.Cell><b>{statistics.currentPartNumbersProcessingCount}</b></Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Parts pending</Table.Cell>
                    <Table.Cell>{statistics.currentPartNumbersPendingProcessingCount}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Parts Processed (1 week)</Table.Cell>
                    <Table.Cell>{statistics.partNumbersProcessedPerWeek}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Parts Processed (24 hours)</Table.Cell>
                    <Table.Cell>{statistics.partNumbersProcessedPerDay}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Parts Processed (last hour)</Table.Cell>
                    <Table.Cell>{statistics.partNumbersProcessedPerHour}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
						</Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Segment>
  );
}
